export const PatientProfileStyles = {
    container: {
        mb: { sm: '10px' }
    },
    avatar: {
        borderRadius: '100px',
        objectFit: 'cover'
    }
}

export const PendingProceduresStyles = {
    titleContainer: {
        padding: '15px 0 0 10px'
    }
}

export const ConflictScheduleStyles = {
    container: {
        padding: '20px 20px 0 20px',
    },
    conflictText: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: 'center'
    },
    docConflictContainer: {
        p: 0,
        m: 0,
        mt: 1
    }
}