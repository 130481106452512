import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ labels, value, legend }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartCanvas = chartRef.current.getContext("2d");
    const chart = new Chart(chartCanvas, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: legend,
            data: value,
            fill: false,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgb(255, 99, 132)",
            tension: 0.1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
          labels: {
            text: {
              display: false,
            },
            padding: 10, // Set the padding of the legend label to 10 pixels
            usePointStyle: false, // Use point style for the legend label
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        // height: 500, // Set the height of the chart to 500 pixels
        // width: 800, // Set the width of the chart to 800 pixels
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
    chart.canvas.parentNode.style.height = "350px";
    return () => chart.destroy();
  }, [labels, value]);

  return (
    <div className="height:40vh; width:80vw">
      <canvas ref={chartRef} />
    </div>
  );
};

export default LineChart;
