import { Avatar, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { sidebarStore } from "../Redux/sideNavigationSlice";
import useIsTablet from "../../../../Utils/UseIsTablet";

export default function UserProfile({ user, onClick }) {
    const { sidebarOpened } = useSelector(sidebarStore);
    const { isTablet } = useIsTablet();
    let nbsp = "\u00A0";

    const getUserFullName = () => {
        let userFullName = '';
        if (user?.type === 'staff') {
            userFullName = user?.name;
        }
        else {
            userFullName = `Dr. ` +
                user?.doctor_information?.firstname +
                nbsp +
                (user?.doctor_information?.middlename
                    ? user?.doctor_information?.middlename + nbsp
                    : "") +
                user?.doctor_information?.lastname +
                nbsp +
                (user?.doctor_information?.suffix
                    ? user?.doctor_information?.suffix
                    : "");
        }

        return userFullName;
    }

    return (
        <>
            <Stack direction={'column'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    maxWidth: '225px'
                }}>
                <Avatar
                    src={user?.avatar}
                    // onClick={!isTablet && !sidebarOpened ? onClick : null}
                    sx={{
                        // width: isTablet ? 120 : (sidebarOpened ? 120 : 40),
                        // height: isTablet ? 120 : (sidebarOpened ? 120 : 40),
                        width: 120,
                        height: 120,
                        // mb: isTablet ? 2 : (sidebarOpened ? 2 : 0),
                        mb: 2,
                        ml: 0,
                        transition: "all 300ms ease-in-out",
                        // cursor: !isTablet && !sidebarOpened ? 'pointer' : 'auto'
                    }}
                />
                {/* {(isTablet || sidebarOpened) && <Typography
                    className={`sidebar-user-name ${!isTablet ? (!sidebarOpened ? 'hide' : 'show') : ''}`}
                    onClick={onClick}
                    textAlign={'center'} >
                    {getUserFullName()}
                </Typography>} */}
                <Typography
                    className={`sidebar-user-name`}
                    onClick={onClick}
                    textAlign={'center'} >
                    {getUserFullName()}
                </Typography>
            </Stack>
        </>
    );
}