import { createSlice, current } from "@reduxjs/toolkit";
import AppointmentUtils from "./AppointmentUtils";
const schedulePatientDefault = {
  visible: false,
  patient: {},
  isReschedule: false,
};

const initialState = {
  requestStatus: {
    appointment: false,
  },
  schedulePatient: schedulePatientDefault,
  rawAppointments: [],
  patients: [],
  recentlyCreatedPatient: {},
  selectedPatient: {},
  calendarEvents: [],
  isOpen: false,
  procedures: [],
  pusherEvents: {
    addAppointmentEvent: false,
    //No show and Cancel Appointment
    editAppointmentEvent: false,
    //Reschedule Appointment
    editAppointmentProcEvent: false,
    //Adding Appointment Procedure 
    addAppointmentProcEvent: false,
    editClinicEvent: false,
    addInvoiceEvent: false,
  }
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setRequestStatus: (state, action) => {
      const payload = action.payload;
      state.requestStatus = {
        ...state.requestStatus,
        [payload.request]: payload.status,
      };
    },
    setAppointments: (state, action) => {
      const appointments = action.payload;
      state.rawAppointments = appointments;
      state.calendarEvents = AppointmentUtils.mapToEvents(action.payload);
    },
    appendAppointment: (state, action) => {
      const appointment = action.payload;
      if (!state.rawAppointments.find(f => f.id === appointment.id)) {
        const mappedEvent = AppointmentUtils.mapToEvent(action.payload);
        state.rawAppointments = state.rawAppointments.concat([]);
        state.calendarEvents = state.calendarEvents.concat([mappedEvent]);
      }
    },
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    addPatient: (state, action) => {
      state.patients = state.patients.concat([action.payload]);
      state.recentlyCreatedPatient = action.payload;
    },
    updateAppointmentRecords: (state, action) => {
      const payload = action.payload;
      //update patient
      const record = AppointmentUtils.updateRecords({
        patients: [...current(state.patients)],
        events: [...current(state.calendarEvents)],
        appointments: [...current(state.rawAppointments)],
        patient: payload,
      });
      state.patients = record.patients;
      state.calendarEvents = record.events;
      state.rawAppointments = record.appointments;
      const selectedPatient = new AppointmentUtils(
        record.appointments,
        state.patients
      ).getAppointment(payload.id, true);
      state.selectedPatient = selectedPatient;
    },
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
    setSchedulingPatient: (state, action) => {
      state.schedulePatient = {
        ...state.schedulePatient,
        ...action.payload,
      };
    },
    clearSchedulingPatient: (state, _) => {
      state.schedulePatient = schedulePatientDefault;
    },
    clearAppointmentModal: (state, _) => {
      state.recentlyCreatedPatient = {};
    },
    rescheduleAppointments: (state, action) => {
      const appointmentPayload = action.payload;
      const records = AppointmentUtils.updateScheduleRecords({
        appointments: [...current(state.rawAppointments)],
        events: [...current(state.calendarEvents)],
        payload: appointmentPayload,
      });

      state.rawAppointments = records.appointments;
      state.calendarEvents = records.events;
    },
    updateAppointmentStatus: (state, action) => {
      const appointmentPayload = action.payload;
      const records = AppointmentUtils.updateAppointmentStatus({
        appointments: [...current(state.rawAppointments)],
        events: [...current(state.calendarEvents)],
        payload: appointmentPayload,
      });

      state.rawAppointments = records.appointments;
      state.calendarEvents = records.events;
    },

    openCreateInvoice: (state, action) => {
      state.isOpen = true;
    },
    closeCreateInvoice: (state, action) => {
      state.isOpen = false;
    },
    setAppointmentProcedures: (state, action) => {
      const procedures = action.payload;
      state.procedures = procedures;
    },
    addAppointmentProcedures: (state, action) => {
      const procedures = action.payload;
      state.procedures = AppointmentUtils.addAppointmentProcedures(state.procedures, procedures);
    },
    updateAppointmentProcedures: (state, action) => {
      if (action.payload) {
        state.procedures = AppointmentUtils.updateAppointmentProcedures(state.procedures, action.payload);
      }
    },
    setAppointmentPusherEvent: (state, action) => {
      const payload = action.payload;
      state.pusherEvents = AppointmentUtils.setAppointmentPusherEvent(state.pusherEvents, payload.event, payload.isTrigger);
    },
  },
});

export const appointmentStore = (state) => state.appointment;

export const {
  setRequestStatus,
  setAppointments,
  appendAppointment,
  setPatients,
  addPatient,
  updateAppointmentRecords,
  setSelectedPatient,
  setSchedulingPatient,
  clearSchedulingPatient,
  clearAppointmentModal,
  rescheduleAppointments,
  updateAppointmentStatus,
  openCreateInvoice,
  closeCreateInvoice,
  setAppointmentProcedures,
  addAppointmentProcedures,
  updateAppointmentProcedures,
  setAppointmentPusherEvent
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
