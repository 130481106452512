import React from "react";
import HeaderBar from "./header";
import SideNavigationBar from "./navbar";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useIsTablet from "../../Utils/UseIsTablet";
import { setSidebarVisibility } from "../../Container/SideNavigationBar.js/Redux/sideNavigationSlice";
import { sidebarStore } from "./navbar/Redux/sideNavigationSlice";

export default function DashboardLayout() {
    const { sidebarOpened } = useSelector(sidebarStore);
    const { isTablet } = useIsTablet();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setSidebarVisibility(!isTablet));
        // eslint-disable-next-line
    }, [isTablet]);
    return (
        <>
            <SideNavigationBar />
            <HeaderBar />
            <Container
                component={`main`}
                maxWidth={`sx`}
                sx={{
                    paddingTop: "65px",
                    paddingRight: { xs: 0, sm: 0, md: 0},
                    paddingBottom: "0",
                    // paddingLeft: { xs: 0, sm: 0, md: !sidebarOpened ? (isTablet ? 0 : '68px') : "200px" },
                    paddingLeft: { xs: 0, sm: 0, md: !sidebarOpened ? 0 : "225px" },
                    height: "100vh",
                    transition: 'all 300ms ease-in-out'
                }}
            >
                <Box id="content-container" padding={'20px'}>
                    <Outlet />
                </Box>
            </Container>
        </>
    )
}