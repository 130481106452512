import { numberWithCommas } from "../../../../../../../Utils/utils";

export default function PricingHeader({ title, monthly, price, priceDiscount }) {
    priceDiscount = numberWithCommas(priceDiscount, false);

    const getCurrency = () => {
        let priceStr = '';
        if (typeof price === 'number') priceStr = price.toString();
        else if (typeof price === 'string') priceStr = price;

        if (priceStr) {
            if (priceStr.includes('.')) return numberWithCommas(parseInt(priceStr.substring(0, priceStr.indexOf("."))), false);
            else return numberWithCommas(parseInt(priceStr), false);
        }
        else return '0';
    }

    const getCent = () => {
        let priceStr = '';
        if (typeof price === 'number') priceStr = price.toString();
        else if (typeof price === 'string') priceStr = price;

        if (priceStr) {
            if (priceStr.includes('.')) return parseInt(priceStr.substring(priceStr.indexOf(".") + 1, priceStr.length)).toLocaleString();
            else return '00';
        }
        else return '0';
    }

    return (
        <div className="generic_head_price clearfix">
            <div className="generic_head_content">
                <div className="head_bg"></div>
                <div className="head">
                    <span>{title}</span>
                </div>
            </div>
            <div className="generic_price_tag clearfix">
                <span className="price">
                    <span className="sign">₱</span>
                    <span className="currency">{getCurrency()}</span>
                    <span className="cent">.{getCent()}</span>
                    <span className="month">/{monthly ? 'MON' : 'YR'}</span>
                </span>
            </div>
            {priceDiscount !== 0 && !monthly && <div className="generic_price_discount clearfix">
                ₱ {priceDiscount} off
            </div>}
            {priceDiscount !== 0 && monthly && <div className="generic_price_discount clearfix" style={{color: 'red', fontStyle: 'italic'}}>
                ₱ {priceDiscount} Billed <span style={{color: 'black'}}>Quarterly</span>
            </div>}
        </div>
    )
}