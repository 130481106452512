import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateExtractDateTimeLocale, numberWithCommas } from "../../Utils/utils";

const PaymentHistory = ({ invoicePaymentHistory }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 650, overflow: `auto` }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Date
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Amount
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Balance
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicePaymentHistory.map((app, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {DateExtractDateTimeLocale(app.created_at)}
                </TableCell>
                <TableCell align="right">
                  Php {numberWithCommas(app.amount)}
                </TableCell>
                <TableCell align="right">
                  {numberWithCommas(app.balance)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentHistory;
