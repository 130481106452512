import { useState } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent
  } from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import { useDispatch, useSelector } from "react-redux";
import { setIsDownloadSOAModalOpen } from "../../Redux/settingsSlice";

export default function DownloadSOAPdfModal(){

    const dispatch = useDispatch();

    const { isDownloadSOAModalOpen, clinicSettingInfo } = useSelector(store => store.settings)

    const [payload, setPayload] = useState({
      clinic_id: clinicSettingInfo?.id,
      start_date: moment().format("yyyy-MM-DD"),
      end_date: moment().format("yyyy-MM-DD")
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
  
    const handleDatePayload = (e, _) => {
      switch (_) {
        case 'start_date':
          setPayload({
            ...payload,
            start_date: moment(e.$d).format("yyyy-MM-DD")
          })
          break;
        case 'end_date':
          setPayload({
            ...payload,
            end_date: moment(e.$d).format("yyyy-MM-DD")
          })
          break;
        default:
          break;
      }
    }
  
    const handleDownloadSoaPdf = async () => {
      setIsSubmitting(true)
      await axios.get(`/soa-pdf?clinic_id=${payload?.clinic_id}&start_date=${payload?.start_date}&end_date=${payload?.end_date}`).then(response => {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.target = '_blank';
        link.download = response.data.filename;
        link.click();
  
        dispatch(setIsDownloadSOAModalOpen(false))
      }).catch(error => {
        Swal.fire({
          icon: "error",
          title: "Unable to download!",
        }).then(() => {
          setIsSubmitting(false)
          dispatch(setIsDownloadSOAModalOpen(false))
        })
      });
  
      setIsSubmitting(false)
    }
  
    return (
      <Dialog
        open={isDownloadSOAModalOpen}
        onClose={() => dispatch(setIsDownloadSOAModalOpen(false))}
      >
        <DialogTitle>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Typography variant="h6">Download SOA PDF</Typography>
            <CloseIcon onClick={() => dispatch(setIsDownloadSOAModalOpen(false))} sx={{ cursor: 'pointer' }} />
          </Stack>
        </DialogTitle>
  
        <DialogContent>
          <Box sx={{ px: 2, py: 2 }}>
            <Stack direction={`column`} spacing={2}>
              <DatePicker
                label={`Start Date`}
                onChange={(e) => handleDatePayload(e, 'start_date')}
                defau
                name="start_date"
                slotProps={{
                  textField: {
                    size: "medium",
                  },
                }}
              />
  
              <DatePicker
                label={`End Date`}
                onChange={(e) => handleDatePayload(e, 'end_date')}
                name="end_date"
                slotProps={{
                  textField: {
                    size: "medium",
                  },
                }}
              />
  
              <LoadingButton
                variant="contained"
                startIcon={<FileDownloadIcon />}
                loading={isSubmitting}
                onClick={() => handleDownloadSoaPdf()}
              >
                Download File
              </LoadingButton>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }