import { useLocation } from "react-router-dom";

import LoginPage from "../Pages/Auth/Login";
import RegisterClinic from "../Pages/Auth/Register";
import DoctorRegistration from "../Pages/Auth/Register/doctor-registration";
import DoctorSpecialization from "../Pages/Auth/Register/doctor-specialization";
import ForgotPassword from "../Pages/Auth/Forgot-Password";
import ResetPassword from "../Pages/Auth/Reset-Password";


const GuestLayout = () => {

    const { pathname } = useLocation();

    const isLogin = pathname === "/auth/login";
    const isForgotPassword = pathname === `/forgot-password`;
    const isResetPassword = pathname === `/forgot-password/${pathname.split("/")[3]}`;
    const isRegisterClinic = pathname === "/auth/register-clinic";
    const isRegisterDoctor = pathname === `/auth/register-doctor/${pathname.split("/")[3]}`;
    const isRegisterDoctorSpecialization = pathname === `/auth/register-doctor-specialization/${pathname.split("/")[3]}`;

    return (
        <>
            { isLogin && <LoginPage /> }
            { isForgotPassword && <ForgotPassword /> }
            { isResetPassword && <ResetPassword /> }
            { isRegisterClinic && <RegisterClinic /> }
            { isRegisterDoctor && <DoctorRegistration /> }
            { isRegisterDoctorSpecialization && <DoctorSpecialization /> }
        </>
    )
}

export default GuestLayout