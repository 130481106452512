import React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance as axios } from "../../../../../../../Utils/AxiosHttp";
import { debounce } from "../../../../../../../Utils/utils";
import Swal from "sweetalert2";
import { Stack } from "@mui/system";
import ImageAttachment from "./ImageAttachment";
import { updateAppointmentProcedures } from "../../../../../Redux/appointmentSlice";
import { getCurrentLoggedInUser } from "../../../../../../../Utils/authStored";
export default function Procedure({ data }) {
  const dispatch = useDispatch();
  const alignRight = { display: "flex", justifyContent: "flex-end" };
  const { selectedPatient } = useSelector((store) => store.appointment);
  const [procedure, setProcedure] = React.useState({});
  const [checked, setChecked] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(true);
  const procedureStore = React.useRef();
  const controller = React.useRef();
  const user = getCurrentLoggedInUser();
  React.useEffect(() => {
    initProcedure();

    return () => {
      dispatch(updateAppointmentProcedures(procedureStore.current));
      if (controller.current && !controller.current.signal.aborted) {
        controller.current.abort();
      }
    };
    // eslint-disable-next-line
  }, []);

  const initProcedure = async () => {
    controller.current = new AbortController();
    setIsFetching(true);
    setChecked(data?.implemented === 1 || data?.implemented === true);
    try {
      const procAttachmentRes = await axios.get(`attachments`, {
        params: {
          entity_id: data.patient_procedure_id,
          entity: "patient_procedures",
        },
        signal: controller.current.signal,
      });

      if (procAttachmentRes.status === 200) {
        data.attachments = procAttachmentRes.data;
      }
    } catch (error) {
      if (!controller.current.signal.aborted) {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch procedure attachments!",
          text: "Try again.",
        });
      }
    }
    setProcedure(data);
    procedureStore.current = data;
    setIsFetching(false);
  };

  const switchHandler = async (event) => {
    const checked = event.target.checked;
    setChecked(checked);
    const modifiedProc = {
      ...procedure,
      implemented: checked,
    };
    try {
      await axios.put(`appointment_procedures/${data.id}`, {
        implemented: checked,
      });
      setProcedure(modifiedProc);
      procedureStore.current = modifiedProc;
    } catch (_) {
      Swal.fire({
        icon: "error",
        title: "Failed to update procedure status!",
      });
    }
  };

  const onChangeRemark = React.useCallback(
    (args) => {
      const remarks = args.target.value;
      const modifiedProc = {
        ...procedure,
        remarks: remarks,
      };

      setProcedure(modifiedProc);
      debounce(async () => {
        try {
          await axios.put(`patient_procedures/${data.patient_procedure_id}`, {
            remarks: remarks,
          });
          procedureStore.current = modifiedProc;
        } catch (_) {
          await Swal.fire({
            icon: "error",
            title: "Failed to update procedure notes!",
          });
          setProcedure(procedureStore.current);
        }
      }, 1000);
      // eslint-disable-next-line
    },
    [procedure]
  );

  const fileChangeHandler = (e) => {
    const uniqueId = "attachment" + Math.random().toString(16).slice(-4);
    const file = e.target.files[0];
    const hasAttachments = procedure?.attachments?.length > 0;
    const payload = [
      {
        id: uniqueId,
        entity: "patient_procedures",
        entity_id: data.patient_procedure_id,
        file: file,
        size: "large",
        title: null,
      },
    ];

    const proc = {
      ...procedure,
      attachments: hasAttachments
        ? procedure.attachments.concat(payload)
        : payload,
    };
    setProcedure(proc);
    procedureStore.current = proc;
  };

  const handleOnDelete = (args) => {
    const proc = {
      ...procedure,
      attachments: procedure.attachments.filter((f) => f.id !== args.id),
    };
    setProcedure(proc);
    procedureStore.current = proc;
  };

  return (
    <div style={{ position: "relative" }}>
      {isFetching && (
        <Box
          position={"absolute"}
          top={"5px"}
          left={0}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
          zIndex={2}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Typography
            variant="subtitle1"
            component="div"
            fontWeight={"bold"}
            color={"black"}
          >
            {procedure.description}
          </Typography>
        </Grid>
        <Grid xs={6} item style={alignRight} sx={{ pr: "5px" }}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={switchHandler}
                disabled={selectedPatient?.isDone || user?.type !== "doctor"}
              />
            }
            label={checked ? "Performed" : "Unperformed"}
            labelPlacement="start"
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            label="General Notes"
            value={procedure?.remarks ?? ""}
            multiline
            rows={3}
            onChange={onChangeRemark}
            fullWidth={true}
            // disabled={selectedPatient?.isDone}
            variant="filled"
          />
        </Grid>
        <Grid xs={12} item style={alignRight}>
          <input
            id="fileUpload"
            type="file"
            hidden
            accept="image/jpeg"
            onChange={fileChangeHandler}
          />
          <Button
            variant="contained"
            // disabled={selectedPatient?.isDone}
            onClick={() => {
              const el = document.getElementById("fileUpload");
              if (el) el.click();
            }}
          >
            Upload Photo (File Upload)
          </Button>
        </Grid>
        {procedure?.attachments?.length > 0 && (
          <Grid xs={12} item>
            <Stack direction={"column"} spacing={2}>
              <Typography variant="h5">Attachments</Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {procedure.attachments.map((attachment) => (
                  <ImageAttachment
                    key={attachment.id}
                    attachment={attachment}
                    onDelete={handleOnDelete}
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
