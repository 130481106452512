import React, { useState } from "react";
import { lightBlue } from "@mui/material/colors";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Stack,
  Avatar,
  Typography,
  Paper,
} from "@mui/material";
import {
  showPatientInfo,
  editPatientInfo,
} from "../../Pages/Patients/Redux/patientSlice";
import { useDispatch } from "react-redux";
import PatientInformationModal from "../Modals/PatienInformationModal";
import PatientRecordModal from "../Modals/PatientRecordModal";

const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

// const iconColor = {
//   color: "#d32f2f",
// };
const PatientsTableV2 = ({ clinicPatients }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 780 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={tableHeader}>
                Patients
              </TableCell>
              <TableCell align="left" sx={tableHeader}>
                Last Visited
              </TableCell>
              <TableCell align="center" sx={tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clinicPatients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((app, index) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    onClick={() => dispatch(showPatientInfo(app))}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      <Stack
                        direction={`row`}
                        justifyContent={"left"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Avatar
                          src={app?.avatar}
                          sx={{ bgcolor: lightBlue[500] }}
                        />
                        <Typography variant="body2">
                          {app?.lastname + ", " + app?.firstname}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell
                      onClick={() => dispatch(editPatientInfo(app?.id))}
                    >
                      <Typography variant="body2">{app?.last_visit}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Stack
                        direction={`row`}
                        justifyContent={"center"}
                        spacing={2}
                      ></Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={clinicPatients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <PatientInformationModal />
      <PatientRecordModal />
      {/* <EditPatientModal /> */}
    </>
  );
};

export default PatientsTableV2;
