import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import ImageAttachment from "../../../../../../Pages/Appointments/Components/Dialog/AppointmentDetails/Components/Procedures/ImageAttachment";
import { debounce } from "../../../../../../Utils/utils";
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import { axiosInstance as axios } from "../../../../../../Utils/AxiosHttp";

const ProcedureNotesModal = React.forwardRef((_, ref) => {
    const [open, setOpen] = React.useState(false);
    const [procedure, setProcedure] = React.useState({});
    const [attachments, setAttachments] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false);

    const procedureStore = React.useRef();
    const controller = React.useRef();

    // const isDone = procedure?.status === 'done';

    React.useImperativeHandle(ref, () => {
        return {
            show(procedure) {
                setOpen(true);
                getAttachmentsReq(procedure);
            }
        }
    });

    const getAttachmentsReq = async (procedure) => {
        setIsFetching(true);
        controller.current = new AbortController();
        try {
            const [attachmentReq, procedureReq] = await Promise.all([
                await axios.get('attachments', {
                    params: {
                        entity_id: procedure.id,
                        entity: "patient_procedures",
                    },
                    signal: controller.current.signal,
                }),
                await axios.get(`patient_procedures?id=${procedure.id}`, {
                    signal: controller.current.signal,
                })
            ]);

            setAttachments(attachmentReq.data);
            const proc = procedureReq.data[0];
            setProcedure(proc);
            procedureStore.current = proc;
        } catch (error) {
            if (!controller.current.signal.aborted) {
                Swal.fire({
                    icon: "error",
                    title: "Failed to fetch procedure attachments!",
                    text: "Try again.",
                });
            }
        }

        setIsFetching(false);
    }

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
            if (controller.current && !controller.current.signal.aborted) {
                controller.current.abort();
            }

            setTimeout(() => {
                setProcedure({});
                setAttachments([]);
            }, 200);
        }
    }

    const onChangeRemark = React.useCallback(
        (args) => {
            const remarks = args.target.value;
            const modifiedProc = {
                ...procedure,
                remarks: remarks,
            };

            setProcedure(modifiedProc);
            debounce(async () => {
                try {
                    await axios.put(`patient_procedures/${procedure.id}`, {
                        remarks: remarks,
                    });
                    procedureStore.current = modifiedProc;
                } catch (_) {
                    await Swal.fire({
                        icon: "error",
                        title: "Failed to update procedure notes!",
                    });
                    setProcedure(procedureStore.current);
                }
            }, 1000);
            // eslint-disable-next-line
        },
        [procedure]
    );

    const fileChangeHandler = (e) => {
        const uniqueId = "attachment" + Math.random().toString(16).slice(-4);
        const file = e.target.files[0];
        const payload = [
            {
                id: uniqueId,
                entity: "patient_procedures",
                entity_id: procedure.id,
                file: file,
                size: "large",
                title: null,
            },
        ];

        setAttachments(prev => prev.concat(payload));
    };

    const handleOnDelete = (args) => {
        setAttachments(prev => prev.filter((f) => f.id !== args.id));
    };

    return (
        <Dialog
            open={open}
            disableScrollLock={true}
            fullWidth
            maxWidth={'md'}
            onClose={handleClose}>
            <DialogTitle variant="h5">
                Procedure Notes
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {isFetching && <Box display={"flex"} justifyContent={"center"}>
                    <CircularProgress />
                </Box>}
                {!isFetching && <Stack direction={'column'} spacing={2}>
                    <TextField
                        label="Notes"
                        value={procedure?.remarks ?? ""}
                        multiline
                        rows={3}
                        onChange={onChangeRemark}
                        fullWidth={true}
                        // disabled={isDone}
                        variant="filled"
                    />
                    <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        <input
                            id="fileUpload"
                            type="file"
                            hidden
                            accept="image/jpeg"
                            onChange={fileChangeHandler}
                        />
                        <Button
                            variant="contained"
                            // disabled={isDone}
                            onClick={() => {
                                const el = document.getElementById("fileUpload");
                                if (el) el.click();
                            }}
                        >
                            Upload Photo (File Upload)
                        </Button>
                    </Box>
                    {attachments.length > 0 && <Stack direction={"column"} spacing={2}>
                        <Typography variant="h5">Attachments</Typography>
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            {attachments.map((attachment) => (
                                <ImageAttachment
                                    key={attachment.id}
                                    attachment={attachment}
                                    onDelete={handleOnDelete}
                                />
                            ))}
                        </Stack>
                    </Stack>}
                </Stack>}
            </DialogContent>
        </Dialog>
    );
});

export default ProcedureNotesModal;