import React from "react";
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { CalendarViews } from "../Redux/AppointmentUtils";
import ScheduleHeader from "./ScheduleHeader";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const CalendarButton = (props) => (
  <Button
    {...props}
    variant="contained"
    color="primary"
    style={{ fontWeight: "bold", color: "white" }}
  >
    {props.children}
  </Button>
);

const FullCalendarHeader = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  const calendarRef = React.useRef();
  const [dateText, setDateText] = React.useState(moment().format("MMMM yyyy"));

  React.useImperativeHandle(ref, () => {
    return {
      setCalendarRef(ref) {
        calendarRef.current = ref;
        if (calendarRef.current)
          setDateText(calendarRef.current.getApi().getCurrentData().viewTitle);
      },
      updateDateHeader() {
        setDateText(calendarRef.current.getApi().getCurrentData().viewTitle);
      },
    };
  });

  const changeMonthView = (action) => () => {
    const calendarApi = calendarRef.current.getApi();
    let currentData;
    switch (action) {
      case "Prev":
        calendarApi.prev();
        currentData = calendarApi.getCurrentData();
        if (currentData.currentViewType === CalendarViews.day) {
          props.dayViewOnClick(
            moment(currentData.currentDate).format("dddd").toLowerCase()
          );
        }
        break;
      case "Next":
        calendarApi.next();
        currentData = calendarApi.getCurrentData();
        if (currentData.currentViewType === CalendarViews.day) {
          props.dayViewOnClick(
            moment(currentData.currentDate).format("dddd").toLowerCase()
          );
        }
        break;
      case "Today":
        calendarApi.today();
        currentData = calendarApi.getCurrentData();
        props.dayViewOnClick(
          moment(currentData.currentDate).format("dddd").toLowerCase()
        );
        break;
      default:
        break;
    }
    const viewTitle = calendarApi.getCurrentData().viewTitle;
    setDateText(viewTitle);
  };

  const changeView = (action) => () => {
    const calendarApi = calendarRef.current.getApi();
    const currentData = calendarApi.getCurrentData();
    switch (action) {
      case "Month":
        calendarApi.changeView(CalendarViews.month, currentData.currentDate);
        props.viewOnChange(CalendarViews.month);
        break;
      case "Week":
        calendarApi.changeView(CalendarViews.week, currentData.currentDate);
        props.viewOnChange(CalendarViews.week);
        props.weekViewOnClick();
        break;
      case "Day":
        calendarApi.changeView(CalendarViews.day, currentData.currentDate);
        props.dayViewOnClick(
          moment(currentData.currentDate).format("dddd").toLowerCase()
        );
        props.viewOnChange(CalendarViews.day);
        break;
      default:
        break;
    }

    const viewTitle = calendarRef.current.getApi().getCurrentData().viewTitle;
    setDateText(viewTitle);
  };

  return (
    <Box
      width={"100%"}
      height={"70%"}
      padding={"0 0 20px 0"}
      position={"relative"}
    >
      <ScheduleHeader />
      <Grid container spacing={1}>
        {!sm && !xs && <>
          <CalendarView changeView={changeView} />
          <CalendarDate changeMonthView={changeMonthView} dateText={dateText} />
          <CalendarCustomToolbar viewPatients={props.viewPatients} changeMonthView={changeMonthView} />
        </>}
        {sm && !xs && <>
          <CalendarDate changeMonthView={changeMonthView} dateText={dateText} />
          <CalendarView changeView={changeView} />
          <CalendarCustomToolbar viewPatients={props.viewPatients} changeMonthView={changeMonthView} />
        </>}
        {xs && <>
          <CalendarView changeView={changeView} />
          <CalendarCustomToolbar viewPatients={props.viewPatients} changeMonthView={changeMonthView} />
          <CalendarDate changeMonthView={changeMonthView} dateText={dateText} />
        </>}
      </Grid>
    </Box>
  );
});

export default FullCalendarHeader;

function CalendarView({ changeView }) {

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      display={"flex"}
      justifyContent={{xs: 'center', sm: 'end', md: 'start'}}
      alignItems={"center"}
    >
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <CalendarButton onClick={changeView("Month")}>MONTH</CalendarButton>
        <CalendarButton onClick={changeView("Week")}>WEEK</CalendarButton>
        <CalendarButton onClick={changeView("Day")}>DAY</CalendarButton>
      </ButtonGroup>
    </Grid>
  );
}

function CalendarDate({ changeMonthView, dateText }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={changeMonthView("Prev")}
      >
        <ArrowBackIosNewIcon />
      </Button>
      <Typography
        fontWeight={"bold"}
        fontSize={{ xs: 15, sm: 20, md: 30, xl: 40 }}
        style={{ margin: "0 15px" }}
        textAlign={"center"}
      >
        {dateText}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={changeMonthView("Next")}
      >
        <ArrowForwardIosIcon />
      </Button>
    </Grid>
  );
}

function CalendarCustomToolbar({ viewPatients, changeMonthView }) {
  
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      display={"flex"}
      justifyContent={{xs: 'center', sm: 'start', md: 'end'}}
      alignItems={"center"}
    >
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <CalendarButton onClick={changeMonthView("Today")}>
          TODAY
        </CalendarButton>
        <CalendarButton onClick={viewPatients}>
          VIEW PATIENTS
        </CalendarButton>
      </ButtonGroup>
    </Grid>
  );
}