import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Password } from "@mui/icons-material";
import Swal from "sweetalert2";
import { getCurrentLoggedInUser } from "../../../../Utils/authStored";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";

const ChangePassword = ({
  openModal,
  closeModal,
  isStaff,
  staffID
}) => {
  const user = getCurrentLoggedInUser();
  const initialValue = {
    newpassword: "",
    confirmnewpassword: "",
  };

  const minLength = 8;
  const loweruppercaseRegex = /[a-z\s]*[A-Z]/;
  const numberRegex = /\d/;
  const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#?])[a-zA-Z\d!@#?]{8,}$/;

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(initialValue);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCopfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { newpassword, confirmnewpassword } = state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  function error(title = "", icon = "") {
    Swal.fire({
      icon: icon,
      title: title,
      text: "",
      footer: '<a href=""></a>',
    });
  }

  const handlePasswordSubmit = async () => {
    let payload = {
      password: newpassword,
      user_id: isStaff == undefined ? user?.user_id : staffID,
      password_confirmation: confirmnewpassword,
    };

    try {
      await axios
        .post("auth/change-password", payload)
        .then(function (response) {
          if (response.status === 201) {
            setState(initialValue);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePassword = () => {
    if (newpassword || confirmnewpassword) {
      if (
        newpassword.length < 8 ||
        !loweruppercaseRegex.test(newpassword) ||
        !numberRegex.test(newpassword) ||
        !specialCharacterRegex.test(newpassword)
      ) {
        error("Your password does not meet the required criteria.", "error");
        return;
      }
      if (confirmnewpassword === newpassword) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            handlePasswordSubmit();
            Swal.fire("Done!", "Password  changed", "success");
          }
        });
      } else {
        error("Password is not matched", "error");
      }
    } else {
      error("Please enter your new password", "error");
    }
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  }

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  }

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      maxWidth={`xl`}
      PaperProps={{
        sx: {
          top: 15,
          width: "54vh",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
        <Stack justifyContent={`flex-end`} alignItems={`flex-end`}>
          <CloseIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </Stack>
        <Stack justifyContent={`center`} alignItems={`center`}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "700" }}>
            Change Password
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#fafafa" }}>
        <Stack direction={`column`} spacing={3} paddingTop={`20px`}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <OutlinedInput
              onChange={handleInputChange}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
              value={newpassword || ""}
              name="newpassword"
              id={`new-password`}
              label={`New Password`}
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel htmlFor="confirm-password">
              Confirm New Password
            </InputLabel>
            <OutlinedInput
              onChange={handleInputChange}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
              value={confirmnewpassword || ""}
              name="confirmnewpassword"
              id={`confirm-password`}
              label={`Confirm New Password`}
              variant="outlined"
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCopfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Stack
            borderRadius={2}
            backgroundColor={`#FAF2B3`}
            justifyContent={`center`}
            direction={`column`}
            fullWidth
            sx={{
              py: 2,
              px: 2,
            }}
          >
            <Typography variant="subtitle1">
              {newpassword.length < 8 &&
                "At least 8 characters-the more characters, the better"}
            </Typography>
            <Typography variant="subtitle1">
              {!loweruppercaseRegex.test(newpassword) &&
                "A combination of both uppercase and lowercase letter"}
            </Typography>
            <Typography variant="subtitle1">
              {!numberRegex.test(newpassword) &&
                "A combination of letters and numbers"}
            </Typography>
            <Typography variant="subtitle1">
              {!specialCharacterRegex.test(newpassword) &&
                "Inclusions of at least one special character, e.g.,!@#?"}
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              {strongPasswordRegex.test(newpassword) && "Strong Password"}
            </Typography>
          </Stack>

          <Stack
            direction={`row`}
            justifyContent={`flex-end`}
            alignItems={`flex-end`}
            spacing={2}
            paddingTop={`20px`}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleChangePassword();
              }}
            >
              Change Password
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
