export const CalendarContainerStyles = {
    progressBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        p: 0,
        zIndex: 2,
    },
    container: {
        width: '100%',
        height: '100%',
        p: 0,
        position: 'relative'
    }
}