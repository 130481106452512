import { getAppCurrentActiveClinic, getCurrentLoggedInUser } from "../Utils/authStored";
import { userCan } from "../Utils/utils";
import file_invoice_dollar from "../assets/image/file-invoice-dollar.svg";
import gear from "../assets/image/gear.svg";
import graph from "../assets/image/graph.svg";
import headset_solid from "../assets/image/headset-solid.svg";
import logout from "../assets/image/logout.svg";
import person from "../assets/image/person.svg";
import peso_line from "../assets/image/peso-line.svg";
import schedule from "../assets/image/schedule.svg";
import { nanoid } from "@reduxjs/toolkit";

export const getUserType = () => {
  const clinic = getAppCurrentActiveClinic();

  const currentUser = getCurrentLoggedInUser();


  if (clinic?.users && currentUser) {

    const user = clinic.users.filter((u) => u.id === currentUser.user_id);

    if (user[0]) {
      if (user[0].type === 'doctor' && user[0].isAdmin === 1) {
        return 'doctor';
      } else if (user[0].type === 'doctor' && user[0].isAdmin === 0) {
        return 'inhousedoc';
      } else {
        return 'staff';
      }

    }
  }

  return null;
}

export const SideBarData = [
  {
    title: "Appointments",
    path: "/dashboard/appointments",
    icon: schedule,
    user_type: getUserType(),
    is_show: (getUserType() === 'staff' || userCan('viewAllClinicAppointments') || userCan('createAppointments') || userCan('rescheduleAppointments') || userCan('cancelAppointments'))
  },
  {
    title: "Patients",
    path: "/dashboard/patients/list",
    icon: person,
    user_type: getUserType(),
    is_show: (getUserType() === 'doctor' || getUserType() === 'inhousedoc' || getUserType() === 'staff' && userCan('viewPatientsList') || userCan('addPatients') ||userCan('editPatient') || userCan('deletePatient'))
  },
  {
    title: "Analytics",
    path: "/dashboard/analytics",
    icon: graph,
    user_type: getUserType(),
    is_show: (getUserType() !== 'inhousedoc' && userCan('viewAnalytics'))
  },
  {
    title: "Invoices",
    path: "/dashboard/invoices/list",
    icon: file_invoice_dollar,
    user_type: getUserType(),
    is_show: (getUserType() !== 'inhousedoc' && (userCan('viewAllInvoices') || userCan('voidInvoice') || userCan('createInvoice')))
  },
  {
    title: "Quote",
    path: "/dashboard/quote/list",
    icon: peso_line,
    user_type: getUserType(),
    is_show: (getUserType() !== 'inhousedoc' && userCan('createQuotations'))
  },
  {
    title: "Settings",
    path: "/dashboard/settings",
    icon: gear,
    user_type: getUserType(),
    is_show: (userCan('editClinic') || userCan('addUsers') || userCan('editUsers') || userCan('deleteUsers') || userCan('editUserPermissions'))
  },
  {
    title: "Support",
    path: "/dashboard/support",
    icon: headset_solid,
    user_type: getUserType(),
    is_show: true
  },
  {
    title: "Logout",
    path: "/logout",
    icon: logout,
    user_type: getUserType(),
    is_show: true
  },
];

export const headCells = [
  {
    id: "name",
    numeric: true,
    label: "Name",
  },
  {
    id: "email_address",
    numeric: true,
    label: "Email Address",
  },
  {
    id: "address",
    numeric: true,
    label: "Address",
  },
  {
    id: "phone_number",
    numeric: true,
    label: "Phone Number",
  },
  {
    id: "last_visited",
    numeric: true,
    label: "Last Visited",
  },
];

export const invoice = [
  {
    date: "July 10,2021",
    amount: "200",
    balance: "0",
    status: "Paid",
  },
  {
    date: "Agust 10,2021",
    amount: "100",
    balance: "0",
    status: "Paid",
  },
  {
    date: "May 10,2021",
    amount: "300",
    balance: "0",
    status: "Paid",
  },
  {
    date: "April 10,2021",
    amount: "700",
    balance: "0",
    status: "Paid",
  },
  {
    date: "july 10,2021",
    amount: "400",
    balance: "0",
    status: "Paid",
  },
  {
    date: "july 10,2021",
    amount: "400",
    balance: "0",
    status: "Paid",
  },
  {
    date: "july 10,2021",
    amount: "400",
    balance: "0",
    status: "Paid",
  },
];

export const appointments = [
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Done",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "No Show",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Done",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
  {
    date: "Feb 20,2023",
    time: "07:00",
    status: "Schedule",
  },
];

export const procedures = [
  {
    treatment: "checkup",
    date: "March 7, 2023 07:52 AM",
    status: "Scheduled",
  },
  {
    treatment: "Cleaning",
    date: "May 7, 2023 09:52 AM",
    status: "Scheduled",
  },
  {
    treatment: "checkup",
    date: "June 10, 2023 10:30 AM",
    status: "Unscheduled",
  },
  {
    treatment: "checkup",
    date: "April 7, 2023 07:52 AM",
    status: "Unscheduled",
  },
  {
    treatment: "Extraction",
    date: "March 15, 2023 07:52 AM",
    status: "Done",
  },
  {
    treatment: "checkup",
    date: "March 7, 2023 07:52 AM",
    status: "Done",
  },
  {
    treatment: "checkup",
    date: "March 12, 2023 07:52 AM",
    status: "Done",
  },
];

function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export const invoices = [
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Mario Aguilar",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Ariane Anderson",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Rafael Benítez",
    amount: "500.00",
    balance: "500.00",
    status: "Unpaid",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Harshil Bharanya",
    amount: "500.00",
    balance: "150.00",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Gabriel Castro",
    amount: "500.00",
    balance: "50.00",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Anton Koistinen",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Noah Mortensen",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Lisa Snyder",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Emma Olsen",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Sara Kristensen",
    amount: "500.00",
    balance: "Andreas Christiansen",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "",
    amount: "1500.00",
    balance: "500",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Terrance Perez",
    amount: "500.00",
    balance: "150",
    status: "Partial",
  },
  {
    number: nanoid(),
    date: randomDate(new Date(2012, 0, 1), new Date()),
    patient: "Daniela Fuentes",
    amount: "500.00",
    balance: "",
    status: "Partial",
  },
];

export const quotation = [
  { procedure: "Extraction", price: "700" },
  { procedure: "Cleaning", price: "300" },
  { procedure: "Extraction", price: "700" },
];

export const userMembers = [
  {
    id: 1,
    role: "admin",
    name: "Dr. Sunny1 Marie Trespuentes",
    email: "summer122@yopmail.com",
    username: "summer123",
    date_created: "May 4, 2022",
  },
  {
    id: 2,
    role: "dentist",
    name: "Dr. Sunny2 Marie Trespuentes",
    email: "summer123@yopmail.com",
    username: "summer124",
    date_created: "May 5, 2022",
  },
  {
    id: 3,
    role: "dentist",
    name: "Dr. Sunny3 Marie Trespuentes",
    email: "summer124@yopmail.com",
    username: "summer125",
    date_created: "May 6, 2022",
  },
  {
    id: 4,
    role: "staff",
    name: "Dr. Sunny4 Marie Trespuentes",
    email: "summer125@yopmail.com",
    username: "summer126",
    date_created: "May 7, 2022",
  },
  {
    id: 5,
    role: "dentist",
    name: "Dr. Sunny5 Marie Trespuentes",
    email: "summer126@yopmail.com",
    username: "summer127",
    date_created: "May 8, 2022",
  },
];
