import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Divider,
  Chip,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import {
  showPatientInfo,
  editPatientInfo,
} from "../../../Pages/Patients/Redux/patientSlice";

import { useDispatch } from "react-redux";

import PatientInformationModal from "../../../Components/Modals/PatienInformationModal";
import PatientRecordModal from "../../../Components/Modals/PatientRecordModal";
import { KeyboardReturnSharp } from "@mui/icons-material";
const GridView = ({ clinicPatients }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const sorted = clinicPatients.sort((a, b) =>
    a.lastname.localeCompare(b.lastname)
  );

  const getUniqueLetter = sorted.map((rows) => rows?.lastname.charAt(0));

  let setObject = new Set(getUniqueLetter);
  const arrObject = [...setObject];
  return (
    <>
      <Paper elevation={2} sx={{ padding: 2, height: 842, overflow: "auto" }}>
        <Box sx={{ minWidth: "250px" }}>
          <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
            {clinicPatients &&
              arrObject.map((row, index) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <Divider sx={{ marginTop: 1, marginBottom: 1 }}>
                        <Chip label={row} size={`medium`} variant="filled" />
                      </Divider>
                    </Grid>
                    {sorted.map((rows, index) => {
                      return (
                        <>
                          {rows?.lastname.charAt(0) === row && (
                            <>
                              <Grid item xs={6}>
                                <Paper
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: "#f5f5f5",
                                    },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="left"
                                    alignItems="center"
                                    padding={1}
                                    spacing={1}
                                    hover
                                    onClick={() =>
                                      dispatch(showPatientInfo(rows))
                                    }
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Avatar
                                      src={rows?.avatar}
                                      sx={{
                                        width: {
                                          xs: 40,
                                          sm: 40,
                                          md: 50,
                                          lg: 60,
                                          xl: 80,
                                        },
                                        height: {
                                          xs: 40,
                                          sm: 40,
                                          md: 50,
                                          lg: 60,
                                          xl: 80,
                                        },
                                      }}
                                    />
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontSize: { sm: 15, md: 20 } }}
                                      >
                                        {rows?.lastname +
                                          ", " +
                                          rows?.firstname}
                                      </Typography>
                                      <Typography variant="subtitle2 ">
                                        Last visited {rows?.last_visit}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Paper>
                              </Grid>
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })}
          </Grid>
          {sorted.length === 0 && (
            <Stack direction="row" justifyContent="center">
              <Typography variant="h5">No Patient found!</Typography>
            </Stack>
          )}
        </Box>

        <PatientInformationModal />
        <PatientRecordModal />
      </Paper>
    </>
  );
};

export default GridView;
