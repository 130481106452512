import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

import React, { useState } from "react";

import { axiosNoAuthInstance as axios } from "../../../Utils/AxiosHttp";

import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [payload, setPayload] = useState({
    email: "",
  });

  const [error, setError] = useState({
    has_error: false,
    message: "",
  });

  const handleForgotPasswordEmailField = (e) => {
    setPayload({
      ...payload,
      email: e.target.value,
    });
  };

  const onSubmit = async () => {
    if (payload?.email === "" || payload?.email === undefined) {
      setError({
        ...error,
        has_error: true,
        message: "Email / Username is required",
      });
      setOpenSnackbar(true);
      return;
    } else {
      await axios
        .post("/auth/request-reset-password", payload)
        .then((response) => {
          setError({
            has_error: false,
            message: response.data.success,
          });
        })
        .catch((error) => {
          setError({
            has_error: true,
            message: error.errors,
          });
        });

      setOpenSnackbar(true);
    }
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  };

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace =
      !data || data.length === 0 || /\s/g.test(data);

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          direction={"column"}
          spacing={2}
          sx={{
            width: "50vh",
          }}
        >
          <Stack direction={"column"}>
            <Typography textAlign="center" variant="h1" color="#004d81">
              MARGO
            </Typography>
            <Typography textAlign="right" color="#747474" sx={{ pr: 4 }}>
              v{process.env.REACT_APP_MARGO_VERSION}
            </Typography>
          </Stack>

          <Stack direction={"column"} spacing={2}>
            <TextField
              autoComplete="off"
              label="Email / Username"
              name="email"
              required
              variant="outlined"
              type="text"
              onChange={(e) => handleForgotPasswordEmailField(e)}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
            />

            <Button variant="contained" size="large" onClick={() => onSubmit()}>
              Submit
            </Button>

            <Typography
              color={`primary`}
              textAlign="center"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/auth/login")}
            >
              Back to Login
            </Typography>
          </Stack>

          <Snackbar
            open={openSnackbar}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={null}
            onClose={() => setOpenSnackbar(false)}
          >
            {error?.has_error ? (
              <Alert severity={"error"}>{error?.message}</Alert>
            ) : (
              <Alert severity={"success"}>{error?.message}</Alert>
            )}
          </Snackbar>
        </Stack>
      </Box>
    </Container>
  );
}
