import React from "react";
import {
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { axiosInstance as axios } from "../../../../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";

export default function ImageAttachment({ attachment, onDelete, }) {
  const fileReader = React.useRef(new FileReader());
  const [loading, setLoading] = React.useState(true);
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const [imgAttachment, setImgAttachment] = React.useState({});

  React.useEffect(() => {
    const fileReaderRef = fileReader.current;
    uploadFile();

    return () => {
      if (fileReaderRef && fileReaderRef.readyState === 1) {
        fileReaderRef.abort();
      }
    }
    // eslint-disable-next-line
  }, []);

  const uploadFile = async () => {
    setLoading(true);
    if (attachment?.file) {
      fileReader.current.onload = async (e) => {
        const { result } = e.target;

        if (result) {
          let serverId;
          try {
            const payload = {
              ...attachment,
              base64_image: result,
            };
            delete payload.file;
            const response = await axios.post(`attachments`, payload);
            if (response.status === 201) {
              serverId = response.data.data.id;
            }
          } catch (_) {
            Swal.fire({
              icon: 'error',
              title: 'Failed to update procedure notes!'
            });
          }

          setImgAttachment({
            ...attachment,
            server_id: serverId,
            src: result
          });
          setLoading(false);
        }
      };
      fileReader.current.readAsDataURL(attachment.file);
    }
    else {
      setImgAttachment({
        ...attachment,
        src: `${process.env.REACT_APP_API_URL}/uploads/${attachment.id}`
      });
      setLoading(false);
    }
  }

  const deleteAttachement = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to delete this attachment?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        try {
          await axios.delete(`attachments/${imgAttachment?.server_id ?? imgAttachment.id}`);
          return true;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete attachment!'
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Successfully delete image.'
        }).then(() => onDelete(imgAttachment));
      }
    });
  }

  const handleOnClick = () => {
    Swal.fire({
      imageUrl: imgAttachment.src,
    });
  }

  return (
    <Box width={180} height={150}
      position={'relative'}
      sx={{
        border: '2px solid #bdbdbd',
        borderRadius: '5px',
      }}>
      {!loading ? <>
        <img
          key={imgAttachment.id}
          src={imgAttachment.src}
          width={176} height={146}
          alt="attachment"
          onClick={handleOnClick}
          style={{
            borderRadius: '4px', objectFit: 'cover',
            display: !imgLoaded ? 'none' : 'unset',
            cursor: 'pointer'
          }}
          onLoad={() => setImgLoaded(true)} />
        {!imgLoaded && <Box display={"flex"} justifyContent={"center"} alignItems={'center'} height={'100%'} width={'100%'}>
          <CircularProgress />
        </Box>}
        {imgLoaded && <IconButton
          aria-label="close"
          onClick={deleteAttachement}
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            color: 'themeColor.error'
          }}
        >
          <DeleteIcon />
        </IconButton>}
      </> :
        <Box display={"flex"} justifyContent={"center"} alignItems={'center'} height={'100%'} width={'100%'}>
          <CircularProgress />
        </Box>}
    </Box>
  )
}