import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Dataset } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
const PieChart = ({ labels, datas }) => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const chartCanvas = chartRef.current.getContext("2d");
    const chart = new Chart(chartCanvas, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
              "rgba(153, 102, 255, 0.5)",
              "rgba(130, 150, 254, 0.5)",
            ],

            hoverOffset: 4,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {
          legend: {
            position: "right",
            padding: 5,

            labels: {
              pointStyle: "circle", // Set the pointStyle to circle to change the shape of the legend
              usePointStyle: true,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          datalabels: {
            display: true,
            color: "black",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1, // Set the aspect ratio to 1 to ensure a circular pie chart
        radius: "100%", //
      },
    });
    // chart.canvas.parentNode.style.height = "450px";
    // chart.canvas.parentNode.style.width = "450px";
    const chartContainer = chart.canvas.parentNode;

    chartContainer.style.height = isSmallScreen ? "300px" : "450px";
    chartContainer.style.width = isSmallScreen ? "100%" : "450px";

    return () => chart.destroy();
  }, [labels, datas, isSmallScreen]);
  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default PieChart;
