import { createContext, useEffect } from "react";
import { initialState as BillingInitialState, verifySubscription } from "../Pages/Settings/Components/Billing/Redux/billingSlice";
import { useDispatch, useSelector } from "react-redux";
import checkOut from "../Pages/Settings/Components/Billing/apis/checkout";
import { getAppCurrentActiveClinic } from "../Utils/authStored";

const BillingContext = createContext({
   ...BillingInitialState,
   handleCheckout: () => Promise.resolve()
});


const BillingProvider = ({ children }) => {

   const dispatch = useDispatch();

   const clinic = getAppCurrentActiveClinic();

   const state = useSelector((state) => state.billing);

   const handleCheckout = async (payload) => {
      await checkOut(payload);
   }

   useEffect(() => {
      dispatch(verifySubscription({
         subscription_status: clinic?.subscription_status,
         plan_interval: clinic?.plan_interval ?? null,
         plan_id: clinic?.plan_id ?? null,
         expiration_date: clinic?.expiration_date,
         is_expired: clinic?.is_expired
      }));
   }, [])

   return (
      <BillingContext.Provider
         value={{ 
            ...state,
            handleCheckout
         }}
      >
         {children}
      </BillingContext.Provider>
   )
}

export { BillingContext, BillingProvider }