import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import ServicesSettings from "./services-settings";
const Services = React.forwardRef((_, ref) => {
  const [clinicProcedures, setClinicProcedures] = useState([]);
  const clinic = getAppCurrentActiveClinic();
  const serviceSettingsRef = React.useRef();

  React.useImperativeHandle(ref, () => {
    return {
      getSelectedProc() {
        return serviceSettingsRef.current.getSelectedProc();
      }
    }
  });

  useEffect(() => {
    fetchClinicProcedures();
    // eslint-disable-next-line
  }, []);

  const fetchClinicProcedures = async () => {
    const response = await axios.get("clinic_procedures/hierarchical", {
      params: { clinic_id: clinic?.id },
    });
    setClinicProcedures(response.data);
  };

  return (
    <>
      {!clinicProcedures.length && <Box
        sx={{
          display: "flex",
          right: `50%`,
          top: `50%`,
          position: "absolute",
        }}
      >
        <CircularProgress />
      </Box>}
      <TableContainer component={Paper} sx={{ height: { sm: 300, md: 600 } }}>
        <Table
          sx={{ minWidth: 500, height: "max-content" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: 17 }}>
                Services
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontStyle: "italic",
                    color: "#e91e63",
                    marginTop: 1,
                  }}
                >
                  To enable/disable more procedures go to Settings &gt;
                  Procedures & Services
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ServicesSettings ref={serviceSettingsRef} data={clinicProcedures}/>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default Services;
