import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Box,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { numberWithCommas } from "../../../Utils/utils";
import { useSelector } from "react-redux";
const ServicesSettings = React.forwardRef(({ selectedItems, isSubItem, data }, ref) => {
  const { procedures } = useSelector((state) => state.quote);
  const [selected, setSelected] = React.useState(selectedItems ?? []);
  const [isOpen, setIsOpen] = React.useState({});
  const serviceSettingsRef = React.useRef();

  React.useImperativeHandle(ref, () => {
    return {
      getSelectedProc() {
        return serviceSettingsRef.current?.getSelectedProc() ?? selected;
      }
    }
  })

  React.useState(() => {
    if (procedures.length > 0) {
      setSelected(procedures);
    }
  }, [procedures]);

  const isSelected = (id) => selected.filter(f => f.id === id).length > 0;


  const showChildren = (name) => {
    setIsOpen({ ...isOpen, [name]: !isOpen[name] });
  };

  const itemCheckedHandler = (item) => (args) => {
    const checked = args.target.checked;
    item.checked = true;
    let items;
    if (checked) {
      items = selected.concat([item]);
    }
    else {
      items = selected.filter(f => f.id !== item.id);
    }

    setSelected(items);
  }

  const ServiceSettingsContent = ({ index, parent }) => {
    const boxStyle = isSubItem ? { padding: '10px 10px 10px 10px' } : {};
    const boxStyle2 = isSubItem ? { padding: '0 25px 0 10px' } : {};
    return (
      <>
        {parent.children.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: { sm: 600 }, ...boxStyle }}
        >
          <Typography variant="subtitle1">
            {parent.name}
          </Typography>
          <IconButton onClick={() => showChildren(parent.name)}>
            {isOpen[parent.name] ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </Box>
        ) : (
        <Box
          sx={{ width: { sm: 600 }, ...boxStyle2 }}
          display="flex"
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <IconButton>
              <Checkbox
                checked={isSelected(parent.id)}
                size="small"
                onChange={itemCheckedHandler(parent)} />
            </IconButton>
            {parent?.name}
          </Box>
          <Box>
            ₱{" "}
            {parent?.children && parent?.price !== null
              ? numberWithCommas(parent?.price)
              : "0.00"}
          </Box>
        </Box>
        )}
        
        {isSubItem && <Divider />}

        {parent?.children && <Collapse
          in={isOpen[parent.name]}
          timeout="auto"
          unmountOnExit
          sx={{
            backgroundColor: isOpen[parent.name] && "#fafafa",
          }}
        >
          <ServicesSettings 
            ref={serviceSettingsRef}
            isSubItem={true}
            selectedItems={selectedItems}
            data={parent?.children} />
        </Collapse>}
      </>
    )
  }

  return (
    <>
      {data.map((parent, index) => {
        if (!isSubItem) {
          return (
            <TableRow key={index}>
              {parent.enabled && (
                <TableCell
                  sx={{
                    borderBottom: !parent.children.length && "none",
                  }}
                >
                  <ServiceSettingsContent key={index} parent={parent} />
                </TableCell>
              )}
            </TableRow>
          );
        }
        else {
          return <ServiceSettingsContent key={index} parent={parent} />
        }
      })}
      {/* </div> */}
    </>
  );
});

export default ServicesSettings;
