import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InvoiceStatusModal from "../../../../../Components/Modals/InvoiceStatusModal";
import ServicesModal from "../../../../../Components/Modals/ServicesModal";
import PatientRecordModal from "../../../../../Components/Modals/PatientRecordModal";
import PatientInformationModal from "../../../../../Components/Modals/PatienInformationModal/index";
import PatientProfile from "./Sections/PatientProfile";
import AppointmentActions from "./Sections/AppointmentActions";
import Procedures from "./Sections/Procedures";

const AppointmentDetailsDialog = React.forwardRef((_, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => {
    return {
      show() {
        setOpen(true);
      },
    };
  });

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" width={300}>
          <Typography variant="h5" component={"div"}>
            Appointment
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={8} item>
              <PatientProfile />
            </Grid>
            <Grid xs={12} sm={12} md={4} item>
              <AppointmentActions close={handleClose} />
            </Grid>
            <Grid xs={12} item>
              <Procedures />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <PatientRecordModal />
      <PatientInformationModal />
      <ServicesModal />
      <InvoiceStatusModal />
    </>
  );
});

export default AppointmentDetailsDialog;