import React, { useEffect, useState } from "react";
import {
  Stack,
  Select,
  MenuItem,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Chart from "../Graph/LineChart";
import ProgressBar from "../Graph/ProgressBar";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { numberWithCommas } from "../../../Utils/utils";

const Sales = () => {
  const [rangemode, setRangeMode] = useState("today");
  const clinic = getAppCurrentActiveClinic();
  const [state, setState] = useState();

  const loadData = async () => {
    try {
      await axios
        .get(`stats/sales?clinic_id=` + clinic?.id + `&date_range=` + rangemode)
        .then(function (response) {
          if (response.status === 200) {
            setState(response?.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (rangemode === "today") {
      loadData(rangemode);
      return;
    }
    loadData(rangemode);
  }, [rangemode]);

  const handleRangeChange = (event) => {
    setRangeMode(event.target.value);
  };

  const salesPercentage = (progress, goal) => {
    let result = parseFloat((progress / goal) * 100);

    if (result > 100) {
      return parseInt(100);
    } else {
      return result;
    }
  };

  let labels = [];
  let value = [];

  labels = state?.sales_6_months.map((i) => {
    return i.month;
  });

  value = state?.sales_6_months.map((i) => {
    return i.total / 1000;
  });

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"}>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 120, marginBottom: 3 }}
          >
            <InputLabel id="demo-simple-select-standard-label"></InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={rangemode}
              onChange={handleRangeChange}
              sx={{
                marginTop: 2,
                width: 200,
                height: 30,
                size: "small",
              }}
            >
              <MenuItem value={"today"}>Today</MenuItem>
              <MenuItem value={"week"}>Week</MenuItem>
              <MenuItem value={"month"}>Month</MenuItem>
              <MenuItem value={"quarter"}>Quarter</MenuItem>
              <MenuItem value={"year"}>Year</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={"row"}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={6}>
              <Stack direction={"column"}>
                <Paper elevation={2} sx={{ padding: 2 }}>
                  <Stack direction={"column"} spacing={1.2}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "bold" }} variant="h6">
                        Expected Income
                      </Typography>

                      <Typography sx={{ fontWeight: "bold", color: "#ef5350" }}>
                        {numberWithCommas(state?.expected_sales)}
                      </Typography>
                    </Stack>

                    <Typography variant="subtitle1">
                      Actual Payment Received:{" "}
                      {numberWithCommas(state?.actual_sales)}
                    </Typography>
                    <ProgressBar
                      value={salesPercentage(
                        state?.actual_sales,
                        state?.expected_sales
                      ).toFixed(2)}
                    />
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="subtitle1">Sales</Typography>
                      <Typography variant="subtitle1">
                        {isNaN(
                          salesPercentage(
                            state?.actual_sales,
                            state?.expected_sales
                          ).toFixed(2)
                        )
                          ? 0
                          : salesPercentage(
                              state?.actual_sales,
                              state?.expected_sales
                            ).toFixed(2)}
                        &nbsp;%
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Stack direction={"column"}>
                  <Stack direction={"row"} spacing={6} alignItems={"center"}>
                    <PersonRemoveIcon sx={{ fontSize: 35 }} />
                    <Box>
                      <Typography variant="subtitle1">
                        Php {numberWithCommas(state?.lost_sales)}
                      </Typography>
                      <Typography variant="subtitle1">
                        Total Lost Sales
                      </Typography>
                      <Typography variant="subtitle1">
                        For missed Appointments
                      </Typography>
                    </Box>
                  </Stack>
                  <Divider />
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    <Typography variant="subtitle1" padding={1}>
                      For This Month
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Stack>

        <Stack>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Monthly Sales
            </Typography>
            <Divider />
            {/* LIne Chart */}
            <Box>
              <Chart legend="Sales" labels={labels} value={value} />
            </Box>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default Sales;
