import { createSlice } from "@reduxjs/toolkit";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";


const initialState = {
   isClinicUpdated: false,
   clinicSettingInfo: {
      id: '',
      name: '',
      branch: '',
      address: '',
      parallelAppts: 0,
      icon: '',
      clinic_users: [],
      operating_hours: []
   },
   uploadedAvatar: '',
   isValidAvatar: false,
   isDownloadSOAModalOpen: false,
   tabIndex: '0'
}


const settingsSlice = createSlice({
   name: "settings",
   initialState,
   reducers: {
      refreshClinic: (state, action) => {
         const clinic = getAppCurrentActiveClinic();
         state.clinicSettingInfo.id = clinic?.id;
         state.clinicSettingInfo.name = clinic?.name;
         state.clinicSettingInfo.branch = clinic?.branch;
         state.clinicSettingInfo.address = clinic?.address;
         state.clinicSettingInfo.parallelAppts = clinic?.parallelAppts;
         state.clinicSettingInfo.icon = clinic?.icon;
         state.clinicSettingInfo.clinic_users = clinic?.users;
         state.clinicSettingInfo.operating_hours = clinic?.operating_hours;
         state.isClinicUpdated = action.payload;
      },
      refreshClinicUsers: (state, action) => {
         state.isClinicChanged = action.payload
      },
      checkIfValidAvatar: (state, action) => {
         state.uploadedAvatar = action.payload?.avatar;
         state.isValidAvatar = action?.payload?.valid;
      },
      selectionTab: (state, action) => {
         state.tabIndex = action.payload?.tab;
      },
      setIsDownloadSOAModalOpen: (state, action) => {
         state.isDownloadSOAModalOpen = action.payload
      }
   }
})

export const settingsStore = (state) => state.settings;

export const {
   refreshClinic,
   checkIfValidAvatar,
   refreshClinicUsers,
   selectionTab,
   setIsDownloadSOAModalOpen
} = settingsSlice.actions;

export default settingsSlice.reducer;