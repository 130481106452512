import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeQouteModal,
  patientStore,
} from "../../Pages/Patients/Redux/patientSlice";
import {
  Dialog,
} from "@mui/material";
import Quote from "../../Pages/Quote";
const GetQuoteModal = () => {
  const dispatch = useDispatch();
  const { isOpenQuote } = useSelector(patientStore);

  return (
    <div>
      <Dialog
        open={isOpenQuote}
        disableScrollLock={true}
        onClose={() => {
          dispatch(closeQouteModal());
        }}
        maxWidth="lg"
      >
        <Quote isGetQuote={true} />
      </Dialog>
    </div>
  );
};

export default GetQuoteModal;
