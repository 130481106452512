import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Switch } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import moment from "moment";
import dayjs from 'dayjs';
import { getAppCurrentActiveClinic, getClinics, getCurrentLoggedInUser, setClinics, setCurrentLoggedInUser } from "../../../../Utils/authStored";
import { TimePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import { setAppCurrentActiveClinic } from "../../../../Utils/authStored";
import { LoadingButton } from "@mui/lab";

const DayOfWeek = {
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday'
}

function OperatingHoursUtils() { }

OperatingHoursUtils.formatTimesToDate = function (days) {
    if (days) {
        let keys = Object.keys(days);
        keys.forEach(k => {
            let d = days[k];
            if (typeof d.open === 'number' && typeof d.close === 'number') {
                let tmpD1 = dayjs();
                let openH = d.open > 0 ? d.open / 100 : 0;
                let openM = (openH % 1) * 100;
                tmpD1 = tmpD1.hour(Math.floor(openH));
                tmpD1 = tmpD1.minute(Math.round(openM));
                d.open = tmpD1;

                let tmpD2 = dayjs();
                let closeH = d.close > 0 ? d.close / 100 : 0;
                let closeM = (closeH % 1) * 100;
                tmpD2 = tmpD2.hour(Math.floor(closeH));
                tmpD2 = tmpD2.minute(Math.round(closeM));
                d.close = tmpD2;
            }
            days[k] = {
                ...d,
                dayOfWeek: k,
                closeAtLunch: d?.closeAtLunch ?? false,
            };
        });
    }

    return days;
}

OperatingHoursUtils.formatTimesToMilitary = function (value) {
    const days = Object.assign({}, JSON.parse(JSON.stringify(value)));
    if (days) {
        let keys = Object.keys(days);
        keys.forEach(k => {
            let d = days[k];
            if (typeof d.open !== 'number' && typeof d.close !== 'number') {
                d.open = moment(d.open).toDate();
                d.close = moment(d.close).toDate();
                if (d.open.getHours && d.close.getHours) {
                    let openH = d.open.getHours() * 100;
                    let openM = d.open.getMinutes();
                    d.open = openH + openM;
                    let closeH = d.close.getHours() * 100;
                    let closeM = d.close.getMinutes();
                    d.close = closeH + closeM;
                }

                days[k] = d;
            }
        });
    }
    return days;
}

const selectedDayDefault = {
    enabled: false,
    closeAtLunch: false,
    exclude: [],
    open: dayjs(),
    close: dayjs()
}

const OperatingHours = ({ openModal, closeModal }) => {
    const [buttonActive, setButtonActive] = useState(DayOfWeek.monday);
    const [selectedDay, setSelectedDay] = useState(selectedDayDefault);
    const [submitting, setSubmitting] = useState(false);
    const operatingHours = useRef();

    useEffect(() => {
        if (openModal) {
            getCurrentWeekday();
        }
        else {
            setTimeout(() => {
                operatingHours.current = null;
                setSelectedDay(selectedDayDefault);
            }, 200);
        }
        // eslint-disable-next-line
    }, [openModal]);

    const getClinicOperatingHours = (dayOfWeek) => {
        const clinic = getAppCurrentActiveClinic();
        if (clinic) {
            if (clinic?.operating_hours && clinic?.operating_hours[DayOfWeek.monday]) {
                operatingHours.current = OperatingHoursUtils.formatTimesToDate(clinic.operating_hours);
                const operatingHour = operatingHours.current[dayOfWeek];
                if (operatingHour) {
                    setSelectedDay(operatingHour);
                }
            }
            else {
                setSelectedDay({
                    dayOfWeek: dayOfWeek,
                    enabled: true,
                    closeAtLunch: false,
                    open: dayjs().hour(8),
                    close: dayjs().hour(17)
                });
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'No clinic selected!'
            }).then(() => {
                closeModal();
            });
        }
    }

    const getCurrentWeekday = () => {
        const dayOfWeek = moment().format('dddd').toLowerCase();
        getClinicOperatingHours(dayOfWeek);
        setButtonActive(dayOfWeek);
    }

    const handleButtonClick = (e) => {
        const dayOfWeek = e.target.name;
        switch (dayOfWeek) {
            case DayOfWeek.monday:
                setButtonActive(DayOfWeek.monday)
                break;
            case DayOfWeek.tuesday:
                setButtonActive(DayOfWeek.tuesday)
                break;
            case DayOfWeek.wednesday:
                setButtonActive(DayOfWeek.wednesday)
                break;
            case DayOfWeek.thursday:
                setButtonActive(DayOfWeek.thursday)
                break;
            case DayOfWeek.friday:
                setButtonActive(DayOfWeek.friday)
                break;
            case DayOfWeek.saturday:
                setButtonActive(DayOfWeek.saturday)
                break;
            case DayOfWeek.sunday:
                setButtonActive(DayOfWeek.sunday)
                break;
            default:
                break;
        }

        operatingHours.current[selectedDay.dayOfWeek] = selectedDay;
        const operatingHour = operatingHours.current[dayOfWeek];
        setSelectedDay(operatingHour);
    }

    const dateOnChange = (field) => (value) => {
        switch (field) {
            case 'open':
                const clinicClose = selectedDay.close;
                let close = dayjs(value);
                close = close.hour(clinicClose.$H);
                close = close.minute(clinicClose.$m);

                if (value.isAfter(close)) {
                    close = value.clone().add(1, 'hour');
                }

                setSelectedDay({
                    ...selectedDay,
                    open: value,
                    close: close
                });
                break;
            case 'close':
                const clinicOpen = selectedDay.open;
                let open = dayjs(value);
                open = open.hour(clinicOpen.$H);
                open = open.minute(clinicOpen.$m);

                if (value.isBefore(open)) {
                    open = value.clone().subtract(1, 'hour');
                }

                setSelectedDay({
                    ...selectedDay,
                    open: open,
                    close: value
                });
                break;
            default:
                break;
        }
    }

    const selectOnChange = (field) => (args) => {
        const value = args.target.checked;
        switch (field) {
            case 'open':
                setSelectedDay({
                    ...selectedDay,
                    enabled: value
                });
                break;
            case 'closeAtLunch':
                setSelectedDay({
                    ...selectedDay,
                    closeAtLunch: value
                });
                break;
            default:
                break;
        }
    }

    const onSubmit = async () => {
        setSubmitting(true);
        const clinic = getAppCurrentActiveClinic();
        operatingHours.current[selectedDay.dayOfWeek] = selectedDay;
        const payload = {
            operating_hours: OperatingHoursUtils.formatTimesToMilitary(operatingHours.current)
        }

        const response = await axios.put(`clinics/${clinic.id}`, payload);
        if (response.status === 200) {
            await Swal.fire({
                icon: 'success',
                title: 'Operating Hours Saved!'
            });

            
            clinic.operating_hours = payload.operating_hours;
            setAppCurrentActiveClinic(clinic);

            const authUser = getCurrentLoggedInUser();
            const filteredAuthClinics = authUser.clinics.filter(f => f.id === clinic.id);
            if (filteredAuthClinics.length > 0) {
                const index = authUser.clinics.indexOf(filteredAuthClinics[0]);
                authUser.clinics[index] = clinic;
                setCurrentLoggedInUser(authUser);
            }

            const clinics = getClinics();
            const filteredClinics = clinics.filter(f => f.id === clinic.id);
            if (filteredClinics.length > 0) {
                const index = clinics.indexOf(filteredClinics[0]);
                clinics[index] = clinic;
                setClinics(clinics);
            }

            closeModal();
        }
        else {
            await Swal.fire({
                icon: 'error',
                title: 'Failed to update clinic operating hours'
            });
        }

        setSubmitting(false);
    }

    return (
        <Dialog
            open={openModal}
            onClose={(_, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") closeModal()
            }}
            maxWidth
            PaperProps={{
                sx: {
                    position: "fixed",
                    top: 15,
                },
            }}
        >
            <DialogTitle variant="h5" sx={{ backgroundColor: "#fafafa" }}>
                Operating Hours
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: "#fafafa" }}>
                <Stack
                    direction={`column`}
                    spacing={4}
                >
                    <Stack
                        direction={`row`}
                        spacing={1}
                    >
                        <Button
                            variant={buttonActive === DayOfWeek.sunday ? `contained` : `outlined`}
                            name={DayOfWeek.sunday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Sunday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.monday ? `contained` : `outlined`}
                            name={DayOfWeek.monday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Monday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.tuesday ? `contained` : `outlined`}
                            name={DayOfWeek.tuesday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Tuesday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.wednesday ? `contained` : `outlined`}
                            name={DayOfWeek.wednesday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Wednesday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.thursday ? `contained` : `outlined`}
                            name={DayOfWeek.thursday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Thursday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.friday ? `contained` : `outlined`}
                            name={DayOfWeek.friday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Friday
                        </Button>
                        <Button
                            variant={buttonActive === DayOfWeek.saturday ? `contained` : `outlined`}
                            name={DayOfWeek.saturday}
                            onClick={(e) => handleButtonClick(e)}
                        >
                            Saturday
                        </Button>
                    </Stack>

                    <Stack
                        direction={`row`}
                        spacing={15}
                        justifyContent={`center`}
                        alignItems={`center`}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedDay.enabled}
                                    onChange={selectOnChange('open')}
                                    color="primary" />
                            }
                            label="Open"
                            labelPlacement="start"
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedDay.closeAtLunch}
                                    onChange={selectOnChange('closeAtLunch')}
                                    color="primary" />
                            }
                            label="Close for lunch"
                            labelPlacement="start"
                        />
                    </Stack>

                    {selectedDay?.enabled && <Stack
                        direction={`row`}
                        spacing={2}
                        justifyContent={`center`}
                        alignItems={`center`}
                    >
                        <TimePicker
                            sx={{ width: '100%' }}
                            label="Open"
                            value={selectedDay.open}
                            onChange={dateOnChange('open')}
                        // onChange={(newValue) => setValue(newValue)}
                        />
                        <TimePicker
                            sx={{ width: '100%' }}
                            label="Close"
                            value={selectedDay.close}
                            onChange={dateOnChange('close')}
                        // onChange={(newValue) => setValue(newValue)}
                        />
                    </Stack>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    Save Changes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )

}

export default OperatingHours