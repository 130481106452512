import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Box, Divider, Typography } from "@mui/material";
import { PendingProceduresStyles } from "../styles";

const styles = PendingProceduresStyles;

const PendingProcedures = React.forwardRef(({ procedures, disabled }, ref) => {
    const selectedProc = React.useRef([]);

    React.useImperativeHandle(ref, () => {
        return {
            getProcedures() {
                return procedures.map(value => {
                    return {
                        ...value,
                        enabled: selectedProc.current.includes(value.data.id)
                    }
                });
            }
        }
    });

    const onChangeHandler = (value) => {
        selectedProc.current = value;
    }

    return (
        procedures && procedures.length > 0 && <>
            <Divider />
            <Box sx={styles.titleContainer}>
                <Typography variant="h6">
                    Pending Procedures
                </Typography>
                <PendingProceduresList disabled={disabled} procedures={procedures} onChange={onChangeHandler}/>
            </Box>
        </>
    );
});

export default PendingProcedures;

function PendingProceduresList({ procedures, onChange, disabled }) {
    const [checked, setChecked] = React.useState([1]);

    React.useEffect(() => {
        if (procedures) {
            const checked = procedures.filter(f => f.enabled).map(value => value.data.id);
            setChecked(checked);
            onChange(checked);
        }
        // eslint-disable-next-line
    }, [procedures]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        onChange(newChecked);
        setChecked(newChecked);
    };

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {procedures.map((value, index) => {
                const labelId = `checkbox-list-${value.name}`;
                return (
                    <ListItem
                        key={index}
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(value.data.id)}
                                checked={checked.includes(value.data.id)}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        }
                        disablePadding
                    >
                        <ListItemButton disabled={disabled}>
                            <ListItemText id={labelId} primary={value.title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}