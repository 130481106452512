import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
  Stack,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import useTabs from "../../../../hooks/useTabs";
import { LoadingButton } from "@mui/lab";
import { capitalCase } from "change-case";
import ServiceList from "./Sections/ServiceList";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../../Utils/authStored";
import Swal from "sweetalert2";

const _getSelectedProcedures = (procedures, isAddProcedure) => {
  if (procedures.length > 0) {
    procedures = procedures.filter((f) => f.enabled);
    procedures = procedures.map((proc) => {
      proc.checked = isAddProcedure ? false : true;
      proc.children = _mapSelectedProcedure(proc.children);
      return proc;
    });
  }

  return procedures;
};

const _mapSelectedProcedure = (children) => {
  if (children.length > 0) {
    children = children.filter((f) => f.enabled);
    if (children.length > 0) {
      return _getSelectedProcedures(children);
    }
  }

  return children;
};

function Services({ openModal, closeModal, isAddProcedure, onAddProcedure }) {
  isAddProcedure = isAddProcedure ?? false;
  const clinic = getAppCurrentActiveClinic();
  const { currentTab, setCurrentTab } = useTabs(
    isAddProcedure ? "my_services" : "all_services"
  );
  const [selectedProcedures, setSelectedProc] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [clinicProcedures, setClinicProcedures] = React.useState([]);

  const serviceRef = React.useRef();
  // eslint-disable-next-line
  const myProcedures = React.useMemo(
    () =>
      _getSelectedProcedures(
        JSON.parse(JSON.stringify(clinicProcedures)),
        isAddProcedure
      ),
    [clinicProcedures]
  );

  React.useEffect(() => {
    fetchClinicProcedures();
    // eslint-disable-next-line
  }, []);

  const fetchClinicProcedures = async () => {
    const response = await axios.get("clinic_procedures/hierarchical", {
      params: { clinic_id: clinic?.id },
    });
    setClinicProcedures([].concat(response.data));
  };

  const listOnClose = () => {
    if (serviceRef.current)
      setSelectedProc(serviceRef.current.getSelectedProc());
  };

  const handleAddProc = (procedure) => {
    const selectedProc = clinicProcedures.find((f) => f.id === 96);
    if (selectedProc) {
      const index = clinicProcedures.indexOf(selectedProc);
      selectedProc.children.unshift(procedure);
      clinicProcedures[index] = selectedProc;
      setSelectedProc(selectedProcedures.concat([procedure]));
      setClinicProcedures(clinicProcedures);
    }
  };

  const SERVICES_TABS = [
    {
      value: "all_services",
      component: (
        <ServiceList
          ref={serviceRef}
          selectedItems={selectedProcedures}
          data={clinicProcedures}
          onClose={listOnClose}
          onAddProcedure={handleAddProc}
          isAddProcedure={isAddProcedure}
        />
      ),
    },
    {
      value: "my_services",
      component: (
        <ServiceList
          ref={serviceRef}
          selectedItems={selectedProcedures}
          data={myProcedures}
          onClose={listOnClose}
          onAddProcedure={handleAddProc}
          isAddProcedure={isAddProcedure}
        />
      ),
    },
  ];

  const onClose = (_, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      closeModal();
      setTimeout(() => {
        setSelectedProc([]);
        setCurrentTab(SERVICES_TABS[0].value);
        if (serviceRef.current) serviceRef.current.clear();
      }, 200);
    }
  };

  const onChangeTab = (_, val) => {
    setCurrentTab(val);
    if (serviceRef?.current) {
      setSelectedProc(serviceRef.current.getSelectedProc());
    }
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const selectedProc = serviceRef.current.getSelectedProc();
    if (!isAddProcedure) {
      const payload = {
        clinic_procedures: selectedProc.filter((f) => f.has_changes),
        add_procedures: selectedProc.filter(
          (f) => !f.has_changes && f.new_procedure
        ),
      };

      try {
        const response = await axios.put(
          `clinic_procedures/bulk/${clinic.id}`,
          payload
        );
        if (response.status === 200 || response.status === 201) {
          await fetchClinicProcedures();
          Swal.fire({
            icon: "success",
            title: "Services Saved!",
          });
          onClose();
        } else {
          await Swal.fire({
            icon: "error",
            title: "Failed to update services!",
          });
        }
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: "Failed to update services!",
        });
      }
    } else {
      await onAddProcedure(selectedProc);
      onClose();
    }

    setSubmitting(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: { xs: "80%", sm: "80%", lg: "40%", xl: "40%" },
          },
        },
      }}
    >
      <DialogTitle variant="h5" sx={{ backgroundColor: "#fafafa" }}>
        Services
        {isAddProcedure && (
          <Typography color="themeColor.error">
            To enable/disable more procedures go to Settings &gt; Procedures &
            Services
          </Typography>
        )}
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ position: "relative", backgroundColor: "#fafafa", p: 0, m: 0 }}
      >
        <Box
          display={submitting ? "block" : "none"}
          position={"absolute"}
          zIndex={2}
          width={"100%"}
          height={"100%"}
          sx={{ cursor: "not-allowed" }}
        ></Box>
        <Paper
          elevation={2}
          sx={{
            maxWidth: "100% !important",
          }}
        >
          <Stack direction={`column`} spacing={5}>
            <Stack direction={`column`}>
              {!isAddProcedure && (
                <Box
                  position={"sticky"}
                  top={0}
                  left={0}
                  width={"100%"}
                  zIndex={2}
                  sx={{ backgroundColor: "#fff" }}
                >
                  <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTab}
                    onChange={onChangeTab}
                    indicatorColor="primary"
                  >
                    {SERVICES_TABS.map((tab) => (
                      <Tab
                        disableRipple
                        key={tab.value}
                        label={capitalCase(tab.value)}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Box>
              )}

              {!isAddProcedure &&
                SERVICES_TABS.map((tab) => {
                  const isMatched = tab.value === currentTab;
                  return (
                    isMatched && <Box key={tab.value}>{tab.component}</Box>
                  );
                })}
              {isAddProcedure && (
                <ServiceList
                  ref={serviceRef}
                  selectedItems={selectedProcedures}
                  data={myProcedures}
                  onClose={listOnClose}
                  onAddProcedure={handleAddProc}
                  isAddProcedure={isAddProcedure}
                />
              )}
            </Stack>
          </Stack>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={submitting}
          variant={`contained`}
          onClick={saveChanges}
        >
          {isAddProcedure ? "Add Procedure" : "Save Changes"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default Services;
