import React, { useEffect, useState, useCallback } from "react";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Grid,
  Box,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import { useSelector, useDispatch } from "react-redux";
import { dateToShortString } from "../../../Utils/utils";
import { numberWithCommas } from "../../../Utils/utils";
import {
  closeCreateInvoice,
  getInvoiceItems,
} from "../../Appointments/Redux/appointmentSlice";
import {
  fetchInvoiceData,
  totalPreviousBalance,
  fetchPatientDetails,
} from "../../../Pages/Invoices/Redux/invoiceSlice";


const computeSubTotal = (items) => () => {
  return items.reduce((prev, current) => prev += (current.price !== '' ? current.price : 0), 0);
}

const computeTotal = (subTotal, discount, isPercent) => () => {
  let totalPrice;
  discount = discount ?? 0;
  if (isPercent) {
    totalPrice = subTotal - (subTotal * (discount / 100));
  }
  else {
    totalPrice = subTotal - discount;
  }

  return totalPrice;
}

const CreateInvoice = () => {
  const { selectedPatient, procedures, isOpen } = useSelector(
    (store) => store.appointment
  );

  const dispatch = useDispatch();
  const clinic = getAppCurrentActiveClinic();
  const today = new Date();
  const currentDate = today.toLocaleDateString();
  const [discount, setDiscount] = useState(null);
  const [isDiscountInPCT, setIsDiscountInPCT] = useState(false);
  const [inputValues, setInputValues] = useState([]);

  const subTotal = React.useMemo(computeSubTotal(inputValues), [inputValues]);
  const totalPrice = React.useMemo(computeTotal(subTotal, discount, isDiscountInPCT), [subTotal, discount, isDiscountInPCT]);

  useEffect(() => {
    if (isOpen) {
      setInputValues(procedures.filter(f => f.implemented === 1 || f.implemented === true).map((value) => {
        return {
          entity_id: value.clinic_procedure_id,
          entity: 'clinic_procedures',
          discount: 0,
          name: value.name,
          description: value.description,
          procedure_code: value.procedure_code
            ? value.procedure_code
            : null,
          price: value.price,
          quantity: value.quantity ? value.quantity : 1,
        }
      }));
    }
  }, [procedures, isOpen])

  useEffect(() => {
    if (!isOpen) {
      setDiscount(null);
      setInputValues([]);
      setIsDiscountInPCT(false);
    }
  }, [isOpen])

  const numberOnly = (e) => {
    const value = e.target.value;
    if (isDiscountInPCT) {
      setDiscount(value > 100 ? 100 : value);
    }
    else {
      setDiscount(subTotal < value ? subTotal : value);
    }
  };

  const onChangeDiscount = (event) => {
    setIsDiscountInPCT(event.target.checked);
    setDiscount("");
  };

  const viewInvoice = async () => {
    const clinic = getAppCurrentActiveClinic();
    Swal.fire({
      icon: 'info',
      title: 'Fetching Invoice Information',
      text: 'Please wait...',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const appointmentId = selectedPatient.id;
          const invoiceRes = await axios.get(`invoices?appointment_id=${appointmentId}`);
          if (invoiceRes.status === 200) {
            const invoice = invoiceRes.data[0];
            const [invoiceData, invoiceItems, invoicePayments, patientInfo, invoices] = await Promise.all([
              axios.get(`invoices/${invoice.id}`),
              axios.get(`invoice_items?invoice_id=${invoice.id}`),
              axios.get(`invoice/payments/${invoice.id}`),
              axios.get(`clinic_patients/${invoice.patient_id}`),
              axios.get(`invoices?clinic_id=${clinic.id}&patient_id=${invoice.patient_id}`)
            ]);

            const totalprevbalance = invoices.data
              .filter((rows) => rows.status !== "paid" && rows.id !== invoice.id)
              .map((rows) => rows.balance);
            dispatch(totalPreviousBalance(invoices.data.length > 1 ? totalprevbalance : []));
            dispatch(fetchPatientDetails(patientInfo.data))
            dispatch(fetchInvoiceData({
              invoiceDetails: invoiceData.data,
              invoiceItemDetails: invoiceItems.data,
              invoicePayments: invoicePayments.data,
              isFromInvoiceList: true,
            }));
            Swal.close();
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to fetch invoice info',
            text: 'Try again.'
          });
        }
      }
    });
  }

  const createInvoice = async () => {
    const payload = {
      clinic_id: clinic?.id,
      patient_id: selectedPatient.patientID,
      total_price: subTotal,
      discounted_price: totalPrice,
      discount: discount ? discount : 0,
      discount_type: isDiscountInPCT ? "pct" : "value",
      items: inputValues, //invoiceItems,
      appointment_id: selectedPatient.id,
    };

    for (var i in payload.items) {
      if (
        !payload.items[i].price ||
        payload.items[i].price == 0 ||
        !payload.items[i].quantity ||
        payload.items[i].quantity == 0 ||
        !payload.items[i].name
      ) {
        Swal.fire({
          icon: 'error',
          title: "Name, Quantity and Price should not be empty or zero",
        });

        return;
      }
    }

    Swal.fire({
      icon: 'info',
      title: 'Creating Invoice',
      text: 'Please wait...',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();

        try {
          const response = await axios.post("invoices", payload);
          if (response.status === 201) {
            Swal.fire({
              icon: 'success',
              title: 'Invoice created. Invoice can now be seen by staff'
            }).then(() => {
              dispatch(closeCreateInvoice());
              setTimeout(() => {
                viewInvoice();
              }, 200);
            });
          }
        } catch (_) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to create invoice!'
          });
        }
      }
    });
  };

  const handleInputChange = (index, name, value) => {
    const newInputValues = [...inputValues];
    if (name !== 'name' && value) {
      value = parseFloat(value);

      if (name === 'quantity' && value === 0) value = 1;
    }

    newInputValues[index][name] = value;
    setInputValues(newInputValues);
  };

  const handleInputOnBlur = (index, name) => {
    const newInputValues = [...inputValues];
    const value = newInputValues[index][name];
    switch (name) {
      case 'quantity':
        newInputValues[index][name] = !value ? 1 : value;
        break;
      case 'price':
        newInputValues[index][name] = !value ? 0 : value;
        break;
      default:
        break;
    }

    setInputValues(newInputValues);
  }

  const handleAdditionalCharges = () => {
    const hasAdditionalCharges = inputValues.filter(f => f.isAdditionalCharges).length > 0;
    setInputValues(inputValues.concat([{
      description: "",
      discount: 0,
      entity: 'Additional Charge',
      entity_id: null,
      name: "",
      price: 0,
      procedure_code: "None",
      quantity: 1,
      ...(!hasAdditionalCharges ? { isAdditionalCharges: true } : {})
    }]));
  };

  const handleRemoveRow = (index) => {
    const newInputValues = [...inputValues];
    newInputValues.splice(index, 1);
    setInputValues(newInputValues);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          // dispatch(closeCreateInvoice());
        }}
        maxWidth="xl"
        PaperProps={{
          sx: { width: "70%", height: "80%", position: "fixed", top: "10%" },
        }}
      >
        <DialogTitle
          variant="h5"
          sx={{
            backgroundColor: "#fafafa",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Create Invoice
          <Stack direction="row" alignItems="center" spacing={5}>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(closeCreateInvoice());
              }}
            >
              <CloseIcon sx={{ color: "#bdbdbd" }} />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <Paper
            style={{ padding: "10px", position: "relative", marginTop: 2 }}
          >
            <Typography align="right" sx={{ marginBottom: 2, marginRight: 2 }}>
              {dateToShortString(currentDate)}
            </Typography>
            <Grid container>
              <Grid
                xs={6}
                item
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: 10,
                }}
              >
                <Box>
                  <Avatar
                    alt="Remy Sharp"
                    src={clinic?.icon}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ marginLeft: 2, fontWeight: "bold" }}>
                    {clinic?.name}
                  </Typography>
                  <Typography sx={{ marginLeft: 2 }}>
                    {selectedPatient?.dentist}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                xs={6}
                item
                style={{ display: "flex", justifyContent: "start" }}
              >
                <Box>
                  <Avatar
                    alt="Remy Sharp"
                    src={selectedPatient?.avatar}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Typography sx={{ marginLeft: 2, fontWeight: "bold" }}>
                  Bill to:
                </Typography>
                <Typography sx={{ marginLeft: 2 }}>
                  {selectedPatient?.name}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Stack direction={`column`} spacing={2} marginTop={2}>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align={'left'}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#f4f6f8",
                      }}
                    >
                      Procedure / Product
                    </TableCell>
                    <TableCell
                      align={'center'}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#f4f6f8",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align={'center'}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#f4f6f8",
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      align={'right'}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#f4f6f8",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align={'right'}
                      width={'75px'}
                      sx={{
                        backgroundColor: "#f4f6f8",
                      }}
                    >
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*----------------------- INVOICE LIST ------------------------------  */}
                  {inputValues.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        {row.isAdditionalCharges && <TableRow key={index}>
                          <TableCell align="left">
                            <Typography variant="subtitle1">
                              Additional Charges
                            </Typography>
                          </TableCell>
                          <TableCell align="left" rowSpan={1} />
                          <TableCell align="left" rowSpan={1} />
                          <TableCell align="left" rowSpan={1} />
                          <TableCell align="left" rowSpan={1} />
                        </TableRow>}
                        <TableRow key={index}>
                          <TableCell align="left">
                            {row.entity === 'Additional Charge' ? <TextField
                              type="text"
                              variant="standard"
                              sx={{ marginBottom: 2 }}
                              value={row.name ?? ""}
                              fullWidth
                              onChange={(event) =>
                                handleInputChange(index, "name", event.target.value)
                              }
                            /> : <>
                              <Typography variant="subtitle1">
                                {row.name}
                              </Typography>
                              <Typography variant="subtitle2">
                                {row.description}
                              </Typography>
                            </>}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              type="number"
                              variant="standard"
                              sx={{
                                marginBottom: 2, width: 50,
                                input: { textAlign: "center" }
                              }}
                              value={row.quantity ?? ""}
                              inputProps={{
                                min: 1
                              }}
                              onBlur={() => handleInputOnBlur(index, "quantity")}
                              onChange={(event) =>
                                handleInputChange(index, "quantity", event.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              type="number"
                              variant="standard"
                              sx={{
                                marginBottom: 2, width: 120,
                                input: { textAlign: "center" }
                              }}
                              inputProps={{
                                min: 0
                              }}
                              value={row.price ?? ""}
                              onBlur={() => handleInputOnBlur(index, "price")}
                              onChange={(event) =>
                                handleInputChange(index, "price", event.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            Php{" "}
                            {row.quantity && row.price
                              ? numberWithCommas((parseFloat(row.quantity) * parseFloat(row.price)), true)
                              : 0.0}
                          </TableCell>
                          <TableCell align="right">
                            {row.entity === "Additional Charge" && <IconButton
                              onClick={() => {
                                handleRemoveRow(index);
                              }}
                            >
                              <DeleteIcon sx={{ color: "#d32f2f" }} />
                            </IconButton>}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                  <TableRow>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          handleAdditionalCharges();
                        }}
                      >
                        Add Additional Charges
                      </Button>
                    </TableCell>
                    <TableCell align="left" rowSpan={1} />
                    <TableCell align="right" rowSpan={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "700" }}
                      >
                        Subtotal:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      Php {numberWithCommas(subTotal)}
                    </TableCell>
                    <TableCell align="right">
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} />

                    <TableCell align="right">
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "700", color: "#d32f2f" }}
                      >
                        Discount:
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <TextField
                          type="number"
                          variant="standard"
                          sx={{
                            marginBottom: 2,
                            width: '100px',
                            fontWeight: "bold",
                            input: { textAlign: "right" }
                          }}
                          inputProps={{
                            min: 0
                          }}
                          onChange={(e) => {
                            numberOnly(e);
                          }}
                          value={discount ?? ""}
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={isDiscountInPCT}
                              onChange={onChangeDiscount}
                              color="primary"
                            />
                          }
                          label="in percent"
                        />
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        width: "500px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "700" }}
                      >
                        Total:
                        {isDiscountInPCT && (discount !== '0' && discount !== "") && (
                          <Typography
                            sx={{ fontStyle: "italic" }}
                            variant="subtitle1"
                          >
                            Incl. {discount === '' ? 0 : discount} % Discount
                          </Typography>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={{ color: "#d32f2f", fontWeight: "bold" }}>
                        Php {numberWithCommas(totalPrice)}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {/* <TableCell rowSpan={3} colSpan={2}>
                      <Button variant="contained" startIcon={<AddIcon />}>
                        Add Additional Charges
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>Subtotal:</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>Php 500.00</Typography>
                    </TableCell> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => createInvoice()}
          >
            Create Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateInvoice;
