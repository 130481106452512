import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack, TextField, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setAppCurrentActiveClinic, getCurrentLoggedInUser, setClinics, getAppCurrentActiveClinic } from "../../../../Utils/authStored";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddNewClinic = ({
    onOpen,
    closeModal,
    fetchUserClinics,
    currUser
}) => {

    const [clinicData, setClinicData] = useState([])


    const handleClinicInfoChange = (e) => {
        setClinicData({
            ...clinicData,
            [e.target.name]: e.target.value
        })
    }

    const handleAddClinic = async () => {

        Swal.fire({
            icon: 'question',
            title: `${clinicData?.name} will be added on your account. Do you wish to continue?`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            reverseButtons: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (value) => {
            if (value.isConfirmed) {

                await axios.post(`/user/${currUser?.user_id}/clinics`, clinicData).then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: "Clinic Successfully Added!",
                    }).then(() => {
                        fetchUserClinics();
                        closeModal();
                    });

                }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: "Unable to add clinic!",
                    });
                })

            }
        });

    }

    return (
        <Dialog
            open={onOpen}
            onClose={closeModal}
            fullWidth
            maxWidth="md"
            disableScrollLock={true}
            PaperProps={{
                sx: {
                    position: "fixed",
                    top: 15
                },
            }}
        >
            <DialogTitle>
                <Stack direction={`column`} spacing={1} justifyContent={`center`} alignItems={`center`}>
                    <Typography variant="h6">Add New Clinic</Typography>
                    <Typography variant="body2" color="error" sx={{ fontStyle: 'italic' }}>New clinic affects your monthly subscription. See pricing</Typography>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Box
                    sx={{
                        px: 2,
                        py: 3
                    }}
                >
                    <Stack direction={`column`} spacing={2}>
                        <TextField
                            label="Clinic Name"
                            variant="outlined"
                            onChange={(e) => handleClinicInfoChange(e)}
                            name={`name`}
                            required
                        />

                        <TextField
                            label="Branch Address (Optional)"
                            variant="outlined"
                            onChange={(e) => handleClinicInfoChange(e)}
                            name={`branch`}
                        />

                        <TextField
                            label="Clinic Address"
                            variant="outlined"
                            onChange={(e) => handleClinicInfoChange(e)}
                            name={`address`}
                            required
                        />

                        <Stack justifyContent={`flex-end`} alignItems={`center`} direction={`row`} spacing={2}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={closeModal}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleAddClinic()}
                            >
                                Submit
                            </Button>
                        </Stack>

                    </Stack>
                </Box>

            </DialogContent>
        </Dialog>
    )
}


export default function ChangeClinic({
    openModal,
    closeModal
}) {

    const activeClinic = getAppCurrentActiveClinic();

    const user = getCurrentLoggedInUser();

    const navigate = useNavigate();

    const [addNewClinicModal, setAddNewClinicModal] = useState(false)

    const [clinics, setClincs] = useState([])

    const [isMounted, setIsMounted] = useState(false);

    const [isFetching, setIsFetching] = useState(false);

    const fetchUserClinics = async () => {
        setIsFetching(true)
        await axios.get(`/user/clinics/${user?.user_id}`).then(response => {
            setClinics(response.data)
            setClincs(response.data)
        })
        setIsFetching(false)
    }

    const handleSelectedClinic = (data) => {
        setAppCurrentActiveClinic(data);
        if (data?.is_expired) {
            navigate('/dashboard/settings');
            window.location.reload();
        } else {
            navigate("/dashboard/appointments");
            window.location.reload();
        }
    }

    useEffect(() => {
        if (!isMounted) {
            fetchUserClinics();
            setIsMounted(true)
        }
    }, [])

    return (
        <Dialog
            disableScrollLock={true}
            open={openModal}
            onClose={closeModal}
            PaperProps={{
                sx: {
                    position: "fixed",
                    top: 15,
                    width: '100vh'
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
                <Stack
                    direction={`column`}
                >
                    <Typography variant="h6" fontWeight={`700`} gutterBottom>Select Clinic</Typography>
                    <Divider />
                </Stack>
            </DialogTitle>

            <DialogContent sx={{ backgroundColor: "#fafafa" }}>
                <Stack
                    direction={`column`}
                    spacing={4}
                >
                    <Stack
                        direction={`column`}
                        spacing={2}
                        sx={{ mt: 4, mb: 4 }}
                    >
                        {
                            (isFetching) ?
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                >
                                    <CircularProgress />
                                </Box>
                                :
                                clinics.filter(f => f.id !== activeClinic?.id).map(data => (
                                    <>
                                        <Stack
                                            direction={`row`}
                                            justifyContent={`space-between`}
                                            alignItems={`space-between`}
                                            sx={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleSelectedClinic(data)}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {data?.name} {data?.branch ? `(${data?.branch})` : ""}
                                            </Typography>

                                            <ArrowForwardIosIcon />
                                        </Stack>
                                        <Divider />
                                    </>

                                ))
                        }
                    </Stack>

                    <Stack
                        direction={`row`}
                        spacing={2}
                        justifyContent={`flex-end`}
                        alignItems={`flex-end`}
                    >
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setAddNewClinicModal(true)}
                        >
                            Add new clinic
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>

            {
                addNewClinicModal &&
                <AddNewClinic
                    onOpen={addNewClinicModal}
                    closeModal={() => setAddNewClinicModal(false)}
                    fetchUserClinics={fetchUserClinics}
                    currUser={user}
                />
            }
        </Dialog>
    )
}