import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { DateExtractDateTimeLocale } from "../../../Utils/utils";

const initialState = {
  isOpen: false,
  //isOpen conflict with other reducer
  isOpenPatientInfoModal: false,
  isOpenPatientRecModal: false,
  isPatientTableV2Changed: false,
  patientRecModalOptions: {},
  isOpenEdit: false,
  isOpenQuote: false,
  isOpenServices: false,
  serviceModalOptions: {},
  selectedPatient: null,
  patientInfo: {},
  procedures: [],
  appointments: [],
  invoices: [],
  isFetching: false,
  patientImage: "",
  totalPrice: "",
  procedurePrice: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    closeModal: (state, _) => {
      state.isOpen = false;
      state.isOpenPatientInfoModal = false;
      state.patientInfo = {};
    },
    openModal: (state, _) => {
      state.isOpen = true;
      state.isOpenPatientInfoModal = true;
    },

    openEditModal: (state, action) => {
      state.isOpenEdit = true;
    },
    patientRecordModal: (state, action) => {
      state.isOpenPatientRecModal = action.payload.visible;
      if (action.payload?.patientInfo)
        state.patientInfo = action.payload?.patientInfo;
      state.patientRecModalOptions = action.payload?.options ?? {};
    },
    closeEditModal: (state, action) => {
      state.isOpenEdit = false;
    },
    showPatientInfo: (state, action) => {
      const patientInfo = action.payload;
      state.isFetching = true;
      state.patientInfo = {
        ...patientInfo,
        fullname: `${patientInfo.lastname}, ${patientInfo.firstname}`,
        birthdateFormat: moment(patientInfo.birthdate).format("M/D/yyyy"),
        upcomingProcedures: 0,
        upcomingAppointments: 0,
        upcomingInvoices: 0,
      };
      state.isOpen = true;
      state.isOpenPatientInfoModal = true;
    },
    setViewPatientModalData: (state, action) => {
      state.procedures = action.payload.procedures;
      state.appointments = action.payload.appointments;
      action.payload.invoices.sort((a, b) =>
        moment(DateExtractDateTimeLocale(a.created_at)).isBefore(DateExtractDateTimeLocale(b.created_at)) ? 1 : -1
      );
      state.invoices = action.payload.invoices;

      if (state?.patientInfo) {
        state.patientInfo = {
          ...state.patientInfo,
          ...action.payload.patientInfo
        }
        
        var balance = 0;
        let upcomingProcedures = state.procedures.filter(
          (f) => f.status !== "done"
        ).length;
        upcomingProcedures =
          upcomingProcedures > 99 ? "99+" : upcomingProcedures;
        state.patientInfo.upcomingProcedures = upcomingProcedures;

        let upcomingAppointments = state.appointments.filter(
          (f) => f.status === "scheduled"
        ).length;
        upcomingAppointments =
          upcomingAppointments > 99 ? "99+" : upcomingAppointments;
        state.patientInfo.upcomingAppointments = upcomingAppointments;

        let upcomingInvoices = state.invoices.filter((inv) => {
          if (inv.status !== "paid") balance += inv.balance;
          return inv.status !== "paid";
        }).length;
        upcomingInvoices = upcomingInvoices > 99 ? "99+" : upcomingInvoices;
        state.patientInfo.upcomingInvoices = upcomingInvoices;

        state.patientInfo.balance = balance;
        state.patientInfo.isPaid = action.payload.invoices?.length > 0 && action.payload.invoices.at(0).status === 'paid';
        state.patientInfo.isUnpaid = action.payload.invoices?.length > 0 && action.payload.invoices.at(0).status === 'unpaid';
      }

      state.isFetching = false;
    },
    editPatientInfo: (state, action) => {
      state.patientInfo = action.payload;
    },
    openQouteModal: (state, _) => {
      state.isOpenQuote = true;
    },
    closeQouteModal: (state, _) => {
      state.isOpenQuote = false;
    },
    refreshPatientTableV2: (state, action) => {
      state.isPatientTableV2Changed = action.payload;
    },
    openServicesModal: (state, action) => {
      state.isOpenServices = true;
      state.serviceModalOptions = {
        ...state.serviceModalOptions,
        ...(action.payload?.options ?? {}),
      };
    },
    closeServicesModal: (state, _) => {
      state.isOpenServices = false;
      state.serviceModalOptions = {};
    },

    quotationTotal: (state, action) => {
      var priceList = action.payload;
      state.totalPrice = priceList.reduce((accum, currentValue) => {
        return accum + currentValue;
      }, 0);
    },
    quotationSubtract: (state, action) => {
      state.totalPrice = state.totalPrice - action.payload;
    },
    quotationAdd: (state, action) => {
      state.totalPrice = state.totalPrice + action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    updateProcedure: (state, action) => {
      state.procedures = action.payload;
    },
  },
});

export const patientStore = (state) => state.patient;

export const {
  closeModal,
  openModal,
  showPatientInfo,
  patientRecordModal,
  openEditModal,
  closeEditModal,
  editPatientInfo,
  openQouteModal,
  closeQouteModal,
  openServicesModal,
  closeServicesModal,
  quotationTotal,
  refreshPatientTableV2,
  quotationSubtract,
  quotationAdd,
  setViewPatientModalData,
  setIsFetching,
  updateProcedure,
} = patientSlice.actions;
export default patientSlice.reducer;
