import axios from "axios";
import getUuidByString from "uuid-by-string";

const checkOut = async (payload) => {

   const d = new Date();

   const options = {
      method: 'POST',
      url: 'https://pg-sandbox.paymaya.com/checkout/v1/checkouts',
      headers: {
         accept: 'application/json',
         'content-type': 'application/json',
         authorization: `Basic ${btoa(process.env.REACT_APP_PAYMAYA_PUBLIC_KEY)}`
      },
      data: {
         totalAmount: { value: payload?.total_amount_value, currency: 'PHP' },
         buyer: {
            contact: {
               email: payload?.email
            },
            firstName: payload?.firstname,
            lastName: payload?.lastname
         },
         items: [
            {
               amount: {
                  details: {
                     subtotal: payload?.subtotal ?? 0.00,
                     discount: payload?.discount ?? 0.00,
                     serviceCharge: payload?.service_charge ?? 0.00,
                     shippingFee: payload?.shipping_fee ?? 0.00,
                     tax: payload?.tax ?? 0.000
                  },
                  value: payload?.amount_value
               },
               totalAmount: {
                  details: {
                     subtotal: payload?.subtotal ?? 0.00,
                     discount: payload?.discount ?? 0.00,
                     serviceCharge: payload?.service_charge ?? 0.00,
                     shippingFee: payload?.shipping_fee ?? 0.00,
                     tax: payload?.tax ?? 0.00
                  },
                  value: payload?.total_amount_value ?? 0.00
               },
               name: payload?.plan_name,
               quantity: payload?.quantity ?? 1,
               code: payload?.plan_id,
               description: payload?.plan_description
            }
         ],
         metadata: {
            clinic_id: payload?.clinic_id,
            plan_interval: payload?.interval,
            plan_id: payload?.plan_id
         },
         redirectUrl: {
            success: `${process.env.REACT_APP_URL}/dashboard/settings`,
            cancel: `${process.env.REACT_APP_URL}/dashboard/settings`,
            failure: `${process.env.REACT_APP_URL}/dashboard/settings`
         },
         requestReferenceNumber: getUuidByString(d.toString(), 5)
      }
   };

   axios.request(options).then(function (response) {
      window.open(response.data.redirectUrl, '_blank')
   }).catch(function (error) {
      console.log('subscription_error');
   });
}


export default checkOut;