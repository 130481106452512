import { createSlice } from "@reduxjs/toolkit";
import QuoteUtils from "./QuoteUtils";

const initialState = {
    procedures: []
}

const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        addProceduresQuote: (state, action) => {
            const newProc = action.payload;
            state.procedures = QuoteUtils.addProcedures(state.procedures, newProc);
        },
        setCheckedProcQuote: (state, action) => {
            const proc = action.payload.procedure;
            const checkAll = action.payload?.checkAll;
            state.procedures = QuoteUtils.setChecked(state.procedures, proc, checkAll);
        },
        setProcPriceQuote: (state, action) => {
            const payload = action.payload;
            state.procedures = QuoteUtils.setPrice(state.procedures, payload.id, payload.price);
        },
        sortProceduresQuote: (state, action) => {
            const config = action.payload;
            state.procedures = QuoteUtils.sort(state.procedures, config.property, config.order);
        },
        clearProcedureQuote: (state, _) => {
            state.procedures = [];
        }
    }
});

export const {
    addProceduresQuote,
    setCheckedProcQuote,
    setProcPriceQuote,
    sortProceduresQuote,
    clearProcedureQuote
} = quoteSlice.actions;

export default quoteSlice.reducer;