import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppointmentUtils from "../../../../Redux/AppointmentUtils";
import CreateInvoice from "../../../../../Invoices/create-invoice/";
import {
  setSelectedPatient, updateAppointmentStatus,
} from "../../../../Redux/appointmentSlice";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance as axios } from "../../../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { AppointmentStatuses } from "../../../../Redux/AppointmentUtils";
import { LoadingButton } from "@mui/lab";
import { photoBoxBorderStyle } from "../../../../../../Components/Modals/PatienInformationModal/style";

export default function PatientProfile() {
  const { selectedPatient, rawAppointments } = useSelector(
    (store) => store.appointment
  );
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);
  const handleMarkAsDone = async () => {
    setSubmitting(true);
    const appointment = AppointmentUtils.getRawAppointment(rawAppointments, selectedPatient.id);
    appointment.status = AppointmentStatuses.done;
    dispatch(updateAppointmentStatus(appointment));

    dispatch(setSelectedPatient({
      ...selectedPatient,
      isDone: appointment.status === AppointmentStatuses.done,
      isMIA: appointment.status === AppointmentStatuses.mia
    }));

    try {
      const response = await axios.put(`appointments/${appointment.id}`, appointment);
      if (response.status === 200) {
        dispatch(updateAppointmentStatus(appointment));
      }
    } catch (_) {
      Swal.fire({
        icon: 'error',
        title: 'Failed to mark as done!',
        text: 'Try again.'
      });
    }
    setSubmitting(false);
  }

  return (
    <>
      <Paper style={{ padding: "10px", position: "relative", height: '100%' }}>
        <Grid container spacing={1}>
          <Grid
            xs={12}
            sm={4}
            md={4}
            item
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box sx={photoBoxBorderStyle}>
              <Avatar
                alt={selectedPatient.name}
                src={selectedPatient.avatar}
                sx={{ width: 130, height: 130 }}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={4} md={8} item>
            <Typography variant="h6" component="h2" textAlign={{ xs: 'center', sm: 'start' }}>
              {selectedPatient?.name}
            </Typography>
            <Divider />
            <Box style={{ padding: "5px 0 5px 0" }}>
              <Typography variant="body2" fontWeight={"400"} textAlign={{ xs: 'center', sm: 'start' }}>
                {selectedPatient?.dentist}
              </Typography>
              <Typography variant="body1" textAlign={{ xs: 'center', sm: 'start' }}>
                {selectedPatient?.date}, {selectedPatient.time}
              </Typography>
            </Box>
            <Box 
              sx={{ position: {sm: 'absolute'}, bottom: { sm: 5 }, right: { sm: 10, md: 'start' } }}
              display={'flex'}
              justifyContent={{xs: 'center'}}
              alignItems={{xs: 'center'}}>
              {!selectedPatient?.isDone ? (
                <LoadingButton
                  loading={submitting}
                  variant="contained"
                  disabled={!selectedPatient.isToday}
                  color="success"
                  onClick={handleMarkAsDone}
                >
                  MARK AS DONE
                </LoadingButton>
              ) : (
                <CheckCircleIcon
                  color="success"
                  style={{
                    fontSize: "50",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <CreateInvoice />
    </>
  );
}