import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeServicesModal,
  patientStore,
} from "../../Pages/Patients/Redux/patientSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Services from "../../Pages/Quote/table/services-table";
import { addProceduresQuote } from "../../Pages/Quote/Redux/QuoteSlice";
const ServicesModal = () => {
  const dispatch = useDispatch();
  const { isOpenServices, serviceModalOptions } = useSelector(patientStore);
  const modalOptions = serviceModalOptions;
  const serviceProc = React.useRef();

  const addProcedures = () => {
    if (modalOptions?.page === "Quote") {
      dispatch(addProceduresQuote(serviceProc.current.getSelectedProc()));
      dispatch(closeServicesModal());
    }
  };

  return (
    <div>
      <Dialog
        disableScrollLock={true}
        open={isOpenServices}
        onClose={() => {
          dispatch(closeServicesModal());
        }}
        maxWidth="lg"
        PaperProps={{ sx: { height: "70%", position: "fixed", top: 15 } }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            size="small"
            onClick={() => dispatch(closeServicesModal())}
          >
            <CloseIcon sx={{ color: "#bdbdbd" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* <Divider
            orientation="horizontal"
            flexItem
            sx={{ borderRightWidth: 5, marginTop: 1, marginBottom: 2 }}
          /> */}
          <Services ref={serviceProc} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch(closeServicesModal());
              }}
              disableElevation
              sx={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ fontWeight: "bold" }}
              disableElevation
              onClick={addProcedures}
            >
              Add Procedures
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ServicesModal;
