const FONT_Poppins = 'Poppins, sans-serif'; // Local Font

const typography = {
    fontFamily: FONT_Poppins,
    fontWeightRegular: 400,
    fontWeightMedium: 200,
    fontWeightBold: 700,
    h1: {
        fontFamily: FONT_Poppins,
    },
    h2: {
        fontFamily: FONT_Poppins,
    },
    h3: {
        fontFamily: FONT_Poppins,
    },
    h4: {
        fontFamily: FONT_Poppins,
    },
    h5: {
        fontWeight: '700',
        fontFamily: FONT_Poppins,
    },
    h6: {
        fontFamily: FONT_Poppins,
    },
    subtitle1: {
        fontFamily: FONT_Poppins,
    },
    subtitle2: {
        fontFamily: FONT_Poppins,
    },
    body1: {
        fontFamily: FONT_Poppins,
    },
    body2: {
        fontFamily: FONT_Poppins,
    },
    caption: {
        fontFamily: FONT_Poppins,
    },
    overline: {
        fontFamily: FONT_Poppins,
    },
    button: {
        fontFamily: FONT_Poppins,
        textTransform: 'capitalize',
    },
};

export default typography;
