import React, { useState } from "react";
import {
  Typography,
  Tabs,
  Tab,
  TabPanel,
  FormControl,
  MenuItem,
  Box,
} from "@mui/material";
import Appointments from "../Analytics/Appointments";
import Patients from "../Analytics/Patients";
import Sales from "../Analytics/Sales";
const Analytics = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Typography variant="h5">Analytics</Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={<Typography variant="subtitle1">Sales</Typography>} />
            <Tab
              label={<Typography variant="subtitle1">Patients</Typography>}
            />
            <Tab
              label={<Typography variant="subtitle1">Appointments</Typography>}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Sales />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Patients />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Appointments />
        </TabPanel>
      </Box>
    </>
  );
};

export default Analytics;
