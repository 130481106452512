import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import AppointmentUtils, {
  AppointmentStatuses,
} from "../../Redux/AppointmentUtils";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { updateAppointmentStatus } from "../../Redux/appointmentSlice";
import moment from "moment";
import { userCan } from "../../../../Utils/utils";

const EventDetailsDialog = React.forwardRef((props, ref) => {
  const { selectedPatient, rawAppointments } = useSelector(
    (store) => store.appointment
  );
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [showQuestion, setShowQuestion] = React.useState({
    visible: false,
    isMIA: false,
    isDone: false,
  });
  const [showNoShow, setShowNoShow] = React.useState(false);

  React.useEffect(() => {
    if (selectedPatient?.id) {
      const appointment = AppointmentUtils.getRawAppointment(
        rawAppointments,
        selectedPatient.id
      );
      if (appointment) {
        setShowNoShow(moment(appointment.time_end).isSameOrBefore());
      }
    }
    // eslint-disable-next-line
  }, [selectedPatient]);

  React.useImperativeHandle(ref, () => {
    return {
      show() {
        setOpen(true);
        setShowQuestion({
          visible: false,
          isMIA: false,
        });
      },
    };
  });

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  };

  const viewAppointmentHandler = () => {
    setOpen(false);
    props.viewOnClick();
  };

  const rescheduleAppointHandler = () => {
    setOpen(false);
    window.scrollTo(0, 0);
    props.rescheduleOnClick(selectedPatient);
  };

  const questionContentHandler = (isMIA) => {
    setShowQuestion({
      visible: !showQuestion.visible,
      isMIA: isMIA,
    });
  };

  const cancelAppointment = async (isMIA) => {
    setSubmitting(true);
    const appointment = AppointmentUtils.getRawAppointment(
      rawAppointments,
      selectedPatient.id
    );
    if (appointment) {
      appointment.status = isMIA
        ? AppointmentStatuses.mia
        : AppointmentStatuses.cancelled;
      try {
        const appointmentRes = await axios.put(
          `appointments/${appointment?.id}`,
          appointment
        );
        if (appointmentRes.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Appointment updated successfully!",
          });
          dispatch(updateAppointmentStatus(appointment));
          questionContentHandler(false);
          handleClose();
        }
      } catch (_) {
        await Swal.fire({
          icon: "error",
          title: "Failed to update appointment!",
        });
      }
    }

    setSubmitting(false);
  };

  const centerContent = { display: "flex", justifyContent: "center" };
  const ButtonDivider = () => <Divider style={{ margin: "20px 0 20px 0" }} />;

  return (
    <Dialog
      open={open}
      disableScrollLock={true}
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle variant="h5" width={300}>
        {selectedPatient.name}
        <IconButton
          disabled={submitting}
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/*<h6> cannot appear as a descendant of <p>(DialogContentText) error */}
        <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{selectedPatient.date}</Typography>
        <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{selectedPatient.time}</Typography>
        <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{selectedPatient.dentist}</Typography>
        <ButtonDivider />
        {!showQuestion.visible && (
          <Grid container spacing={2}>
            <Grid xs={12} item style={centerContent}>
              <Button
                variant="contained"
                onClick={viewAppointmentHandler}
                fullWidth
                size="large"
              >
                View Appointment
              </Button>
            </Grid>
            {!selectedPatient?.isDone && userCan('rescheduleAppointments') && (
              <Grid xs={12} item style={centerContent}>
                <Button
                  variant="contained"
                  onClick={rescheduleAppointHandler}
                  fullWidth
                  size="large"
                >
                  Reschedule Appointment
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {!showQuestion.visible &&
          !selectedPatient?.isDone &&
          !selectedPatient?.isMIA && userCan('cancelAppointments') && (
            <>
              <ButtonDivider />
              <Grid container spacing={2}>
                <Grid xs={12} item style={centerContent}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => questionContentHandler(false)}
                    fullWidth
                    size="large"
                  >
                    Cancel Appointment
                  </Button>
                </Grid>
                {showNoShow && (
                  <Grid xs={12} item style={centerContent}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => questionContentHandler(true)}
                      fullWidth
                    >
                      NO SHOW
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        {showQuestion.visible && (
          <QuestionContent
            isSubmitting={submitting}
            isMIA={showQuestion.isMIA}
            onSubmit={cancelAppointment}
            onCancel={() => questionContentHandler(false)}
            onClose={() => {
              setOpen(false);
              setTimeout(() => {
                setShowQuestion({ visible: false, isMIA: false });
              }, 500);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
});

function QuestionContent(props) {
  const ButtonDivider = () => <Divider style={{ margin: "10px 0 10px 0" }} />;

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Typography color={"red"} fontWeight={"bold"}>
            {props.isMIA
              ? "Please confirm that patient did not attend"
              : "Are you sure you want to cancel this appointment?"}
          </Typography>
        </Grid>
      </Grid>
      <ButtonDivider />
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <LoadingButton
            loading={props.isSubmitting}
            variant="contained"
            color="error"
            onClick={() => props.onSubmit(props.isMIA)}
            fullWidth
          >
            {props.isMIA ? "Did not attend" : "Cancel Appointment"}
          </LoadingButton>
        </Grid>
        <Grid xs={12} item>
          <Button
            variant="outlined"
            color="error"
            disabled={props.isSubmitting}
            onClick={props.onCancel}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EventDetailsDialog;
