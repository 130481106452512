import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function MenuItem({ open, title, path, show, icon, iconAlt, onClick, isDisabled = false }) {
    return (
        <ListItem
            component={Link}
            to={path}
            onClick={(e) => isDisabled ? e.preventDefault() : onClick()}
            sx={{
                color: "white",
                display: show ?? true ? "flex" : "none",
                // p: '10px 0 0 3px',
                cursor: isDisabled ? 'not-allowed' : 'pointer'
            }}
        >
            <Tooltip title={open ? null : title} placement='right'>
                <ListItemButton disabled={isDisabled}>
                    <ListItemIcon sx={{ color: "white", minWidth: '50px' }}>
                        <img src={icon} alt={iconAlt} width={30} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography
                                sx={{
                                    fontWeight: "bold"
                                }}
                            >
                                {title}
                            </Typography>
                        }
                    />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}