function QuoteUtils() { }

QuoteUtils.addProcedures = function (oldProc, newProc) {
    // oldProc.forEach((proc) => {
    //     const selectedProc = newProc.find(f => f.id === proc.id);
    //     if (!selectedProc) {
    //         oldProc = oldProc.filter(f => f.id !== proc.id);
    //     }
    // });

    // (newProc ?? []).forEach((proc) => {
    //     const selectedProc = oldProc.find(f => f.id === proc.id);
    //     if (!selectedProc) {
    //         oldProc = [proc].concat(oldProc);
    //     }
    // });
    oldProc = newProc.concat(oldProc);

    return oldProc;
}

QuoteUtils.setChecked = function (procs, proc, checkAll) {
    if (checkAll) {
        const checkedCount = procs.filter(f => f.checked).length;
        if (checkedCount === procs.length) {
            return procs.map(proc => {
                return { ...proc, checked: false}
            });
        }
        else {
            return procs.map(proc => {
                return { ...proc, checked: true}
            });
        }
    }
    else if (proc) {
        const selectedProc = procs.find(f => f.id === proc.id);
        if (selectedProc) {
            const index = procs.indexOf(selectedProc);
            procs[index] = {
                ...selectedProc,
                checked: !proc.checked
            }
        }
    }

    return procs;
}

QuoteUtils.setPrice = function (procs, id, price) {
    const proc = procs.find(f => f.id === id);
    if (proc) {
        const index = procs.indexOf(proc);
        procs[index] = {
            ...proc,
            price: price
        }
    }

    return procs;
}

QuoteUtils.sort = function (procs, property, order) {
    switch (order) {
        case 'asc':
            procs.sort((a, b) => a[property] > b[property] ? 1 : -1);
            break;
        case 'desc':
            procs.sort((a, b) => a[property] < b[property] ? 1 : -1);
            break;
        default:
            break;
    }

    return procs;
}

export default QuoteUtils;