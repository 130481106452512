import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { getIsAuthenticated, getVersion, clearAuth } from "../Utils/authStored";

DashboardGuard.propTypes = {
    children: PropTypes.node
}

export default function DashboardGuard({ children }) {

    const isAuthenticated = getIsAuthenticated();
    
    const version = getVersion();

    const { pathname } = useLocation();

    if (version === undefined || version === null) {
        clearAuth();
        return <Navigate to={"/auth/login"} replace />; 
    } else {
        if (version !== process.env.REACT_APP_MARGO_VERSION) {
            clearAuth();
            return <Navigate to={"/auth/login"} replace />; 
        }
    }

    // for now need concrete condition
    if (isAuthenticated && isAuthenticated === 'true') {
        return <>{children}</>;
    }

    if (pathname === `/auth/register-doctor/${pathname.split("/")[3]}` || pathname === `/auth/register-doctor-specialization/${pathname.split("/")[3]}`) {
        return <>{children}</>;
    }

    return <Navigate to={"/auth/login"} replace />; 
    
}   