import axios from "axios";
import { getUserToken, clearAuth } from "./authStored";
import { useNavigate } from "react-router-dom";

const NavigateToLogin = () => {
  const navigate = useNavigate();
  return navigate("/auth/login");
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosNoAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getUserToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      clearAuth();
      NavigateToLogin();
    } else {
      return Promise.reject(
        (error.response && error.response.data) || "Something went wrong"
      );
    }
  }
);

axiosNoAuthInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export { axiosInstance, axiosNoAuthInstance };
