import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { getIsAuthenticated } from "../Utils/authStored";
import { useLocation } from "react-router-dom";

GuestGuard.propTypes = {
    children: PropTypes.node
}

export default function GuestGuard({ children }) {

    const { pathname } = useLocation();

    const isAuthenticated = getIsAuthenticated();

    if (pathname === `/auth/register-doctor-specialization/${pathname.split("/")[3]}`) {
        return <>{children}</>
    }

    if (isAuthenticated && isAuthenticated === 'true') {
        return <Navigate to={"/dashboard/appointments"} />; 
    }
    
    if ( pathname === '/auth' || pathname === '/') {
        return <Navigate to={"/auth/login"} />
    }
    
    return <>{children}</>
}   