import { useMemo } from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  responsiveFontSizes,
  StyledEngineProvider,
} from "@mui/material/styles";
import ComponentsOverrides from "./overrides";
import typography from "./typography";

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const customPalette = {
    themeColor: {
      primary: "#1976d2",
      success: "#49b545",
      error: "#ed4763",
    },
  };

  const themeOptions = useMemo(() => ({
      palette: customPalette,
      typography,
      // eslint-disable-next-line
    }),
    []
  );

  let theme = createTheme(themeOptions);
  theme = responsiveFontSizes(theme);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
