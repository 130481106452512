import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { getAppCurrentActiveClinic, getCurrentLoggedInUser } from "../../../Utils/authStored";
import ChangeClinic from "./modal/clinicSelection";
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarVisibility, sidebarStore } from "../../../Container/SideNavigationBar.js/Redux/sideNavigationSlice";
import useIsTablet from "../../../Utils/UseIsTablet";

const HeaderBar = () => {
  const { sidebarOpened } = useSelector(sidebarStore);
  const dispatch = useDispatch();
  const { isTablet } = useIsTablet();
  const user = getCurrentLoggedInUser();

  const clinic = getAppCurrentActiveClinic();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showClinicSelect, setShowClinicSelect] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack
      id="headerBar"
      direction={`row`}
      justifyContent={`space-between`}
      alignItems={`space-between`}
      position={"fixed"}
      top={0}
      left={0}
      width={"100%"}
      height={"65px"}
      // paddingLeft={sidebarOpened ? "200px" : (isTablet ? 0 : '68px')}
      paddingLeft={sidebarOpened ? "235px" : '10px'}
      zIndex={3}
      sx={{
        backgroundColor: "#FFFFFF", boxShadow: "0 -17px 23px #000000a1",
        transition: 'all 300ms ease-in-out'
      }}
    >
      <Box
        width={'100px'}
        display={'flex'}
        alignItems={'center'}
        paddingLeft={'10px'}>
        <Button
          variant="contained"
          onClick={() => dispatch(setSidebarVisibility())}
          sx={{
            maxWidth: '50px',
            minWidth: '50px',
            backgroundColor: "#004d81",
            color: '#ffffff',
            '&:hover': {
              backgroundColor: "#004d81",
            },
          }}>
          <MenuIcon sx={{ color: 'white' }} />
        </Button>
        {/* {!isTablet && !sidebarOpened && <IconButton onClick={() => dispatch(setSidebarVisibility())}>
          <ArrowForwardIosIcon sx={{ color: '#004d81' }} />
        </IconButton>} */}
      </Box>
      <Box
        sx={{
          px: 2,
          py: 2,
          width: '100%'
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
          {clinic?.name} {clinic?.branch ? `(${clinic?.branch})` : ""}
        </Typography>
      </Box>

      <Box
        //position={'absolute'}
        height={"100%"}
        display={"flex"}
        justifyContent={'end'}
        alignItems={"center"}
        sx={{
          pr: "15px",
          width: 100
        }}
      >
        <Tooltip arrow title="Clinic Profile" placement="left-start">
          <Avatar
            sx={{
              width: { xs: 20, sm: 50, md: 50 },
              height: { xs: 20, sm: 50, md: 50 },
              border: 1,
              cursor: "pointer",
            }}
            src={clinic?.icon}
            onClick={handleClick}
          >
            A
          </Avatar>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            width={`250px`}
            sx={{
              py: 1,
              px: 1,
            }}
          >
            <Stack direction={`column`} spacing={2}>
              <Stack
                direction={`column`}
                justifyContent={`center`}
                alignItems={`center`}
                sx={{
                  px: 1,
                  py: 1,
                }}
              >
                <Typography variant="h6">{clinic?.name}</Typography>
                <Typography variant="caption">{clinic?.branch}</Typography>
              </Stack>
              <Divider />
              <MenuItem
                disabled={user?.type === "staff" || user?.clinics.length === 1}
                onClick={() => {
                  setShowClinicSelect(true);
                  setAnchorEl(null);
                }}
              >
                <Stack
                  direction={`row-reverse`}
                  justifyContent={`space-between`}
                  alignItems={`center`}
                >
                  <Typography variant="subtitle1">Select Clinic</Typography>
                  <NavigateNextIcon />
                </Stack>
              </MenuItem>
            </Stack>
          </Box>
        </Popover>
      </Box>
      {showClinicSelect && (
        <ChangeClinic
          openModal={showClinicSelect}
          closeModal={() => setShowClinicSelect(false)}
        />
      )}
    </Stack>
  );
};

export default HeaderBar;
