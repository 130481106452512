import rootReducer from "./reducers";
import { applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

const middlewareEnhancer = applyMiddleware(thunk);

const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = configureStore({ reducer: rootReducer }, composedEnhancers);

export default store;
