import React, { useEffect, useRef, useState } from "react";
import { userMembers } from "../data";
import { lightBlue } from "@mui/material/colors";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  Stack,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  getAppCurrentActiveClinic,
  setAppCurrentActiveClinic,
} from "../../Utils/authStored";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PasswordIcon from "@mui/icons-material/Password";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import {
  getCurrentLoggedInUser,
  getPermissibles,
} from "../../Utils/authStored";
import SaveIcon from "@mui/icons-material/Save";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import ChangePassword from "../../Container/SideNavigationBar.js/modal/change-password";
import { LensBlurTwoTone } from "@mui/icons-material";
import { userCan } from "../../Utils/utils";
import useBilling from "../../hooks/useBilling";

const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

const PermissionsModal = ({
  onOpen,
  closeModal,
  selectedUser,
  fetchClinicUsers,
}) => {
  const user = getCurrentLoggedInUser();

  const permissibles = getPermissibles(selectedUser.id);

  const [perm, setPerm] = useState([]);
  const [permList, setPermList] = useState([]);
  const handleSavePermissions = async () => {
    const mergedArray = permissibles.map((obj) => {
      const matchingObj = perm.find((item) => item.name === obj.name);

      if (matchingObj) {
        return { ...obj, ...matchingObj };
      }
      return obj;
    });

    let perms = mergedArray
      .filter((p) => {
        return p.enabled;
      })
      .map((p) => {
        return p.name;
      });

    perms.push("viewAllClinicAppointments");
    if (selectedUser?.user_type === "staff") {
      perms.push("viewPatientsList");
    }

    if (selectedUser?.user_type === "doctor") {
      perms.push("createAppointments");
      perms.push("rescheduleAppointments");
      perms.push("cancelAppointments");
    }

    Swal.fire({
      icon: "question",
      title: `Are you sure you want to save these permission for ${selectedUser?.name}?`,
      showCancelButton: true,
      cancelButtonColor: "#ed4763",
      confirmButtonText: "Confirm",
      closeOnConfirm: false,
      closeOnCancel: false,
      reverseButtons: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return new Promise((resolve) => {
          axios
            .put(`/clinic_users`, {
              permissions: JSON.stringify(perms),
              clinic_id: selectedUser?.clinic_id,
              user_id: selectedUser?.id,
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Successfully Saved!",
              }).then(() => {
                fetchClinicUsers();
                closeModal();
                resolve();
                setPerm([]);
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Failed to save permissions!",
                text: error.errors,
              }).then(() => {
                resolve();
              });
            });
        });
      },
    });
  };

  const handlePermissableChanged = async (row, event, i) => {
    let updatedPermission = { ...row, enabled: event.target.checked };
    let updatedPermissibles = [];

    updatedPermissibles = { ...permList, [i]: updatedPermission };
    setPermList(updatedPermissibles);

    let updatedPermissiblesArray = Object.values(updatedPermissibles);

    // for (let i in permissibles) {
    //   if (permissibles[i].id == row.id) {
    //     permissibles[i].enabled = !row.enabled;
    //   }
    // }
    setPerm(updatedPermissiblesArray);
  };

  function handleCloseModal() {
    closeModal();
    setPerm([]);
  }

  return (
    <Dialog
      open={onOpen}
      onClose={closeModal}
      disableScrollLock={true}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack
          justifyContent={`space-between`}
          alignItems={`center`}
          direction={`row`}
        >
          <Typography variant="h6">Edit Permissions</Typography>
          <CloseIcon onClick={handleCloseModal} sx={{ cursor: "pointer" }} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        {permissibles.map(
          (row, i) =>
            ((selectedUser.type === "doctor" &&
              (row.label === "Add Patients" ||
                row.label === "Delete Patient")) ||
              selectedUser.type === "staff") && (
              <Stack
                id={i}
                justifyContent={`space-between`}
                alignItems={`center`}
                direction={`row`}
              >
                <Typography variant="subtitle1">{row.label}</Typography>
                <Checkbox
                  defaultChecked={row.enabled}
                  onChange={(event) => handlePermissableChanged(row, event, i)}
                />
              </Stack>
            )
        )}
        <Stack
          justifyContent={`flex-end`}
          alignItems={`center`}
          direction={`column`}
          spacing={2}
          sx={{ mt: 5 }}
        >
          <Button
            variant={`contained`}
            color={`success`}
            fullWidth
            size="medium"
            startIcon={<SaveIcon />}
            onClick={() => handleSavePermissions()}
          >
            Save Permissions
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const MemberUsersTable = () => {
  const user = getCurrentLoggedInUser();

  const clinic = getAppCurrentActiveClinic();

  const { subscriptionInfo } = useBilling();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [clinicUsers, setClinicUsers] = useState(clinic?.users ?? []);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const [isStaff, setIsStaff] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchClinicUsers = async () => {
    const response = await axios.get(`/clinic_users/${clinic.id}`);
    setAppCurrentActiveClinic({ ...clinic, users: response.data.clinic_users });
    setClinicUsers(response.data.clinic_users);
  };

  const handleClick = (event, row) => {
    setIsStaff(row.user_type !== "staff" ? true : false);
    setSelectedUser(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleRemoveUser = () => {
    setAnchorEl(null);
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to remove this user?",
      showCancelButton: true,
      confirmButtonColor: "#ed4763",
      cancelButtonColor: "#28a745",
      confirmButtonText: "Confirm Delete",
      reverseButtons: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          try {
            axios
              .delete(`/clinic_users/${clinic.id}/${selectedUser.id}`)
              .then((response) => {
                Swal.fire({
                  icon: "success",
                  title: "Successfully deleted!",
                }).then(() => {
                  fetchClinicUsers();
                  resolve();
                });
              });
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Failed to remove user!",
              text: "Try again.",
            }).then(() => {
              resolve();
            });
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleEditPermission = () => {
    setAnchorEl(null);
    setShowPermissionsModal(true);
  };

  const handleStaffChangePassword = () => {
    setAnchorEl(null);
    setChangePasswordModal(true);
  };

  useEffect(() => {
    fetchClinicUsers();
  }, []);

  const index = clinic.users.findIndex((obj) => obj.id === user.user_id);
  const userIsAdmin = clinic.users[index];

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}> </TableCell>
              <TableCell align="center" sx={tableHeader}>
                Role
              </TableCell>
              <TableCell sx={tableHeader}>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clinicUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Stack direction={`row`} alignItems={`center`} spacing={2}>
                      <Avatar
                        sx={{ bgcolor: lightBlue[500] }}
                        src={row?.avatar}
                      >
                        {row?.name.charAt(4).toUpperCase()}
                      </Avatar>
                      <Stack direction={`column`}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 700 }}
                        >
                          {row?.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          {row?.email}
                        </Typography>
                        <Typography variant="subtitle2">
                          {row?.username}
                        </Typography>
                        <Typography variant="subtitle2">
                          Added on {row?.created_at}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack alignItems={`center`}>
                      {row?.isAdmin ? (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            backgroundColor: "#5076e6",
                            color: "white",
                            borderRadius: 4,
                            py: 0.5,
                            px: 2,
                          }}
                        >
                          Clinic Admin
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            py: 0.5,
                            px: 2,
                          }}
                        >
                          {row?.type === "doctor"
                            ? "Dentist"
                            : row?.type.charAt(0).toUpperCase() +
                              row?.type.slice(1)}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>

                  <TableCell>
                    {user?.type === "doctor" && userIsAdmin.isAdmin === 1 ? (
                      <IconButton sx={{ padding: 1 }} disabled={subscriptionInfo?.is_expired}>
                        {!row?.isAdmin && (
                          <SettingsIcon
                            color="primary"
                            onClick={(e) => handleClick(e, row)}
                          />
                        )}

                        <Menu
                          disableScrollLock={false}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem onClick={() => handleEditPermission()}>
                            <Stack direction={`row`} spacing={2}>
                              <EditIcon />
                              <Typography variant="subtitle1">
                                Edit Permisisions
                              </Typography>
                            </Stack>
                          </MenuItem>
                          <MenuItem onClick={() => handleRemoveUser()}>
                            <Stack direction={`row`} spacing={2}>
                              <DeleteIcon />
                              <Typography variant="subtitle1">
                                Remove User
                              </Typography>
                            </Stack>
                          </MenuItem>
                          <MenuItem
                            disabled={isStaff}
                            onClick={() => handleStaffChangePassword()}
                          >
                            <Stack direction={`row`} spacing={2}>
                              <PasswordIcon />
                              <Typography variant="subtitle1">
                                Change Password
                              </Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </IconButton>
                    ) : (
                      <IconButton sx={{ padding: 1 }} disabled>
                        {!row?.isAdmin && (
                          <SettingsIcon
                            color="#eeeeee"
                            onClick={(e) => handleClick(e, row)}
                          />
                        )}

                        <Menu
                          disableScrollLock={false}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem onClick={() => handleEditPermission()}>
                            <Stack direction={`row`} spacing={2}>
                              <EditIcon />
                              <Typography variant="subtitle1">
                                Edit Permisisions
                              </Typography>
                            </Stack>
                          </MenuItem>
                          <MenuItem onClick={() => handleRemoveUser()}>
                            <Stack direction={`row`} spacing={2}>
                              <DeleteIcon />
                              <Typography variant="subtitle1">
                                Remove User
                              </Typography>
                            </Stack>
                          </MenuItem>
                          <MenuItem
                            disabled={isStaff}
                            onClick={() => handleStaffChangePassword()}
                          >
                            <Stack direction={`row`} spacing={2}>
                              <PasswordIcon />
                              <Typography variant="subtitle1">
                                Change Password
                              </Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showPermissionsModal && (
        <PermissionsModal
          onOpen={showPermissionsModal}
          closeModal={() => setShowPermissionsModal(false)}
          selectedUser={selectedUser}
          fetchClinicUsers={() => fetchClinicUsers()}
        />
      )}

      {changePasswordModal && (
        <ChangePassword
          openModal={changePasswordModal}
          isStaff={true}
          staffID={selectedUser.id}
          closeModal={() => setChangePasswordModal(false)}
        />
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={clinicUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default MemberUsersTable;
