import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";

import PieChart from "../Graph/PieChart";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import PatientsTable from "./patients-table";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
const commonStyles = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: 1,
  width: "5rem",
  height: "5rem",
};

const Patients = () => {
  const clinic = getAppCurrentActiveClinic();
  const [state, setState] = useState({});
  const [demographic, setDemographic] = useState("age");
  const [demoObj, setDemoObj] = useState();
  const [labels, setLabels] = useState();
  const [data, setData] = useState();
  const loadData = async () => {
    try {
      await axios
        .get(`stats/patients?clinic_id=${clinic?.id}`)
        .then(function (response) {
          if (response.status === 200) {
            setState(response?.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  function getLabelsDatas(source) {
    if (source) {
      setLabels(Object.keys(source));
      setData(Object.values(source));
    }
  }

  const fetchData = () => {
    const { demographics } = state;

    if (demographic === "age") {
      const data = demographics?.byAge;
      const label = [];
      for (let key in data) {
        label.push(data[key] + " = " + key);
      }
      if (label && data) {
        setLabels(label);
        setData(Object.values(data));
      }
    } else if (demographic === "sex") {
      getLabelsDatas(demographics?.bySex);
    } else if (demographic === "occupation") {
      getLabelsDatas(demographics?.byOccupation);
    } else if (demographic === "company") {
      getLabelsDatas(demographics?.byCompany);
    }
  };

  useEffect(() => {
    fetchData();
  }, [demographic, state]);

  const handleDemoChange = (event) => {
    setDemographic(event.target.value);
  };

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Stack direction={"row"}>
                <CircularCard value={state.patients_today} color="#4caf50" />
                <Box>
                  <Typography variant="subtitle1">Patients Today</Typography>
                  <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                    Appointments Today
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Stack direction={"row"}>
                <CircularCard
                  value={state.patients_to_recall}
                  color="#ef5350"
                />
                <Box>
                  <Typography variant="subtitle1">
                    Patients to Recall
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                    Patients needed to schedule
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Stack direction={"row"}>
                <CircularCard value={state.active_patients} color="#4caf50" />
                <Box>
                  <Typography variant="subtitle1">Active Patients</Typography>
                  <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                    Past 3 months
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>

        <Stack direction={"column"}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack
              direction={{ sm: "column", md: "row" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ marginBottom: 1 }}
            >
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Demographics
              </Typography>
              <FormControl variant="standard" sx={{ minWidth: 50 }}>
                <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={demographic}
                  onChange={handleDemoChange}
                  sx={{
                    width: 200,
                    height: 50,
                    size: "small",
                  }}
                >
                  <MenuItem value={"age"}>Age</MenuItem>
                  <MenuItem value={"sex"}>Sex</MenuItem>
                  <MenuItem value={"occupation"}>Occupation</MenuItem>
                  <MenuItem value={"company"}>Company</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Divider />

            <Stack direction={"row"} justifyContent={"center"}>
              <PieChart
                labels={labels ? labels : []}
                datas={data ? data : []}
              />
            </Stack>
          </Paper>
        </Stack>
        <Stack direction={"column"}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Patients Need Recall
              </Typography>
              <Divider />

              <PatientsTable patients={state?.patients_recall} />
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default Patients;

function CircularCard({ value, color }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const imagecontainer = {
    borderRadius: "100%",
    width: isSmallScreen ? 50 : 70, // Adjust the width based on the screen size
    height: isSmallScreen ? 50 : 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${color}`,
    marginRight: 2,
  };
  const imagebgcontainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <Box sx={imagecontainer}>
        <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
      </Box>
    </>
  );
}
