import React, { useEffect, useState } from "react";
import InvoiceStatusTable from "../../Components/Tables/InvoiceStatusTable";
import moment from "moment";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Invoices = () => {
  const [value, setValue] = useState(0);
  const [filterStatus, setFilterStatus] = useState(false);
  const [events, setEvent] = useState(1);
  const [filterDate, setFilterDate] = useState({
    action: "",
    datetofilter: "",
  });

  function getCurrentWeek() {
    const startOfWeek = moment().startOf("isoWeek");
    var weekDates = [];
    for (var i = 0; i <= 6; i++) {
      weekDates.push(moment(startOfWeek).add(i, "days").format("YYYY-DD-MM"));
    }
    return weekDates;
  }

  function getCurrentMonth() {
    var dates = [];
    const thisMonth = moment().month();
    const totalDays = moment().daysInMonth();
    for (let i = 1; i <= totalDays; i++) {
      const date = moment().month(thisMonth).date(i);
      dates.push(date.format("YYYY-DD-MM"));
    }

    return dates;
  }
  useEffect(() => {
    if (events === 1) {
      setFilterDate({
        action: "Today",
        datetofilter: moment().format("YYYY-DD-MM"),
      });
    } else if (events === 2) {
      setFilterDate({ action: "This_Week", datetofilter: getCurrentWeek() });
    } else if (events === 3) {
      setFilterDate({ action: "This_Month", datetofilter: getCurrentMonth() });
    } else if (events === 4) {
      setFilterDate({ action: "In_History", datetofilter: "" });
    }
  }, [events]);

  const handleChangeStatus = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyle = {
    fontWeight: "bold",
  };

  const handleChange = (event) => {
    setEvent(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h5">Invoices</Typography>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChangeStatus}
            aria-label="basic tabs example"
          >
            <Tab
              label={<Typography variant="subtitle1">Unpaid</Typography>}
              onClick={() => setFilterStatus(false)}
            />
            <Tab
              label={<Typography variant="subtitle1">Paid</Typography>}
              onClick={() => setFilterStatus(true)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <InvoiceStatusTable filterStatus={filterStatus} filterDate="" />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <FormControl sx={{ width: 200 }} size="small">
              <Select value={events} onChange={handleChange}>
                <MenuItem value={1}>Today</MenuItem>
                <MenuItem value={2}>This Week</MenuItem>
                <MenuItem value={3}>This Month</MenuItem>
                <MenuItem value={4}>In History</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <InvoiceStatusTable
            filterStatus={filterStatus}
            filterDate={filterDate}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Invoices;
