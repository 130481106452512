import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppCurrentActiveClinic,
  getCurrentLoggedInUser,
} from "../../../Utils/authStored";
import PatientProfile from "./Components/PatientProfile";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import AppointmentUtils from "../../../Pages/Appointments/Redux/AppointmentUtils";
import PendingProcedures from "./Components/PendingProcedures";
import ConflictSchedule from "./Components/ConflictSchedule";
import { LoadingButton } from "@mui/lab";
import {
  appendAppointment,
  clearAppointmentModal,
  clearSchedulingPatient,
  rescheduleAppointments,
} from "../../../Pages/Appointments/Redux/appointmentSlice";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import PatientRecordModal from "../PatientRecordModal";
import { patientRecordModal } from "../../../Pages/Patients/Redux/patientSlice";
import { userCan } from "../../../Utils/utils";
import { useTheme } from "@emotion/react";

const conflictDefaultVal = {
  timeConflicts: [],
  docConflicts: [],
  showConflicts: false,
};

const defaultSelectVal = {
  label: "",
  avatar: null,
  id: 0,
};

const useDentists = (setValue, modalOpened) => {
  const [dentists, setDentists] = React.useState([]);
  React.useEffect(() => {
    if (modalOpened) {
      const clinic = getAppCurrentActiveClinic();
      const dentists = clinic?.users ?? [];
      setDentists(
        dentists
          .filter((f) => f.status === "active" && f.type === "doctor")
          .map((value) => mapDentist(value))
      );

      const currentUser = getCurrentLoggedInUser();
      if (currentUser && currentUser?.type === "doctor")
        setValue("doctor_id", mapDentist(currentUser, true));
      else {
        setValue("doctor_id", defaultSelectVal);
      }
    }
    // eslint-disable-next-line
  }, [modalOpened]);

  const mapDentist = (value, isUserInfo) => {
    return {
      label: value.name,
      avatar: value.avatar,
      id: isUserInfo ? value.user_id : value.id,
    };
  };

  return { dentists };
};

const AppointmentModal = React.forwardRef(({ visible }, ref) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const clinic = getAppCurrentActiveClinic();
  const { rawAppointments, schedulePatient, patients, recentlyCreatedPatient } =
    useSelector((store) => store.appointment);
  const hasPatientInfo = schedulePatient.patient?.id !== undefined;

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [procedures, setProcedures] = React.useState([]);
  const [dateHeader, setDateHeader] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [conflicts, setConflicts] = React.useState(conflictDefaultVal);
  const [isToday, setIsToday] = React.useState(false);
  const [timeConfig, setTimeConfig] = React.useState({
    min: null,
    max: null,
  });

  const patientProfRef = React.useRef();
  const pendingProcRef = React.useRef();

  const defaultVal = {
    date: "",
    patient: defaultSelectVal,
    clinic_id: clinic?.id,
    time_start: "",
    time_end: "",
    procedures: [],
    walkin: false,
    doctor_id: defaultSelectVal,
  };

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: defaultVal,
  });
  const watchPatient = watch("patient");

  const { dentists } = useDentists(setValue, open);

  React.useEffect(() => {
    if (visible !== null && visible !== undefined) {
      setOpen(visible);
    }
  }, [visible]);

  React.useEffect(() => {
    if (open && schedulePatient.patient?.id) {
      const patient = schedulePatient.patient;
      const isReschedule = schedulePatient?.isReschedule ?? false;
      setValue("patient", {
        avatar: patient.avatar,
        label: isReschedule
          ? patient.name
          : `${patient.lastname} ${patient.firstname}`,
        id: !isReschedule ? patient.id : patient.patientID,
      });
      if (!isReschedule) {
        setValue("doctor_id", patient.doctorID);
      }
    }
    // eslint-disable-next-line
  }, [schedulePatient, open]);

  React.useEffect(() => {
    if (recentlyCreatedPatient?.id) {
      setValue("patient", {
        avatar: recentlyCreatedPatient.avatar,
        label: `${recentlyCreatedPatient.lastname} ${recentlyCreatedPatient.firstname}`,
        id: recentlyCreatedPatient.id,
      });
    }
    // eslint-disable-next-line
  }, [recentlyCreatedPatient]);

  React.useEffect(() => {
    if (watchPatient === null) {
      setProcedures([]);
      setValue("patient", defaultSelectVal);
    }
    // eslint-disable-next-line
  }, [watchPatient]);

  React.useImperativeHandle(ref, () => {
    return {
      show(date) {
        const clinic = getAppCurrentActiveClinic();
        setOpen(true);
        const selectedDate = moment(date).format("MMM DD, yyyy");
        setValue("time_start", dayjs(date));
        setValue("time_end", dayjs(date).add(1, "hour"));
        setValue("date", selectedDate);
        setDateHeader(selectedDate);
        const isCurrentDate = moment(date).format("L") === moment().format("L");
        setIsToday(isCurrentDate);
        setValue("walkin", isCurrentDate);

        const operatingHours =
          clinic.operating_hours[moment(date).format("dddd").toLowerCase()];
        if (operatingHours) {
          let minStr = operatingHours.open.toString();
          let minTime = dayjs();
          minTime = minTime.hour(
            parseInt(
              minStr.length === 4
                ? minStr.substring(0, 2)
                : minStr.substring(0, 1)
            )
          );
          minTime = minTime.minute(
            parseInt(
              minStr.length === 4
                ? minStr.substring(2, 5)
                : minStr.substring(1, 3)
            )
          );
          minTime = minTime.second(0);

          let maxStr = operatingHours.close.toString();
          let maxTime = dayjs();
          maxTime = maxTime.hour(
            parseInt(
              maxStr.length === 4
                ? maxStr.substring(0, 2)
                : maxStr.substring(0, 1)
            )
          );
          maxTime = maxTime.minute(
            parseInt(
              maxStr.length === 4
                ? maxStr.substring(2, 5)
                : maxStr.substring(1, 3)
            )
          );
          maxTime = maxTime.second(0);
          setTimeConfig({
            min: minTime,
            max: maxTime,
          });
        }
      },
    };
  });

  const mappedPatients = patients.map((value) => {
    return {
      avatar: value.avatar,
      label: `${value.lastname} ${value.firstname}`,
      id: value.id,
    };
  });

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setTimeout(() => {
        setSubmitting(false);
        setConflicts(conflictDefaultVal);
        reset(defaultVal);
        dispatch(clearAppointmentModal());

        //when walk-in btn clicked in Patient Info
        if (!schedulePatient.visible) {
          dispatch(clearSchedulingPatient());
        }
      }, 200);
    }
  };

  const dateOnChange = (field) => (value) => {
    if (conflicts.showConflicts) {
      setConflicts({
        ...conflicts,
        showConflicts: false,
      });
    }

    switch (field) {
      case "From":
        const timeEnd = getValues("time_end");
        let to = dayjs(value);
        to = to.hour(timeEnd.$H);
        to = to.minute(timeEnd.$m);

        if (value.isAfter(to)) {
          to = value.clone().add(1, "hour");
        }
        setValue("time_start", value);
        setValue("time_end", to);
        break;
      case "To":
        const timeStart = getValues("time_start");
        let from = dayjs(value);
        from = from.hour(timeStart.$H);
        from = from.minute(timeStart.$m);

        if (value.isBefore(from)) {
          from = value.clone().subtract(1, "hour");
        }

        setValue("time_start", from);
        setValue("time_end", value);
        break;
      default:
        break;
    }
  };

  const procedureOnLoad = (procedures) => {
    setProcedures(procedures);
  };

  const checkForAppointmentConflicts = (payload) => {
    const clinic = getAppCurrentActiveClinic();
    const isReschedule = schedulePatient?.isReschedule;
    const maxParallel = clinic.parallelAppts;
    const newTimeStart = moment(payload.time_start);
    const newTimeEnd = moment(payload.time_end);
    const selectedDoctorID = payload.doctor_id;
    let todayAppointments = new AppointmentUtils(
      rawAppointments
    ).getAppointmentsByDate(newTimeStart.toDate());
    let conflicts = [],
      docConflicts = [];

    if (isReschedule) {
      //patient.id => appointment id
      todayAppointments = todayAppointments.filter(
        (f) => f.id !== schedulePatient.patient.id
      );
    }

    todayAppointments.forEach((appointment) => {
      const timeStart = moment(appointment.time_start);
      const timeEnd = moment(appointment.time_end);
      const doctorID = appointment.doctor_id;

      const newTimeStartNum = parseInt(
        `${newTimeStart.format("HH")}${newTimeStart.format("mm")}`
      );
      const newTimeEndNum = parseInt(
        `${newTimeEnd.format("HH")}${newTimeEnd.format("mm")}`
      );
      const timeStartNum = parseInt(
        `${timeStart.format("HH")}${timeStart.format("mm")}`
      );
      const timeEndNum = parseInt(
        `${timeEnd.format("HH")}${timeEnd.format("mm")}`
      );

      if (
        (newTimeStartNum >= timeStartNum && newTimeStartNum < timeEndNum) ||
        (timeStartNum >= newTimeStartNum && timeStartNum < newTimeEndNum)
      ) {
        conflicts.push(appointment);

        if (selectedDoctorID && doctorID && selectedDoctorID === doctorID) {
          if (!docConflicts.find((f) => f.doctor_id === selectedDoctorID))
            docConflicts.push(appointment);
        }
      }
    });

    if (maxParallel > conflicts.length) {
      conflicts = [];
    }

    //lunch time is already disabled
    // let opHours = clinic.operating_hours;

    // opHours = opHours[newTimeStart.format('dddd').toLowerCase()];
    // let isClosedLunch = opHours ? opHours.closeAtLunch : false;

    // if (isClosedLunch) {
    //     let lStart = newTimeStart.clone();
    //     lStart.set({
    //         hour: 12,
    //         minute: 0
    //     });

    //     let lEnd = newTimeStart.clone();
    //     lEnd.set({
    //         hour: 13,
    //         minute: 0
    //     });

    //     if (
    //         (newTimeStart.toDate().getTime() >= lStart.toDate().getTime() &&
    //             newTimeStart.toDate().getTime() < lEnd.toDate().getTime()) ||
    //         (newTimeEnd.toDate().getTime() > lStart.toDate().getTime() &&
    //             newTimeEnd.toDate().getTime() <= lEnd.toDate().getTime())
    //     )
    //         conflicts.push({
    //             firstname: 'Lunch',
    //             lastname: 'Time',
    //             time_start: AppointmentUtils.toSQLDateTime(lStart),
    //             time_end: AppointmentUtils.toSQLDateTime(lEnd),
    //         });
    // }

    let finalConflict = {
      timeConflicts: conflicts,
      docConflicts: docConflicts,
    };

    return finalConflict;
  };

  const createAppointment = async (payload) => {
    const appointmentRes = await axios.post("appointments", payload);

    const appointment = appointmentRes.data;
    const appointmentInfoRes = await axios.get(
      `appointments?id=${appointment.id}`
    );

    const appointmentInfo = appointmentInfoRes.data[0];
    Swal.fire({
      icon: "success",
      title: `Appointment set for ${appointmentInfo.firstname} ${appointmentInfo.lastname}`,
    }).then(() => {
      dispatch(appendAppointment(appointmentInfo));
      handleClose();
    });
  };

  const instantCheckUp = async (payload) => {
    const appointmentConflicts = checkForAppointmentConflicts(payload);
    if (
      appointmentConflicts.timeConflicts.length > 0 ||
      appointmentConflicts.docConflicts.length > 0
    ) {
      setConflicts({
        ...conflicts,
        ...appointmentConflicts,
        showConflicts: true,
      });
      return true;
    } else {
      setSubmitting(true);
      setConflicts({
        timeConflict: [],
        docConflicts: [],
        showConflicts: false,
      });

      const patientProcPayload = {
        data: [
          {
            patient_id: payload.patient_id,
            procedure_id: 675, // static from db
            clinic_id: payload.clinic_id,
          },
        ],
      };
      const patientProcRes = await axios.post(
        "patient_procedures/batch_store",
        patientProcPayload
      );
      if (patientProcRes.status === 201 || patientProcRes.status === 200) {
        const procForAppointment = patientProcRes.data.map((proc) => {
          return proc.id;
        });
        payload.procedures = procForAppointment;
        await createAppointment(payload);
      }

      return false;
    }
  };

  const submitAppointment = async (payload, procedures) => {
    payload.procedures = procedures;
    const appointmentConflicts = checkForAppointmentConflicts(payload);
    if (
      appointmentConflicts.timeConflicts.length > 0 ||
      appointmentConflicts.docConflicts.length > 0
    ) {
      setConflicts({
        ...conflicts,
        ...appointmentConflicts,
        showConflicts: true,
      });
      return true;
    } else {
      setSubmitting(true);
      setConflicts({
        timeConflict: [],
        docConflicts: [],
        showConflicts: false,
      });
      await createAppointment(payload);
      return false;
    }
  };

  const rescheduleAppointment = async (payload) => {
    const appointmentConflicts = checkForAppointmentConflicts(payload);
    if (
      appointmentConflicts.timeConflicts.length > 0 ||
      appointmentConflicts.docConflicts.length > 0
    ) {
      setConflicts({
        ...conflicts,
        ...appointmentConflicts,
        showConflicts: true,
      });
      return true;
    } else {
      setSubmitting(true);
      setConflicts({
        timeConflict: [],
        docConflicts: [],
        showConflicts: false,
      });

      const reschedulePayload = {
        //patient.id === appointment.id
        //use patientID for patient id
        id: schedulePatient.patient.id,
        date: payload.date,
        time_start: payload.time_start,
        time_end: payload.time_end,
        rescheduleId: schedulePatient.patient.id,
      };

      const rescheduleRes = await axios.post(
        "appointments/reschedule",
        reschedulePayload
      );
      if (rescheduleRes.status === 201) {
        await Swal.fire({
          icon: "success",
          title: "Appointment rescheduled successfully!",
        });

        const reschedAppointment = rescheduleRes.data;
        reschedulePayload.newID = reschedAppointment.id;
        dispatch(rescheduleAppointments(reschedulePayload));
        handleClose();
      }

      return false;
    }
  };

  const onSubmit = async (payload) => {
    payload.date = AppointmentUtils.toSQLDateTime(payload.time_start.$d);
    payload.patient_id = payload.patient.id;
    payload.time_start = AppointmentUtils.toSQLDateTime(payload.time_start.$d);
    payload.time_end = AppointmentUtils.toSQLDateTime(payload.time_end.$d);
    payload.doctor_id = payload.doctor_id.id;
    delete payload.patient;

    const proc = pendingProcRef.current
      ? pendingProcRef.current.getProcedures()
      : [];
    var procForAppointment = proc
      .filter((proc) => {
        return proc.enabled ? proc.enabled : false;
      })
      .map((proc) => {
        return proc.data.id;
      });

    let isConflict = false;
    const isReschedule = schedulePatient?.isReschedule;
    try {
      if (!isReschedule) {
        if (procForAppointment.length > 0) {
          isConflict = await submitAppointment(payload, procForAppointment);
        } else {
          isConflict = await instantCheckUp(payload);
        }
      } else {
        isConflict = await rescheduleAppointment(payload);
      }

      if (!isConflict && schedulePatient.visible) {
        dispatch(clearSchedulingPatient());
      }
    } catch (_) {
      Swal.fire({
        icon: "error",
        title: `Failed to ${
          isReschedule ? "reschedule" : "create"
        } an appointment`,
      });
    }
  };

  const isFieldError = (value) => {
    return value === undefined ? false : value !== null && value !== "";
  };

  return (
    <>
      <Dialog open={open} disableScrollLock={true} onClose={handleClose}>
        <DialogTitle>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" component="div">
              {!schedulePatient?.isReschedule
                ? `Create Appointment ${dateHeader ? `on ${dateHeader}` : ""}`
                : "Reschedule"}
            </Typography>

            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {!schedulePatient?.isReschedule && (
                <Grid xs={12} md={12} lg={12} item>
                  <PatientProfile
                    ref={patientProfRef}
                    watch={watch}
                    procedureOnLoad={procedureOnLoad}
                  />
                </Grid>
              )}

              {!hasPatientInfo && (
                <Grid xs={12} md={12} lg={12} item>
                  <Controller
                    name="patient"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value || value?.id === 0)
                          return "Please fill out all required fields";
                      },
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        disablePortal
                        freeSolo
                        forcePopupIcon={true}
                        id="patient"
                        options={mappedPatients}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.label}
                            </li>
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        disabled={submitting}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Patient"
                            value={field.value?.id ?? ""}
                            error={isFieldError(errors?.patient)}
                            helperText={errors?.patient?.message}
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                  />
                </Grid>
              )}
              {!schedulePatient?.isReschedule && isToday && (
                <Grid
                  xs={12}
                  md={12}
                  lg={12}
                  item
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Controller
                    name="walkin"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        componentsProps={{
                          typography: { variant: "h6", fontWeight: "bold" },
                        }}
                        control={
                          <Switch
                            checked={field.value}
                            onChange={(value) =>
                              field.onChange(value.target.checked)
                            }
                          />
                        }
                        label="Mark as Walk-in"
                        labelPlacement="start"
                      />
                    )}
                  />
                </Grid>
              )}
              {!schedulePatient?.isReschedule && (
                <Grid xs={12} md={12} lg={12} item>
                  <Controller
                    name="doctor_id"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value || value?.id === 0)
                          return "Please fill out all required fields";
                      },
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        disablePortal
                        freeSolo
                        forcePopupIcon={true}
                        id="dentist"
                        options={dentists}
                        disabled={submitting}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assign Dentist"
                            error={isFieldError(errors?.doctor_id)}
                            helperText={errors?.doctor_id?.message}
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid xs={12} md={6} lg={6} item>
                <Controller
                  name="time_start"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value)
                          return "Please fill out all required fields";
                        const min = value
                          .clone()
                          .hour(timeConfig.min.$H)
                          .minute(timeConfig.min.$m);
                        const max = value
                          .clone()
                          .hour(timeConfig.max.$H)
                          .minute(timeConfig.max.$m);
                        if (value.isBefore(min)) return "Invalid Date";
                        if (value.isAfter(max)) return "Invalid Date";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={isFieldError(errors?.time_start)}
                    >
                      <TimeField
                        {...field}
                        fullWidth
                        label="From"
                        disabled={submitting}
                        minTime={timeConfig.min}
                        maxTime={timeConfig.max}
                        onChange={dateOnChange("From")}
                      />
                      <FormHelperText>
                        {errors?.time_start?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6} item>
                <Controller
                  name="time_end"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value)
                          return "Please fill out all required fields";
                        const min = value
                          .clone()
                          .hour(timeConfig.min.$H)
                          .minute(timeConfig.min.$m);
                        const max = value
                          .clone()
                          .hour(timeConfig.max.$H)
                          .minute(timeConfig.max.$m);
                        if (value.isBefore(min)) return "Invalid Date";
                        if (value.isAfter(max)) return "Invalid Date";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={isFieldError(errors?.time_end)}
                    >
                      <TimeField
                        {...field}
                        fullWidth
                        label="To"
                        disabled={submitting}
                        minTime={timeConfig.min}
                        maxTime={timeConfig.max}
                        onChange={dateOnChange("To")}
                      />
                      <FormHelperText>
                        {errors?.time_end?.message ?? ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {procedures && procedures.length > 0 && (
                <Grid xs={12} md={12} lg={12} item>
                  <PendingProcedures
                    ref={pendingProcRef}
                    procedures={procedures}
                    disabled={submitting}
                  />
                </Grid>
              )}
              {conflicts.showConflicts && (
                <Grid xs={12} md={12} lg={12} item>
                  <ConflictSchedule conflicts={conflicts} />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              {/* <Button type="button" variant="contained" color='error' disabled={submitting} onClick={handleClose}>Cancel</Button> */}
              {!schedulePatient?.isReschedule &&
                recentlyCreatedPatient?.id === undefined &&
                watchPatient?.id === 0 &&
                userCan("addPatients") && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      display={"flex"}
                      justifyContent={{ sm: "end" }}
                      alignItems={"center"}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={xs}
                        onClick={() => {
                          dispatch(
                            patientRecordModal({
                              visible: true,
                              options: {
                                isAddPatientAppointment: true,
                              },
                            })
                          );
                        }}
                      >
                        Add New Patient
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      display={"flex"}
                      justifyContent={{ sm: "start" }}
                      alignItems={"center"}
                    >
                      <LoadingButton
                        type="submit"
                        loading={submitting}
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={!userCan("createAppointments")}
                        fullWidth={xs}
                      >
                        {!schedulePatient?.isReschedule
                          ? "Schedule Appointment"
                          : "Reschedule Appointment"}
                      </LoadingButton>
                    </Grid>
                  </>
                )}

              {watchPatient?.id ? (
                <Grid
                  item
                  xs={12}
                  // sm={6}
                  // md={6}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <LoadingButton
                    type="submit"
                    loading={submitting}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={!userCan("createAppointments")}
                    fullWidth={xs}
                  >
                    {!schedulePatient?.isReschedule
                      ? "Schedule Appointment"
                      : "Reschedule Appointment"}
                  </LoadingButton>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <PatientRecordModal />
    </>
  );
});

export default AppointmentModal;
