import React from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Stack,
  Paper,
  IconButton,
} from "@mui/material";
import QuotationTable from "../../Components/Tables/QuotationTable";
import ServicesModal from "../../Components/Modals/ServicesModal";
import { useSelector, useDispatch } from "react-redux";
import {
  closeQouteModal,
  openServicesModal,
} from "../Patients/Redux/patientSlice";
import { addProceduresQuote, clearProcedureQuote } from "./Redux/QuoteSlice";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { numberWithCommas } from "../../Utils/utils";
import Services from "../Settings/Components/Services";

const Quote = ({ isGetQuote }) => {
  const { procedures } = useSelector((state) => state.quote);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();

  const totalPrice = () => {
    return procedures
      .filter((f) => f.checked)
      .reduce((prev, current) => prev + (current?.price ?? 0), 0);
  };

  useEffect(() => {
    dispatch(clearProcedureQuote());
    return () => dispatch(clearProcedureQuote());
    // eslint-disable-next-line
  }, []);

  const handleOnAddProcedure = async (procedure) => {
    dispatch(addProceduresQuote(procedure));
  };

  return (
    <>
      <Box component={Paper}>
        <Grid container>
          <Grid
            sm={6}
            item
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            sx={{ pl: 2, pt: 1 }}
          >
            <Typography variant="h5">Quotation</Typography>
          </Grid>
          <Grid
            sm={6}
            item
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            sx={{ pr: 2, pt: 1 }}
          >
            {!isGetQuote ? (
              <>
                <Typography variant="h6" sx={{ fontSize: "30px" }}>
                  Total: ₱{" "}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "30px" }}>
                  {procedures ? numberWithCommas(totalPrice()) : "0.00"}
                </Typography>
              </>
            ) : (
              <>
                <IconButton
                  size="small"
                  onClick={() => dispatch(closeQouteModal())}
                >
                  <CloseIcon sx={{ color: "#bdbdbd" }} />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>

        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderRightWidth: 5, marginTop: 1 }}
        />

        <Grid container>
          <Grid sm={12} md={6} lg={6} item>
            <Stack
              direction={{ xs: `column`, md: `row` }}
              display={"flex"}
              spacing={1}
              sx={{ ml: 1, mt: !isGetQuote ? 1 : 0, mb: !isGetQuote ? 1 : 0 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setVisible(true);
                  // dispatch(openServicesModal({
                  //   options: {
                  //     page: 'Quote'
                  //   }
                  // }));
                }}
              >
                Add Procedures
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => dispatch(clearProcedureQuote())}
              >
                Clear All
              </Button>
            </Stack>
          </Grid>
          {isGetQuote && (
            <Grid
              sm={12}
              md={6}
              lg={6}
              item
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              sx={{ pr: 2 }}
            >
              <Typography variant="h6" sx={{ fontSize: "30px" }}>
                Total: ₱{" "}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "30px" }}>
                {procedures ? numberWithCommas(totalPrice()) : "0.00"}
              </Typography>
            </Grid>
          )}
        </Grid>
        <QuotationTable />
      </Box>

      {/* <ServicesModal /> */}
      <Services
        openModal={visible}
        closeModal={() => setVisible(false)}
        isAddProcedure={true}
        onAddProcedure={handleOnAddProcedure}
      />
    </>
  );
};

export default Quote;
