import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";

import {
  closeModal,
  setViewPatientModalData,
} from "../../../Pages/Patients/Redux/patientSlice";

import CloseIcon from "@mui/icons-material/Close";
import PatientProfile from "./sections/PatientProfile";
import PatientDetails from "./sections/PatientDetails";
import PatientMedicalInfo from "./sections/PatientMedicalInfo";

const PatientInformationModal = () => {
  const { isOpen, patientInfo } = useSelector((store) => store.patient);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      PatientInformationModal.initService({
        patientID: patientInfo.id,
        dispatch: dispatch,
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(closeModal())}
      maxWidth="xl"
      PaperProps={{ sx: { height: "100%", position: "fixed", top: 15 } }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#fafafa",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Personal Information</Typography>
        <IconButton size="small" onClick={() => dispatch(closeModal())}>
          <CloseIcon sx={{ color: "#bdbdbd" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Stack
          direction={{
            xs: `column`,
            sm: `column`,
            md: `column`,
            lg: `row`,
            xl: `row`,
          }}
          spacing={2}
        >
          <PatientProfile patientInfo={patientInfo} />
          <PatientDetails patientInfo={patientInfo} />
        </Stack>
        <PatientMedicalInfo />
      </DialogContent>
    </Dialog>
  );
};

PatientInformationModal.initService = async ({ patientID, dispatch }) => {
  const clinic = getAppCurrentActiveClinic();
  const [patientInfo, procedures, appointments, invoices] = await Promise.all([
    axios.get(`patients/${patientID}`),
    axios.get(
      `patient_procedures?clinic_id=${clinic.id}&patient_id=${patientID}`
    ),
    axios.get(`appointments?clinic_id=${clinic.id}&patient_id=${patientID}`),
    axios.get(`invoices?clinic_id=${clinic.id}&patient_id=${patientID}`),
  ]);

  dispatch(
    setViewPatientModalData({
      patientInfo: patientInfo.data,
      procedures: procedures.data,
      appointments: appointments.data,
      invoices: invoices.data,
    })
  );
};

export default PatientInformationModal;
