import PricingButton from "./Components/PricingButton";
import PricingFeatures from "./Components/PricingFeatures";
import PricingHeader from "./Components/PricingHeader";

export default function PricingCard({ title, features, selected, monthly, price, btnTitle, priceDiscount, onClick }) {
    const selectedClass = selected ? ' active ' : ' ';
    return (
        <div className={`generic_content${selectedClass}clearfix`}>
            <PricingHeader title={title} monthly={monthly} price={price} priceDiscount={priceDiscount} />
            <PricingFeatures items={features} />
            <PricingButton onClick={!selected ? onClick : () => {}}>
                {selected ? 'Current Plan' : btnTitle}
            </PricingButton>
        </div>
    )
}