import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const useIsTablet = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  var isLandscape = window.innerWidth > window.innerHeight;
  const checkForDevice = () => sm || (isLandscape && window.innerWidth <= 1024);

  const [isTablet, setIsTablet] = useState(checkForDevice());

  useEffect(() => {
    const handlePageResized = () => {
      setIsTablet(checkForDevice());
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handlePageResized);
      window.addEventListener('orientationchange', handlePageResized);
      window.addEventListener('load', handlePageResized);
      window.addEventListener('reload', handlePageResized);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handlePageResized);
        window.removeEventListener('orientationchange', handlePageResized);
        window.removeEventListener('load', handlePageResized);
        window.removeEventListener('reload', handlePageResized);
      }
    };
    // eslint-disable-next-line
  }, []);

  return {
    isTablet,
  };
};

export default useIsTablet;