import React, { useState } from "react";
import { procedures } from "../data";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  Paper,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import ProcedureNotesModal from "../Modals/PatienInformationModal/sections/PatientMedicalInfo/Components/ProcedureNotesModal";

const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

const ProcedureTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const procedureNotesRef = React.useRef();

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const rows = () => {

    const rows = props.procedures.filter((row) => row?.status?.toLowerCase() === "done");
    rows.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
    return rows;
  };

  const handleProcNotes = (procedure) => () => {
    if (procedureNotesRef.current) {
      procedureNotesRef.current.show(procedure);
    }
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 403 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}>Procedure / Treatment</TableCell>
              <TableCell sx={tableHeader}>Date Performed</TableCell>
              <TableCell sx={tableHeader}>Status</TableCell>
              <TableCell sx={tableHeader}>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((app, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{app?.name}</TableCell>
                    <TableCell>{moment(app?.created_at).format('MMM DD, yyyy hh:mm A')}</TableCell>
                    <TableCell style={{ textTransform: 'capitalize' }}>{app?.status}</TableCell>
                    <TableCell>
                      <IconButton sx={{ padding: 1 }} onClick={handleProcNotes(app)}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={procedures.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ProcedureNotesModal ref={procedureNotesRef} />
    </>
  );
};

export default ProcedureTable;
