import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AppointmentDetailsDialog from "./Dialog/AppointmentDetails";
import EventDetailsDialog from "./Dialog/EventDetailsDialog";
import ViewPatientDialog from "./Dialog/ViewPatientsDialog";
import {
  Box,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import FullCalendarHeader from "./FullCalendarHeader";
import { useDispatch, useSelector } from "react-redux";
import AppointmentUtils, { CalendarViews } from "../Redux/AppointmentUtils";
import AppointmentModal from "../../../Components/Modals/AppointmentModal";
import { appointmentStore, setSchedulingPatient, setSelectedPatient } from "../Redux/appointmentSlice";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import { userCan } from "../../../Utils/utils";
import { sidebarStore } from "../../../Container/SideNavigationBar.js/Redux/sideNavigationSlice";

//event colors
//done = #def1dd
//no show = #ffe0e6

export default function AppointmentCalendar() {
  const { sidebarOpened } = useSelector(sidebarStore);
  const clinic = getAppCurrentActiveClinic();

  const { calendarEvents, rawAppointments, patients, schedulePatient } = useSelector(appointmentStore);
  const dispatch = useDispatch();

  const [slotTime, setSlotTime] = React.useState({
    min: "00:00:00",
    max: "23:59:00"
  });
  const calendarRef = React.useRef();
  const eventDetailsRef = React.useRef();
  const appntmentsDetailsRef = React.useRef();
  const patientDialogRef = React.useRef();
  // const scheduleRef = React.useRef();
  const calendarHeaderRef = React.useRef();
  const appointmentRef = React.useRef();

  //day time indicator
  const initIndicatorContent = React.useRef(false);
  const indicatorContentInterval = React.useRef();

  const buttonText = {
    today: "TODAY",
    month: "MONTH",
    week: "WEEK",
    day: "DAY",
  };

  const calendarValidRange = schedulePatient?.visible ? {
    start: new Date().toISOString().slice(0, 10)
  } : null;

  React.useEffect(() => {
    updateSlotTime();

    return () => {
      if (indicatorContentInterval.current) {
        // eslint-disable-next-line
        clearInterval(indicatorContentInterval.current);
      }
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (calendarRef.current) {
      setTimeout(() => {
        calendarRef.current.getApi().updateSize();
      }, 300);
    }
    // eslint-disable-next-line
  }, [sidebarOpened]);

  const updateSlotTime = (dayOfWeek) => {
    // const clinic = getAppCurrentActiveClinic();
    if (clinic) {
      if (!dayOfWeek) {
        const operatingHours = clinic.operating_hours;
        let min = 2300;
        let max = 100;

        const daysOfWeek = Object.keys(operatingHours);
        if (daysOfWeek.length > 0) {
          daysOfWeek.forEach(key => {
            const value = operatingHours[key];
            if (value.open < min) min = value.open;
            if (value.close > max) max = value.close;
          });

          let minStr = min.toString();
          minStr = minStr.length === 4 ? `${minStr.substring(0, 2)}:${minStr.substring(2, 5)}:00` : `0${minStr.substring(0, 1)}:${minStr.substring(1, 3)}:00`;
          let maxStr = max.toString();
          maxStr = maxStr.length === 4 ? `${maxStr.substring(0, 2)}:${maxStr.substring(2, 5)}:00` : `0${maxStr.substring(0, 1)}:${maxStr.substring(1, 3)}:00`;
          setSlotTime({
            min: minStr,
            max: maxStr
          });
        }
      }
      else {
        const operatingHour = clinic.operating_hours[dayOfWeek];
        if (operatingHour) {
          let minStr = operatingHour.open.toString();
          minStr = minStr.length === 4 ? `${minStr.substring(0, 2)}:${minStr.substring(2, 5)}:00` : `0${minStr.substring(0, 1)}:${minStr.substring(1, 3)}:00`;
          let maxStr = operatingHour.close.toString();
          maxStr = maxStr.length === 4 ? `${maxStr.substring(0, 2)}:${maxStr.substring(2, 5)}:00` : `0${maxStr.substring(0, 1)}:${maxStr.substring(1, 3)}:00`;
          setSlotTime({
            min: minStr,
            max: maxStr
          });
        }
      }
    }
  }

  const dayHeaderFormat = (param) => {
    const date = moment(param.date);
    let headerDate = "";
    headerDate += date.format('dddd').substring(0, 3);
    headerDate += ` ${date.date()}`;
    return headerDate;
  }

  const eventDidMount = (args) => {
    if (AppointmentUtils.isWalkIn(calendarEvents, args.event.id)) {
      args.el.classList.add('fc-event-walkin')
    } else {
      args.el.style.border = `1px solid #ddd`;
    }
  };

  const eventClickHandler = (args) => {
    if (!schedulePatient.visible) {
      const appointmentUtil = new AppointmentUtils(rawAppointments, patients);
      const patient = appointmentUtil.getAppointment(args.event.id);
      dispatch(setSelectedPatient(patient));
      eventDetailsRef.current.show()
    }
  };

  const viewAppointOnClick = (args) => {
    appntmentsDetailsRef.current.show(args);
  };

  const viewPatientsHandler = () => {
    const currentData = calendarRef.current.getApi().getCurrentData();
    patientDialogRef.current.show({
      title: currentData.viewTitle,
      viewType: currentData.currentViewType,
      date: currentData.currentDate
    });
  };

  const rescheduleOnClick = (patientInfo) => {
    dispatch(setSchedulingPatient({
      visible: true,
      patient: patientInfo,
      isReschedule: true
    }));
  };

  const dateOnClick = (args) => {
    const selectedDate = moment(args.date);
    const calendarApi = calendarRef.current.getApi();
    if (args.view.type !== CalendarViews.day) {
      updateSlotTime(selectedDate.format('dddd').toLowerCase());
      calendarApi.changeView(CalendarViews.day, args.date);
      calendarHeaderRef.current.updateDateHeader();
    }
    else {
      // const clinic = getAppCurrentActiveClinic();
      const operatingHours = clinic?.operating_hours[selectedDate.format("dddd").toLowerCase()];
      let isClosedAtLunch = false;
      if (operatingHours) {
        const hour = selectedDate.hour();
        isClosedAtLunch = operatingHours?.closeAtLunch && (hour === 12);
      }

      if (!isClosedAtLunch && selectedDate.isSameOrAfter() && userCan('createAppointments')) {
        appointmentRef.current.show(args.date);
      }
    }
  };

  const dayViewOnClicked = (dayofWeek) => {
    updateSlotTime(dayofWeek);
  }

  const renderEventContent = (args) => {
    return (
      <Box
        width="100%"
        padding="1px 3px 1px 3px"
        overflow="hidden"
        sx={{
          backgroundColor: args.backgroundColor,
        }}
      >
        <Typography fontWeight="bold" color={args.textColor}>
          {args.event.title}
        </Typography>
      </Box>
    );
  };

  const dayCellDidMount = (e) => {
    const today = moment().subtract(1, 'day');
    if (today.isAfter(moment(e.date)) && e.el?.style) {
      e.el.style.backgroundColor = "#f7f7f7";
    }
  }

  const slotLaneClassNames = (e) => {
    const selectedDate = moment(e.view.getCurrentData().currentDate);
    const date = moment(e.date);
    // const clinic = getAppCurrentActiveClinic();
    const operatingHours = clinic?.operating_hours[selectedDate.format("dddd").toLowerCase()];
    if (operatingHours && operatingHours?.closeAtLunch) {
      const hour = date.hour();
      if (hour === 12) {
        return ['closeAtLaunch'];
      }
    }
  }

  const handleViewOnChange = (view) => {
    if (view !== CalendarViews.day) {
      if (indicatorContentInterval.current) {
        clearInterval(indicatorContentInterval.current);
        indicatorContentInterval.current = null;
      }
    }
  }

  const nowIndicatorContent = (args) => {

    const updateTimeGrid = () => {
      const currentDate = moment();
      const [hours, minutes] = currentDate.format('HH:mm').split(':');

      const children = document.querySelector('div.fc-timegrid-slots table tbody').children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const [childHours, childMinutes] = child.children[0].getAttribute('data-time').split(':');
        if (parseInt(`${childHours}${childMinutes}`) < parseInt(`${hours}${minutes}`)) {
          child.children[1].style.backgroundColor = '#F7F7F7';
        }
      }
    }

    try {
      if (args.view.type === CalendarViews.day && !initIndicatorContent.current) {
        initIndicatorContent.current = true;
        setTimeout(() => {
          if (indicatorContentInterval.current) clearInterval(indicatorContentInterval.current);
          updateTimeGrid();
          indicatorContentInterval.current = setInterval(() => {
            updateTimeGrid();
          }, 60000);

          initIndicatorContent.current = false;
        }, 500);
      }
    } catch (_) {}
  }

  const dayCellRender = (args) => {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <Typography fontWeight={"bold"} fontSize={"1.7em"}>
          {args.dayNumberText}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box id="appointmentCalendar" position={"relative"}>
        <FullCalendarHeader
          ref={calendarHeaderRef}
          viewPatients={viewPatientsHandler}
          viewOnChange={handleViewOnChange}
          dayViewOnClick={dayViewOnClicked}
          weekViewOnClick={updateSlotTime}
        />
        <FullCalendar
          ref={(ref) => {
            calendarRef.current = ref;
            if (calendarHeaderRef.current)
              calendarHeaderRef.current.setCalendarRef(ref);
          }}
          dayCellDidMount={dayCellDidMount}
          slotLaneClassNames={slotLaneClassNames}
          dayHeaderContent={null}
          initialView={CalendarViews.month}
          contentHeight={'auto'}
          handleWindowResize={true}
          allDaySlot={false}
          slotMinTime={slotTime.min}
          slotMaxTime={slotTime.max}
          nowIndicator={true}
          nowIndicatorContent={nowIndicatorContent}
          buttonText={buttonText}
          headerToolbar={false}
          eventClick={eventClickHandler}
          dayCellContent={dayCellRender}
          dayMaxEvents={3}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: true,
          }}
          expandRows={true}
          dayHeaderFormat={dayHeaderFormat}
          dateClick={dateOnClick}
          aspectRatio={2}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={calendarEvents}
          eventDidMount={eventDidMount}
          eventContent={renderEventContent}
          validRange={calendarValidRange}
        />
      </Box>

      {/* <RescheduleDialog
        ref={scheduleRef}
        onComplete={rescheduleOnComplete} /> */}
      <ViewPatientDialog
        ref={patientDialogRef}
        onItemClick={eventClickHandler} />
      <AppointmentDetailsDialog
        ref={appntmentsDetailsRef} />
      <EventDetailsDialog
        ref={eventDetailsRef}
        viewOnClick={viewAppointOnClick}
        rescheduleOnClick={rescheduleOnClick}
      />
      <AppointmentModal
        ref={appointmentRef} />
    </>
  );
}