import React from "react";
import {
  IconButton,
  Checkbox,
  Box,
  Collapse,
  Typography,
  Divider,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { numberWithCommas } from "../../../../../Utils/utils";
import AddServiceModal from "../Modals/AddService";

const ServiceList = React.forwardRef(
  (
    {
      selectedItems,
      selectedGroup,
      onGroupClick,
      onItemClick,
      isSubItem,
      data,
      onClose,
      onPriceChanged,
      onAddProcedure,
      isAddProcedure,
    },
    ref
  ) => {
    const [selected, setSelected] = React.useState(selectedItems ?? []);
    const [isOpen, setIsOpen] = React.useState(selectedGroup ?? {});
    const serviceSettingsRef = React.useRef();
    const addServiceRef = React.useRef();

    React.useEffect(() => {
      return () => {
        if (!isSubItem) onClose();
      };
      // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
      setSelected(selectedItems);
      // eslint-disable-next-line
    }, [selectedItems]);

    React.useEffect(() => {
      if (
        !isSubItem &&
        data?.length > 0 &&
        selected.length === 0 &&
        !isAddProcedure
      ) {
        const items = [];
        _getSelectedProcedures(JSON.parse(JSON.stringify(data)), (item) => {
          items.push(item);
        });

        setSelected(items);
      }
      // eslint-disable-next-line
    }, [data]);

    React.useImperativeHandle(ref, () => {
      return {
        getSelectedProc() {
          return serviceSettingsRef.current?.getSelectedProc() ?? selected;
        },
        clear() {
          setSelected([]);
          setIsOpen([]);
        },
      };
    });

    const isSelected = (id) =>
      selected.find((f) => f.id === id)?.checked ?? false;

    const showChildren = (name) => {
      if (!isSubItem) {
        setIsOpen({ ...isOpen, [name]: !(isOpen[name] ?? false) });
      } else {
        onGroupClick(name);
      }
    };

    const itemCheckedHandler = (item, checked) => {
      if (!isSubItem) {
        item = { ...item };
        item.checked = checked;
        item.has_changes = true;
        item.selected = checked;

        let items;
        const selectedItem = selected.find((f) => f.id === item.id);
        if (!selectedItem) {
          item.new_procedure = true;
          item.enabled = true;
          items = selected.concat([item]);
        } else {
          if (item?.new_procedure && !item?.checked) {
            items = selected.filter((f) => f.id !== item.id);
          } else {
            const index = selected.indexOf(selectedItem);
            item.selected = false;
            selected[index] = item;
            items = selected;
          }
        }

        setSelected([].concat(items));
      } else {
        onItemClick(item, checked);
      }
    };

    const onPriceChangedHandler = (item) => {
      if (!isSubItem) {
        item = { ...item };
        const selectedItem = selected.find((f) => f.id === item.id);
        if (selectedItem) {
          const index = selected.indexOf(selectedItem);
          selectedItem.price = item.price;
          selectedItem.has_changes = true;
          selectedItem.selected = selectedItem.checked;
          selected[index] = selectedItem;
          setSelected([].concat(selected));
        }
      } else {
        onPriceChanged(item);
      }
    };

    const handleAddService = (proc) => {
      onAddProcedure(proc);
    };

    const ServiceItem = ({ item }) => {
      const boxStyle = isSubItem
        ? {
          padding: "10px 10px 10px 10px",
          backgroundColor:
            item?.enabled && !isOpen[item.name] ? "#deecf9" : null,
        }
        : {};
      const boxStyle2 = isSubItem
        ? {
          backgroundColor: item?.enabled ? "#deecf9" : null,
          cursor: 'pointer'
        }
        : {};

      return (
        <>
          {item.children.length ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                ...boxStyle,
                mb: isOpen[item.name] ? "10px" : 0,
                "&:hover:not(.Mui-disabled)": {
                  cursor: "pointer",
                },
              }}
              onClick={() => showChildren(item.name)}
            >
              <Typography variant="subtitle1">{item.name}</Typography>
              <Stack direction={"row"}>
                {item.id === 96 && isOpen[item.name] && !isAddProcedure && (
                  <Button
                    variant="contained"
                    onClick={() => addServiceRef.current.show()}
                  >
                    Add Service
                  </Button>
                )}
                <IconButton onClick={() => showChildren(item.name)}>
                  {isOpen[item.name] ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              </Stack>
            </Box>
          ) : (
            <Stack direction={'row'} sx={{ ...boxStyle2 }}>
              <Box
                display="flex" alignItems={"center"}
                sx={{ p: "10px 0 10px 10px" }}
                width={"80%"}
                onClick={() => itemCheckedHandler(item, !isSelected(item.id))}>
                <Box width={"7.5%"}>
                  <Checkbox
                    checked={isSelected(item.id)}
                    size="small"
                    onChange={() => itemCheckedHandler(item, !isSelected(item.id))}
                  />
                </Box>
                <Box width={"92.5%"}>
                  <Stack direction={"column"}>
                    <Typography fontWeight={"normal"}>{item?.name}</Typography>
                    <Box>
                      <Typography variant="subtitle2">
                        {item?.description}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Box width={"18%"} display={'flex'} justifyContent={'end'} alignItems={'center'} sx={{ pr: "10px" }}>
                <PriceComponent
                  id={item.id}
                  price={
                    selected.find((f) => f.id === item.id)?.price ?? item?.price
                  }
                  isChecked={selected.find((f) => f.id === item.id)?.checked}
                  onPressEnter={onPriceChangedHandler}
                  isAddProcedure={isAddProcedure}
                  onClick={() => itemCheckedHandler(item, !isSelected(item.id))}
                />
              </Box>
            </Stack>
          )}

          {item?.children && (
            <Collapse
              in={isOpen[item.name]}
              timeout="auto"
              unmountOnExit
              sx={{
                backgroundColor: isOpen[item.name] && "#fafafa",
              }}
            >
              <ServiceList
                ref={serviceSettingsRef}
                isSubItem={true}
                selectedItems={!isSubItem ? selected : selectedItems}
                selectedGroup={isOpen}
                onGroupClick={showChildren}
                onItemClick={itemCheckedHandler}
                onPriceChanged={onPriceChangedHandler}
                data={item?.children}
                isAddProcedure={isAddProcedure}
              />
            </Collapse>
          )}
        </>
      );
    };

    const itemStyle = !isSubItem
      ? { padding: "15px 10px 15px 20px" }
      : { padding: "0" };

    return (
      <>
        {data.map((item) => {
          return (
            <Box
              key={item.id}
              style={{
                backgroundColor:
                  item?.enabled && !isOpen[item.name] ? "#deecf9" : null,
              }}
            >
              <Divider />
              <Box sx={itemStyle}>
                <ServiceItem item={item} />
              </Box>
            </Box>
          );
        })}
        <AddServiceModal ref={addServiceRef} onSubmit={handleAddService} />
      </>
    );
  }
);

export default ServiceList;

//get selected items
function _getSelectedProcedures(procedures, getItem) {
  if (procedures.length > 0) {
    procedures = procedures.filter((f) => f.enabled);
    procedures = procedures.map((proc) => {
      if (!proc?.parent_id && proc?.parent_id !== 0) {
        getItem({
          ...proc,
          checked: true,
        });
      }
      proc.children = _checkSubProcedure(proc.children, getItem);
      return proc;
    });
  }

  return procedures;
}

function _checkSubProcedure(children, getItem) {
  if (children.length > 0) {
    children = children.filter((f) => f.enabled);
    if (children.length > 0) {
      return _getSelectedProcedures(children, getItem);
    }
  }

  return children;
}

function PriceComponent({
  price,
  id,
  onPressEnter,
  isChecked,
  isAddProcedure,
  onClick
}) {
  price = price ?? 0;
  const [editable, setEditable] = React.useState(false);
  const [value, setValue] = React.useState(price);

  const onChange = (value) => {
    if (!value || value < 0) {
      setValue(0);
    } else setValue(value.replace(/^0+/, ""));
  };

  const onKeyPressed = (ev) => {
    if (ev.key === "Enter") {
      updatePrice();
      ev.preventDefault();
    }
  };

  const updatePrice = () => {
    setEditable(false);
    onPressEnter({
      id: id,
      price: parseFloat(value),
    });
  }

  return !editable ? (
    <div
      onClick={() => {
        if (isChecked && !isAddProcedure) {
          setValue(price);
          setEditable(true);
        }
        else onClick();
      }}
      style={{
        display: isChecked || isAddProcedure ? "block" : "none",
        cursor: !isAddProcedure ? "text" : "pointer"
      }}
    >
      <Typography>
        {price === 0 && !isAddProcedure ? "Input Price" : `₱${numberWithCommas(price)}`}
      </Typography>
    </div>
  ) : (
    <TextField
      inputRef={(input) => input?.focus()}
      onBlur={updatePrice}
      variant="standard"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyPressed}
      type="number"
    />
  );
}
