import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { axiosNoAuthInstance as axios } from "../../../Utils/AxiosHttp";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
export default function ResetPassword() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const token = pathname.split("/")[2];

  const [showpassword, setShowPassword] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [validationError, setValidationError] = useState({});

  const [error, setError] = useState({
    has_error: false,
    message: "",
  });

  const [resetPasswordData, setResetPasswordData] = useState({
    password: "",
    password_confirmation: "",
  });

  const { password, password_confirmation } = resetPasswordData;

  const checkResetPasswordToken = async () => {
    setIsLoading(true);
    const response = await axios
      .get(`/auth/reset-password?token=${token}`)
      .then((response) => {
        setError({
          ...error,
          has_error: false,
          message: "",
        });
        return;
      })
      .catch((error) => {
        setError({
          ...error,
          has_error: true,
          message: error?.errors[0],
        });
        return;
      });
    setIsLoading(false);
  };

  const handleResetPasswordFields = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  const validation = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "The password field is required.";
    }

    if (!values.password_confirmation) {
      errors.password_confirmation =
        "The password confirmation field is required.";
    }
    return errors;
  };

  const onSubmit = async () => {
    if (!password || !password_confirmation) {
      setValidationError(validation(resetPasswordData));

      return;
    }

    const payload = {
      ...resetPasswordData,
      token,
    };

    await axios
      .post("/auth/reset-password", payload)
      .then((response) => {
        navigate("/auth/login");
      })
      .catch((error) => {
        setValidationError(validation(resetPasswordData));

        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: error?.errors[0]
        });
        return;
      });
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  };

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace =
      !data || data.length === 0 || /\s/g.test(data);

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!isMounted) {
      checkResetPasswordToken();
      setIsMounted(true);
    }
  }, []);

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          direction={"column"}
          spacing={2}
          sx={{
            width: "50vh",
          }}
        >
          <Stack direction={"column"}>
            <Typography textAlign="center" variant="h1" color="#004d81">
              MARGO
            </Typography>
            <Typography textAlign="right" color="#747474" sx={{ pr: 4 }}>
              v{process.env.REACT_APP_MARGO_VERSION}
            </Typography>
          </Stack>

          {isLoading ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ px: 20, py: 20 }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div>
              {error?.has_error ? (
                <Box
                  component={Paper}
                  sx={{
                    px: 5,
                    py: 5,
                  }}
                >
                  <Stack direction={`column`} spacing={2}>
                    <Typography color="error" textAlign="center" variant="h6">
                      {error?.message}.
                    </Typography>
                    <Typography
                      color={`primary`}
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/auth/login")}
                    >
                      Back to Login
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                <Stack direction={"column"} spacing={2}>
                  <TextField
                    autoComplete="off"
                    label="New Password"
                    name="password"
                    value={password || ""}
                    required
                    error={Boolean(validationError.password)}
                    helperText={
                      Boolean(validationError.password)
                        ? validationError.password
                        : ""
                    }
                    variant="outlined"
                    type={`${showpassword ? "" : "password"}`}
                    onChange={(e) => handleResetPasswordFields(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showpassword)}
                            edge="end"
                          >
                            {showpassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    onPaste={(e) => handleOnPaste(e)}
                  />

                  <TextField
                    label="Confirm Password"
                    name="password_confirmation"
                    value={password_confirmation || ""}
                    required
                    variant="outlined"
                    error={Boolean(validationError.password_confirmation)}
                    helperText={
                      Boolean(validationError.password_confirmation)
                        ? validationError.password_confirmation
                        : ""
                    }
                    type={`${showpassword ? "" : "password"}`}
                    onChange={(e) => handleResetPasswordFields(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            {showpassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    onPaste={(e) => handleOnPaste(e)}
                  />

                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => onSubmit()}
                  >
                    Reset Password
                  </Button>

                  <Typography
                    color={`primary`}
                    textAlign="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Back to Login
                  </Typography>
                </Stack>
              )}
            </div>
          )}
        </Stack>
      </Box>
    </Container>
  );
}
