import React from "react";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const TermsOfUserModal = ({ open, closeModal }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        disableScrollLock={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              End-User License Agreement (EULA)
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          This End-User License Agreement ("EULA") is a legal agreement between
          you and MARGO.
          <br />
          <br />
          This EULA governs your acquisition and use of MARGO directly from
          LINNEA SYSTEMS. Our Privacy Policy can be found on our website.
          <br />
          <br />
          Please read this EULA carefully before using MARGO.
          <br />
          <br />
          If you register for a free trial of the MARGO, this EULA will also
          govern that trial. By clicking "accept" or installing and/or using the
          MARGO, you are confirming your acceptance and agreeing to become bound
          by the terms of this EULA.
          <br />
          <br />
          If you are entering into this EULA on behalf of a company or other
          legal entity, you represent that you have the authority to bind such
          entity and its affiliates to these terms and conditions. If you do not
          have such authority or if you do not agree with the terms and
          conditions of this EULA, do not install or use My Dental Clinic, and
          you must not accept this EULA.
          <br />
          <br />
          This EULA shall apply only to MARGO supplied by LINNEA SYSTEMS.
          herewith regardless of whether other software is referred to or
          described herein. The terms also apply to LINNEA SYSTEMS updates,
          supplements, Internet-based services, and support services for the
          MARGO, unless other terms accompany those items on delivery. If so,
          those terms apply.
          <br />
          <br />
          <br />
          <h5>License Grant</h5>
          LINNEA SYSTEMS hereby grants you a personal, non-transferable,
          non-exclusive licence to use MARGO on your devices in accordance with
          the terms of this EULA.
          <br />
          <br />
          <br />
          <h5>You are not permitted to:</h5>
          Edit, alter, modify, adapt, translate or otherwise change the whole or
          any part of the MARGO nor permit the whole or any part of the MARGO to
          be combined with or become incorporated in any other software, nor
          decompile, disassemble or reverse engineer MARGO or attempt to do any
          such things Reproduce, copy, distribute, resell or otherwise use MARGO
          for any commercial purpose Allow any third party to use MARGO on
          behalf of or for the benefit of any third party Use MARGO in any way
          which breaches any applicable local, national or international law Use
          MARGO for any purpose that LINNEA SYSTEMS considers is a breach of
          this EULA Intellectual Property and Ownership LINNEA SYSTEMS shall at
          all times retain ownership of MARGO as originally downloaded by you
          and all subsequent downloads of the MARGO by you. MARGO (and the
          copyright, and other intellectual property rights of whatever nature
          in the MARGO, including any modifications made thereto) are and shall
          remain the property of LINNEA SYSTEMS
          <br />
          <br />
          LINNEA SYSTEMS reserves the right to grant licences to use MARGO to
          third parties.
          <br />
          <br />
          <br />
          <h5>Termination</h5>
          This EULA is effective from the date you first use MARGO and shall
          continue until terminated. You may terminate it at any time upon
          written notice to hello@linneasystems.com.
          <br />
          <br />
          It will also terminate immediately if you fail to comply with any term
          of this EULA. Upon such termination, the licenses granted by this EULA
          will immediately terminate and you agree to stop all access and use of
          the MARGO. The provisions that by their nature continue and survive
          will survive any termination of this EULA.
          <br />
          <br />
          <br />
          <h5>Governing Law</h5>
          This EULA, and any dispute arising out of or in connection with this
          EULA, shall be governed by and construed in accordance with the laws
          of the Philippines.
          <br />
          <br />
          <br />
          <h5>Contact Information</h5>
          For questions, complaints or suggestions, you can contact MARGO team
          via hello@linneasystems.com.
          <br />
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TermsOfUserModal;
