import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import {
  getClinics,
  setAppCurrentActiveClinic,
  clearAuth,
} from "../../../../Utils/authStored";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SelectClinic = ({ openModal, closeModal }) => {
  const { pathname } = useLocation();

  const clinics = getClinics();
  const navigate = useNavigate();

  const handleSelectedClinic = (data) => {
    setAppCurrentActiveClinic(data);
    if (data?.is_expired) {
      navigate('/dashboard/settings');
    } else {
      navigate("/dashboard/appointments");
    }
  };

  const handleLogout = () => {
    clearAuth();
    closeModal();
  };

  return (
    <Dialog
      open={openModal}
      disableScrollLock={true}
      onClose={closeModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "45%",
          },
        },
      }}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 15,
          width: "100vh",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
        <Stack direction={`column`}>
          <Typography variant="h6" fontWeight={`700`} gutterBottom>
            Select Clinic
          </Typography>
          <Divider />
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#fafafa" }}>
        <Stack direction={`column`} spacing={4}>
          <Stack direction={`column`} spacing={2} sx={{ mt: 4, mb: 4 }}>
            {clinics?.map((data) => (
              <>
                <Stack
                  key={data.id}
                  direction={`row`}
                  justifyContent={`space-between`}
                  alignItems={`space-between`}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectedClinic(data)}
                >
                  <Typography
                    variant="subtitle1"
                    onClick={() => handleSelectedClinic(data)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {data?.name} {data?.branch ? `(${data?.branch})` : ""}
                  </Typography>

                  <ArrowForwardIosIcon />
                </Stack>
                <Divider />
              </>
            ))}
          </Stack>

          <Stack
            direction={`row`}
            spacing={2}
            justifyContent={`flex-end`}
            alignItems={`flex-end`}
          >
            {pathname !== "/auth/login" && (
              <Button variant="contained" startIcon={<AddIcon />}>
                Add new clinic
              </Button>
            )}

            {pathname === "/auth/login" ? (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            ) : (
              <Button variant="contained" color="error" onClick={closeModal}>
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SelectClinic;
