// Imports: Dependencies
import { combineReducers } from "redux";
// Imports: Reducers
// import loginReducer from "../../Pages/Auth/Login/Redux/reducers";
import authReducer from "../../Pages/Auth/Redux/authSlice";
import patientReducer from "../../Pages/Patients/Redux/patientSlice";
import invoiceReducer from "../../Pages/Invoices/Redux/invoiceSlice";
import appointmentReducer from "../../Pages/Appointments/Redux/appointmentSlice";
import quoteReducer from "../../Pages/Quote/Redux/QuoteSlice";
import settingReducer from "../../Pages/Settings/Redux/settingsSlice";
import billingReducer from "../../Pages/Settings/Components/Billing/Redux/billingSlice";
import sidebarReducer from "../../Container/SideNavigationBar.js/Redux/sideNavigationSlice"

// Redux: Top Level reducers
const appReducer = combineReducers({
  // login: loginReducer,
  sidebar: sidebarReducer,
  auth: authReducer,
  patient: patientReducer,
  invoice: invoiceReducer,
  appointment: appointmentReducer,
  quote: quoteReducer,
  settings: settingReducer,
  billing: billingReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// Exports
export default rootReducer;
