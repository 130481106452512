import { getCurrentLoggedInUser } from "./authStored";
import { getAppCurrentActiveClinic } from "./authStored";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const timeGetMeridian = function (date) {
  return date.getHours() >= 12 ? "PM" : "AM";
};

export const numberWithCommas = function (x, hasDecimals = true) {
  if (!x) return "0";
  if (hasDecimals) x = x.toFixed(2);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateToShortString = function (date) {
  if (typeof date == "string") {
    date = date.replace(/-/g, "/");
    date = new Date(date);
  }

  if (!date) return "";

  var formatted =
    months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();

  return formatted;
};

export const DateExtractDateLocale = (date) => {
  if (!date) return "nan";
  return (
    months[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear() +
    ""
  );
};

export const SQLDateToLocale = function (datestring) {
  datestring.replace(/-/g, "/");
  datestring = new Date(datestring);
  return dateToString(datestring);
};

export const dateToString = function (date) {
  if (typeof date == "string") {
    date = date.replace(/-/g, "/");
    date = new Date(date);
  }

  if (!date) return "";

  var formatted =
    days[date.getDay()] +
    ", " +
    months[date.getMonth()] +
    " " +
    date.getDate() +
    " " +
    date.getFullYear() +
    ", ";

  var hours = date.getHours();
  if (hours > 12) hours -= 12;
  if (hours < 10) hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  formatted += hours + ":" + minutes + " " + timeGetMeridian(date);
  return formatted;
};

export const SQLDateToIosCompatible = function (datestring) {
  if (typeof datestring != "string") return new Date();
  var tmp = datestring.replace(/-/g, "/");
  var date = new Date(tmp);
  return date;
};

export const DateExtractDateTimeLocale = (date) => {
  if (!date) return "nan";
  date = date.replace(" ", "T") + ".000+00:00";

  date = new Date(date);

  // workaround - TODO get user timezone from settings

  return DateExtractDateLocale(date) + " " + DateExtractTimeLocale(date);
};

export const DateExtractTimeLocale = (date) => {
  if (!date) return "nan";
  var hours = date.getHours();
  if (hours > 12) hours -= 12;
  if (hours < 10) hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;

  return hours + ":" + minutes + " " + timeGetMeridian(date) + "";
};

let timer = null;
export function debounce(func) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, 1000);
}

export const userCan = (permission) => {
  const user = findClinicUser();

  if (user && user.isAdmin == 1) {
    return true;
  }

  let currentPermissions = [];
  if (!user || user.permissions === "") {
    currentPermissions = [];
  } else if (typeof user.permissions === "string") {
    currentPermissions = JSON.parse(user.permissions);
  } else {
    currentPermissions = user.permissions;
  }

  return currentPermissions.indexOf(permission) > -1;
};

export const findClinicUser = (user = null) => {
  const clinic = getAppCurrentActiveClinic();

  const currentLoggedInUser = getCurrentLoggedInUser();

  if (clinic) {
    const { users } = clinic;

    for (var i in users) {
      if (user) {
        if (users[i].id == user.id) return users[i];
      } else {
        if (users[i].id == currentLoggedInUser.user_id) return users[i];
      }
    }
  }

  return null;
};
