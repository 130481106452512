import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAppCurrentActiveClinic,
  getCurrentLoggedInUser,
  getSpecialization,
  setAppCurrentActiveClinic,
} from "../../../../Utils/authStored";
import CloseIcon from "@mui/icons-material/Close";
import Specialization from "./specialization";
import { useEffect, useState, useRef } from "react";
import ChangePassword from "./change-password";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import AvatarImage from "../../../../Components/AvatarImage";
import {
  setCurrentLoggedInUser,
  setUserToken,
  isAuthenticated,
  clearAuth,
} from "../../../../Utils/authStored";
import useBilling from "../../../../hooks/useBilling";

const ProfileInformation = ({ openModal, closeModal }) => {
  const patientProfPic = useRef(null);
  const user = getCurrentLoggedInUser();

  const { subscriptionInfo } = useBilling();

  const currentActiveClinic = getAppCurrentActiveClinic();

  const specialization = getSpecialization();
  let nbsp = "\u00A0";
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showSpecialization, setShowSpecialization] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const defaultVal = {
    firstname: user?.doctor_information?.firstname,
    middlename: user?.doctor_information?.middlename,
    lastname: user?.doctor_information?.lastname,
    email: user?.email,
    username: user?.username,
    birthdate: user?.doctor_information?.birthdate,
    license_number: user?.doctor_information?.license_number,
    name: user?.name,
  };

  const [state, setState] = useState(defaultVal);

  const {
    firstname,
    middlename,
    lastname,
    email,
    username,
    birthdate,
    license_number,
    name,
  } = state;

  const validation = (values) => {
    const errors = {};
    const emailRegex = `/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/`;

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.firstname) {
      errors.firstname = "Firstname is required";
    }
    if (!values.lastname) {
      errors.lastname = "Lastname is required";
    }
    // if (!values.middlename) {
    //   errors.middlename = "Middlename is required";
    // }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.license_number) {
      errors.license_number = "License is required";
    }
    if (!values.birthdate) {
      errors.birthdate = "Birthdate is required";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    if (user?.type === "staff") {
      if (!name || !username) {
        setValidationError(validation(state));
      } else {
        onSubmit();
      }
    }

    if (user?.type === "doctor") {
      if (
        !firstname ||
        !lastname ||
        !email ||
        !username ||
        !birthdate ||
        !license_number
      ) {
        setValidationError(validation(state));
      } else {
        setValidationError(validation(state));
        onSubmit();
      }
    }
  };

  const onImageLoaded = async (payload) => {
    patientProfPic.current = payload;
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    let response = {};
    if (user?.type === "doctor") {
      const stateVal = { ...state };

      let payload = {
        firstname: stateVal.firstname,
        middlename: stateVal.middlename,
        lastname: stateVal.lastname,
        email: stateVal.email,
        username: stateVal.username,
        birthdate: stateVal.birthdate,
        license_number: stateVal.license_number,
      };
      response = await axios.put("doctors/" + user?.user_id, payload);
    } else {
      const stateVal = { ...state };

      let payload = {
        name: stateVal?.name,
        username: stateVal?.username,
      };

      response = await axios.put("staff/" + user?.user_id, payload);
    }

    if (
      (response.status === 200 && user?.type === "doctor") ||
      user?.type === "staff"
    ) {
      Swal.fire({
        icon: "success",
        title: "Profile updated successfully",
        text: "",
        footer: '<a href=""></a>',
      });

      const current = { ...user };

      const currActiveClinic = { ...currentActiveClinic };

      const { users } = currentActiveClinic;

      if (patientProfPic.current !== null) {
        const payload = {
          entity: "users",
          entity_id: user?.user_id,
          base64_image: patientProfPic.current?.base64_image,
          size: patientProfPic.current?.size,
          title: patientProfPic.current?.title,
        };

        try {
          await axios.post("attachments", payload).then(function (response) {
            if (response.status === 201) {
              current.avatar = response.data.url;
            }
          });
        } catch (error) {}
      }

      if (user?.type === "doctor") {
        current.doctor_information.firstname = response.data.firstname;
        current.doctor_information.middlename = response.data.middlename;
        current.doctor_information.lastname = response.data.lastname;
        current.doctor_information.birthdate = response.data.birthdate;
        current.doctor_information.license_number =
          response.data.license_number;
        current.usename = response.data.username;
        current.name =
          "Dr. " +
          response.data.firstname +
          (response?.data?.middlename
            ? " " + response?.data?.middlename + " "
            : " ") +
          response.data.lastname;

        for (let i in users) {
          if (users[i].id === response.data.user_id) {
            users[i].name =
              "Dr. " +
              response.data.firstname +
              (response?.data?.middlename
                ? " " + response?.data?.middlename + " "
                : " ") +
              response.data.lastname;
          }
        }

        currActiveClinic.users = users;

        setAppCurrentActiveClinic({ ...currActiveClinic });
      } else {
        current.name = name;
      }

      setCurrentLoggedInUser({ ...current });

      closeModal();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Profile updated failed",
        footer: '<a href=""></a>',
      });
    }
  };

  useEffect(() => {
    if (Object.keys(validationError).length === 0) {
    }
  }, [validationError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      maxWidth={`xl`}
      PaperProps={{
        sx: {
          top: 15,
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: { xs: "90%", md: "45%" },
          },
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
        <Stack justifyContent={`flex-end`} alignItems={`flex-end`}>
          <CloseIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </Stack>

        <Stack justifyContent={`center`} alignItems={`center`} spacing={4}>
          {user?.type === "doctor" && (
            <Stack justifyContent={`center`} alignItems={`center`} spacing={3}>
              <AvatarImage
                fileData={user?.avatar}
                defaultImage={user?.avatar}
                sx={{ width: 130, height: 130 }}
                onImageLoaded={onImageLoaded}
              />
              <Typography
                variant="h5"
                textAlign={{ xs: `center` }}
                gutterBottom
              >
                Dr. {user?.doctor_information?.firstname}{" "}
                {user?.doctor_information?.middlename ?? ""}{" "}
                {user?.doctor_information?.lastname}
              </Typography>

              <Box
                alignItems={`center`}
                textAlign={`center`}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    alignItems: "center",
                    textOverflow: "clip",
                    overflow: "hidden",
                  }}
                >
                  {specialization
                    .filter((row) => row.enabled)
                    .map((row, index) => (
                      <>
                        {row?.specialization}
                        {index <
                        specialization.filter((a) => a.enabled).length - 1
                          ? ","
                          : ""}
                        {nbsp}
                      </>
                    ))}
                </Typography>
              </Box>
            </Stack>
          )}

          {user?.type === "staff" && (
            <Stack justifyContent={`center`} alignItems={`center`} spacing={3}>
              <AvatarImage
                fileData={user?.avatar}
                sx={{ width: 130, height: 130 }}
                onImageLoaded={onImageLoaded}
              />
              <Button
                variant="contained"
                color="warning"
                onClick={() => setShowChangePassword(true)}
                disabled={subscriptionInfo?.is_expired}
              >
                Change Password
              </Button>
            </Stack>
          )}

          {user?.type === "doctor" && (
            <Stack
              justifyContent={`space-between`}
              alignItems={`center`}
              direction={{ xs: `column`, sm: `column`, md: `row` }}
              spacing={{ xs: 1, sm: 1, md: 1, xl: 10 }}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() => setShowChangePassword(true)}
                disabled={subscriptionInfo?.is_expired}
              >
                Change Password
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowSpecialization(true)}
                disabled={subscriptionInfo?.is_expired}
              >
                Edit Specialization
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#fafafa" }}>
        <Box paddingTop={`40px`}>
          <Stack spacing={2}>
            {user?.type === "staff" && (
              <Stack direction={`column`} spacing={2}>
                <TextField
                  label={`Name`}
                  variant="outlined"
                  value={name || ""}
                  name="name"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.name}
                </Typography>
                <TextField
                  label={`Username`}
                  variant="outlined"
                  value={username || ""}
                  name="username"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.username}
                </Typography>
              </Stack>
            )}

            {user?.type === "doctor" && (
              <Stack direction={`column`} spacing={2}>
                <TextField
                  label={`First Name`}
                  variant="outlined"
                  value={firstname || ""}
                  name="firstname"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.firstname}
                </Typography>
                <TextField
                  label={`Middle Name`}
                  variant="outlined"
                  value={middlename || ""}
                  name="middlename"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {/* {validationError.middlename} */}
                </Typography>
                <TextField
                  label={`Last Name`}
                  variant="outlined"
                  value={lastname || ""}
                  name="lastname"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.lastname}
                </Typography>
                <TextField
                  label={`Email`}
                  variant="outlined"
                  value={email || ""}
                  name="email"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.email}
                </Typography>
                <TextField
                  label={`Username`}
                  variant="outlined"
                  value={username || ""}
                  name="username"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.username}
                </Typography>
                <TextField
                  label={`Birthdate`}
                  variant="outlined"
                  value={birthdate || ""}
                  name="birthdate"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.birthdate}
                </Typography>
                <TextField
                  label={`PRC License Number`}
                  variant="outlined"
                  value={license_number || ""}
                  name="license_number"
                  fullWidth
                  onChange={handleInputChange}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontStyle: "italic", color: "red" }}
                >
                  {validationError.license_number}
                </Typography>
                <Stack
                  direction={`column`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  textAlign={`center`}
                >
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontStyle: "italic" }}
                  >
                    Your PRC License Number will be kept private and will only
                    be used for security as Margo's effort to prevent illegal
                    practice of dentistry
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      </DialogContent>

      <Stack
        direction={`row`}
        justifyContent={{
          xs: `center`,
          sm: `center`,
          md: `flex-end`,
          lg: `flex-end`,
        }}
        alignItems={`center`}
        spacing={2}
        sx={{
          px: 5,
          py: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={subscriptionInfo?.is_expired}
        >
          Save Changes
        </Button>
      </Stack>

      <ChangePassword
        openModal={showChangePassword}
        closeModal={() => setShowChangePassword(false)}
      />
      <Specialization
        openModal={showSpecialization}
        closeModal={() => setShowSpecialization(false)}
      />
    </Dialog>
  );
};

export default ProfileInformation;
