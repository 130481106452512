import { Alert, Snackbar } from "@mui/material";
import React from "react";

const defaultAlertOptions = {
    severity: 'info',
    message: '',
    duration: null
};

const AppointmentSnackbar = React.forwardRef((_, ref) => {
    const [alertOptions, setAlertOptions] = React.useState(defaultAlertOptions);
    const [open, setOpen] = React.useState(false);

    React.useImperativeHandle(ref, () => {
        return {
            show(options) {
                setAlertOptions({
                    ...defaultAlertOptions,
                    ...options
                });
                setOpen(true);
            },
            close() {
                handleClose();
            }
        }
    });

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setAlertOptions(defaultAlertOptions);
        }, 200);
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={alertOptions.duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={alertOptions.severity}>{alertOptions.message}</Alert>
        </Snackbar>
    );
});

export default AppointmentSnackbar;