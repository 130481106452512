import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React from "react"
import AppointmentUtils from "../../Redux/AppointmentUtils";
import { useSelector } from "react-redux";

const ViewPatientDialog = React.forwardRef((_, ref) => {
    const { rawAppointments, patients } = useSelector((store) => store.appointment);
    const [modalConfig, setModalConfig] = React.useState({
        open: false,
        title: ''
    });
    const [patientList, setPatientList] = React.useState([]);

    React.useImperativeHandle(ref, () => {
        return {
            show(data) {
                setModalConfig({
                    open: true,
                    title: data.title
                });
                const appointmentUtil = new AppointmentUtils(rawAppointments, patients);
                setPatientList(appointmentUtil.getAppointmentsViewData(data.viewType, data.date));
            }
        }
    });

    const onCloseHandler = (_, reason) => {
        if (reason === 'backdropClick') return;
        setModalConfig({
            open: false,
            title: ''
        });
        setTimeout(() => {
            setPatientList([]);
        }, 200);
    }

    return (
        <Dialog
            open={modalConfig.open}
            disableScrollLock={true}
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Typography variant="h5" component={'div'}>
                    Patients for {modalConfig.title}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onCloseHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <PatientList patients={patientList} onItemClick={() => { }} />
            </DialogContent>
        </Dialog>
    );
});

function PatientList({ patients, onItemClick }) {
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {patients.length === 0 && <Typography
                sx={{ display: 'block' }}
                component="div"
                variant="h5"
                color="text.primary"
                fontWeight='500'
                width={280}
                textAlign={'center'}
            >
                No records found
            </Typography>}
            {patients.map(patient => <PatientListItem key={patient.id} data={patient} onClick={onItemClick} />)}
        </List>
    );
}

function PatientListItem({ data, onClick }) {
    return (
        <ListItem
            alignItems="flex-start"
            onClick={onClick}
        // style={{cursor: 'pointer'}}
        >
            <ListItemAvatar>
                <Avatar alt={data.patientName} src={data.avatar} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography
                        sx={{ display: 'block' }}
                        component="h2"
                        variant="h6"
                        color="text.primary"
                        fontWeight='500'
                        width={280}
                    >
                        {data.patientName}
                    </Typography>
                }
                secondary={
                    <>
                        <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            fontWeight='300'
                        >
                            Assigned Dentist:
                        </Typography>
                        <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                        >
                            {data.doctor}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    )
}

export default ViewPatientDialog;