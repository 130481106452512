import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosNoAuthInstance as axios } from "../../../Utils/AxiosHttp";
import PrivacyModal from "./Terms/PrivacyModal";
import TermsOfUserModal from "./Terms/TermsOfUserModal";

export default function RegisterClinic() {
  
  const navigate = useNavigate();
  const [term, setTerm] = useState({ isOpen: false, terms: "" });
  const [clinicData, setClinicData] = useState([]);
  const [isTermAndCondChecked, SetIsTermAndConditionChecked] = useState(false);

  const handleClinicInput = (e) => {
    e.preventDefault();

    setClinicData({
      ...clinicData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTermsAndConditionChecked = (e) => {
    SetIsTermAndConditionChecked(e.target.checked);
  };

  const handleAddClinic = async () => {
    if (isTermAndCondChecked) {
      await axios
        .post(`/clinics`, clinicData)
        .then((response) => {
          navigate(`/auth/register-doctor/${response.data.id}`);
        })
        .catch((error) => {

        });
    } else {

    }
  };

  const closeModal = () => {
    setTerm({ isOpen: false });
  };

  return (
    <Container fixed>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          direction={"column"}
          spacing={2}
          sx={{
            width: "50vh",
          }}
        >
          <Stack direction={"column"}>
            <Typography textAlign="center" variant="h1" color="#004d81">
              MARGO
            </Typography>
            <Typography textAlign="right" color="#747474" sx={{ pr: 6 }}>
              v{process.env.REACT_APP_MARGO_VERSION}
            </Typography>
          </Stack>

          <Stack direction={"column"} alignItems={"center"} spacing={2}>
            <Typography variant="h6">Clinic Registration</Typography>

            <Divider flexItem orientation="horizontal" />
          </Stack>

          <Stack direction={"column"} spacing={2}>
            <TextField
              variant="outlined"
              onChange={(e) => handleClinicInput(e)}
              label="Clinic Name"
              name="name"
              required
            />

            <TextField
              multiline
              onChange={(e) => handleClinicInput(e)}
              label="Clinic Address"
              name="address"
              rows={5}
              variant="outlined"
              required
            />

            <FormGroup>
              <FormControlLabel
                name="terms_and_condition"
                control={
                  <Checkbox
                    onChange={(e) => handleTermsAndConditionChecked(e)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    I have read, understood and agreed to the
                    <Link
                      onClick={() =>
                        setTerm({ isOpen: true, terms: "privacy" })
                      }
                    >
                      {" "}
                      Terms and Conditions{" "}
                    </Link>{" "}
                    and the
                    <Link
                      onClick={() =>
                        setTerm({ isOpen: true, terms: "end-user" })
                      }
                    >
                      {" "}
                      End-User License Agreement (EULA).
                    </Link>
                  </Typography>
                }
              />
            </FormGroup>

            <Button
              variant="contained"
              size="large"
              onClick={() => handleAddClinic()}
            >
              Continue
            </Button>

            <Typography
              variant="subtitle1"
              color={`primary`}
              textAlign="center"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/auth/login")}
            >
              Already Registered?
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {term.terms === "privacy" ? (
        <PrivacyModal open={term.isOpen} closeModal={closeModal} />
      ) : (
        <TermsOfUserModal open={term.isOpen} closeModal={closeModal} />
      )}
    </Container>
  );
}
