import React from "react";
import { PatientProfileStyles } from "../styles";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../../Utils/authStored";
import Swal from "sweetalert2";
import { photoBoxBorderStyle } from "../../PatienInformationModal/style";

const styles = PatientProfileStyles;

const useProcedures = () => {
  const [isChecking, setIsChecking] = React.useState(false);
  const [patient, setPatient] = React.useState(null);
  const [procedures, setProcedures] = React.useState([]);

  React.useEffect(() => {
    if (patient) {
      getPatientProcedures();
    }
    // eslint-disable-next-line
  }, [patient]);

  const getPatientProcedures = async () => {
    setIsChecking(true);
    const clinic = getAppCurrentActiveClinic();
    try {
      const proceduresRes = await axios.get("patient_procedures_notes", {
        params: {
          patient_id: patient.id,
          clinic_id: clinic.id,
          status: "pending",
        },
      });

      if (proceduresRes.status === 200) {
        const mappedProcedures = (
          proceduresRes.data?.pending_procedures ?? []
        ).map((value) => {
          return {
            title: value.name,
            subtitle: value.description,
            enabled: proceduresRes.data?.pending_procedures ? true : false,
            data: value,
          };
        });
        setProcedures(mappedProcedures);
      }
    } catch (_) {
      Swal.fire({
        icon: "error",
        title: "Failed to fetch calendar data",
      });
    }
    setIsChecking(false);
  };

  const clearProcedures = () => {
    setProcedures([]);
  };

  return {
    isChecking,
    procedures,
    totalEnabled: procedures.filter((f) => f.enabled).length,
    checkProcedures: setPatient,
    clearProcedures,
  };
};

export const PatientProfile = React.forwardRef(
  ({ watch, procedureOnLoad }, ref) => {
    const {
      isChecking,
      totalEnabled,
      procedures,
      checkProcedures,
      clearProcedures,
    } = useProcedures();
    const patient = watch("patient");

    React.useEffect(() => {
      if (patient?.id !== 0) {
        clearProcedures();
        checkProcedures(patient);
      }
      // eslint-disable-next-line
    }, [patient]);

    React.useEffect(() => {
      procedureOnLoad(procedures);
      // eslint-disable-next-line
    }, [procedures]);

    React.useImperativeHandle(ref, () => {
      return {
        getProcedures() {
          return procedures;
        },
      };
    });

    return (
      <Box sx={styles.container}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            display={"flex"}
            justifyContent={{ xs: "center", sm: "center", md: "end" }}
            alignItems={"center"}
          >
            <Box sx={photoBoxBorderStyle}>
              <img
                loading="lazy"
                width="130"
                height="130"
                src={
                  patient?.avatar ??
                  process.env.REACT_APP_API_URL + "/assets/avatar"
                }
                alt={patient?.label}
                style={styles.avatar}
              />
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={7}
            lg={7}
            display={"flex"}
            flex={"auto"}
            justifyContent={{ xs: "center", sm: "center", md: "left" }}
            alignItems={"center"}
            style={{ paddingRight: "15" }}
          >
            <Stack spacing={0}>
              <Typography variant="h5" textAlign={{ sm: "center", md: "left" }}>
                {patient?.label ?? "Select Patient"}
              </Typography>
              <Box sx={{ height: "20px" }}>
                {totalEnabled > 0 && (
                  <Typography variant="h6" textAlign={"center"}>
                    Scheduling {totalEnabled} Procedures
                  </Typography>
                )}
                {!isChecking && procedures.length <= 0 && (
                  <Typography variant="h6" textAlign={"left"}>
                    No Pending Procedures
                  </Typography>
                )}
                {isChecking && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {isChecking && (
                      <CircularProgress
                        color="primary"
                        sx={{ padding: "10px" }}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PatientProfile;
