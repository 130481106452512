import React, { useState } from "react";
import { procedures } from "../data";

import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Swal from "sweetalert2";
import { axiosInstance } from "../../Utils/AxiosHttp";
import { useDispatch, useSelector } from "react-redux";
import { setIsFetching, updateProcedure } from "../../Pages/Patients/Redux/patientSlice";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";
import { DateExtractDateTimeLocale } from "../../Utils/utils";
const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

const iconColor = {
  color: "#ed4763",
};

const ProcedureTable = (props) => {
  const { patientInfo } = useSelector((store) => store.patient);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const rows = props.procedures.filter(
    (row) => row.status?.toLowerCase() !== "done"
  );

  return (
    <>
      <TableContainer sx={{ height: 403 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeader}>Procedures / Treatment</TableCell>
              <TableCell sx={tableHeader}>Date Added</TableCell>
              <TableCell sx={tableHeader}>Status</TableCell>
              <TableCell sx={tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((app, index) => {
                const status = app?.status?.toLowerCase();
                return (
                  <TableRow key={index}>
                    <TableCell>{app?.name}</TableCell>
                    <TableCell>
                      {/* {moment(app?.created_at).format("MMM DD, yyyy hh:mm A")} */}
                      {DateExtractDateTimeLocale(app?.created_at)}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {status === "pending" ? "Unscheduled" : status}
                    </TableCell>
                    <TableCell sx={{ maxWidth: '90px', textAlign: 'right' }}>
                      <DeleteProcedure procedure={app} patientID={patientInfo.id} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

      </TableContainer >

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={procedures.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

function DeleteProcedure({ procedure, patientID }) {
  const [isDeleteProc, setIsDeleteProc] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteProcedure = () => {
    const clinic = getAppCurrentActiveClinic();

    let errorMessage = {
      title: 'Failed to delete procedure!',
      text: 'Try again'
    }

    Swal.fire({
      icon: 'info',
      title: 'Deleting Procedure',
      html: 'Please wait.',
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();

        try {
          const response = await axiosInstance.delete(`patient_procedures/${procedure.id}`);
          if (response.status === 200 || response.status === 204) {
            errorMessage = {
              title: 'Failed to delete procedure!',
              text: 'Try again'
            }

            const procResponse = await axiosInstance.get(`patient_procedures?clinic_id=${clinic.id}&patient_id=${patientID}`)
            if (procResponse.status === 200) {
              dispatch(updateProcedure(procResponse.data));
            }

            Swal.fire({
              icon: 'success',
              title: 'Procedure deleted!'
            });
            setIsDeleteProc(false);
          }
        } catch (_) {
          Swal.fire({
            icon: 'success',
            title: errorMessage.title,
            text: errorMessage.text
          });
        }

        dispatch(setIsFetching(false));
      },
    });
  }

  return (
    !isDeleteProc ? (procedure.status === "pending" ? <IconButton
      onClick={() => setIsDeleteProc(true)}>
      <DeleteIcon sx={iconColor} />
    </IconButton> : <></>) : <>
      <Button
        variant="contained"
        fullWidth
        onClick={() => setIsDeleteProc(false)}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="error"
        fullWidth
        sx={{ mt: '5px' }}
        onClick={handleDeleteProcedure}>
        Confirm Delete
      </Button>
    </>
  )
}

export default ProcedureTable;
