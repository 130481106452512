import { Box, Button, Chip, CircularProgress, Paper, Stack, Grid, Typography, Switch } from "@mui/material";
import { getAppCurrentActiveClinic, getCurrentLoggedInUser } from "../../../../Utils/authStored";
import { dateToShortString } from "../../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";

import { reloadBilling, switchPlan } from "./Redux/billingSlice";
import React, { useEffect, useState } from "react";
import PricingCard from "./Components/PricingCard";
import PricingContainer from "./Components/PricingContainer";
import useBilling from '../../../../hooks/useBilling';


export default function Billing() {

    const dispatch = useDispatch();

    const {
        planList,
        isBillingInformationLoading,
        planDuration,
        handleCheckout,
        subscriptionInfo
    } = useBilling();

    const clinic = getAppCurrentActiveClinic();

    const user = getCurrentLoggedInUser();

    const [subInfo, setSubInfo] = useState([]);

    const fetchSubscription = async () => {
        dispatch(reloadBilling({
            reload: false
        }))
    }

    useEffect(() => {
        if (isBillingInformationLoading) {
            fetchSubscription();
        }
    }, [isBillingInformationLoading]);

    const handleSubscribe = async (plan) => {

        const result = planList.find((f) => f.label === plan);

        const payload = {
            firstname: user?.doctor_information?.firstname,
            lastname: user?.doctor_information?.lastname,
            email: user?.email,
            total_amount_value: (planDuration === 'monthly') ? result['price_breakdown']['quarterly'].sale_price : result['price_breakdown']['yearly'].sale_price,
            amount_value: (planDuration === 'monthly') ? result['price_breakdown']['quarterly'].sale_price : result['price_breakdown']['yearly'].sale_price,
            plan_id: result?.id,
            plan_name: result?.name,
            plan_description: result?.description,
            clinic_id: clinic.id,
            interval: (planDuration === 'monthly') ? 'quarterly' : 'yearly'
        };

        await handleCheckout(payload);
    }

    return (
        <>
            {
                (isBillingInformationLoading) ?
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{ px: 20, py: 20 }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Box component={Paper}>
                            <Grid container>
                                <Grid
                                    sm={12}
                                    item
                                    sx={{
                                        py: 2,
                                        px: 3
                                    }}
                                >
                                    <Stack
                                        direction={'column'}
                                        spacing={2}
                                    >
                                        <Typography variant="h6">Subscription Status:
                                            {
                                                (subscriptionInfo?.is_expired)
                                                    ? <Chip label={'Expired'} color="error" variant="outlined" sx={{ mx: 2 }} />
                                                    : (subscriptionInfo?.subscription_status === 'trial')
                                                        ? <Chip label={'Trial'} color="primary" variant="outlined" sx={{ mx: 2 }} />
                                                        : <Chip label={'Active'} color="success" variant="outlined" sx={{ mx: 2 }} />
                                            }
                                        </Typography>

                                        <Stack
                                            justifyContent={`space-between`}
                                            alignItems={`center`}
                                            direction={`row`}
                                        >
                                            <Typography variant="h6">Expiration: &nbsp;
                                                {dateToShortString(subscriptionInfo?.expiration_date)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            sx={{
                                height: '500px',
                                mt: 4
                            }}
                        >
                            <Stack
                                direction={'column'}
                                spacing={3}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {
                                    (!subscriptionInfo?.plan_interval) &&
                                    <Box
                                        sx={{
                                            height: '20px',
                                            mb: 3
                                        }}
                                    >
                                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                            <Typography variant="h6" color={planDuration == 'monthly' ? 'primary' : '#00000'}>Quarterly</Typography>
                                            <Switch
                                                onChange={() => dispatch(switchPlan(planDuration === 'yearly' ? 'monthly' : 'yearly'))}
                                            />
                                            <Typography variant="h6" color={planDuration == 'yearly' ? 'primary' : '#00000'}>Yearly</Typography>
                                        </Stack>
                                    </Box>
                                }

                                <PricingContainer>
                                    <Grid container spacing={3} justifyContent={`center`} alignItems={`center`}>
                                        {planList.filter((x) => {
                                            return subscriptionInfo?.subscription_status === 'subscribed'
                                                ? x.id === subscriptionInfo?.plan_id
                                                : x
                                        }).map((plan) => {
                                            console.log(plan);
                                            return (
                                                <Grid key={plan?.id} item xs={12} sm={12} md={4}>
                                                    <PricingCard
                                                        title={plan?.name}
                                                        selected={subscriptionInfo?.subscription_status === 'subscribed' && (subscriptionInfo?.plan_id === plan?.id || plan?.id === 'MT-000')}
                                                        monthly={planDuration == 'monthly'}
                                                        price={planDuration == 'monthly' ? plan?.price_breakdown?.monthly?.sale_price : plan?.price_breakdown?.yearly?.sale_price}
                                                        priceDiscount={planDuration == 'yearly' && plan?.id !== 'MT-000' ? plan?.price_breakdown?.yearly?.savings : plan?.price_breakdown?.quarterly?.sale_price}
                                                        btnTitle={'Get Started'}
                                                        features={[
                                                            <><span>{plan?.plan_details?.storage}</span> GB Storage Limit</>,
                                                            <>Up to <span>{plan?.plan_details?.dentist}</span> Dentist{plan?.plan_details?.dentist > 1 ? 's' : ''}</>,
                                                            <><span>{plan?.plan_details?.staff}</span> Staff Account{plan?.plan_details?.staff > 1 ? 's' : ''}</>,
                                                        ]}
                                                        onClick={() => handleSubscribe(plan?.label)} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </PricingContainer>
                                {
                                    (!subscriptionInfo?.plan_interval) &&
                                    <Stack
                                        justifyContent={'center'}
                                        direction={'row'}
                                        spacing={10}
                                    >
                                        <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>You can contact our sales team for more information about the Enterprise Plan. <span style={{ fontStyle: 'italic', cursor: 'pointer', textDecoration: 'underline' }}>here</span></Typography>
                                    </Stack>
                                }
                            </Stack>
                        </Box>
                    </>
            }

        </>
    )
}