import React from "react";
import {
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { useSelector } from "react-redux";
import Procedure from "./Procedure";

export default function ProcedureTabs() {
  const { procedures } = useSelector((store) => store.appointment);
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        key={index}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: "15px 10px 5px 10px" }}>{children}</Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {procedures.map((row, key) => {
            return <Tab key={row.id} label={row.name} {...a11yProps({ key })} />;
          })}
        </Tabs>
      </Box>
      {procedures.map((row, key) => {
        return (
          <TabPanel key={row.id} value={value} index={key}>
            <Procedure data={{ ...row }} />
          </TabPanel>
        );
      })}
    </Box>
  );
}