import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { dateToShortString } from "../../../Utils/utils";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { useDispatch } from "react-redux";
import { showPatientInfo } from "../../../Pages/Patients/Redux/patientSlice";
import PatientInformationModal from "../../../Components/Modals/PatienInformationModal";
const PatientsTable = ({ patients }) => {
  const clinic = getAppCurrentActiveClinic();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allPatient, setAllPatient] = useState();
  const tableHeader = {
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: "#f4f6f8",
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const fetchClinicPatients = async () => {
    const response = await axios.get(`clinic_patients?clinic_id=${clinic?.id}`);
    setAllPatient(response.data);
  };

  useEffect(() => {
    fetchClinicPatients();
  }, []);

  const handleSelectedPatient = async(row) => {
    const response = await axios.get(`patients/${row.patient_id}`);
    dispatch(showPatientInfo(response.data));
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={tableHeader}>
                Patient
              </TableCell>
              <TableCell align="center" sx={tableHeader}>
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients &&
              patients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rows, index) => {
                  return (
                    <TableRow key={index} hover sx={{ cursor: "pointer" }}>
                      <TableCell>
                        <Stack
                          direction={`row`}
                          justifyContent={"left"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Avatar
                            src={rows?.avatar}
                            sx={{ bgcolor: lightBlue[500] }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              "&:hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                              },
                            }}
                            // onClick={() => {
                            //   dispatch(showPatientInfo(rows));
                            //   // dispatch(openModal());
                            // }}

                            onClick={() => {
                              handleSelectedPatient(rows);
                            }}
                          >
                            {rows?.lastname + ", " + rows?.firstname}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="center">
                        {dateToShortString(rows?.date)}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={patients && patients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PatientInformationModal />
    </>
  );
};

export default PatientsTable;
