import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import SupportForm from "./form/support-form";

const Support = () => {
  return (
    <Paper
      elevate={1}
      sx={{
        py: 3,
        px: 3,
      }}
    >
      <Stack direction={`column`} spacing={1}>
        <Typography variant="h6">Report a Problem / Send Inquiry</Typography>

        <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
          Feel free to use English / Tagalog / Bisaya
        </Typography>

        <Divider />
      </Stack>

      <SupportForm />
    </Paper>
  );
};

export default Support;
