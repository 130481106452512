import React, { useState } from "react";
import { Avatar, Divider, Paper, Stack, Typography, Chip } from "@mui/material";
import {
  showPatientInfo,
  editPatientInfo,
} from "../../../Pages/Patients/Redux/patientSlice";

import { useDispatch } from "react-redux";

import PatientInformationModal from "../../../Components/Modals/PatienInformationModal";
import PatientRecordModal from "../../../Components/Modals/PatientRecordModal";
const ListView = ({ clinicPatients }) => {
  const dispatch = useDispatch();

  const sorted = clinicPatients.sort((a, b) =>
    a.lastname.localeCompare(b.lastname)
  );

  const getUniqueLetter = sorted.map((rows) => rows?.lastname.charAt(0));

  let setObject = new Set(getUniqueLetter);
  const arrObject = [...setObject];

  return (
    <>
      <Paper
        elevation={2}
        sx={{ padding: 2, maxHeight: 842, overflow: "auto" }}
      >
        {clinicPatients &&
          arrObject.map((row, index) => {
            return (
              <Stack key={index} direction="column" spacing={1}>
                <Divider sx={{ marginTop: 1 }}>
                  <Chip label={row} />
                </Divider>

                {sorted.map((rows, index) => {
                  return (
                    <>
                      {rows?.lastname.charAt(0) === row && (
                        <Stack
                          key={index}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f5f5f5",
                            },
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            // marginLeft={2}
                            // marginRight={2}
                            margin={1}
                            onClick={() => dispatch(showPatientInfo(rows))}
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: { sm: 15, md: 20 } }}
                            >
                              {rows?.lastname + ", " + rows?.firstname}
                            </Typography>
                            <Avatar
                              src={rows?.avatar}
                              sx={{
                                width: {
                                  xs: 40,
                                  sm: 40,
                                  md: 50,
                                  lg: 60,
                                  xl: 80,
                                },
                                height: {
                                  xs: 40,
                                  sm: 40,
                                  md: 50,
                                  lg: 60,
                                  xl: 80,
                                },
                              }}
                            />
                          </Stack>
                          <Divider />
                        </Stack>
                      )}
                    </>
                  );
                })}
              </Stack>
            );
          })}
        {sorted.length === 0 && (
          <Stack direction="row" justifyContent="center">
            <Typography variant="h5">No Patient found</Typography>
          </Stack>
        )}

        <PatientInformationModal />
        <PatientRecordModal />
      </Paper>
    </>
  );
};

export default ListView;
