import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Box,
  Paper,
  Typography,
  AlertTitle,
  Tab,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import {
  getAppCurrentActiveClinic,
  setAppCurrentActiveClinic,
  getCurrentLoggedInUser,
} from "../../../../Utils/authStored";

export default function AddUser({ open, close, fetchCUser }) {
  const currUser = getCurrentLoggedInUser();
  const clinic = getAppCurrentActiveClinic();
  const [selectedTab, setSelectedTab] = useState("dentist");
  const [dentistData, DentistData] = useState([]);
  const [staffData, StaffData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [errorExp, setErrorExp] = useState([]);
  const [role, setRole] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const handleSelectTab = (event, newValue) => {
    setErrorExp([]);
    setSelectedTab(newValue);
  };

  const { staffname, username, password } = staffData;
  const { email } = dentistData;

  const validation = (values) => {
    const errors = {};

    if (!values.staffname) {
      errors.staffname = "Staff name is required";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    }

    return errors;
  };

  const dentistValidation = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    }

    return errors;
  };

  const passwordValidation = () => {
    const loweruppercaseRegex = /[a-z\s]*[A-Z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let errors = [];

    if (password?.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (username?.length < 8) {
      errors.push("Username must be at least 8 characters long");
    }

    if (!loweruppercaseRegex.test(password)) {
      errors.push("A combination of both uppercase and lowercase letter");
    }

    if (!numberRegex.test(password)) {
      errors.push("A combination of letters and numbers");
    }

    if (!specialCharacterRegex.test(password)) {
      errors.push("Inclusions of at least one special character, e.g.,!@#?");
    }

    setPasswordError(errors);
  };

  const emailValidation = () => {
    const error = [];
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!emailRegex.test(email)) {
      error.push("The email must be a valid email address");
    }

    setIsEmail(error);
  };

  useEffect(() => {
    emailValidation();
  }, [email]);

  const fetchClinicUsers = async () => {
    const response = await axios.get(`/clinic_users/${clinic.id}`);
    setAppCurrentActiveClinic({ ...clinic, users: response.data.clinic_users });
    fetchCUser();
  };

  useEffect(() => {
    passwordValidation();
  }, [username, password]);

  const getUserRoles = async () => {
    const response = await axios.get(`/clinic_users/${clinic.id}`);
    const type = response.data.clinic_users.map((row) => {
      return row.user_type;
    });

    const hasRole = type.includes("staff");
    setRole(hasRole);
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  const handleAddNewStaff = () => {
    if (!staffname || !username || !password) {
      setValidationError(validation(staffData));
    } else {
      Swal.fire({
        icon: "question",
        title: "Are you sure you want to add this staff?",
        showCancelButton: true,
        cancelButtonColor: "#ed4763",
        confirmButtonText: "Confirm",
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
          return new Promise((resolve) => {
            axios
              .post(`/clinic_user/staff`, staffData)
              .then(() => {
                setValidationError({});
                Swal.fire({
                  icon: "success",
                  title: "Successfully added!",
                }).then(() => {
                  fetchClinicUsers({});
                  close();
                  resolve();
                });
              })
              .catch((error) => {
                setValidationError({});
                Swal.fire({
                  icon: "error",
                  title: "Failed to add staff!",
                  text: error.errors[0],
                }).then(() => {
                  resolve();
                });
              });
          });
        },
      });
    }
  };

  const handleAddNewDentist = () => {
    if (!email) {
      setValidationError(dentistValidation(dentistData));
      return;
    }

    Swal.fire({
      icon: "question",
      title: "Are you sure you want to add this dentist?",
      showCancelButton: true,
      cancelButtonColor: "#ed4763",
      confirmButtonText: "Confirm",
      closeOnConfirm: false,
      closeOnCancel: false,
      reverseButtons: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return new Promise((resolve) => {
          axios
            .post(`clinic_user/invite`, dentistData)
            .then((response) => {
              setValidationError({});
              Swal.fire({
                icon: "success",
                title: "Successfully added!",
                text: `Email invitation has been sent to ${dentistData.email}`,
              }).then(() => {
                fetchClinicUsers();
                close();
                resolve();
              });
            })
            .catch((error) => {
              setValidationError({});
              Swal.fire({
                icon: "error",
                title: "Failed to add dentist!",
                text: error.errors[0],
              }).then(() => {
                resolve();
              });
            });
        });
      },
    });
  };

  const handleDentistFieldOnChange = (e) => {
    DentistData({
      ...dentistData,
      clinic_id: clinic?.id,
      user_id: currUser?.user_id,
      email: e.target.value,
    });
  };

  const handleStaffFieldOnChange = (e) => {
    StaffData({
      ...staffData,
      clinic_id: clinic?.id,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  };

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace =
      !data || data.length === 0 || /\s/g.test(data);

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      // onBackdropClick={close}
      maxWidth="xs"
      //fullWidth
    >
      <DialogTitle>Add New User</DialogTitle>

      <DialogContent>
        <Box
          component={Paper}
          sx={{
            py: 3,
            px: 3,
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "45%",
              },
            },
          }}
        >
          <TabContext value={selectedTab}>
            <Stack direction={`column`} spacing={1}>
              <TabList onChange={handleSelectTab}>
                <Tab
                  label={<Typography variant="subtitle1">Dentist</Typography>}
                  value={`dentist`}
                />
                {role ? (
                  <Tab
                    disabled
                    label={<Typography variant="subtitle1">Staff</Typography>}
                    value={`staff`}
                  />
                ) : (
                  <Tab
                    label={<Typography variant="subtitle1">Staff</Typography>}
                    value={`staff`}
                  />
                )}
              </TabList>

              <TabPanel value={`dentist`}>
                <Stack direction={`column`} spacing={2}>
                  <TextField
                    name={`email`}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => handleDentistFieldOnChange(e)}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ fontStyle: "italic", color: "red" }}
                  >
                    {validationError.email}
                  </Typography>

                  <Stack
                    justifyContent={`flex-end`}
                    direction={{ sm: `column`, md: `row` }}
                    spacing={2}
                  >
                    <Button variant="contained" color="error" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddNewDentist()}
                    >
                      Send Invite
                    </Button>
                  </Stack>
                </Stack>
              </TabPanel>

              <TabPanel value={`staff`}>
                <Stack direction={`column`} spacing={2}>
                  {errorExp.length > 0 && (
                    <Alert severity="error">
                      <Typography variant="subtitle2">{errorExp[0]}</Typography>
                    </Alert>
                  )}

                  <TextField
                    name={`staffname`}
                    onChange={(e) => handleStaffFieldOnChange(e)}
                    variant="outlined"
                    label="Staff Name"
                    fullWidth
                    autoComplete="off"
                    // onKeyDown={(e) => handleOnKeyDown(e)}
                    // onPaste={(e) => handleOnPaste(e)}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ fontStyle: "italic", color: "red" }}
                  >
                    {validationError.staffname}
                  </Typography>

                  <TextField
                    name={`username`}
                    onChange={(e) => handleStaffFieldOnChange(e)}
                    variant="outlined"
                    label="App Username (for login)"
                    fullWidth
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    onPaste={(e) => handleOnPaste(e)}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ fontStyle: "italic", color: "red" }}
                  >
                    {validationError.username}
                  </Typography>

                  <TextField
                    name={`password`}
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    label="Password"
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => handleStaffFieldOnChange(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    onPaste={(e) => handleOnPaste(e)}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ fontStyle: "italic", color: "red" }}
                  >
                    {validationError.password}
                  </Typography>
                  <Stack
                    justifyContent={`flex-end`}
                    alignItems={`center`}
                    direction={`row`}
                    spacing={2}
                  >
                    <Button variant="contained" color="error" onClick={close}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddNewStaff()}
                    >
                      Add User
                    </Button>
                  </Stack>
                </Stack>
              </TabPanel>
            </Stack>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
