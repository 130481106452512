import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isOpenViewInvoice: false,
  isOpenVoidInvoice: false,
  isOpenOverPayment: false,
  isPaymentConfirm: false,
  totalprevBal: 0,
  invoiceDetails: [],
  invoiceItemDetails: [],
  invoicePayments: [],
  isFromInvoiceList: false,
  patientDetails: {},
  isVoidInvoiceConfirm: false,
  isInvoiceListLoading: false,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    openModal: (state, _) => {
      return {
        ...state,
        isOpen: true,
        isOpenViewInvoice: true,
      };
    },
    
    refreshInvoiceList: (state, _) => {
      state.isInvoiceListLoading = _.payload;
    },

    fetchInvoiceData: (state, action) => {
      return {
        ...state,
        isOpen: true,
        isOpenViewInvoice: true,
        invoiceDetails: action.payload.invoiceDetails,
        invoiceItemDetails: action.payload.invoiceItemDetails,
        invoicePayments: action.payload.invoicePayments,
        isFromInvoiceList: action.payload.isFromInvoiceList,
      };
    },

    closeModal: (state, _) => {
      return {
        ...state,
        isOpen: false,
        isOpenViewInvoice: false,
        invoiceDetails: [],
        invoiceItemDetails: [],
        invoicePayments: [],
        isFromInvoiceList: false,
        totalprevBal: 0
      };
    },
    closevoidinvoiceModal: (state, _) => {
      state.isOpenVoidInvoice = false;
    },
    openvoidinvoiceModal: (state, _) => {
      state.isOpenVoidInvoice = true;
    },

    openoverpaymentDialog: (state, _) => {
      state.isOpenOverPayment = true;
    },
    closeoverpaymentDialog: (state, _) => {
      state.isOpenOverPayment = false;
    },

    paymentConfirmation: (state, _) => {
      state.isPaymentConfirm = true;
    },
    voidConfirmation: (state, _) => {
      state.isVoidInvoiceConfirm = true;
    },
    totalPreviousBalance: (state, action) => {
      const balance = action.payload;

      state.totalprevBal = balance.reduce((acc, currVal) => {
        return acc + currVal;
      }, 0);
    },

    fetchPatientDetails: (state, action) => {
      state.patientDetails = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        phone: action.payload.phone,
        credit: action.payload.credit,
        avatar: action.payload.avatar,
      };
    },
  },
});

export const invoiceStore = (state) => state.invoice;

export const {
  openModal,
  closeModal,
  fetchInvoiceData,
  closevoidinvoiceModal,
  openvoidinvoiceModal,
  openoverpaymentDialog,
  closeoverpaymentDialog,
  paymentConfirmation,
  totalPreviousBalance,
  fetchPatientDetails,
  voidInvoiceConfirmation,
  refreshInvoiceList,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
