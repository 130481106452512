import { getAppCurrentActiveClinic, getCurrentLoggedInUser, setSpecialization } from "../Utils/authStored";
import { axiosInstance as axios } from "../Utils/AxiosHttp";


export const updateSpecialization = async(specialization) => {
     const user = getCurrentLoggedInUser();

     const payLoad = {
          batch: specialization
     }

     const response = await axios.put(`/doctor_specializations/${user.user_id}`, payLoad);

     if (response.status === 201) {
          setSpecialization(specialization)
          return response;
     }
     return false;
}

