import React from "react";
import { useState } from "react";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";

import { useSelector } from "react-redux";
import moment from "moment";
import { AppointmentStatuses } from "../../Pages/Appointments/Redux/AppointmentUtils";

const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

const AppointmentsTable = (props) => {
  const { isFetching, appointments } = useSelector((store) => store.patient);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const StatusBadge = (props) => {
    let backgroundColor;
    switch (props?.status) {
      case 'scheduled':
        backgroundColor = '#17a2b8';
        break;
      case 'rescheduled':
        backgroundColor = '#007bff';
        break;
      case 'done':
        backgroundColor = '#28a745';
        break;
      case 'pending':
        backgroundColor = '#dc3545';
        break;
      case 'mia':
        backgroundColor = '#dc3545';
        break;
      default:
        break;
    }

    let status = props?.status === 'mia' ? 'No Show' : props?.status;
    status = status === 'pending' ? 'Unscheduled' : status;

    return (
      <Paper sx={{ color: 'white', backgroundColor: backgroundColor, p: '5px 10px', textAlign: 'center', borderRadius: '50px' }}>
        {(status === 'pending' ? 'Unscheduled' : status).toUpperCase()}
      </Paper>
    );
  }

  return (
    <>
      {
        !isFetching ?
          <>
            <TableContainer sx={{ height: 450 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHeader}>Date</TableCell>
                    <TableCell sx={tableHeader}>Time</TableCell>
                    <TableCell sx={tableHeader}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments.filter(f => f.status !== AppointmentStatuses.rescheduled)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((app, index) => {
                      const date = moment(app?.time_start);
                      return (
                        <TableRow key={index}>
                          <TableCell>{date.format('MMM DD, yyyy')}</TableCell>
                          <TableCell>{date.format('hh:mm A')}</TableCell>
                          <TableCell width={'130px'}><StatusBadge status={app?.status} /></TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={appointments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
          :
          <Box display={'flex'} justifyContent={'center'} marginTop={'30px'}>
            <CircularProgress />
          </Box>
      }
    </>
  );
};

export default AppointmentsTable;
