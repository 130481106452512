import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarOpened: true,
}

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarVisibility: (state, action) => {
            state.sidebarOpened = action.payload ?? !state.sidebarOpened;
        }
    }
});

export const sidebarStore = (state) => state.sidebar;

export const {
    setSidebarVisibility
 } = sidebarSlice.actions;
 
 export default sidebarSlice.reducer;