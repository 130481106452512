import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Paper from "@mui/material/Paper";

import InvoiceStatusModal from "../../Components/Modals/InvoiceStatusModal";
import {
  fetchInvoiceData,
  invoiceStore,
  totalPreviousBalance,
  fetchPatientDetails,
  refreshInvoiceList,
} from "../../Pages/Invoices/Redux/invoiceSlice";

import { useDispatch, useSelector } from "react-redux";

import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";

function createData(
  number,
  date,
  firstname,
  lastname,
  amount,
  balance,
  status,
  id,
  patient_id
) {
  return {
    number,
    date,
    firstname,
    lastname,
    amount,
    balance,
    status,
    id,
    patient_id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Number",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "patient_name",
    numeric: true,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Balance",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ backgroundColor: "#f4f6f8", fontWeight: "bold" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ backgroundColor: "#f4f6f8", fontWeight: "bold" }}
            align="left"
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ filterStatus, filterDate }) {

  const { isInvoiceListLoading } = useSelector(store => store.invoice);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("address");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [invoicePatients, setInvoicePatients] = useState([]);

  const clinic = getAppCurrentActiveClinic();

  const fetchInvoicePatients = async ({ offsetVal, limitVal, timespanVal }) => {
    const payload = {
      clinic_id: clinic?.id,
      offset: offsetVal,
      limit: limitVal,
      timespan: timespanVal,
    };

    const response = await axios.get(`invoices`, {
      params: payload,
    });

    setInvoicePatients(response.data);
  };

  const rowFilter = invoicePatients.filter((row) =>
    filterStatus ? row.status === "paid" : row.status !== "paid"
  );
  useEffect(() => {
    fetchInvoicePatients(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
      filterStatus ? "paid" : "unpaid"
    );
    dispatch(refreshInvoiceList(false))
  }, [rowFilter.length, isInvoiceListLoading]);

  const getDates = (row) => {
    return moment(row.created_at).format("YYYY-DD-MM");
  };

  const rows = rowFilter
    .filter(
      (row) =>
        filterDate && filterDate.action === "Today"
          ? filterDate.datetofilter === getDates(row)
          : filterDate.action === "This_Week"
          ? filterDate.datetofilter.includes(getDates(row))
          : filterDate.action === "This_Month"
          ? filterDate.datetofilter.includes(getDates(row))
          : filterDate.action === "In_History"
          ? row.status === "paid"
          : row.status !== "paid"

      // filterDate.action === "Today"
      //   ? filterDate.datetofilter === row.date
      //   : filterDate.action === "This_Week"
      //   ? row.date <=
      //       filterDate.datetofilter[filterDate.datetofilter.length - 1] &&
      //     row.date >= filterDate.datetofilter[0]
      //   : filterDate.action === "This_Month"
      //   ? row.date <=
      //       filterDate.datetofilter[filterDate.datetofilter.length - 1] &&
      //     row.date >= filterDate.datetofilter[0]
      //   : filterDate.action === "In_History"
      //   ? row.status === "paid"
      //   : row.status !== "paid"
    )
    .map((row) => {
      return createData(
        row.invoice_number,
        row.created_at,
        row.firstname,
        row.lastname,
        row.total_price,
        row.balance,
        row.status,
        row.id,
        row.patient_id
      );
    });

  const patientCell = {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },

    color: "#2196f3",
  };

  const capitalizeFirstLowercaseRest = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  const handleOpenInvoice = async (row) => {
    const [invoiceData, invoiceItems, invoicePayments] = await Promise.all([
      axios.get(`invoices/${row.id}`),
      axios.get(`invoice_items?invoice_id=${row.id}`),
      axios.get(`invoice/payments/${row.id}`),
    ]);

    dispatch(
      fetchInvoiceData({
        invoiceDetails: invoiceData.data,
        invoiceItemDetails: invoiceItems.data,
        invoicePayments: invoicePayments.data,
        isFromInvoiceList: true,
      })
    );
  };

  const fetchPatientInvoices = async (row) => {
    try {
      const totalprevbalance = invoicePatients
        .filter(
          (rows) => rows.patient_id === row.patient_id && rows.status !== "paid"
        )
        .map((rows) => rows.balance);
      dispatch(totalPreviousBalance(totalprevbalance));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPatientData = async (row) => {
    try {
      const response = await axios.get(`clinic_patients/` + row.patient_id);
      const data = response.data;

      dispatch(fetchPatientDetails(data));
    } catch (error) {
      console.error(error);
    }
  };

  function handleInvoices(row) {
    handleOpenInvoice(row);
    fetchPatientInvoices(row);
    fetchPatientData(row);
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 780 }}>
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                const date = moment(row.date).format("LL");

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.number)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    sx={{ height: "70px" }}
                  >
                    <TableCell></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.number}
                    </TableCell>
                    <TableCell align="left">{date}</TableCell>
                    <TableCell
                      sx={patientCell}
                      align="left"
                      onClick={() => {
                        handleInvoices(row);
                      }}
                    >
                      {row.lastname + "," + row.firstname}
                    </TableCell>
                    <TableCell align="left">{row.amount.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.balance.toFixed(2)}</TableCell>
                    <TableCell align="left">
                      {row.status === "unpaid" && (
                        <Box
                          sx={{
                            backgroundColor: "#ec1027",
                            color: "white",
                            width: 50,
                            padding: 0.1,
                            textAlign: "center",
                            borderRadius: 10,
                            fontSize: 12,
                          }}
                        >
                          {capitalizeFirstLowercaseRest(row.status)}
                        </Box>
                      )}
                      {row.status === "partial" && (
                        <Box
                          sx={{
                            backgroundColor: "#ebaf55",
                            color: "white",
                            width: 50,
                            padding: 0.1,
                            textAlign: "center",
                            borderRadius: 10,
                            fontSize: 12,
                          }}
                        >
                          {capitalizeFirstLowercaseRest(row.status)}
                        </Box>
                      )}
                      {row.status === "paid" && (
                        <Box
                          sx={{
                            backgroundColor: "#2e7d32",
                            color: "white",
                            width: 50,
                            padding: 0.1,
                            textAlign: "center",
                            borderRadius: 10,
                            fontSize: 12,
                          }}
                        >
                          {capitalizeFirstLowercaseRest(row.status)}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={rowFilter.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <InvoiceStatusModal />
    </>
  );
}
