import { createSlice } from "@reduxjs/toolkit";
import { axiosInstance as axios } from '../../../Utils/AxiosHttp'

const initialState = {
     isAuth: false,
     permissions: []
};

const authSlice = createSlice({
     name: "auth",
     initialState,
     reducers: {}
})

export const authStore = (state) => state.auth;

export const {
     
} = authSlice.actions;

export default authSlice.reducer;