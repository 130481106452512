import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AvatarImage from "../../../../Components/AvatarImage";
import {
  getCurrentLoggedInUser,
  setCurrentLoggedInUser,
} from "../../../../Utils/authStored";
import { LoadingButton } from "@mui/lab";
import { useRef, useState } from "react";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import ChangePassword from "./change-password";
import useBilling from "../../../../hooks/useBilling";

export default function StaffProfileInformation({ openModal, closeModal }) {
  const user = getCurrentLoggedInUser();

  const staffProfilePic = useRef(null);

  const { subscriptionInfo } = useBilling();

  const [showChangePassword, setShowChangePassword] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [staffData, setStaffData] = useState({
    name: user?.name,
    username: user?.username,
  });

  const validation = (values) => {
    const errors = {};
  };

  const handleSubmit = async () => {
    if (!staffData.name || !staffData.username) {
      return;
    }

    setIsSubmit(true);

    await axios.put("/staff/" + user.user_id, staffData).then(() => {
      Swal.fire({
        icon: "success",
        title: "Successfully updated",
      });
    });

    const current = { ...user };

    if (
      staffProfilePic.length !== 0 &&
      staffProfilePic.current?.size &&
      staffProfilePic.current?.title
    ) {
      const payload = {
        entity: "users",
        entity_id: user?.user_id,
        base64_image: staffProfilePic.current?.base64_image,
        size: staffProfilePic.current?.size,
        title: staffProfilePic.current?.title,
      };

      await axios
        .post("attachments", payload)
        .then((response) => {
          if (response.status === 201) {
            current.avatar = response.data.url;
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Unable to update",
            text: error.errors[0],
          });
        });
    }

    current.name = staffData.name;
    current.username = staffData.username;

    setCurrentLoggedInUser({ ...current });
    setIsSubmit(false);
  };

  const onImageLoaded = async (payload) => {
    staffProfilePic.current = payload;
  };

  const handleInputChange = (e) => {
    e.preventDefault();

    setStaffData({
      ...staffData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  };

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace =
      !data || data.length === 0 || /\s/g.test(data);

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      disableScrollLock
      maxWidth={`sm`}
      PaperProps={{
        sx: {
          top: 15,
          width: "100vh",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
        <Stack justifyContent={`flex-end`} alignItems={`flex-end`}>
          <CloseIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </Stack>
        <Stack justifyContent={`center`} alignItems={`center`} spacing={4}>
          <Stack justifyContent={`center`} alignItems={`center`} spacing={3}>
            <AvatarImage
              fileData={user?.avatar}
              sx={{ width: 130, height: 130 }}
              onImageLoaded={onImageLoaded}
            />

            <Button
              variant="contained"
              color="warning"
              onClick={() => setShowChangePassword(true)}
              disabled={subscriptionInfo?.is_expired}
            >
              Change Password
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#fafafa" }}>
        <Box paddingTop={`40px`}>
          <Stack spacing={2}>
            <Stack direction={`column`} spacing={2}>
              <TextField
                label={`Name`}
                variant="outlined"
                value={staffData.name}
                name="name"
                error={Boolean(!staffData.name)}
                helperText={Boolean(!staffData.name) ? "Name is required" : ""}
                fullWidth
                onChange={(e) => handleInputChange(e)}
              />

              <TextField
                label={`Username`}
                variant="outlined"
                value={staffData.username}
                name="username"
                fullWidth
                error={Boolean(!staffData.username)}
                helperText={
                  Boolean(!staffData.username) ? "Username is required" : ""
                }
                onChange={(e) => handleInputChange(e)}
                onKeyDown={(e) => handleOnKeyDown(e)}
                onPaste={(e) => handleOnPaste(e)}
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>

      <Stack
        direction={`row`}
        justifyContent={`flex-end`}
        alignItems={`center`}
        spacing={2}
        sx={{
          px: 5,
          py: 2,
        }}
      >
        <LoadingButton
          loading={isSubmit}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={subscriptionInfo?.is_expired}
        >
          Save Changes
        </LoadingButton>
      </Stack>

      {showChangePassword && (
        <ChangePassword
          openModal={showChangePassword}
          closeModal={() => setShowChangePassword(false)}
        />
      )}
    </Dialog>
  );
}
