import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedProcQuote, setProcPriceQuote, sortProceduresQuote } from "../../Pages/Quote/Redux/QuoteSlice";
import { useRef } from "react";
import { TextField, Typography } from "@mui/material";
import { numberWithCommas } from "../../Utils/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Procedures",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "calculation",
    numeric: true,
    disablePadding: false,
    label: "Include in Calculation",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: 300,
              backgroundColor: "#f4f6f8",
              fontWeight: "bold",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
            {/* {headCell.id === "procedure" && (
              <Button
                variant="text"
                sx={{
                  display: "block",
                  padding: 0,
                  marginRight: 1,
                  fontSize: 10,
                }}
              >
                Clear All
              </Button>
            )} */}
          </TableCell>
        ))}

        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: "#f4f6f8",
            fontWeight: "bold",
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// function PriceComponent({ price, id }) {
//   price = price ?? 0;
//   const dispatch = useDispatch();
//   const [editable, setEditable] = useState(false);
//   const [value, setValue] = useState(price);

//   const onChange = (value) => {
//     if (!value || value < 0) {
//       setValue(0);
//     }
//     else setValue(value.replace(/^0+/, ''));
//   }

//   const onKeyPressed = (ev) => {
//     if (ev.key === 'Enter') {
//       setEditable(false);
//       dispatch(setProcPriceQuote({
//         id: id,
//         price: parseFloat(value)
//       }));
//       ev.preventDefault();
//     }
//   }

//   return (
//     !editable ? (
//       <div
//         onClick={() => {
//           setValue(price);
//           setEditable(true);
//         }}>
//         <Typography>
//           {numberWithCommas(price)}
//         </Typography>
//       </div>
//     ) :
//       (
//         <TextField
//           inputRef={(input) => input?.focus()}
//           onBlur={() => setEditable(false)}
//           variant="standard"
//           value={value}
//           onChange={(e) => onChange(e.target.value)}
//           onKeyDown={onKeyPressed}
//           type="number" />
//       )
//   );
// }

export default function QuotationTable() {
  const { procedures } = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  const rows = procedures;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const sortConfig = useRef({
    property: '',
    order: 'asc'
  });


  const handleRequestSort = (_, property) => {
    const isAsc = sortConfig.current.order === "asc";
    sortConfig.current.order = isAsc ? 'desc' : 'asc';
    sortConfig.current.property = property;
    dispatch(sortProceduresQuote({
      ...sortConfig.current
    }));
  };

  const handleSelectAllClick = () => {
    dispatch(setCheckedProcQuote({
      checkAll: true
    }));
  };

  const checkboxOnChange = (item) => {
    dispatch(setCheckedProcQuote({
      procedure: item
    }));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <TableContainer sx={{ maxHeight: 450 }}>
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={rows.filter(f => f.checked).length}
            order={sortConfig.current.order}
            orderBy={sortConfig.current.property}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(sortConfig.current.order, sortConfig.current.property))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = row.checked;

                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ height: "70px", cursor: 'pointer' }}
                    onClick={() => checkboxOnChange(row)}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {/* <PriceComponent id={row.id} price={row.price} /> */}
                      {numberWithCommas(row.price)}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
