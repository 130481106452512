import React, { useState } from "react";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Stack,
  Snackbar,
} from "@mui/material";

import { axiosNoAuthInstance as axios } from "../../../Utils/AxiosHttp";
import { axiosInstance as axiosWithAuth } from "../../../Utils/AxiosHttp";
import CloseIcon from "@mui/icons-material/Close";

import {
  setClinics,
  setUserToken,
  setCurrentLoggedInUser,
  setAppCurrentActiveClinic,
  isAuthenticated,
  setTokenExpiry,
  setSpecialization,
  setPermissions,
  setVersion,
} from "../../../Utils/authStored";
import SelectClinic from "./modal/ClinicSelection";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [openClinicsModal, setClinicModal] = useState(false);

  const [password, setPassword] = useState("");

  const [showpassword, setShowPassword] = useState(false);

  const [isSubmitting, setSubmitting] = useState(false);

  const [openSnackbar, setOpenSnackBar] = useState(false);

  const [loginError, setLoginError] = useState("");

  const onLogin = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    await axios
      .post(`login`, { email: email, password: password })
      .then((response) => {
        if (response.status === 200) {
          setClinics(response.data.clinics);
          setTokenExpiry(moment().add(30, "minutes").unix());
          setCurrentLoggedInUser(response.data);
          setUserToken(response.data.token);
          setVersion(process.env.REACT_APP_MARGO_VERSION)
          setSpecialization(
            response?.data?.doctor_information?.specializations ?? []
          );
          
          const { clinics } = response.data;

          fetchPermissions();
          
          if (response.data?.type === "staff") {
            setAppCurrentActiveClinic(clinics[0]);

            isAuthenticated(true);

            if (clinics.length > 1) {
              isAuthenticated(true);
              setClinicModal(true);
            } else {
              isAuthenticated(true);
              if (clinics[0]?.is_expired) {
                navigate("/dashboard/settings");
              } else {
                navigate("/dashboard/appointments");
              }
            }

          
          } else {

            if (clinics.length > 1) {
              isAuthenticated(true);
              setClinicModal(true);
            } else {
              setAppCurrentActiveClinic(clinics[0]);
              isAuthenticated(true);
              if (clinics[0]?.is_expired) {
                navigate("/dashboard/settings");
              } else {
                navigate("/dashboard/appointments");
              }
            }

          }
        }
      })
      .catch((error) => {
        setLoginError(error.error);

        setOpenSnackBar(true);

        setSubmitting(false);
      });
    setSubmitting(false);
  };

  const fetchPermissions = async () => {
    const response = await axiosWithAuth.get(`permissions`);
    setPermissions(response.data);
  };

  const handleRegister = () => {
    navigate("/auth/register-clinic");
  };

  return (
    <Grid
      container
      spacing={5}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Typography variant="h1" color="#004d81">
          MARGO
        </Typography>
        <Typography textAlign="right" color="#747474">
          v{process.env.REACT_APP_MARGO_VERSION}
        </Typography>
      </Grid>

      <Grid item width={450}>
        <form onSubmit={onLogin}>
          <Stack direction={"column"} spacing={2}>
            <TextField
              placeholder="Username/Email"
              variant="outlined"
              fullWidth={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />

            <TextField
              placeholder="Password"
              variant="outlined"
              fullWidth={true}
              type={`${showpassword ? "" : "password"}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showpassword)}
                      edge="end"
                    >
                      {showpassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              fullWidth={true}
              style={{ backgroundColor: "#162964" }}
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              Login
            </LoadingButton>

            <Button
              variant="text"
              sx={{
                backgroundColor: "transparent",
                color: "#747474",
              }}
              onClick={() => navigate('/forgot-password')}
            >
              Forgot Password?
            </Button>

            <Grid mb={3}>
              <Divider style={{ width: "100%" }}>OR</Divider>
            </Grid>
            <Button
              fullWidth={true}
              variant="contained"
              onClick={() => handleRegister()}
              style={{ backgroundColor: "#6a82fc" }}
            >
              CREATE A MARGO ACCOUNT
            </Button>
          </Stack>
        </form>
      </Grid>
      {openClinicsModal && (
        <SelectClinic
          openModal={openClinicsModal}
          closeModal={() => setClinicModal(false)}
        />
      )}

      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={null}
          onClose={() => setOpenSnackBar(false)}
          message={loginError}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpenSnackBar(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </Grid>
  );
}
