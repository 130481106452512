

export default function Button() {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    boxShadow: 'none',
                },
                sizeLarge: {
                    height: 48,
                },
                default: "#1976d2",
                contrastText: 'white',
                containedPrimary: {
                    backgroundColor: "#6a82fc",
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: "#6a82fc",
                    },
                },
                containedSuccess: {
                    backgroundColor: "#28a745",
                    '&:hover': {
                        backgroundColor: "#28a745",
                    },
                },
                containedError: {
                    backgroundColor: "#ed4763",
                    '&:hover': {
                        backgroundColor: "#ed4763",
                    },
                },
                outlined: {
                    '&:hover': {
                        backgroundColor: "ed4763",
                    },
                },
                outlinedPrimary: {
                    border: '1px solid #6a82fc',
                    color: '#6a82fc',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: "#6a82fc",
                    },
                }
            }
        }
    }
}