const photoBoxBorderStyle = {
    borderRadius: "50%",
    border: "2px dotted",
    height: 140,
    width: 140,
    color: "#bdbdbd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const photoButtonStyle = {
    backgroundColor: "#f4f6f8",
    borderRadius: "50%",
    height: 130,
    width: 130,
    position: "relative",
};

const textStyle = {
    color: "#6f7e8b",
    fontSize: 16,
    fontWeight: "bold",
};
const textStyleSpan = {
    color: "#6f7e8b",
    fontSize: 16,
    marginLeft: '5px',
    fontWeight: "normal"
};
const closeButton = {
    "&:hover": {
        backgroundColor: "#fafafa",
        boxShadow: "none",
    },
};

const badgeStyle = {
    "& .MuiBadge-badge": {
        top: -1,
        right: -6,
        padding: "0 4px",
    },
};

const invoicesBadgeStyle = (backgroundColor) => {
    return {
        color: 'white',
        backgroundColor: backgroundColor,
        p: '5px 10px',
        textAlign: 'center',
        borderRadius: '50px',
        textTransform: 'capitalize'
    };
}

export { invoicesBadgeStyle, photoBoxBorderStyle, photoButtonStyle, textStyle, textStyleSpan, closeButton, badgeStyle }