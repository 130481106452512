import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { textStyle, textStyleSpan } from "../style";
import { useDispatch, useSelector } from "react-redux";
import archive from "../../../../assets/image/archive.svg";
import folder from "../../../../assets/image/folder-fill.svg";
import file_pdf from "../../../../assets/image/file-pdf.svg";

import user_edit from "../../../../assets/image/user-edit.svg";
import { numberWithCommas, userCan } from "../../../../Utils/utils";

import {
  patientRecordModal,
  closeModal,
  refreshPatientTableV2,
} from "../../../../Pages/Patients/Redux/patientSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { getAppCurrentActiveClinic } from "../../../../Utils/authStored";

const DownloadSOAPdfModal = ({ onOpen, onClose, patientInfo }) => {
  const [payload, setPayload] = useState({
    patient_id: patientInfo?.id,
    start_date: moment().format("yyyy-MM-DD"),
    end_date: moment().format("yyyy-MM-DD"),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDatePayload = (e, _) => {
    switch (_) {
      case "start_date":
        setPayload({
          ...payload,
          start_date: moment(e.$d).format("yyyy-MM-DD"),
        });
        break;
      case "end_date":
        setPayload({
          ...payload,
          end_date: moment(e.$d).format("yyyy-MM-DD"),
        });
        break;
      default:
        break;
    }
  };

  const handleDownloadSoaPdf = async () => {
    setIsSubmitting(true);
    await axios
      .get(
        `/soa-pdf?patient_id=${payload?.patient_id}&start_date=${payload?.start_date}&end_date=${payload?.end_date}`
      )
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.target = "_blank";
        link.download = response.data.filename;
        link.click();

        onClose();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unable to download!",
        }).then(() => {
          setIsSubmitting(false);
          onClose();
        });
      });

    setIsSubmitting(false);
  };

  return (
    <Dialog open={onOpen} onClose={onClose}>
      <DialogTitle>
        <Stack direction={`row`} justifyContent={`space-between`}>
          <Typography variant="h6">Download SOA PDF</Typography>
          <CloseIcon onClick={onClose} sx={{ cursor: "pointer" }} />
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ px: 2, py: 2 }}>
          <Stack direction={`column`} spacing={2}>
            <DatePicker
              label={`Start Date`}
              onChange={(e) => handleDatePayload(e, "start_date")}
              defau
              name="start_date"
              slotProps={{
                textField: {
                  size: "medium",
                },
              }}
            />

            <DatePicker
              label={`End Date`}
              onChange={(e) => handleDatePayload(e, "end_date")}
              name="end_date"
              slotProps={{
                textField: {
                  size: "medium",
                },
              }}
            />

            <LoadingButton
              variant="contained"
              startIcon={<FileDownloadIcon />}
              loading={isSubmitting}
              onClick={() => handleDownloadSoaPdf()}
            >
              Download File
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default function PatientDetails(props) {
  const patientInfo = props.patientInfo;
  const dispatch = useDispatch();

  const clinic = getAppCurrentActiveClinic();

  const { invoices } = useSelector((store) => store.patient);

  const [openDownloadSOA, setOpenDownloadSOA] = useState(false);

  const handleArchivePatient = () => {
    Swal.fire({
      icon: "question",
      title: `Patient ${patientInfo?.firstname} ${patientInfo?.lastname} will be archive. Do you wish to continue?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (value) => {
      if (value.isConfirmed) {
        await axios.delete(`/clinic_patients/${patientInfo?.id}/${clinic?.id}`);
        dispatch(closeModal());
        dispatch(refreshPatientTableV2(true));
      }
    });
  };

  return (
    <Box
      component={Paper}
      sx={{
        p: "40px",
      }}
    >
      <Stack direction={`row`} spacing={2}>
        <Stack direction={`row`} spacing={2}>
          <Stack direction={`column`}>
            <Typography sx={textStyle}>
              Birthday:
              <span style={textStyleSpan}>
                {patientInfo?.birthdateFormat
                  ? patientInfo.birthdateFormat
                  : "N/A"}
              </span>
            </Typography>
            <Typography sx={textStyle}>
              Phone:
              <span style={textStyleSpan}>
                {patientInfo?.phone ? patientInfo.phone : "N/A"}
              </span>
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={`row`}
          spacing={3}
          sx={{
            px: 5,
          }}
        >
          <Stack direction={`column`}>
            <Typography sx={textStyle}>
              Occupation:
              <span style={textStyleSpan}>
                {patientInfo?.occupation ? patientInfo.occupation : "N/A"}
              </span>
            </Typography>
            <Typography sx={textStyle}>
              Company:
              <span style={textStyleSpan}>
                {patientInfo?.company ? patientInfo.company : "N/A"}
              </span>
            </Typography>
            <Typography sx={textStyle}>
              Address:
              <span style={textStyleSpan}>
                {patientInfo?.address ? patientInfo.address : "N/A"}
              </span>
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={`row`}
          justifyContent={`flex-end`}
          alignItems={`flex-start`}
          spacing={1}
          sx={{
            px: 5,
          }}
        >
          {userCan("editPatient") && (
            <Tooltip title="Edit Patient Info">
              <IconButton
                onClick={() => {
                  dispatch(
                    patientRecordModal({
                      visible: true,
                      patientInfo: patientInfo,
                      options: {
                        isViewProfile: true,
                      },
                    })
                  );
                }}
              >
                <img src={user_edit} alt="" />
              </IconButton>
            </Tooltip>
          )}

          {invoices.length > 0 ? (
            <Tooltip title="Download SOA Pdf">
              <IconButton onClick={() => setOpenDownloadSOA(true)}>
                <img src={file_pdf} alt="" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Download SOA Pdf">
              <IconButton disabled>
                <img src={file_pdf} alt="" />
              </IconButton>
            </Tooltip>
          )}

          {userCan("deletePatient") && (
            <Tooltip title="Archive Patient Record">
              <IconButton onClick={() => handleArchivePatient()} disabled={patientInfo?.is_archived}>
                <img src={folder} alt="" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>

      <Stack direction={`column`} spacing={1} sx={{ mt: 4, px: 5 }}>
        <ButtonGroup fullWidth>
          <Button size="small" variant="outlined" sx={{ fontWeight: "bold" }}>
            Medical Notes
          </Button>
          <Button size="small" variant="outlined" sx={{ fontWeight: "bold" }}>
            Next Prophylaxis
          </Button>
        </ButtonGroup>
        {patientInfo &&
          patientInfo.balance > 0 &&
          (patientInfo.isPaid || patientInfo.isUnpaid) && (
            <Box
              sx={{
                backgroundColor: "#ed4763",
                padding: 1,
                color: "black",
                borderRadius: 1,
                marginBottom: 1,
              }}
            >
              <Typography sx={{ color: "white" }} textAlign="center">
                Previous Balance: ₱ {numberWithCommas(patientInfo.balance)}
              </Typography>
            </Box>
          )}

        {patientInfo && patientInfo.credit > 0 && (
          <Box
            sx={{
              backgroundColor: "#49b545",
              padding: 1,
              color: "black",
              borderRadius: 1,
            }}
          >
            <Typography sx={{ color: "white" }} textAlign="center">
              Credit Balance: ₱ {numberWithCommas(patientInfo.credit)}
            </Typography>
          </Box>
        )}

        {patientInfo &&
          patientInfo.balance > 0 &&
          !patientInfo.isPaid &&
          !patientInfo.isUnpaid && (
            <Box
              sx={{
                backgroundColor: "#ed4763",
                padding: 1,
                color: "black",
                borderRadius: 1,
                marginTop: 1,
              }}
            >
              <Typography sx={{ color: "white" }} textAlign="center">
                Outstanding Balance: ₱ {numberWithCommas(patientInfo.balance)}
              </Typography>
            </Box>
          )}
      </Stack>

      {openDownloadSOA && (
        <DownloadSOAPdfModal
          onOpen={openDownloadSOA}
          onClose={() => setOpenDownloadSOA(false)}
          patientInfo={patientInfo}
        />
      )}
    </Box>
  );
}
