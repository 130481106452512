import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { axiosNoAuthInstance as axios } from "../../../Utils/AxiosHttp";
import DoctorInvitationRegistration from "./doctor-registration";

export default function ProcessInvite() {

   const navigate = useNavigate();

   const { pathname } = useLocation();

   const [isLoading, setIsLoading] = useState(false);

   const hashData = pathname.split("/")[2];

   const [isRegistrationRequired, setIsRegistrationRequired] = useState(false)

   const [doctorInvitationEmail, setDoctorInvitatioEmail] = useState(null);

   const [clinicId, setClinicId] = useState(null)

   const [isMounted, setIsMounted] = useState(false);

   const handleRedirect = () => {
      navigate('/auth/login')
   }

   const handleRequiredRegistration = async () => {
      setIsLoading(true)

      await axios.post(`/clinic/invite/accept`, { hash: hashData }).then(response => {

         if (response.data.require_reg) {

            setIsRegistrationRequired(true)
            setDoctorInvitatioEmail(response.data.email)
            setClinicId(response.data.clinic_id)
         }

      }).catch(error => {
         navigate(`/auth/login`)
      });
      setIsLoading(false)
   }

   useEffect(() => {
      if (!isMounted) {
         handleRequiredRegistration()
         setIsMounted(true)
      }
   }, [])

   return (
      <>
         {
            (isRegistrationRequired) ?
               <DoctorInvitationRegistration 
                  doctorEmailVal={doctorInvitationEmail}
                  hash={hashData}
                  clinicId={clinicId}
               />
               :
               <Container fixed>
                  {
                     (!isLoading) &&
                     <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh"
                     >
                        <Stack
                           direction={'column'}
                           spacing={2}
                           sx={{
                              width: '50vh'
                           }}
                        >
                           <Stack
                              direction={'column'}
                           >
                              <Typography textAlign="center" variant="h1" color="#004d81">
                                 MARGO
                              </Typography>
                              <Box
                                 sx={{
                                    border: '1px dashed grey',
                                    px: 5,
                                    py: 5
                                 }}
                              >
                                 <Stack
                                    direction={`column`}
                                    spacing={2}
                                 >
                                    <Typography color="grey" textAlign="center" variant="h6">Invitation Accepted!</Typography>
                                    <Typography color="grey" textAlign="center" variant="caption">Please clink this <span onClick={() => handleRedirect()} style={{ color: 'green', cursor: 'pointer' }}>link</span> to redirect to login page</Typography>
                                 </Stack>
                              </Box>
                           </Stack>
                        </Stack>
                     </Box>
                  }
               </Container>
         }
      </>
   )
}