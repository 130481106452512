import React from "react";
import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import { photoBoxBorderStyle, textStyle } from "../style";
import { useDispatch } from "react-redux";
import { setSchedulingPatient } from "../../../../Pages/Appointments/Redux/appointmentSlice";
import { useNavigate } from "react-router-dom";
import AppointmentModal from "../../AppointmentModal";
import { closeModal } from "../../../../Pages/Patients/Redux/patientSlice";
import { SQLDateToIosCompatible, userCan } from "../../../../Utils/utils";

export default function PatientProfile(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientInfo = props.patientInfo;
  const appointmentRef = React.useRef();

  const walkInOnClick = () => {
    dispatch(
      setSchedulingPatient({
        patient: patientInfo,
      })
    );
    appointmentRef.current.show(new Date());
  };

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = SQLDateToIosCompatible(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <>
      <Box
        component={Paper}
        sx={{
          px: 8,
          py: 5,
        }}
      >
        <Stack
          direction={`column`}
          justifyContent={`center`}
          alignItems={"center"}
        >
          <Box sx={photoBoxBorderStyle}>
            <Avatar
              alt="Remy Sharp"
              src={patientInfo?.avatar}
              sx={{ width: 130, height: 130 }}
            />
          </Box>

          <Typography sx={textStyle}>
            {patientInfo?.fullname} {patientInfo?.middlename}
          </Typography>

          <Typography sx={textStyle}>
            {`${patientInfo?.nickname ?? ""}`}
          </Typography>

          <Stack direction={`row`}>
            <Typography variant="subtitle2" sx={textStyle}>
              {getAge(patientInfo?.birthdate) +
                ", " +
                patientInfo?.sex +
                ", " +
                patientInfo?.marital_status}
            </Typography>
          </Stack>

          <Stack direction={`row`} spacing={1}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={!userCan("createAppointments")}
              onClick={() => {
                dispatch(
                  setSchedulingPatient({
                    visible: true,
                    patient: patientInfo,
                  })
                );
                dispatch(closeModal());
                navigate("/dashboard/appointments");
              }}
            >
              Schedule
            </Button>
            <Button
              variant="outlined"
              disableElevation
              size="small"
              sx={{ fontWeight: "bold" }}
              onClick={walkInOnClick}
              disabled={!userCan("createAppointments")}
            >
              Walk&#8211;in
            </Button>
          </Stack>
        </Stack>
      </Box>
      <AppointmentModal ref={appointmentRef} />
    </>
  );
}
