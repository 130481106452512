import { useEffect, useState } from "react";
import { Box, Avatar, IconButton, Typography } from "@mui/material";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
const imageMimeType = /image\/(jpeg)/i;

function AvatarImage({
  disabled,
  fileData,
  onImageLoaded
}) {
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(fileData ?? null);
  const [filename, setFilename] = useState(null);
  const [error, setError] = useState(false);
  const changeHandler = (e) => {
    const file = e.target.files[0];

    if (!file.type.match(imageMimeType)) {
      setError(true);
      setFileDataURL(null);
      return;
    }
    setFile(file);
    setFilename(file.name);
    setError(false);
  };
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;

        if (result && !isCancel) {
          onLoad(result);
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };

  }, [file]);

  const imagecontainer = {
    position: "relative",

    borderRadius: "100%",
    width: 160,
    height: 160,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dotted #bdbdbd",
  };
  const imagebgcontainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const onLoad = (base64Image) => {
    const payload = {
      entity: 'clinic_patient',
      // entity_id: this.props.patient.id,
      base64_image: base64Image,
      size: 'small',
      title: 'profile_photo',
    };

    if (onImageLoaded) onImageLoaded(payload);
  }

  return (
    <>
      <form>
        <Box sx={imagebgcontainer}>
          <Box sx={imagecontainer}>
            <Avatar
              alt={filename}
              src={fileDataURL}
              sx={{ width: 150, height: 150 }}
            ></Avatar>
            {!disabled && <IconButton
              component="label"
              sx={{ position: "absolute", bottom: 0, right: 5 }}
            >
              <AddAPhotoIcon sx={{ color: "#007fff" }} />
              <input type="file" hidden onChange={changeHandler} />
            </IconButton> }
          </Box>

          {/* <Box sx={{ textAlign: "center", mt: 1.5, color: "#6f7e8b" }}>
            {error ? (
              <Box>
                <Typography sx={{ fontSize: "12px", color: "#f50057", mb: 2 }}>
                  Image type is not valid
                </Typography>
              </Box>
            ) : (
              <Box
                component="div"
                sx={{
                  fontSize: "12px",
                  color: "#757575",
                  mb: 2,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {filename}
              </Box>
            )}
          </Box> */}
        </Box>
      </form>
    </>
  );
}
export default AvatarImage;
