import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ListIcon from "@mui/icons-material/List";

import ReceiptIcon from "@mui/icons-material/Receipt";
import { showPatientInfo } from "../../../../../Patients/Redux/patientSlice";
import {
  fetchInvoiceData,
  fetchPatientDetails,
  totalPreviousBalance,
} from "../../../../../Invoices/Redux/invoiceSlice";
import AppointmentUtils from "../../../../Redux/AppointmentUtils";
import { openCreateInvoice } from "../../../../Redux/appointmentSlice";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance as axios } from "../../../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { getAppCurrentActiveClinic } from "../../../../../../Utils/authStored";
import { userCan } from "../../../../../../Utils/utils";
export default function AppointmentActions(props) {
  const { selectedPatient, patients, isOpen } = useSelector(
    (store) => store.appointment
  );
  const { isOpenViewInvoice } = useSelector((state) => state.invoice);

  const [invoice, setInvoice] = React.useState([]);
  const dispatch = useDispatch();

  const hideCreateInvoice =
    invoice.filter(
      (f) =>
        f.status === "unpaid" || f.status === "partial" || f.status === "paid"
    ).length > 0;

  React.useEffect(() => {
    if (selectedPatient && !isOpen && !isOpenViewInvoice) {
      getInvoice();
    }
    // eslint-disable-next-line
  }, [selectedPatient, isOpen, isOpenViewInvoice]);

  const getInvoice = async () => {
    try {
      const invoiceRes = await axios.get(
        `invoices?appointment_id=${selectedPatient.id}`
      );
      if (invoiceRes.status === 200) {
        setInvoice(invoiceRes.data);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to fetch appointment invoice!",
      });
    }
  };

  const handleViewInvoice = async () => {
    const clinic = getAppCurrentActiveClinic();
    Swal.fire({
      icon: "info",
      title: "Fetching Invoice Information",
      text: "Please wait...",
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const appointmentId = selectedPatient.id;
          const invoiceRes = await axios.get(
            `invoices?appointment_id=${appointmentId}`
          );
          if (invoiceRes.status === 200) {
            const invoice = invoiceRes.data[0];
            const [
              invoiceData,
              invoiceItems,
              invoicePayments,
              patientInfo,
              invoices,
            ] = await Promise.all([
              axios.get(`invoices/${invoice.id}`),
              axios.get(`invoice_items?invoice_id=${invoice.id}`),
              axios.get(`invoice/payments/${invoice.id}`),
              axios.get(`clinic_patients/${invoice.patient_id}`),
              axios.get(
                `invoices?clinic_id=${clinic.id}&patient_id=${invoice.patient_id}`
              ),
            ]);

            const totalprevbalance = invoices.data
              .filter(
                (rows) => rows.status !== "paid" && rows.id !== invoice.id
              )
              .map((rows) => rows.balance);
            dispatch(
              totalPreviousBalance(
                invoices.data.length > 1 ? totalprevbalance : []
              )
            );
            dispatch(fetchPatientDetails(patientInfo.data));
            dispatch(
              fetchInvoiceData({
                invoiceDetails: invoiceData.data,
                invoiceItemDetails: invoiceItems.data,
                invoicePayments: invoicePayments.data,
                isFromInvoiceList: true,
              })
            );
            Swal.close();
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Failed to fetch invoice info",
            text: "Try again.",
          });
        }
      },
    });
  };

  return (
    <Paper style={{ padding: "5px", height: "100%" }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          bgcolor: "background.paper",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            // selected={selectedIndex === 0}
            onClick={() => {
              const patient = AppointmentUtils.getPatientInfo(
                patients,
                selectedPatient.patientID
              );
              dispatch(showPatientInfo(patient));
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                !selectedPatient.isDone
                  ? "View Patient Record"
                  : "Update Record"
              }
            />
          </ListItemButton>

          {selectedPatient.isDone && ((!hideCreateInvoice && userCan("createInvoice")) || (hideCreateInvoice && userCan("viewAllInvoices"))) && (
            <ListItemButton
              onClick={() => {
                if (!hideCreateInvoice) {
                  dispatch(openCreateInvoice());
                } else handleViewInvoice();
              }}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  !hideCreateInvoice ? "Create Invoice" : "View Invoice"
                }
              />
            </ListItemButton>
          )}
          <ListItemButton
            // selected={selectedIndex === 1}
            onClick={props.close}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Back to appointments" />
          </ListItemButton>
        </List>
      </Box>
    </Paper>
  );
}
