import CryptoJS from 'crypto-js';
import getUuidByString from "uuid-by-string";

const SECRET_KEY = `${btoa(getUuidByString('margoweb2023'))}`;

export const getTokenyExpiry = () => localStorage.getItem("tokenExpiry");

export const setTokenExpiry = (expiry) =>
  localStorage.setItem("tokenExpiry", expiry);

export const isAuthenticated = (condition) =>
  localStorage.setItem("isAuthenticated", condition);

export const getIsAuthenticated = () => localStorage.getItem("isAuthenticated");


export const setUserToken = (token) => {
  const encrypted = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
  localStorage.setItem("accessToken", encrypted);
}

export const getUserToken = () => {
  const encrypted = localStorage.getItem("accessToken");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

export const setCurrentLoggedInUser = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  localStorage.setItem("authUser", encrypted);
}

export const getCurrentLoggedInUser = () => {
  const encrypted = localStorage.getItem("authUser");
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }
};

export const setPermissions = (permissions) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(permissions), SECRET_KEY).toString();
  localStorage.setItem("permissions", encrypted);
}

export const getPermissions = () => {
  const encrypted = localStorage.getItem("permissions");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};


export const setSpecialization = (specialization) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(specialization), SECRET_KEY).toString();
  localStorage.setItem("specialization", encrypted);
}

export const getSpecialization = () => {
  const encrypted = localStorage.getItem("specialization");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

export const setClinics = (clinics) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(clinics), SECRET_KEY).toString();
  localStorage.setItem("clinics", encrypted);
}

export const getClinics = () => {
  const encrypted = localStorage.getItem("clinics");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

export const setAppCurrentActiveClinic = (clinic) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(clinic), SECRET_KEY).toString();
  localStorage.setItem("active-clinic", encrypted);
};

export const getAppCurrentActiveClinic = () => {
  const encrypted = localStorage.getItem("active-clinic");
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }
};

export const setVersion = (version) => {
  localStorage.setItem("margo-web-version", version);
};

export const getVersion = () => {
  return localStorage.getItem("margo-web-version");
};

export const clearAuth = async () => {
  await localStorage.removeItem("accessToken");
  await localStorage.removeItem("clinics");
  await localStorage.removeItem("active-clinic");
  await localStorage.removeItem("isAuthenticated");
  await localStorage.removeItem("authUser");
  await localStorage.removeItem("tokenExpiry");
  await localStorage.removeItem("specialization");
  await localStorage.removeItem("permissions");
  await localStorage.removeItem("margo-web-version");
};

export const getPermissibles = (userId) => {
  const user = findClinicUser(userId);

  if (!user || !user.permissions) user.permissions = [];

  const key =
    user.user_type === "doctor"
      ? user && user.isAdmin === 1
        ? "doctor"
        : "inhousedoc"
      : "staff";

  const encrypted = localStorage.getItem("permissions");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);

  let availablePermissions = JSON.parse(decrypted)
    .filter((p) => {
      return p.user_type_availability.indexOf(key) > -1;
    })
    .map((p) => {
      if (p.name === "editUserPermissions") {
        p.label = "Edit Permissions";
      }
      if (p.name === "editInvoice") {
        p.name = "voidInvoice";
        p.label = "Void Invoice";
      }
      return {
        name: p.name,
        label: p.label,
        enabled:
          user && user.permissions && user.permissions.indexOf(p.name) > -1,
      };
    });

  return availablePermissions;
};

export const findClinicUser = (user = null) => {
  const encrypted = localStorage.getItem("active-clinic");
  const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const { users } = JSON.parse(decrypted);

  const encrypted2 = localStorage.getItem("authUser");
  const decrypted2 = CryptoJS.AES.decrypt(encrypted2, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const actUser = JSON.parse(decrypted2);

  for (var i in users) {
    if (user) {
      if (users[i].id === user) return users[i];
    } else {
      if (users[i].id === actUser.user_id) return users[i];
    }
  }

  return null;
};
