import React from "react";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const PrivacyModal = ({ open, closeModal }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        disableScrollLock={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              Terms and Conditions
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          These terms and conditions outline the rules and regulations for the
          use of MARGO.
          <br />
          <br />
          By accessing this APP we assume you accept these terms and conditions
          in full. Do not continue to use MARGO if you do not accept all of the
          terms and conditions stated on this page.
          <br />
          <br />
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and any or all Agreements:
          <br />
          <br />
          “Client”, “You” and “Your” refers to you, the person accessing this
          APP and accepting the Company's terms and conditions. “The Company”,
          “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
          “Parties”, or “Us”, refers to both the Client and ourselves, or either
          the Client or ourselves. All terms refer to the offer, acceptance, and
          consideration of payment necessary to undertake the process of our
          assistance to the Client in the most appropriate manner, whether by
          formal meetings of a fixed duration or any other means, for the
          express purpose of meeting the Client’s needs in respect of provision
          of the Company’s stated services/products, in accordance with and
          subject to, prevailing law of Philippines.
          <br />
          <br />
          Any use of the above terminology or other words in the singular,
          plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to the same.
          <br />
          <br />
          <br />
          <h5>COOKIES</h5>
          We employ the use of cookies. By using MARGO you consent to the use of
          cookies in accordance with MARGO privacy policy. Most of the
          modern-day interactive websites use cookies to enable us to retrieve
          user details for each visit. Cookies are used in some areas of our
          site to enable the functionality of this area and ease of use for
          those people visiting. Some of our affiliate/advertising partners may
          also use cookies.
          <br />
          <br />
          <br />
          <h5>LICENSE</h5>
          You must not:
          <br />
          <br />
          Republish material from our APP.
          <br />
          Sell, rent or sub-license material from our APP.
          <br />
          Reproduce, duplicate or copy material from our APP.
          <br />
          Redistribute content from MARGO (unless content is specifically made
          for redistribution).
          <br />
          <br />
          <br />
          <h5>HYPERLINKING TO OUR CONTENT</h5>
          The following organizations may link to our APP without prior written
          approval:
          <br />
          <br />
          Government agencies;
          <br />
          Search engines;
          <br />
          News organizations;
          <br />
          We may consider and approve in our sole discretion other link requests
          from the following types of organizations:
          <br />
          <br />
          associations or other groups representing charities, including charity
          giving sites;
          <br />
          online directory distributors;
          <br />
          internet portals;
          <br />
          accounting, law and consulting firms whose primary clients are
          businesses;
          <br />
          educational institutions and trade associations.
          <br />
          <br />
          <br />
          We will approve link requests from these organizations if we determine
          that: (a) the link would not reflect unfavorably on us or our
          accredited businesses (for example, trade associations or other
          organizations representing inherently suspect types of business, such
          as work-at-home opportunities, shall not be allowed to link); (b)the
          organization does not have an unsatisfactory record with us; (c) the
          benefit to us from the visibility associated with the hyperlink
          outweighs the absence of; and (d) where the link is in the context of
          general resource information or is otherwise consistent with editorial
          content in a newsletter or similar product furthering the mission of
          the organization.
          <br />
          <br />
          These organizations may link to our home page, to publications or to
          other APP information so long as the link: (a) is not in any way
          misleading; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products or services; and (c)
          fits within the context of the linking party's site.
          <br />
          <br />
          If you are among the organizations listed in paragraph 2 above and are
          interested in linking to our APP, you must notify us by sending an
          e-mail to hello@linneasystems.com.
          <br />
          <br />
          Please include your name, your organization name, contact information
          (such as a phone number and/or e-mail address) as well as the URL of
          your site, a list of any URLs from which you intend to link to our
          Website, and a list of the URL(s) on our site to which you would like
          to link. Allow 2-3 weeks for a response.
          <br />
          <br />
          Approved organizations who may hyperlink to our APP are as follows:
          <br />
          <br />
          By use of our corporate name; or
          <br />
          By use of the uniform resource locator (Web address) being linked to;
          or
          <br />
          By use of any other description of our APP or material being linked to
          that makes sense within the context and format of content on the
          linking party's site.
          <br />
          No use of MARGO logo or other artwork will be allowed for linking
          absent a trademark license agreement.
          <br />
          <br />
          <br />
          <h5>DATA SECURITY</h5>
          The security of your personal information is important to us. We
          follow industry-standard technical and organizational measures to
          protect the security of our users' and applicants' personal
          information. Our software is hosted on secure, fire-walled servers in
          an SSAE-16 compliant data center. We use 256-bit SSL (Secure Socket
          Layer) encryption for all data transfers. All client data is backed up
          daily. Only authorized MARGO employees have access to clients' data
          and we never share that data with other clients.
          <br />
          <br />
          <br />
          <h5>IFRAMES</h5>
          Without prior approval and express written permission, you may not
          create frames around our APP pages or use other techniques that alter
          in any way the visual presentation or appearance of our APP.
          <br />
          <br />
          <br />
          <h5>RESERVATION OF RIGHTS</h5>
          We reserve the right at any time and in its sole discretion to request
          that you remove all links or any particular link to our APP. You agree
          to immediately remove all links to our APP upon such request. We also
          reserve the right to amend these terms and conditions and its linking
          policy at any time. By continuing to link to our APP, you agree to be
          bound to and abide by these linking terms and conditions.
          <br />
          <br />
          Removal of links from our APP If you find any link on our APP or any
          linked web site objectionable for any reason, you may contact us about
          this. We will consider requests to remove links but will have no
          obligation to do so or to respond directly to you.
          <br />
          <br />
          Whilst we endeavor to ensure that the information on this APP is
          correct, we do not warrant its completeness or accuracy; nor do we
          commit to ensuring that the website remains available or that the
          material on the APP is kept up to date.
          <br />
          <br />
          <br />
          <h5>DISCLAIMER</h5>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties, and conditions relating to our APP and
          the use of this APP (including, without limitation, any warranties
          implied by law in respect of satisfactory quality, fitness for purpose
          and/or the use of reasonable care and skill). Nothing in this
          disclaimer will:
          <br />
          <br />
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
          <br />
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
          <br />
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
          <br />
          The limitations and exclusions of liability set out in this Section
          and elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the disclaimer
          or in relation to the subject matter of this disclaimer, including
          liabilities arising in contract, in tort (including negligence) and
          for breach of statutory duty.
          <br />
          <br />
          To the extent that the APP and the information and services on the APP
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
          <br />
          <br />
          <br />
          <h5>CREDIT & CONTACT INFORMATION</h5>
          If you have any queries regarding any of our terms, please contact us.
          <br />
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrivacyModal;
