import { Box, Button, Checkbox, Container, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState, useCallback, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router";
import { axiosNoAuthInstance as axios } from "../../../../Utils/AxiosHttp";
import { useNavigate } from "react-router-dom";
import { FaTeeth } from 'react-icons/fa';
import { getIsAuthenticated } from "../../../../Utils/authStored";
import CircularProgress from '@mui/material/CircularProgress';

export default function DoctorSpecialization() {

   const navigate = useNavigate();

   const isWebHasLoggedInUser = getIsAuthenticated();

   const { pathname } = useLocation();

   const [specialization, setSpecialization] = useState([]);

   const [isMounted, setIsMounted] = useState(false);

   const [rerender, setRerender] = useState(false);

   const doctor_id = pathname.split("/")[3];

   const getSpecialization = async () => {

      await axios.get(`specializations`).then(response => {
         var tmp = response.data;

         for (var i in tmp) {
            tmp[i].enabled = false;
         }

         setSpecialization(tmp);

      })

   }

   const handleRegisterDoctorSpecialization = async () => {
      var inserts = specialization.filter(function (spec) {
         return spec.enabled;
      }).map(spec => {
         return {
            doctor_id: doctor_id,
            specialization_id: spec.id
         }
      })

      await axios.post(`/doctor_specializations`, { batch: inserts }).then(response => {

         if (isWebHasLoggedInUser) {

            navigate(`dashboard/appointments`)

         } else {

            navigate(`/auth/login`);
         }

      })
      
   }

   const handleSelectSpecialization = (row) => {
      for (let i in specialization) {
         if (specialization[i].id === row.id) {
            specialization[i].enabled = !row.enabled;
         }
      }
      setSpecialization(specialization)
      setRerender(!rerender)
   }

   useEffect(() => {
      if (!isMounted) {
         getSpecialization();
         setIsMounted(true);
      }
   });


   return (
      <Container>
         <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
         >
            <Stack
               direction={'column'}
               spacing={2}
               sx={{
                  width: '120vh'
               }}
            >
               <Stack
                  direction={'column'}
               >
                  <Typography textAlign="center" variant="h1" color="#004d81">
                     MARGO
                  </Typography>
                  <Typography textAlign="right" color="#747474" sx={{ pr: 45 }}>
                     v{process.env.REACT_APP_MARGO_VERSION}
                  </Typography>
               </Stack>

               <Stack
                  direction={'column'}
                  alignItems={'center'}
                  spacing={2}
               >
                  <Typography variant="h6">Doctor Specialization</Typography>
                  <span>Tap on specializations below to toggle</span>
                  <Divider flexItem orientation="horizontal" />
               </Stack>

               <Stack
                  direction={'column'}
                  spacing={2}
               >
                  <Grid container spacing={6}>
                     {
                        (isMounted) ?
                        specialization?.map((row, index) => (
                           <Grid item xs={4} key={index}>
                              <Button
                                 variant={row.enabled ? 'contained' : 'outlined'}
                                 size="large"
                                 color="success"
                                 startIcon={<FaTeeth />}
                                 sx={{
                                    width: '100%',
                                    color: 'black',
                                    justifyContent: "left",
                                    minWidth: "25vh",
                                    minHeight: "8vh",
                                    color: row.enabled ? 'white' : 'black'
                                 }}
                                 onClick={() => handleSelectSpecialization(row)}
                              >
                                 <Box sx={{ width: "100%" }}>
                                    <Typography variant="subtitle1">{row.specialization}</Typography>
                                 </Box>
                              </Button>
                           </Grid>
                        ))

                        :
                        <Box sx={{ display: 'flex' }}>
                           <CircularProgress color="inherit"/>
                        </Box>
                     }
                  </Grid>
                  
                  {
                     isMounted &&
                     <Button
                        variant="contained"
                        size="large"
                        onClick={() => handleRegisterDoctorSpecialization()}
                     >
                        Submit
                     </Button>
                  }
               </Stack>
            </Stack>
         </Box>
      </Container>
   )
}