import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useLocation } from "react-router";
import { axiosNoAuthInstance as axios } from "../../../../Utils/AxiosHttp";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function DoctorInvitationRegistration({
  doctorEmailVal,
  hash,
  clinicId
}) {

  const { pathname } = useLocation();

  const [showpassword, setShowPassword] = useState(false);
  const [validationError, setValidationError] = useState({});
  const navigate = useNavigate();

  const defaultVal = {
    birthdate: "",
    password: "",
    password_confirmation: "",
    email: doctorEmailVal,
    extensions: "",
    firstname: "",
    lastname: "",
    license: "",
    middlename: "",
    username: "",
  };

  const [doctorData, DoctorData] = useState(defaultVal);

  const {
    birthdate,
    password,
    password_confirmation,
    email,
    extensions,
    firstname,
    lastname,
    license,
    middlename,
    username,
  } = doctorData;

  const validations = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "The email field is required.";
    }
    if (!values.username) {
      errors.username = "The username field is required.";
    }
    if (!values.password) {
      errors.password = "The password field is required.";
    }
    if (!values.password_confirmation) {
      errors.password_confirmation =
        "The password confirmation field is required.";
    }
    if (!values.firstname) {
      errors.firstname = "The firstname field is required.";
    }

    if (!values.lastname) {
      errors.lastname = "The lastname field is required.";
    }

    if (!values.birthdate) {
      errors.birthdate = "The birthdate field is required.";
    }
    if (!values.license) {
      errors.license = "The license number field is required.";
    }
    return errors;
  };

  const handleDoctorInput = (e) => {
    e.preventDefault();

    DoctorData({
      ...doctorData,
      is_admin: false,
      clinic_id: clinicId,
      hash: hash,
      user_type: "doctor",
      [e.target.name]: e.target.value,
    });
  };

  const handleDoctorBirthdate = (e) => {
    DoctorData({
      ...doctorData,
      birthdate: moment(e?.$d).format("yyyy-MM-DD"),
    });
  };

  const handleRegisterDoctor = async () => {
    if (
      !birthdate ||
      !password ||
      !password_confirmation ||
      !email ||
      !firstname ||
      !lastname ||
      !license ||
      !username
    ) {
      setValidationError(validations(doctorData));
      return;
    }
    await axios.post(`/doctors`, doctorData).then((response) => {

      navigate(`/auth/register-doctor-specialization/${response.data.id}`);

    }).catch((error) => {
      if (error.errors[0]) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: error.errors[0],
        });
      }
    });
  };

  const handleOnKeyDown = (e) => {
    var e = window.event || e;
    var key = e.keyCode;

    if (key == 32) {
      e.preventDefault();
    }
  }

  const handleOnPaste = (event) => {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }
  }

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          direction={"column"}
          spacing={2}
          sx={{
            width: "80vh",
          }}
        >
          <Stack direction={"column"}>
            <Typography textAlign="center" variant="h1" color="#004d81">
              MARGO
            </Typography>
            <Typography textAlign="right" color="#747474" sx={{ pr: 20 }}>
              v{process.env.REACT_APP_MARGO_VERSION}
            </Typography>
          </Stack>

          <Stack direction={"column"} alignItems={"center"} spacing={2}>
            <Typography variant="h6"></Typography>

            <Divider flexItem orientation="horizontal" />
          </Stack>

          <Stack direction={"column"} spacing={2}>
            <TextField
              variant="outlined"
              onChange={(e) => handleDoctorInput(e)}
              label="Email"
              name="email"
              required
              disabled
              value={email || ""}
              onBlur={validations}
              error={Boolean(validationError.email)}
              helperText={validationError.email}
            />

            <TextField
              onChange={(e) => handleDoctorInput(e)}
              label="Username"
              name="username"
              variant="outlined"
              required
              autoComplete="off"
              value={username || ""}
              onBlur={validations}
              error={Boolean(validationError.username)}
              helperText={validationError.username}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
            />

            <TextField
              autoComplete="off"
              label="Password"
              name="password"
              required
              value={password || ""}
              onBlur={validations}
              error={Boolean(validationError.password)}
              helperText={validationError.password}
              variant="outlined"
              type={`${showpassword ? "" : "password"}`}
              onChange={(e) => handleDoctorInput(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showpassword)}
                      edge="end"
                    >
                      {showpassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
            />

            <TextField
              label="Confirm Password"
              name="password_confirmation"
              required
              value={password_confirmation || ""}
              onBlur={validations}
              error={Boolean(validationError.password_confirmation)}
              helperText={validationError.password_confirmation}
              variant="outlined"
              type={`${showpassword ? "" : "password"}`}
              onChange={(e) => handleDoctorInput(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {showpassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              onKeyDown={(e) => handleOnKeyDown(e)}
              onPaste={(e) => handleOnPaste(e)}
            />

            <TextField
              variant="outlined"
              onChange={(e) => handleDoctorInput(e)}
              label="First Name"
              name="firstname"
              required
              value={firstname || ""}
              onBlur={validations}
              error={Boolean(validationError.firstname)}
              helperText={validationError.firstname}
            />

            <TextField
              onChange={(e) => handleDoctorInput(e)}
              label="Middle Name"
              name="middlename"
              variant="outlined"
            />

            <TextField
              variant="outlined"
              onChange={(e) => handleDoctorInput(e)}
              label="Last Name"
              name="lastname"
              required
              value={lastname || ""}
              onBlur={validations}
              error={Boolean(validationError.lastname)}
              helperText={validationError.lastname}
            />

            <TextField
              onChange={(e) => handleDoctorInput(e)}
              label="Name Suffix"
              placeholder="e.g. Jr, Sr, III"
              name="suffix"
              variant="outlined"
            />

            <DatePicker
              label={`Birthday *`}
              onChange={(e) => handleDoctorBirthdate(e)}
              name="birthdate"
              slotProps={{
                textField: {
                  size: "medium",
                  helperText: birthdate ? "" : validationError.birthdate,
                },
              }}
              value={validationError.birthdate}
              onBlur={validations}
              error={Boolean(validationError.birthdate)}
            />

            <TextField
              onChange={(e) => handleDoctorInput(e)}
              label="PRC Licence Number"
              name="license"
              variant="outlined"
              required
              helperText={
                <Stack direction={"column"}>
                  {validationError.license && (
                    <Typography variant="caption">
                      {validationError.license}
                    </Typography>
                  )}

                  <Typography variant="caption" color={`error`}>
                    This information will be kept private and will only be used
                    for security to prevent illegal practice of dentistry
                  </Typography>
                </Stack>
              }
              value={license || ""}
              onBlur={validations}
              error={Boolean(validationError.license)}
            />

            <TextField
              onChange={(e) => handleDoctorInput(e)}
              label="Extensions"
              name="extensions"
              variant="outlined"
            />

            <Button
              variant="contained"
              size="large"
              onClick={() => handleRegisterDoctor()}
            >
              Register
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}
