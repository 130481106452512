import { useEffect, useRef, useState } from "react";
// import { useTheme } from "@emotion/react";
import { useMediaQuery, useTheme } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";
import {
  Button,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import {
  getAppCurrentActiveClinic,
  getCurrentLoggedInUser,
} from "../../../Utils/authStored";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: "blue",
//     color: "white",
//     padding: theme.spacing(1),
//   },
// }));

const SupportForm = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [Attachedfiles, setAttachedFiles] = useState([]);

  const clinic = getAppCurrentActiveClinic();
  const user = getCurrentLoggedInUser();
  const types = ["image/jpeg", "image/png"];

  const fileRef = useRef(null);

  const handleUploadFiles = (files) => {
    const uploaded = [...Attachedfiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setAttachedFiles(uploaded);
  };

  const handleFileInputChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...Attachedfiles];
    newFiles.splice(index, 1);
    setAttachedFiles(newFiles);

    fileRef.current.value = "";
  };

  const submitReport = async () => {
    if (!summary || !description || !priority) {
      Swal.fire({
        title: "Description or Summary or Priority must not be empty!",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      return;
    }

    if (summary.length < 8) {
      Swal.fire({
        title: "Summary is required with at least 8 characters",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      return;
    }

    let payload = {
      subject: summary,
      description: description,
      user: user?.user_id + "",
      clinic: clinic?.id + "",
      device: "Web",
      priority: priority,
      app_version: process.env.REACT_APP_MARGO_VERSION,
      attachments: Attachedfiles,
    };

    try {
      await axios
        .post(`report`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            let timerInterval;
            Swal.fire({
              title: "Please wait while I send your report...",
              timer: 4000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  // b.textContent = Swal.getTimerLeft();
                }, 200);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Done",
                  showConfirmButton: false,
                  timer: 1500,
                });

                setDescription("");
                setPriority("");
                setSummary("");
                setAttachedFiles([]);
              }
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack direction={`column`} spacing={2} sx={{ mt: 3 }}>
      <Stack direction={`column`} spacing={2}>
        <Stack
          direction={{ xs: `column`, md: `row` }}
          justifyContent={`center`}
          spacing={2}
          sx={{
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <TextField
            variant="outlined"
            label="Urgency"
            size="medium"
            placeholder={`Select Priority`}
            fullWidth
            select
            value={priority}
            onChange={(event) => {
              setPriority(event.target.value);
            }}
          >
            <MenuItem value={""}>
              <em>Select Priority</em>
            </MenuItem>
            <MenuItem value={"Highest"}>Highest</MenuItem>
            <MenuItem value={"High"}>High</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
            <MenuItem value={"Lowest"}>Lowest</MenuItem>
          </TextField>

          <Button
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            fullWidth
            sx={{
              width: "30vh",
            }}
          >
            Add Attachment
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              ref={fileRef}
              onChange={handleFileInputChange}
            />
          </Button>
        </Stack>

        <TextField
          label={`Summary`}
          variant="outlined"
          size="medium"
          fullWidth
          required
          value={summary}
          onChange={(event) => {
            setSummary(event.target.value);
          }}
        />

        <TextField
          label={`Description`}
          variant="outlined"
          size="medium"
          multiline
          rows={10}
          fullWidth
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </Stack>

      <Stack
        direction={{ xs: `column-reverse`, md: `row` }}
        justifyContent={`space-between`}
        alignItems={!matches && `center`}
        spacing={{ xs: `10px` }}
      >
        <Typography fontSize={15}>
          Version {process.env.REACT_APP_MARGO_VERSION}
        </Typography>
        <Button
          variant={`contained`}
          startIcon={<SendIcon />}
          onClick={(event) => submitReport(event.target.value)}
        >
          Submit Report
        </Button>
      </Stack>

      <Stack direction={`column`} sx={{ py: 5 }}>
        {Attachedfiles.length > 0 && (
          <Typography variant="h6" sx={{ mb: 5 }}>
            Attachments
          </Typography>
        )}

        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          justifyContent="start"
          sx={{ margin: "4px 4px 10px 4px " }}
          // columns={{ xs: 12, sm: 12, md: 12 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Attachedfiles.map((file, index) => (
            <>
              {types.includes(file.type) ? (
                <Grid item xs={12} md={4} key={index}>
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      p: 1,
                      border: "1px dashed grey",
                      borderRadius: 2,
                      width: { xs: "100%", md: 350 },
                      maxWidth: { xs: "100%", md: 250 },
                    }}
                  >
                    <IconButton
                      sx={{ position: "absolute", top: -30, right: -25 }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Box
                      component="img"
                      sx={{
                        width: "100%",

                        padding: "1px",
                      }}
                      key={file.name}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} md={4} key={index}>
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      p: 1,
                      border: "1px dashed grey",
                      borderRadius: 2,
                      width: { xs: "100%", md: 350 },
                      maxWidth: { xs: "100%", md: 250 },
                    }}
                  >
                    <IconButton
                      sx={{ position: "absolute", top: -30, right: -25 }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Box
                      component="img"
                      sx={{
                        width: "100%",

                        padding: "1px",
                      }}
                      key={file.name}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  </Box>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SupportForm;
