import React from "react";
import Button from '@mui/material/Button';
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearSchedulingPatient } from "../Redux/appointmentSlice";

const ScheduleHeader = () => {
    const { schedulePatient } = useSelector((store) => store.appointment);
    const dispatch = useDispatch();

    const cancelScheduling = () => {
        // const headerEl = document.getElementsByClassName("appointment-header")[0];
        // if (headerEl?.style) {
        //     headerEl.classList.remove("show");
        //     headerEl.classList.add("hide");
        // }
        dispatch(clearSchedulingPatient());
    };

    return (
        schedulePatient.visible && <Box>
            <Paper style={{ padding: "10px", height: "65px", marginBottom: '15px' }}>
                <Grid container height={'100%'}>
                    <Grid
                        xs={12}
                        md={1}
                        lg={1}
                        item
                    >

                    </Grid>
                    <Grid
                        xs={12}
                        md={10}
                        lg={10}
                        item
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Box display={"flex"} justifyContent={'center'} alignItems={'center'} margin={"0 20px"}>
                            <Typography variant="h5" textAlign={"center"}>
                                {schedulePatient.isReschedule ? `Select date to reschedule the appointment of ${schedulePatient.patient.name}` :
                                    `Scheduling for ${schedulePatient.patient.lastname}, ${schedulePatient.patient.firstname}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={1} lg={1} item
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"center"}>
                        <Button
                            variant="contained"
                            color="error"
                            style={{ marginLeft: "5px" }}
                            onClick={cancelScheduling}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default ScheduleHeader;