import { createSlice } from "@reduxjs/toolkit";

const planList = [
    {
        id: 'MB-100',
        label: 'basic',
        name: 'Basic Plan',
        description: 'Margo Basic Plan',
        price_breakdown: {
            monthly: {
                base_price: 523.00,
                fees: 25.69,
                sale_price: 549.00
            },
            quarterly: {
                sale_price: 1647.00
            },
            yearly: {
                base_price: 5648.40,
                fees: 179.45,
                sale_price: 5828.00,
                savings: 448.00
            }
        },
        plan_details: {
            storage: 10,
            dentist: 1,
            staff: 1
        },
        button_label: 'Get Started'
    },
    {
        id: 'MS-200',
        label: 'plus',
        name: 'Plus Plan',
        description: 'Margo Plus Plan',
        price_breakdown: {
            monthly: {
                base_price: 620.00,
                fees: 28.60,
                sale_price: 649.00
            },
            quarterly: {
                sale_price: 1947.00
            },
            yearly: {
                base_price: 6696.00,
                fees: 210.88,
                sale_price: 6907.00,
                savings: 533
            }
        },
        plan_details: {
            storage: 10,
            dentist: 3,
            staff: 2
        },
        button_label: 'Get Started'
    },
    {
        id: 'MP-300',
        label: 'platinum',
        name: 'Platinum Plan',
        description: 'Margo Platinum Plan',
        price_breakdown: {
            monthly: {
                base_price: 911.00,
                fees: 37.33,
                sale_price: 949.00
            },
            quarterly: {
                sale_price: 2847.00
            },
            yearly: {
                base_price: 9838.80,
                fees: 305.16,
                sale_price: 10144.00,
                savings: 788.00
            }
        },
        plan_details: {
            storage: 10,
            dentist: 8,
            staff: 2
        },
        button_label: 'Get Started'
    }
];

const storagePlan = [
    {
        id: 'basic',
        label: 'Basic',
        description: 'Storage Plan x 10GB',
        price_breakdown: {
            monthly: {

            },
            yearly: {

            }
        }
    },
    {
        id: 'plus',
        label: 'Plus',
        description: 'Storage Plan x 30GB',
        price_breakdown: {
            monthly: {

            },
            yearly: {
                
            }
        }
    },
    {
        id: 'platinum',
        label: 'Platinum',
        description: 'Storage Plan x 50GB',
        price_breakdown: {
            monthly: {

            },
            yearly: {
                
            }
        }
    }
]

export const initialState = {
    summaryModalOpen: false,
    checkoutId: null,
    planDuration: 'monthly',
    planName: "",
    selectedPlan: {},
    planList: planList,
    storagePlans: storagePlan,
    isTransactionLogsLoading: false,
    isBillingInformationLoading: false,
    hasNextItems: false,
    hasPrevItems: false,
    subscriptionInfo: {}
}


const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        openSummaryModal: (state, action) => {
            state.summaryModalOpen = action.payload?.open;
            state.selectedPlan = action.payload?.plan;
        },
        closeSummaryModal: (state) => {
            state.summaryModalOpen = false;
        },
        reloadBilling: (state, action) => {
            state.isBillingInformationLoading = action.payload?.reload;
        },
        reloadTransactionLogs: (state, action) => {
            state.isTransactionLogsLoading = action.payload?.reload;
            state.hasNextItems = action.payload?.hastNextItems ?? false;
            state.hasPrevItems = action.payload?.hasPrevItems ?? false;
        },
        switchPlan: (state, action) => {
            state.planDuration = action.payload
        },
        createCustomerInfo: (state, action) => {
            state.customerInfo = action.payload?.data;
        },
        verifySubscription: (state, action) => {
            return {
                ...state,
                subscriptionInfo: action.payload
            }
        }
    }


});

export const billingStore = (state) => state.billing;

export const {
    openSummaryModal,
    reloadBilling,
    closeSummaryModal,
    reloadTransactionLogs,
    switchPlan,
    createCustomerInfo,
    verifySubscription
} = billingSlice.actions;


export default billingSlice.reducer;