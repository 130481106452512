import AppointmentsTable from "../../../../Tables/AppointmentsTable";
import InvoicesTable from "../../../../Tables/InvoicesTable";
import { MedicalInfoTabs } from "./Components/MedicalInfoTabs";
import PatientProcedures from "./Components/PatientProcedures";

export default function PatientMedicalInfo() {

    return (
        <>
            <MedicalInfoTabs contents={[
                <PatientProcedures />,
                <AppointmentsTable />,
                <InvoicesTable />
            ]} />
        </>
    );
}