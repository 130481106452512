import React from "react";

import { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  closeoverpaymentDialog,
  invoiceStore,
  paymentConfirmation,
  closeModal,
} from "../Redux/invoiceSlice";
const OverPayment = () => {
  const { isOpenOverPayment } = useSelector(invoiceStore);
  const dispatch = useDispatch();

  return (
    <>
      <Dialog
        open={isOpenOverPayment}
        onClose={() => {
          dispatch(closeoverpaymentDialog());
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            <WarningAmberRoundedIcon sx={{ fontSize: 100, color: "#ebaf55" }} />
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontStyle: "italic", color: "#e91e63" }}
          >
            Payment amount is greater than the balance.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              dispatch(paymentConfirmation());
              dispatch(closeoverpaymentDialog());
            }}
          >
            Confirm OverPayment
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              dispatch(closeoverpaymentDialog());
            }}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverPayment;
