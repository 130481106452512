import { Badge, Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { badgeStyle } from "../../../style";
import { useState } from "react";
import { useSelector } from "react-redux";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export function MedicalInfoTabs(props) {
    const { patientInfo } = useSelector((store) => store.patient);
    const [tabIndex, setTabIndex] = useState(0);


    const tabsChangeHandler = (_, index) => {
        setTabIndex(index);
    };

    return (
        <Box sx={{ width: "100%", mt: 2 }} component={Paper}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tabIndex}
                    onChange={tabsChangeHandler}
                >
                    <Tab
                        sx={{ px: 4 }}
                        label={
                            patientInfo?.upcomingProcedures !== 0 ? (
                                <Badge
                                    badgeContent={patientInfo?.upcomingProcedures}
                                    color="error"
                                    sx={badgeStyle}
                                >
                                    <Typography variant="subtitle1">Procedures</Typography>
                                </Badge>
                            ) : (
                                <Typography variant="subtitle1">Procedures</Typography>
                            )
                        }
                    />
                    <Tab
                        sx={{ px: 4 }}
                        label={
                            patientInfo?.upcomingAppointments !== 0 ? (
                                <Badge
                                    badgeContent={patientInfo?.upcomingAppointments}
                                    color="error"
                                    sx={badgeStyle}
                                >
                                    <Typography variant="subtitle1">Appointments</Typography>
                                </Badge>
                            ) : (
                                <Typography variant="subtitle1">Appointments</Typography>
                            )
                        }
                    />
                    <Tab
                        sx={{ px: 4 }}
                        label={
                            patientInfo?.upcomingInvoices !== 0 ? (
                                <Badge
                                    badgeContent={patientInfo?.upcomingInvoices}
                                    color="error"
                                    sx={badgeStyle}
                                >
                                    <Typography variant="subtitle1">Invoices</Typography>
                                </Badge>
                            ) : (
                                <Typography variant="subtitle1">Invoices</Typography>
                            )
                        }
                    />
                </Tabs>
            </Box>
            {props?.contents && props.contents.map((content, index) => {
                return (
                    <TabPanel key={index} value={tabIndex} index={index}>
                        {content}
                    </TabPanel>
                );
            })}
        </Box>
    );
}