import React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  addAppointmentProcedures, setAppointmentProcedures, setAppointmentPusherEvent
} from "../../../../Redux/appointmentSlice";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance as axios } from "../../../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import Services from "../../../../../Settings/Components/Services";
import { getAppCurrentActiveClinic } from "../../../../../../Utils/authStored";
import ProcedureTabs from "../Components/Procedures/ProcedureTabs"
import { AppointmentPusherEvents } from "../../../../Redux/AppointmentUtils";
import AppointmentSnackbar from "../../../AppointmentSnackbar";

export default function Procedures() {
  const { selectedPatient, pusherEvents: { addAppointmentProcEvent } } = useSelector((store) => store.appointment);
  const [isFetching, setIsFetching] = React.useState(true);
  const [visiblity, setVisibility] = React.useState(false);
  const appointmentSnackbarRef = React.useRef();
  const appointmentID = selectedPatient.id;
  const dispatch = useDispatch();

  React.useEffect(() => {
    getAppointmentProcedures();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (addAppointmentProcEvent) {
      handleAddAppointmentProcEvent();
    }
  }, [addAppointmentProcEvent]);

  const handleAddAppointmentProcEvent = async () => {
    try {
      appointmentSnackbarRef.current?.show({
        severity: 'info',
        message: 'Changes in procedure/s is detected! Refreshing data...'
      });

      const procResponse = await axios.get(`appointment_procedures?appointment_id=${appointmentID}`)
      const procedures = procResponse.data;
      if (procedures.length > 0) {
        const procAttachmentRes = await axios.get(`attachments`, {
          params: {
            entity_id: procedures[0].patient_procedure_id,
            entity: 'patient_procedures'
          }
        });

        procedures[0].attachments = procAttachmentRes.data;
        dispatch(setAppointmentProcedures(procedures));
      }

      appointmentSnackbarRef.current?.show({
        severity: 'success',
        message: 'Appointment procedure/s is succesfully updated!',
        duration: 1500
      });
    } catch (_) {
      appointmentSnackbarRef.current?.show({
        severity: 'error',
        message: 'Failed to refresh procedures!',
        duration: 1500
      });
    }

    if (addAppointmentProcEvent) dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.addAppointmentProcEvent, isTrigger: false }));
  }

  const getAppointmentProcedures = async () => {
    setIsFetching(true);
    try {
      const procResponse = await axios.get(`appointment_procedures?appointment_id=${appointmentID}`)
      const procedures = procResponse.data;
      if (procedures.length > 0) {
        const procAttachmentRes = await axios.get(`attachments`, {
          params: {
            entity_id: procedures[0].patient_procedure_id,
            entity: 'patient_procedures'
          }
        });
        procedures[0].attachments = procAttachmentRes.data;
        dispatch(setAppointmentProcedures(procedures));
      }
    } catch (_) {
      Swal.fire({
        icon: 'error',
        title: 'Failed to fetch appointment procedures!',
        text: 'Try again.'
      });
    }
    setIsFetching(false);
  }

  const handleOnAddProcedure = async (selectedProc) => {
    const clinic = getAppCurrentActiveClinic();

    const procedures = selectedProc.map((procedure) => {
      return {
        patient_id: selectedPatient.patientID,
        procedure_id: procedure.id,
        clinic_id: clinic.id,
        status: "scheduled"
      };
    });

    const payload = {
      data: procedures,
      set_to_appointment: appointmentID
    }

    let errorMessage = {
      title: 'Failed to add procedure!',
      text: ''
    };

    try {
      const response = await axios.post('patient_procedures/batch_store', payload);
      errorMessage = {
        title: 'Failed to fetch added procedure!',
        text: 'Refresh your browser to fetch the added procedure'
      }
      const createdProc = response.data;
      const fetchedProc = [];
      for (let i = 0; i < createdProc.length; i++) {
        const proc = createdProc[i];
        const procResponse = await axios.get(`appointment_procedures?patient_procedure_id=${proc.id}`)
        if (procResponse.status === 200 && procResponse.data?.length > 0) {
          fetchedProc.push(procResponse.data[0]);
        }
      }
      Swal.fire({
        icon: 'success',
        title: 'Successfully added procedures'
      }).then(() => {
        dispatch(addAppointmentProcedures(fetchedProc));
      });
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: errorMessage.title,
        text: errorMessage.text
      });
    }
  }


  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography gutterBottom variant="h5" component="div">
                Procedures
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              {!selectedPatient?.isDone && <Button
                variant="contained"
                startIcon={<AddIcon />}
                disabled={isFetching}
                onClick={() => setVisibility(true)}
              >
                Add Procedures
              </Button>}
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              {!isFetching ? <ProcedureTabs /> : <Box display={"flex"} justifyContent={"center"} marginTop={"30px"}>
                <CircularProgress />
              </Box>}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Services
        openModal={visiblity}
        closeModal={() => setVisibility(false)}
        isAddProcedure={true}
        onAddProcedure={handleOnAddProcedure}
      />
      <AppointmentSnackbar ref={appointmentSnackbarRef} />
    </>
  );
}