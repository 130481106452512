import { useDispatch, useSelector } from "react-redux";
import {
  openQouteModal,
  setIsFetching,
  updateProcedure,
} from "../../../../../../Pages/Patients/Redux/patientSlice";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import HistoryTable from "../../../../../Tables/HistoryTable";
import ProcedureTable from "../../../../../Tables/ProcedureTable";
import GetQuoteModal from "../../../../GetQuoteModal";
import { addProceduresQuote } from "../../../../../../Pages/Quote/Redux/QuoteSlice";
import Services from "../../../../../../Pages/Settings/Components/Services";
import { axiosInstance as axios } from "../../../../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../../../../Utils/authStored";
import Swal from "sweetalert2";
import { userCan } from "../../../../../../Utils/utils";
export default function PatientProcedures() {
  const { isFetching, procedures, patientInfo } = useSelector(
    (store) => store.patient
  );
  const dispatch = useDispatch();
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [serviceVisible, setServiceVisible] = useState(false);

  const handleOnAddProcedure = async (selectedProc) => {
    dispatch(setIsFetching(true));
    const clinic = getAppCurrentActiveClinic();

    const procedures = selectedProc.map((procedure) => {
      return {
        patient_id: patientInfo.id,
        procedure_id: procedure.id,
        clinic_id: clinic.id,
        status: "pending",
      };
    });

    const payload = {
      data: procedures,
    };

    let errorMessage = {
      title: "Failed to add procedure!",
      text: "",
    };

    try {
      const response = await axios.post(
        "patient_procedures/batch_store",
        payload
      );
      if (response.status === 201) {
        errorMessage = {
          title: "Failed to fetch added procedure!",
          text: "Refresh your browser to fetch the added procedure",
        };

        let fetchedProc = [];
        const procResponse = await axios.get(
          `patient_procedures?clinic_id=${clinic.id}&patient_id=${patientInfo.id}`
        );
        if (procResponse.status === 200 && procResponse.data?.length > 0) {
          fetchedProc = procResponse.data;
        }

        dispatch(setIsFetching(false));
        dispatch(
          updateProcedure(fetchedProc.length > 0 ? fetchedProc : procedures)
        );

        Swal.fire({
          icon: "success",
          title: "Successfully added procedures",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: errorMessage.title,
        text: errorMessage.text,
      });
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Button
          startIcon={<AddIcon />}
          size="small"
          variant="contained"
          disableElevation
          onClick={() => setServiceVisible(true)}
          disabled={isFetching || !userCan("createAppointments")}
          sx={{ fontWeight: "bold" }}
        >
          Add Procedures
        </Button>
        <ButtonGroup size="small">
          <Button
            variant={isUpcoming ? "contained" : "outlined"}
            disableElevation
            onClick={() => setIsUpcoming(true)}
            disabled={isFetching}
            sx={{ fontWeight: "bold" }}
          >
            Upcoming
          </Button>
          <Button
            disableElevation
            variant={!isUpcoming ? "contained" : "outlined"}
            onClick={() => setIsUpcoming(false)}
            disabled={isFetching}
            sx={{ fontWeight: "bold" }}
          >
            History
          </Button>
        </ButtonGroup>

        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            const upcomingProcs = procedures
              .filter((f) => f.status !== "done")
              .map((value) => {
                return {
                  ...value,
                  checked: true,
                };
              });
            dispatch(openQouteModal());
            setTimeout(() => {
              dispatch(addProceduresQuote(upcomingProcs));
            }, 200);
          }}
          disabled={isFetching || !userCan("createQuotations")}
          sx={{ fontWeight: "bold" }}
        >
          Get Quote
        </Button>
      </Stack>
      {!isFetching ? (
        !isUpcoming ? (
          <HistoryTable procedures={procedures} />
        ) : (
          <ProcedureTable procedures={procedures} />
        )
      ) : (
        <Box display={"flex"} justifyContent={"center"} marginTop={"30px"}>
          <CircularProgress />
        </Box>
      )}
      {/* <ServicesModal /> */}
      <GetQuoteModal />
      <Services
        openModal={serviceVisible}
        closeModal={() => setServiceVisible(false)}
        isAddProcedure={true}
        onAddProcedure={handleOnAddProcedure}
      />
    </>
  );
}
