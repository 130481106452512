import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  Tooltip,
  CircularProgress,
  Avatar,
  Tab,
  AvatarGroup,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import SettingForm from "./Components/ClinicInfo/SettingsForm";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MemberUsersTable from "../../Components/Tables/MemberUsersTable";
import OperatingHours from "./Components/Modals/OperatingHours";
import Services from "./Components/Services";
import AddUser from "./Components/Modals/AddUser";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { userCan } from "../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshClinic,
  selectionTab,
  setIsDownloadSOAModalOpen,
} from "./Redux/settingsSlice";
import {
  reloadBilling,
  reloadTransactionLogs,
} from "./Components/Billing/Redux/billingSlice";
import TransactionLogs from "./Components/Billing/TransactionLogs";
import DownloadSOAPdfModal from "./Components/Modals/DownloadSOAPdf";
import Billing from "./Components/Billing";
import AvatarImage from "../../Components/AvatarImage";
import useBilling from "../../hooks/useBilling";

export default function Settings() {
  const dispatch = useDispatch();

  const { isClinicUpdated, clinicSettingInfo, tabIndex } = useSelector(
    (store) => store.settings
  );

  const { subscriptionInfo } = useBilling();

  const [openOperatingHoursModal, setOpenOperatingHoursModal] = useState(false);

  const [openServiceModal, setOpenServicesModal] = useState(false);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const clinicIcon = useRef(null);

  const onImageLoaded = async (payload) => {
    clinicIcon.current = payload;
  };

  useEffect(() => {
    dispatch(refreshClinic(true));
    if (tabIndex == 1) {
      dispatch(reloadBilling({ reload: true }));
    }
  }, []);

  useEffect(() => {
    if (isClinicUpdated) {
      setTimeout(() => {
        dispatch(refreshClinic(false));
      }, 1000);
    }
  }, [isClinicUpdated]);

  const handleTabSelection = (event, tabSelected) => {
    if (tabSelected == 0) {
      dispatch(refreshClinic(true));
    } else if (tabSelected == 1) {
      dispatch(reloadBilling({ reload: true }));
    } else if (tabSelected == 2) {
      dispatch(reloadTransactionLogs({ reload: true }));
    }
    dispatch(selectionTab({ tab: tabSelected }));
  };

  return (
    <>
      <TabContext value={tabIndex}>
        <TabList component={Paper} onChange={handleTabSelection}>

          <Tab
            label={<Typography variant="subtitle1">Clinic Settings</Typography>}
            value={"0"}
          />

          <Tab
            label={
              <Typography variant="subtitle1">Billing</Typography>
            }
            value={"1"}
            disabled={
              process.env.REACT_APP_IS_BILLING_IMPLEMENTED !== "true"
            }
          />
        </TabList>
      </TabContext>

      <TabContext value={tabIndex}>
        <TabPanel value={"0"}>
          {isClinicUpdated ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ px: 20, py: 20 }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Paper
                elevate={1}
                sx={{
                  py: 3,
                  px: 3,
                }}
              >
                <Stack direction={`column`} spacing={2}>
                  <Stack direction={`column`} spacing={1}>
                    <Stack direction={`row`} justifyContent={`space-between`}>
                      <Typography variant="h5">Clinic Settings</Typography>
                      <Tooltip title="Download Clinic SOA">
                        <IconButton
                          color={`primary`}
                          onClick={() =>
                            dispatch(setIsDownloadSOAModalOpen(true))
                          }
                        >
                          <DownloadIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>

                    <Stack
                      direction={{ sm: "column", md: `row` }}
                      spacing={{ xs: 2, md: 2 }}
                    >
                      <Paper
                        elevation={4}
                        sx={{
                          height: "20%",
                          maxHeight: "100%",
                          // width: !userCan('editClinic') ? '100%' : '20%',
                          py: 10,
                          px: 10,
                        }}
                      >
                        {userCan("editClinic") ? (
                          <>
                            <AvatarImage
                              alt={clinicSettingInfo?.name}
                              fileData={clinicSettingInfo?.icon}
                              defaultData={clinicSettingInfo?.icon}
                              sx={{ width: 130, height: 130 }}
                              onImageLoaded={onImageLoaded}
                            />
                          </>
                        ) : (
                          <Box sx={imagebgcontainer}>
                            <Box sx={imagecontainer}>
                              <Avatar
                                alt={clinicSettingInfo?.name}
                                src={clinicSettingInfo?.icon}
                                sx={{ width: 150, height: 150 }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Paper>

                      {userCan("editClinic") && (
                        <Paper
                          elevation={4}
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            py: 5,
                            px: 5,
                          }}
                        >
                          <Stack direction={`column`} spacing={2}>
                            <Stack
                              direction={{ xs: `column`, md: `row` }}
                              spacing={2}
                              justifyContent={`flex-end`}
                            >
                              <Button
                                variant={`contained`}
                                startIcon={<AccessTimeIcon />}
                                onClick={() => setOpenOperatingHoursModal(true)}
                                disabled={subscriptionInfo?.is_expired}
                              >
                                Operating Hours
                              </Button>
                              <Button
                                variant={`contained`}
                                startIcon={<MedicalInformationIcon />}
                                onClick={() => setOpenServicesModal(true)}
                                disabled={subscriptionInfo?.is_expired}
                              >
                                Services
                              </Button>
                            </Stack>

                            <SettingForm
                              clinic={clinicSettingInfo}
                              clinicIcon={clinicIcon}
                            />
                          </Stack>
                        </Paper>
                      )}
                    </Stack>
                  </Stack>

                  <Paper
                    elevation={4}
                    sx={{
                      py: 5,
                      px: 5,
                    }}
                  >
                    <Stack direction={`column`} spacing={2}>
                      <Stack
                        direction={{ sm: `column`, md: `row` }}
                        justifyContent={`flex-start`}
                        spacing={4}
                      >
                        <Typography variant="h6">Users</Typography>
                        {userCan("addUsers") && (
                          <Button
                            variant="contained"
                            startIcon={<PersonAddIcon />}
                            onClick={() => setOpenAddUserModal(true)}
                            disabled={subscriptionInfo?.is_expired}
                          >
                            Add new user
                          </Button>
                        )}
                      </Stack>

                      <Divider />

                      {isClinicUpdated ? (
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{ px: 20, py: 20 }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <MemberUsersTable />
                      )}
                    </Stack>
                  </Paper>
                </Stack>
              </Paper>

              {openOperatingHoursModal && (
                <OperatingHours
                  openModal={openOperatingHoursModal}
                  closeModal={() => setOpenOperatingHoursModal(false)}
                />
              )}

              {openServiceModal && (
                <Services
                  openModal={openServiceModal}
                  closeModal={() => setOpenServicesModal(false)}
                />
              )}

              {openAddUserModal && (
                <AddUser
                  open={openAddUserModal}
                  close={() => setOpenAddUserModal(false)}
                />
              )}

              <DownloadSOAPdfModal />
            </Box>
          )}
        </TabPanel>
      </TabContext>

      <TabContext value={tabIndex}>
        <TabPanel value={"1"}>
          <Billing />
        </TabPanel>
      </TabContext>
    </>
  );
}

const imagecontainer = {
  position: "relative",

  borderRadius: "100%",
  width: 160,
  height: 160,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "2px dotted #bdbdbd",
};

const imagebgcontainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
