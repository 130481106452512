import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  Stack,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  invoiceStore,
  closeModal,
  openoverpaymentDialog,
  refreshInvoiceList,
} from "../../Pages/Invoices/Redux/invoiceSlice";
import { patientStore } from "../../Pages/Patients/Redux/patientSlice";
import PatientInvoice from "../Tables/PatientInvoiceTable";
import {
  numberWithCommas,
  dateToShortString,
  userCan,
} from "../../Utils/utils";
import PaymentHistory from "../Tables/PaymentHistory";
import OverPayment from "../../Pages/Invoices/dialog/over-payment";
import Swal from "sweetalert2";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import PatientInformationModal from "./PatienInformationModal";
const InvoiceStatusModal = () => {
  const [isOverpay, setIsOverpay] = useState(false);
  const { isOpenPatientInfoModal, patientInfo } = useSelector(patientStore);
  const [isCredit, setIsCredit] = useState(false);

  const {
    isOpen,
    invoiceDetails,
    invoiceItemDetails,
    invoicePayments,
    isPaymentConfirm,
    patientDetails,
    totalprevBal,
  } = useSelector(invoiceStore);
  const invoiceBalance = numberWithCommas(invoiceDetails?.balance);
  const [paymentVal, setPayment] = useState(0);
  const [useCredit, setUseCredit] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const invoice_id = invoiceItemDetails.map((rows) => rows.invoice_id);

  function stringTonumber(num) {
    const res = parseFloat(num.toString().replace(",", ""));
    return res;
  }

  const handlePayment = () => {
    if (paymentVal > invoiceDetails?.balance && paymentVal !== 0) {
      Swal.fire({
        icon: "question",
        title:
          "Payment amount is greater than the balance. Do you wish to continue?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((value) => {
        if (value.isConfirmed) {
          paymentMethod();
        }
      });
    } else {
      paymentMethod();
    }
    dispatch(refreshInvoiceList(true));
  };

  const updatePatientInfoModal = async () => {
    if (isOpenPatientInfoModal) {
      PatientInformationModal.initService({
        patientID: invoiceDetails.patient_id,
        dispatch: dispatch,
      });
    }
  };

  const paymentMethod = async () => {
    const paymentAmount = paymentVal ? stringTonumber(paymentVal) : 0;
    const creditAmount = useCredit ? stringTonumber(useCredit) : 0;

    const payload = {
      payment_amount: paymentAmount,
      credit_amount: isCredit ? creditAmount : 0,
    };

    if (patientDetails?.credit) {
      if (patientDetails?.credit < creditAmount) {
        Swal.fire({
          icon: "error",
          title: "Insufficient credit balance!",
        });
        return;
      }
    }

    if (isCredit) {
      if (creditAmount === 0) {
        Swal.fire({
          icon: "error",
          title: "Invalid Credit Amount!",
        });
        return;
      }
    }

    if (paymentAmount === 0 && !isCredit) {
      Swal.fire({
        icon: "error",
        title: "Invalid Payment Amount!",
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Processing Payment",
      text: "Please wait...",
      timerProgressBar: true,
      backdrop: true,
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: async () => {
        Swal.showLoading();
        try {
          const response = await axios.post(
            `invoices/payment/` + invoiceDetails?.id,
            payload
          );
          if (response.status === 200) {
            await updatePatientInfoModal();
            Swal.fire({
              icon: "success",
              title: "Payment successful!",
            }).then(() => {
              setUseCredit(0);
              dispatch(closeModal());
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Unable to process patient's payment",
            text: "Try again.",
          });
        }
      },
    });
    dispatch(refreshInvoiceList(true));
  };

  const voidInvoice = () => {
    Swal.fire({
      icon: "question",
      title: "Do you wish to void invoice? You cannot undo this action after.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          try {
            const payload = {
              status: "void",
              new_invoice: "",
            };

            axios.delete(`invoices/` + invoice_id, payload).then((response) => {
              if (response.status === 204) {
                updatePatientInfoModal().then(() => {
                  Swal.fire({
                    icon: "success",
                    title: "Successfully deleted!",
                  }).then(() => {
                    dispatch(closeModal());
                    resolve();
                  });
                });
              }
            });
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Failed to void invoice!",
              text: "Try again.",
            }).then(() => {
              resolve();
            });
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
    dispatch(refreshInvoiceList(true));
  };

  useEffect(() => {
    isOverpay && dispatch(openoverpaymentDialog());
    setIsOverpay(false);
    // eslint-disable-next-line
  }, [isOverpay]);

  useEffect(() => {
    isPaymentConfirm && paymentMethod();
    // eslint-disable-next-line
  }, [isPaymentConfirm]);

  // useEffect(() => {
  //   isVoidInvoiceConfirm && voidInvoice();
  // }, [isVoidInvoiceConfirm]);

  useEffect(() => {
    setPayment(invoiceBalance);
  }, [invoiceBalance]);

  useEffect(() => {
    setIsCredit(false);
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (isCredit) {
      const sum = parseFloat(useCredit) + parseFloat(paymentVal);
      setTotal(sum);
    }
    // eslint-disable-next-line
  }, [useCredit, paymentVal]);

  useEffect(() => {
    isCredit && setUseCredit(invoiceBalance);
    // eslint-disable-next-line
  }, [isCredit]);

  return (
    <Dialog
      open={isOpen}
      disableScrollLock={true}
      onClose={() => {
        dispatch(closeModal());
      }}
      maxWidth="xl"
      PaperProps={{
        sx: {
          position: "fixed",
          top: 15,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#fafafa",
        }}
      >
        <Stack direction={`column`} spacing={2}>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Typography variant="h5">Invoice</Typography>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(closeModal());
              }}
            />
          </Stack>

          <Stack
            direction={{
              xs: `column`,
              sm: `column`,
              md: `row`,
              lg: `row`,
              xl: `row`,
            }}
            spacing={2}
          >
            {/* Billing */}
            <Box
              component={Paper}
              sx={{
                px: 3,
                py: 3,
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} spacing={2}>
                  <Avatar
                    src={patientDetails.avatar}
                    sx={{ width: 90, height: 90 }}
                  />
                  <Stack direction={"column"}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "700" }}
                    ></Typography>
                    <Typography variant="body2">
                      {patientDetails?.firstname} {patientDetails?.lastname}
                    </Typography>
                    <Typography variant="body2">
                      {patientInfo.address}
                    </Typography>
                    <Typography variant="body2">
                      {patientDetails?.phone}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction={"row"} alignItems={"left"} spacing={20}>
                  <Stack direction={"column"}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                      Invoice Number:
                    </Typography>
                    <Typography variant="subtitle2">
                      {invoiceDetails?.invoice_number}
                    </Typography>
                  </Stack>

                  <Stack direction={"column"}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                      Date:
                    </Typography>
                    <Typography variant="subtitle2">
                      {dateToShortString(invoiceDetails?.created_at)}
                    </Typography>
                  </Stack>
                </Stack>
                <Box sx={{ mt: "20px !important" }}>
                  {invoicePayments.length === 0 && totalprevBal > 0 && (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "#ed4763",
                        color: "white",
                        borderRadius: 1,
                        py: 1,
                        px: 1,
                        mb: "10px",
                      }}
                    >
                      <Typography sx={{ color: "white" }} textAlign="center">
                        Previous Balance: ₱{numberWithCommas(totalprevBal)}
                      </Typography>
                    </Stack>
                  )}
                  {patientDetails?.credit > 0 && (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "#49b545",
                        color: "white",
                        borderRadius: 1,
                        py: 1,
                        px: 1,
                        mt: "10px !important",
                      }}
                    >
                      <Typography sx={{ color: "white" }} textAlign="center">
                        Credit Balance: ₱{" "}
                        {numberWithCommas(patientDetails?.credit)}
                      </Typography>
                    </Stack>
                  )}
                  {invoicePayments.length > 0 &&
                    invoicePayments.at(0)?.balance !== 0 && (
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          backgroundColor: "#ed4763",
                          color: "white",
                          borderRadius: 1,
                          py: 1,
                          px: 1,
                          mt: "10px",
                        }}
                      >
                        <Typography sx={{ color: "white" }} textAlign="center">
                          Outstanding Balance: ₱
                          {numberWithCommas(
                            invoiceDetails?.balance + totalprevBal
                          )}
                        </Typography>
                      </Stack>
                    )}
                </Box>
              </Stack>
            </Box>
            {/* <VoidInvoice /> */}
            <OverPayment />

            {/* Invoice Summary */}
            <Box
              component={Paper}
              sx={{
                px: 3,
                py: 3,
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Stack direction={"column"} spacing={4}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack direction={"column"} spacing={1}>
                    <Typography variant="subtitle1">Subtotal: </Typography>
                    <Typography variant="subtitle1">Discount: </Typography>
                    <Typography variant="subtitle1">Total: </Typography>
                    <Typography variant="subtitle1">Paid Amount: </Typography>
                    <Typography variant="subtitle1">Balance: </Typography>
                    {invoiceDetails?.overpayment > 0 && (
                      <Typography variant="subtitle1">Overpayment: </Typography>
                    )}
                  </Stack>

                  <Stack direction={"column"} spacing={1}>
                    <Typography variant="subtitle1" textAlign={"right"}>
                      Php {numberWithCommas(invoiceDetails?.total_price)}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={"right"}>
                      {invoiceDetails?.discount}
                      {invoiceDetails?.discount_type === "pct" ? "%" : ""}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={"right"}>
                      Php{" "}
                      {/* {numberWithCommas(
                        invoiceDetails?.discount
                          ? invoiceDetails?.discounted_price *
                              ((100 - invoiceDetails?.discount) / 100)
                          : invoiceDetails?.discounted_price
                      )} */}
                      {numberWithCommas(invoiceDetails?.discounted_price)}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={"right"}>
                      - Php{" "}
                      {numberWithCommas(
                        invoiceDetails?.discounted_price -
                          invoiceDetails?.balance +
                          (invoiceDetails?.overpayment
                            ? invoiceDetails?.overpayment
                            : 0.0)
                      )}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={"right"}>
                      Php {numberWithCommas(invoiceDetails?.balance)}
                    </Typography>
                    {invoiceDetails?.overpayment > 0 && (
                      <Typography variant="subtitle1" textAlign={"right"}>
                        + Php {numberWithCommas(invoiceDetails?.overpayment)}
                      </Typography>
                    )}
                  </Stack>
                </Stack>

                {invoiceDetails?.status !== "paid" && (
                  <TextField
                    variant={`standard`}
                    type="text"
                    inputProps={{
                      style: { textAlign: "right", paddingRight: "10px" },
                    }}
                    onChange={(e) => setPayment(e.target.value)}
                    value={paymentVal}
                    color="error"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          Payment
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {isCredit && (
                  <TextField
                    variant={`standard`}
                    type="text"
                    inputProps={{
                      style: { textAlign: "right", paddingRight: "10px" },
                    }}
                    onChange={(e) => setUseCredit(e.target.value)}
                    value={useCredit}
                    color="error"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Credit</InputAdornment>
                      ),
                    }}
                  />
                )}
                {patientDetails?.credit > 0 &&
                  invoiceDetails?.status !== "paid" && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            onChange={() => {
                              setIsCredit(!isCredit);
                              setPayment(isCredit ? invoiceBalance : 0.0);
                            }}
                          />
                        }
                        label={
                          <Typography sx={{ fontStyle: "italic" }}>
                            Use Credit?
                          </Typography>
                        }
                      />
                    </FormGroup>
                  )}

                {invoiceDetails?.status === "paid" ? (
                  <Stack direction={"row"} justifyContent={"right"}>
                    <Typography
                      variant="h6"
                      sx={{ display: "flex-end", color: "green" }}
                    >
                      Fully Paid
                    </Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction={"row"}
                    justifyContent={"right"}
                    spacing={1}
                    sx={{ display: userCan("createInvoice") ? "flex" : "none" }}
                  >
                    {isCredit ? (
                      total < invoiceDetails?.balance ? (
                        <Button
                          variant="outlined"
                          color="warning"
                          sx={{
                            textTransform: "inherit",
                          }}
                          onClick={() => handlePayment()}
                        >
                          Partial Payment
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            textTransform: "inherit",
                          }}
                          onClick={() => {
                            handlePayment();
                          }}
                        >
                          Mark as Fully Paid
                        </Button>
                      )
                    ) : paymentVal < invoiceDetails?.balance &&
                      paymentVal !== 0 ? (
                      <Button
                        variant="outlined"
                        color="warning"
                        sx={{
                          textTransform: "inherit",
                        }}
                        onClick={() => handlePayment()}
                      >
                        Partial Payment
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          textTransform: "inherit",
                        }}
                        onClick={() => {
                          handlePayment();
                        }}
                      >
                        Mark as Fully Paid
                      </Button>
                    )}

                    {userCan("voidInvoice") && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          // dispatch(openvoidinvoiceModal());
                          voidInvoice();
                        }}
                      >
                        Void invoice
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: "#fafafa",
        }}
      >
        <Stack direction={"column"} spacing={3}>
          {invoicePayments && invoicePayments.length > 0 && (
            <Stack direction={`column`} spacing={1}>
              <Typography
                variant={`subtitle1`}
                sx={{ fontWeight: "700" }}
                gutterBottom
              >
                Payment History
              </Typography>
              <PaymentHistory invoicePaymentHistory={invoicePayments} />
            </Stack>
          )}

          <PatientInvoice
            invoiceItems={invoiceItemDetails}
            invoiceData={invoiceDetails}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceStatusModal;
