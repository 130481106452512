
import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
   refreshPatientTableV2 
} from '../Pages/Patients/Redux/patientSlice';

import {
   refreshClinicUsers
} from '../Pages/Settings/Redux/settingsSlice';

import { 
   clearAuth,
   getAppCurrentActiveClinic,
   getCurrentLoggedInUser,
   setAppCurrentActiveClinic
} from './authStored';
import { setAppointmentPusherEvent } from '../Pages/Appointments/Redux/appointmentSlice';
import { AppointmentPusherEvents } from '../Pages/Appointments/Redux/AppointmentUtils';
import { refreshInvoiceList } from '../Pages/Invoices/Redux/invoiceSlice';
import { reloadBilling } from '../Pages/Settings/Components/Billing/Redux/billingSlice';

export default function PusherJS() {

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const user = getCurrentLoggedInUser();

   const clinic = getAppCurrentActiveClinic();

   const { pathname } = useLocation();

   useEffect(() => {

      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
         cluster: 'ap1'
      })

      const event = pusher.subscribe(`clinic-channel-${clinic?.id}`);

      event.bind('add-patient-event', function () {
         dispatch(refreshPatientTableV2(true));
      });

      event.bind('delete-patient-event', function () {
         dispatch(refreshPatientTableV2(true));
      });

      event.bind('edit-patient-event', data => {
         dispatch(refreshPatientTableV2(true));
         console.log('feed-back', data)
      });

      event.bind('add-staff-event', function () {
         dispatch(refreshClinicUsers(true))
      });

      event.bind('add-dentist-event', function () {
         dispatch(refreshClinicUsers(true))
      });

      event.bind('delete-clinic-user-event', data => {
         if (data?.data?.user_id === user?.user_id) {
            clearAuth();
            navigate('/auth/login')
         } else {
            dispatch(refreshClinicUsers(true))
         }
      });

      event.bind('edit-clinic-user-permission-event', function (data) {
         if (data?.data?.user_id === user?.user_id) { 
            clearAuth();
            navigate('/auth/login')
         } else {
            window.location.reload()
         }
      });

      event.bind('add-appointment-event', function () {
         //Add appointment
         if (pathname === "/dashboard/appointments") {
            dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.addAppointmentEvent, isTrigger: true }));
         }
      });

      event.bind('add-patient-and-appointment-event', () => {
         if (pathname === "/dashboard/appointments") {
            
         }
      });

      event.bind('delete-appointment-event', function () {
         if (pathname === "/dashboard/appointments") {

         }
      });

      event.bind('edit-appointment-event', function () {
         //No show and Cancel Appointment
         if (pathname === "/dashboard/appointments") {
            dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editAppointmentEvent, isTrigger: true }));
         }
      });

      event.bind('edit-appointment-procedure-event', () => {
         //Reschedule Appointment
         if (pathname === "/dashboard/appointments") {
            dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editAppointmentProcEvent, isTrigger: true }));
         }
      });

      event.bind('add-batch-appointment-procedure-event', () => {
         //Adding Appointment Procedure 
         if (pathname === "/dashboard/appointments") {
            dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.addAppointmentProcEvent, isTrigger: true }));
         }
      });

      event.bind('add-invoice-event', () => {
         dispatch(refreshInvoiceList(true))
      });

      event.bind('edit-invoice-event', () => {
         dispatch(refreshInvoiceList(true))
      });

      event.bind('delete-invoice-event', () => {
         dispatch(refreshInvoiceList(true))
      });

      event.bind('add-clinic-procedure-event', () => {

      });

      event.bind('edit-clinic-procedure-event', () => {

      });

      event.bind('delete-clinic-procedure-event', () => {

      });

      event.bind('add-procedure-event', () => {

      });

      event.bind('edit-procedure-event', () => {

      });

      event.bind('delete-procedure-event', () => {
      });

      event.bind('edit-dentist-event', () => {
         
      });

      event.bind('edit-clinic-event', (data) => {
         const clinicData = { ...clinic }

         clinicData.icon = data?.clinic_icon;
         clinicData.address = data?.clinic?.address;
         clinicData.branch = data?.clinic?.branch;
         clinicData.name = data?.clinic?.name;
         clinicData.parallelAppts = data?.clinic?.parallelAppts;
         clinicData.operating_hours = data?.clinic?.operating_hours;

         setAppCurrentActiveClinic({ ...clinicData })
         dispatch(refreshClinicUsers(true))
         
         if (pathname === "/dashboard/appointments") {
            dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editClinicEvent, isTrigger: true }));
         }
      });

      event.bind('subscription-expired-event', () => {

      })

      event.bind('subscription-about-to-expire-event', () => {

      });

      event.bind('paymaya-payment-success-event', (result) => {

         const {
            clinic_id,
            subscription
         } = result;

         const clinic_data = { ...clinic }

         clinic_data.plan_interval = subscription?.plan_interval;
         clinic_data.plan_id = subscription?.plan_id;
         clinic_data.expiration_date = subscription?.expiration_date;
         clinic_data.subscription_status = subscription?.status;
         clinic_data.is_expired = subscription?.is_expired;

         setAppCurrentActiveClinic({ ...clinic_data })

         window.location.reload();
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   })

   return (
      <></>
   )
}