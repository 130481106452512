import React, { useState } from "react";
import {
  getCurrentLoggedInUser,
  getAppCurrentActiveClinic,
  clearAuth,
} from "../../../Utils/authStored";

import {
  Box,
  Divider,
  List,
  Stack,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import ProfileInformation from "./modal/profile-information";
import StaffProfileInformation from "./modal/staff-profile-information";
import file_invoice_dollar from "../../../assets/image/file-invoice-dollar.svg";
import gear from "../../../assets/image/gear.svg";
import graph from "../../../assets/image/graph.svg";
import headset_solid from "../../../assets/image/headset-solid.svg";
import logout from "../../../assets/image/logout.svg";
import person from "../../../assets/image/person.svg";
import peso_line from "../../../assets/image/peso-line.svg";
import dashboard from "../../../assets/image/dashboard.svg";
import { userCan } from "../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import useIsTablet from "../../../Utils/UseIsTablet";
import { setSidebarVisibility, sidebarStore } from "./Redux/sideNavigationSlice";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuItem from "./Components/MenuItem";
import UserProfile from "./Components/UserProfile";
import useBilling from "../../../hooks/useBilling";

export const getUserType = () => {
  const clinic = getAppCurrentActiveClinic();

  const currentUser = getCurrentLoggedInUser();

  if (clinic?.users && currentUser) {
    const user = clinic.users.filter((u) => u.id === currentUser.user_id);

    if (user[0]) {
      if (user[0].type === "doctor" && user[0].isAdmin === 1) {
        return "doctor";
      } else if (user[0].type === "doctor" && user[0].isAdmin === 0) {
        return "inhousedoc";
      } else {
        return "staff";
      }
    }
  }

  return null;
};

export default function SideNavigationBar() {
  const user = getCurrentLoggedInUser();
  const dispatch = useDispatch();
  const { sidebarOpened } = useSelector(sidebarStore);
  const { isTablet } = useIsTablet();
  const [showProfileInformation, setShowProfileInformation] = useState(false);
  const [sidebarClass, setSidebarClass] = useState('sidebar-container');
  const [showStaffProfileInformation, setShowStaffProfileInformation] = useState(false);

  const clickAwayFirstClick = React.useRef(true);
  const firstRender = React.useRef(true);

  const { subscriptionInfo } = useBilling();

  React.useEffect(() => {
    if (!firstRender.current) updateSidebarClassName();
    else {
      if ((isTablet && !sidebarOpened) || (!isTablet && sidebarOpened)) {
        firstRender.current = false
      }
    };
    if (!sidebarOpened) clickAwayFirstClick.current = true;
    // eslint-disable-next-line
  }, [sidebarOpened])


  const handleShowProfile = () => {
    if (user?.type === "staff") {
      setShowStaffProfileInformation(true);
    } else if (user?.type === "doctor") {
      setShowProfileInformation(true);
    }
  };

  const handleLogout = async () => {
    await clearAuth();
    handleMenuOnClick();
  };

  const updateSidebarClassName = () => {
    let className = "sidebar-container ";
    // className += sidebarOpened ? `${isTablet ? 'show' : 'show-desktop'}` : `${isTablet ? 'close' : 'close-desktop'}`;
    className += sidebarOpened ? 'show' : 'close';
    setSidebarClass(className);
  }

  const handleOnClickAway = (e) => {
    if (isTablet && sidebarOpened && e.type === 'click') {
      if (!clickAwayFirstClick.current) {
        dispatch(setSidebarVisibility());
      }
      else clickAwayFirstClick.current = false;
    }
  }

  const handleMenuOnClick = () => {
    if (isTablet) {
      dispatch(setSidebarVisibility());
    }
  }


  return (
    <>
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <Box className={sidebarClass}>
          {/* {!isTablet && <Box position={'fixed'} top={13} zIndex={1001}
            sx={{
              backgroundColor: 'white',
              borderRadius: '100px',
              boxShadow: '0 0 3px rgba(0, 0, 0, .5)',
              transform: sidebarOpened ? 'translateX(180px)' : 'translateX(48px)',
              transition: 'transform 372ms ease-in-out'
            }}>
            <IconButton onClick={() => dispatch(setSidebarVisibility())}
              sx={{
                height: '40px', width: '40px'
              }}>
              {sidebarOpened && <ArrowBackIosIcon sx={{ color: '#004d81', position: 'absolute', left: '12px' }} />}
              {!sidebarOpened && <ArrowForwardIosIcon sx={{ color: '#004d81' }} />}
            </IconButton>
          </Box>} */}
          <Stack direction="column">
            {/* {!sidebarOpened && !isTablet && <Divider sx={{ bgcolor: "#fff", mb: '10px' }} />} */}
            <UserProfile user={user} onClick={handleShowProfile} />
            <Divider sx={{ bgcolor: "#fff", mt: '10px' }} />
            <Box position={'relative'}>
              <List>
                <MenuItem
                  open={sidebarOpened}
                  title={'Appointments'}
                  path={"/dashboard/appointments"}
                  show={getUserType() === "staff" ||
                    userCan("viewAllClinicAppointments") ||
                    userCan("createAppointments") ||
                    userCan("rescheduleAppointments") ||
                    userCan("cancelAppointments")}
                  icon={dashboard}
                  iconAlt={"icon_appointments"}
                  onClick={handleMenuOnClick}
                  isDisabled={subscriptionInfo?.is_expired} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Patients'}
                  path={"/dashboard/patients/list"}
                  show={getUserType() === "doctor" ||
                    getUserType() === "inhousedoc" ||
                    (getUserType() === "staff" && userCan("viewPatientsList")) ||
                    userCan("addPatients") ||
                    userCan("editPatient") ||
                    userCan("deletePatient")}
                  icon={person}
                  iconAlt={"icon_patients"}
                  onClick={handleMenuOnClick}
                  isDisabled={subscriptionInfo?.is_expired} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Analytics'}
                  path={"/dashboard/analytics"}
                  show={getUserType() !== "inhousedoc" && userCan("viewAnalytics")}
                  icon={graph}
                  iconAlt={"icon_analytics"}
                  onClick={handleMenuOnClick}
                  isDisabled={subscriptionInfo?.is_expired} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Invoices'}
                  path={"/dashboard/invoices/list"}
                  show={getUserType() !== "inhousedoc" && userCan("viewAllInvoices")}
                  icon={file_invoice_dollar}
                  iconAlt={"icon_invoices"}
                  onClick={handleMenuOnClick}
                  isDisabled={subscriptionInfo?.is_expired} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Quote'}
                  path={"/dashboard/quote/list"}
                  show={getUserType() !== "inhousedoc" && userCan("createQuotations")}
                  icon={peso_line}
                  iconAlt={"icon_quote"}
                  onClick={handleMenuOnClick}
                  isDisabled={subscriptionInfo?.is_expired} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Settings'}
                  path={"/dashboard/settings"}
                  show={true}
                  icon={gear}
                  iconAlt={"icon_settings"}
                  onClick={handleMenuOnClick} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Support'}
                  path={"/dashboard/support"}
                  show={true}
                  icon={headset_solid}
                  iconAlt={"icon_support"}
                  onClick={handleMenuOnClick} />
                <MenuItem
                  open={sidebarOpened}
                  title={'Logout'}
                  path={"/logout"}
                  show={true}
                  icon={logout}
                  iconAlt={"icon_logout"}
                  onClick={() => handleLogout()} />
                {/* {SideBarData.map((item, index) => (
              <ListItem
                key={index}
                component={Link}
                to={item.path}
                sx={{
                  color: "white",
                  display: item.is_show ? "flex" : "none",
                }}
              >
                {item.title === "Logout" ? (
                  <ListItemButton>
                    <ListItemIcon sx={{ color: "white" }}>
                      <img src={item?.icon} alt={item.title} width={30} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: "bold"
                          }}
                          onClick={() => handleLogout()}
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton>
                    <ListItemIcon sx={{ color: "white" }}>
                      <img src={item?.icon} alt={item.title} width={30} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: "bold"
                          }}
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))} */}
              </List>
            </Box>
          </Stack>
        </Box >
      </ClickAwayListener>
      {showStaffProfileInformation && (
        <StaffProfileInformation
          openModal={showStaffProfileInformation}
          closeModal={() => setShowStaffProfileInformation(false)}
        />
      )
      }

      {
        showProfileInformation && (
          <ProfileInformation
            openModal={showProfileInformation}
            closeModal={() => setShowProfileInformation(false)}
          />
        )
      }
    </>
  );
}
