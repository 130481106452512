import React from "react";
import { invoice } from "../data";
import { useState } from "react";
import InvoiceStatusModal from "../Modals/InvoiceStatusModal";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  fetchInvoiceData,
  totalPreviousBalance,
  fetchPatientDetails,
} from "../../Pages/Invoices/Redux/invoiceSlice";
import {
  TableContainer,
  TableCell,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import moment from "moment";
import { invoicesBadgeStyle } from "../Modals/PatienInformationModal/style";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";
import { userCan } from "../../Utils/utils";

const tableHeader = {
  fontWeight: "bold",
  backgroundColor: "#f4f6f8",
};

const InvoicesTable = () => {
  const { isFetching, invoices } = useSelector((store) => store.patient);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const StatusBadge = (props) => {
    let backgroundColor;
    switch (props?.status) {
      case "partial":
        backgroundColor = "#dec18b";
        break;
      case "paid":
        backgroundColor = "#3cb63e";
        break;
      case "unpaid":
        backgroundColor = "#ec1027";
        break;
      case "void":
        backgroundColor = "#ec1027";
        break;
    }

    return (
      <Paper sx={invoicesBadgeStyle(backgroundColor)}>{props?.status}</Paper>
    );
  };

  const handleOpenInvoice = async (row) => {
    if (userCan("viewAllInvoices")) {
      const clinic = getAppCurrentActiveClinic();
      Swal.fire({
        icon: "info",
        title: "Fetching Invoice Information",
        text: "Please wait...",
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: async () => {
          Swal.showLoading();
          try {
            const [
              invoiceData,
              invoiceItems,
              invoicePayments,
              patientInfo,
              invoices,
            ] = await Promise.all([
              axios.get(`invoices/${row.id}`),
              axios.get(`invoice_items?invoice_id=${row.id}`),
              axios.get(`invoice/payments/${row.id}`),
              axios.get(`clinic_patients/${row.patient_id}`),
              axios.get(
                `invoices?clinic_id=${clinic.id}&patient_id=${row.patient_id}`
              ),
            ]);

            const totalprevbalance = invoices.data
              .filter((rows) => rows.status !== "paid" && rows.id !== row.id)
              .map((rows) => rows.balance);
            dispatch(
              totalPreviousBalance(
                invoices.data.length > 1 ? totalprevbalance : []
              )
            );
            dispatch(fetchPatientDetails(patientInfo.data));
            dispatch(
              fetchInvoiceData({
                invoiceDetails: invoiceData.data,
                invoiceItemDetails: invoiceItems.data,
                invoicePayments: invoicePayments.data,
                isFromInvoiceList: true,
              })
            );
            Swal.close();
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Failed to fetch invoice info",
              text: "Try again.",
            });
          }
        },
      });
    }
  };

  return (
    <>
      {!isFetching ? (
        <>
          <TableContainer sx={{ height: 450 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeader}>Date</TableCell>
                  <TableCell sx={tableHeader}>Full Amount</TableCell>
                  <TableCell sx={tableHeader}>Balance</TableCell>
                  <TableCell sx={tableHeader}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((app, index) => {
                    const date = moment(app?.created_at);
                    return (
                      <TableRow
                        hover
                        key={index}
                        onClick={() => handleOpenInvoice(app)}
                        sx={{
                          "&:hover:not(.Mui-disabled)": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell>
                          {moment(date).format("MMM DD, yyyy")}
                        </TableCell>
                        <TableCell>{app?.total_price?.toFixed(2)}</TableCell>
                        <TableCell>
                          {app?.balance?.toFixed(2)}
                          {app?.overpayment > 0 ? (
                            <span style={{ color: "green" }}>
                              {" "}
                              (-{app.overpayment?.toFixed(2)})
                            </span>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell width={"90px"}>
                          <StatusBadge status={app?.status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <InvoiceStatusModal />
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={invoice.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"} marginTop={"30px"}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default InvoicesTable;
