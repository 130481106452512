import React, { useEffect, useState } from "react";
import PatientRecordModal from "../../Components/Modals/PatientRecordModal";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Stack,
  Button,
  TextField,
  CircularProgress,
  Divider,
  Tooltip,
  Grid,
} from "@mui/material";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";
import PatientsTableV2 from "../../Components/Tables/PatientsTableV2";
import {
  patientRecordModal,
  refreshPatientTableV2,
} from "./Redux/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ListView from "./View/list-view";
import GridView from "./View/grid-view";
import SearchIcon from "@mui/icons-material/Search";
import { userCan } from "../../Utils/utils";

// ----------------------------------------------Sort --------------------------------------

const Patients = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { isOpen, isPatientTableV2Changed } = useSelector(
    (store) => store.patient
  );
  const dispatch = useDispatch();
  const clinic = getAppCurrentActiveClinic();

  const [clinicPatients, setClinicPatients] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isView, setIsView] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [isGrid, setIsGrid] = useState(false);
  const [isList, setIsList] = useState(true);
  const [variant, setVariant] = useState("");

  const fetchClinicPatients = async () => {
    setIsFetching(true);
    const response = await axios.get(`clinic_patients?clinic_id=${clinic?.id}`);
    setClinicPatients(response.data);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchClinicPatients();
  }, []);

  useEffect(() => {
    if (!isOpen && isPatientTableV2Changed) {
      //refresh patient table v2 when updating patient information in view profile modal
      dispatch(refreshPatientTableV2(false));
      fetchClinicPatients();
    }
  }, [isOpen, isPatientTableV2Changed]);

  const handleClose = () => {
    // setIsOpen(false);
    fetchClinicPatients();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      const findPatient = clinicPatients
        .map((row) => {
          return row;

          // ako sa g comment kay pag mag search ka sa patient then pag click nimo sa selected patient kay dli mo gawas iyahang info - DONGSKIE

          // return {
          //   firstname: row.firstname,
          //   lastname: row.lastname,
          // };
        })
        .filter((row) => {
          const lastName = `${row.lastname}`.toLowerCase();
          const firstName = `${row.firstname}`.toLowerCase();
          const searchLetter = searchTerm.toLowerCase();
          return (
            lastName.startsWith(searchLetter) ||
            firstName.startsWith(searchLetter)
          );
        });

      setSearchResult(findPatient);
    } else {
      setClinicPatients(clinicPatients);
    }
  }, [searchTerm]);

  return (
    <Stack direction={`column`} spacing={5}>
      <Stack
        direction={{ xs: `column`, md: `row` }}
        justifyContent={`space-between`}
        alignItems={!matches && `center`}
        spacing={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size={!matches ? "medium" : "small"}
          fullWidth
          value={searchTerm}
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          sx={{
            width: { xs: `100%`, md: `24%` },
            minWidth: { xs: `100%`, md: `24%` },
          }}
          inputProps={{
            autocomplete: "off",
          }}
        />

        <Stack direction={`row`} spacing={1}>
          {userCan("addPatients") && (
            <Button
              variant="contained"
              fullWidth
              size={!matches ? "large" : "small"}
              onClick={() => {
                dispatch(
                  patientRecordModal({
                    visible: true,
                    patientInfo: {},
                  })
                );
              }}
              startIcon={<AddIcon />}
            >
              Add New Patient
            </Button>
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack direction={`column`} spacing={2}>
        <Stack
          direction={`row`}
          spacing={2}
          justifyContent={`flex-end`}
          alignItems={`flex-end`}
        >
          <Tooltip title="Grid View">
            <Button
              fullWidth={!matches ? false : true}
              variant={isGrid ? "contained" : "outlined"}
              onClick={() => {
                setIsView(false);
                setIsGrid(true);
                setIsList(false);
              }}
            >
              <GridViewIcon />
            </Button>
          </Tooltip>

          <Tooltip title="List View">
            <Button
              fullWidth={!matches ? false : true}
              variant={isList ? "contained" : "outlined"}
              onClick={() => {
                setIsView(true);
                setIsList(true);
                setIsGrid(false);
              }}
            >
              <ViewListIcon />
            </Button>
          </Tooltip>
        </Stack>
        {isFetching ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ px: 20, py: 20 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          // <PatientsTableV2 clinicPatients={clinicPatients} />
          <>
            {isView ? (
              <ListView
                clinicPatients={searchTerm ? searchResult : clinicPatients}
              />
            ) : (
              <GridView
                clinicPatients={searchTerm ? searchResult : clinicPatients}
              />
            )}
          </>
        )}
      </Stack>

      <PatientRecordModal handleClose={handleClose} />
    </Stack>
    // </Box>
  );
};

export default Patients;
