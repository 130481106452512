import { Box, Divider, Typography } from "@mui/material";
import { ConflictScheduleStyles } from "../styles";
import moment from "moment";

const styles = ConflictScheduleStyles;

function TimeConflict({ data }) {
    return (
        data && data.length > 0 && <>
            <Typography color='themeColor.error' sx={styles.conflictText}>
                The chosen time conflicts with:
            </Typography>
            {data.map((value, index) => {
                const fullName = `${value.firstname} ${value.lastname}`;
                const timeStart = moment(value.time_start).format('hh:mm A');
                const timeEnd = moment(value.time_end).format('hh:mm A');
                return (
                    <Typography key={index} color='themeColor.error' sx={styles.conflictText}>
                        {`${fullName} ${timeStart} - ${timeEnd}`}
                    </Typography>
                )
            })}
        </>
    )
}

function DoctorConflict({ data }) {
    return (
        data && data.length > 0 && <>
            <Box sx={styles.docConflictContainer}>
                <Typography color='themeColor.error' sx={styles.conflictText}>
                    The chosen time conflicts with doctors appointment:
                </Typography>
                {data.map((value, index) => {
                    const fullName = `${value.firstname} ${value.lastname}`;
                    const timeStart = moment(value.time_start).format('hh:mm A');
                    const timeEnd = moment(value.time_end).format('hh:mm A');
                    return (
                        <Typography key={index} color='themeColor.error' sx={styles.conflictText}>
                            {`${fullName} ${timeStart} - ${timeEnd}`}
                        </Typography>
                    )
                })}
            </Box>
        </>
    )
}

export default function ConflictSchedule({ conflicts }) {
    return (
        conflicts.showConflicts && <>
            <Divider />
            <Box sx={styles.container}>
                <TimeConflict data={conflicts.timeConflicts} />
                <DoctorConflict data={conflicts.docConflicts} />
            </Box>
        </>
    );
}