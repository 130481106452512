import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { CalendarContainerStyles } from "../styles";

const styles = CalendarContainerStyles;

const CalendarProgress = () => {
    return (
        <Box sx={styles.progressBox}>
            <CircularProgress />
        </Box>
    );
}

export default function CalendarContainer({ children }) {
    const { requestStatus } = useSelector((store) => store.appointment);
    return (
        <Box sx={styles.container}>
            {requestStatus.appointment && <CalendarProgress />}
            {children}
        </Box>
    );
}