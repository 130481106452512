// import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/Auth/Login";
import PageNotFound from "./layouts/page-not-found-layout";
import Appointments from "./Pages/Appointments";
import Patients from "./Pages/Patients";
import Analytics from "./Pages/Analytics";
import Invoices from "./Pages/Invoices";
import Quote from "./Pages/Quote";
import Settings from "./Pages/Settings";
import Support from "./Pages/Support";

import { useRoutes, Navigate } from "react-router";
import DashboardGuard from "./guards/dashboard-guard";
import GuestGuard from "./guards/guest-guard";
import GuestLayout from "./layouts/guest-layout";
import RegisterClinic from "./Pages/Auth/Register";
import ProcessInvite from "./Pages/Auth/Invite";
import DoctorRegistration from "./Pages/Auth/Register/doctor-registration";
import DoctorSpecialization from "./Pages/Auth/Register/doctor-specialization";
import ResetPassword from "./Pages/Auth/Reset-Password";
import ForgotPassword from "./Pages/Auth/Forgot-Password";
import DashboardLayout from "./layouts/dashboard-layout";
import { BillingProvider } from "./contexts/billing-context";

const Routes = () => {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "login",
          element: <LoginPage />
        },
        {
          path: "register-clinic",
          element: <RegisterClinic />
        },
        {
          path: "register-doctor/:clinic_id",
          element: <DoctorRegistration />
        },
        {
          path: "register-doctor-specialization/:doctor_id",
          element: <DoctorSpecialization />
        }
      ]
    },
    {
      path: '/',
      element: (
        <DashboardGuard>
          <BillingProvider>
            <DashboardLayout />
          </BillingProvider>
        </DashboardGuard>
      ),
      children: [
        { element: <Navigate to={'/dashboard/appointments'} />, index: true },
        {
          path: 'dashboard',
          children: [
            { element: <Navigate to={'/appointments'} />, index: true },
            {
              path: 'appointments',
              element: <Appointments />,
            },
            {
              path: 'patients',
              children: [
                { element: <Navigate to={'/patients/list'} />, index: true },
                { path: 'list', element: <Patients /> }
              ]
            },
            {
              path: 'analytics',
              element: <Analytics />
            },
            {
              path: 'invoices',
              children: [
                { element: <Navigate to={'/invoices/list'} />, index: true },
                { path: 'list', element: <Invoices /> }
              ]
            },
            {
              path: 'quote',
              children: [
                { element: <Navigate to={'/quote/list'} />, index: true },
                { path: 'list', element: <Quote /> }
              ]
            },
            {
              path: 'settings',
              element: <Settings />
            },
            {
              path: 'support',
              element: <Support />
            },
            { path: "*", element: <PageNotFound /> }
          ]
        }
      ]
    },
    { path: 'invite/:hash', element: <ProcessInvite /> },
    {
      path: 'forgot-password',
      children: [
        { element: <ForgotPassword />, index: true },
        { path: ':hash', element: <ResetPassword /> }
      ]
    },
    { path: "*", element: <Navigate to="/auth/login" replace /> }
  ]);
}

export default Routes;
