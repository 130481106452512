import React, { useEffect, useState } from "react";
import {
  Stack,
  Select,
  MenuItem,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Divider,
  Box,
} from "@mui/material";
import LineChart from "../Graph/LineChart";
import ProgressBar from "../Graph/ProgressBar";
import { axiosInstance as axios } from "../../../Utils/AxiosHttp";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
const Appointments = () => {
  const [state, setState] = useState();

  const clinic = getAppCurrentActiveClinic();

  const loadData = async () => {
    try {
      await axios
        .get(`stats/appointments?clinic_id=${clinic?.id}`)
        .then(function (response) {
          if (response.status === 200) {
            setState(response?.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  function PercentageCalculation(x, total_appt) {
    let res = 0;

    if (x && total_appt) {
      res = (x / total_appt) * 100;
    }

    return res;
  }

  let value = [];
  let labels = [];

  labels = state?.appointments_monthly.map((i) => {
    return i.month;
  });
  value = state?.appointments_monthly.map((i) => {
    return i.total;
  });

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={6}>
              <Stack direction={"column"}>
                <Paper elevation={2} sx={{ padding: 2 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography sx={{ fontWeight: "bold" }} variant="h6">
                      Rescheduled Appointment
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {state?.appointments_rescheduled}
                    </Typography>
                  </Stack>

                  <Typography variant="subtitle1">
                    From {state?.appointments_total_30D} Appointments within
                    this month
                  </Typography>
                  <ProgressBar
                    value={PercentageCalculation(
                      state?.appointments_rescheduled,
                      state?.appointments_total_30D
                    )}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="subtitle1">Reschedule Rate</Typography>
                    <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
                      {PercentageCalculation(
                        state?.appointments_rescheduled,
                        state?.appointments_total_30D
                      ).toFixed(2)}
                      %
                    </Typography>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Stack direction={"column"}>
                <Paper elevation={2} sx={{ padding: 2 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography sx={{ fontWeight: "bold" }} variant="h6">
                      Missed Appointments
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {state?.appointment_missed}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle1">
                    From {state?.appointments_total_30D} Appointments within
                    this month
                  </Typography>
                  <ProgressBar
                    value={PercentageCalculation(
                      state?.appointment_missed,
                      state?.appointments_total_30D
                    )}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="subtitle1">
                      No-Show & Cancellation Rate
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {PercentageCalculation(
                        state?.appointment_missed,
                        state?.appointments_total_30D
                      ).toFixed(2)}
                      %
                    </Typography>
                  </Stack>
                </Paper>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                ></Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Paper elevation={2} sx={{ padding: 1 }}>
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Monthly Appointments
            </Typography>
            <Divider />
            <Box>
              <LineChart labels={labels} value={value} legend="Appointment" />
            </Box>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default Appointments;
