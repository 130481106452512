import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { numberWithCommas } from "../../Utils/utils";

const PatientInvoice = ({ invoiceItems, invoiceData }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Procedure / Product
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Quantity
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Price
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", backgroundColor: "#f4f6f8" }}
            >
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItems.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">
                <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                  {row.name}
                </Typography>
                <Typography variant="subtitle2">{row.description}</Typography>
              </TableCell>
              <TableCell align="center">{row.quantity}</TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">Php {row.price}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} colSpan={2} />
            <TableCell align="left" colSpan={1}>
              <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                Subtotal:
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
                Php {numberWithCommas(invoiceData.total_price)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" colSpan={1}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "700", color: "#ed4763" }}
              >
                Discount:
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
                {invoiceData.discount_type == "pct" ? "" : "Php"}{" "}
                {numberWithCommas(invoiceData.discount)}
                {invoiceData.discount_type == "pct" ? "%" : ""}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" colSpan={1}>
              <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                Total:
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Stack direction={`row`} justifyContent={`flex-end`} spacing={1}>
                <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
                  {invoiceData &&
                    invoiceData.discount > 0 &&
                    invoiceData.discount_type == "pct" && (
                      <>Incl. {invoiceData.discount}% Discount</>
                    )}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "700", color: "#ed4763" }}
                >
                  {<>Php {numberWithCommas(invoiceData.discounted_price)}</>}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientInvoice;
