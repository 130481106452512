import moment from "moment";
import { getAppCurrentActiveClinic } from "../../../Utils/authStored";
import { SQLDateToLocale } from "../../../Utils/utils";

export const AppointmentPusherEvents = {
  addAppointmentEvent: 'addAppointmentEvent',
  //No show and Cancel Appointment
  editAppointmentEvent: 'editAppointmentEvent',
  //Reschedule Appointment
  editAppointmentProcEvent: 'editAppointmentProcEvent',
  //Adding Appointment Procedure 
  addAppointmentProcEvent: 'addAppointmentProcEvent',
  editClinicEvent: 'editClinicEvent',
  addInvoiceEvent: 'addInvoiceEvent',
}

export const CalendarViews = {
  month: "dayGridMonth",
  week: "timeGridWeek",
  day: "timeGridDay",
};

export const AppointmentStatuses = {
  rescheduled: "rescheduled",
  scheduled: "scheduled",
  done: "done",
  mia: "mia",
  cancelled: "cancelled",
};

function AppointmentUtils(appointments, patients) {
  this.appointments = appointments ?? [];
  this.patients = patients ?? [];
  this.getAppointmentsByDate = function (date) {
    return this.appointments.filter(
      (f) =>
        moment(f.time_start).format("L") === moment(date).format("L") &&
        f.status !== AppointmentStatuses.rescheduled && f.status !== AppointmentStatuses.done
    );
  };

  this.getAppointmentsViewData = function (viewType, date) {
    const selectedDate = moment(date);
    let appointments;
    switch (viewType) {
      case CalendarViews.month:
        selectedDate.set("date", 1);
        const endDate = selectedDate.clone().set({
          date: selectedDate.clone().endOf("month").date(),
          hour: "23",
          minute: "59",
        });
        appointments = this.appointments.filter((f) => {
          return (
            moment(f.time_start).isSameOrAfter(selectedDate) &&
            moment(f.time_end).isSameOrBefore(endDate)
          );
        });
        break;
      case CalendarViews.week:
        const startDayWeek = selectedDate.clone().subtract(3, "days");
        const lastDayWeek = selectedDate.clone().add(3, "days").set({
          hour: "23",
          minute: "59",
        });
        appointments = this.appointments.filter((f) => {
          return (
            moment(f.time_start).isSameOrAfter(startDayWeek) &&
            moment(f.time_end).isSameOrBefore(lastDayWeek)
          );
        });
        break;
      case CalendarViews.day:
        appointments = this.appointments.filter((f) => {
          return (
            moment(f.time_start).format("L") === selectedDate.format("L") ||
            moment(f.time_end).format("L") === selectedDate.format("L")
          );
        });
        break;
      default:
        appointments = [];
        break;
    }

    appointments = appointments.filter(
      (f) => f.status !== AppointmentStatuses.rescheduled
    );

    const clinicDoctors = getAppCurrentActiveClinic().users;
    const removedDups = appointments.reduce(
      (items, item) =>
        items.find((f) => f.patient_id === item.patient_id)
          ? items
          : items.concat([
            { ...item, patientName: `${item.lastname} ${item.firstname}` },
          ]),
      []
    );
    removedDups.sort((a, b) => (a.patientName > b.patientName ? 1 : -1));
    return removedDups.map((value) => {
      let assignedDoctor = "No assigned dentist";
      let avatar = process.env.REACT_APP_API_URL+'/assets/avatar';

      const doctors = clinicDoctors.filter((f) => f.id === value.doctor_id);
      if (doctors.length > 0) {
        assignedDoctor = doctors[0].name;
      }

      const patients = this.patients.filter((f) => f.id === value.patient_id);
      if (patients.length > 0) {
        avatar = patients[0].avatar;
      }

      return {
        id: value.id,
        avatar: avatar,
        patientName: value.patientName,
        doctorid: value.doctor_id,
        doctor: assignedDoctor,
      };
    });
  };

  this.getAppointment = function (id, byPatientID = false) {
    const clinicDentists = getAppCurrentActiveClinic().users;
    const appointments = this.appointments.filter((f) =>
      byPatientID ? f.patient_id === id : f.id.toString() === id
    );
    if (appointments.length > 0) {
      let dentist = "No assigned dentist";
      const appointment = appointments[0];
      const dentists = clinicDentists.filter(
        (f) => f.id === appointment.doctor_id
      );

      if (dentists.length > 0) {
        dentist = dentists[0].name;
      }

      const date = moment(appointment.time_start);
      const dayOfWeek = date.format("dddd").substring(0, 3);
      let appointmentTime = "";
      appointmentTime += moment(appointment.time_start).format("hh:mm A");
      appointmentTime += ` - ${moment(appointment.time_end).format("hh:mm A")}`;

      let avatar = process.env.REACT_APP_API_URL+'/assets/avatar';
      const patients = this.patients.filter(
        (f) => f.id === appointment.patient_id
      );
      if (patients.length > 0) {
        avatar = patients[0].avatar;
      }


      return {
        id: appointment.id,
        avatar: avatar,
        patientID: appointment.patient_id,
        name: `${appointment.lastname}, ${appointment.firstname}`,
        date: `${dayOfWeek} ${date.format('MMM DD')}`,
        time: appointmentTime,
        doctorID: appointment.doctor_id,
        dentist: dentist,
        isToday: isToday(appointment.date),
        isDone: appointment.status === AppointmentStatuses.done,
        isMIA: appointment.status === AppointmentStatuses.mia
      }
    }

    return {};
  };
}

function isToday(date) {
  let today = new Date();
  let apptDate = new Date(SQLDateToLocale(date))
  today.setHours(23);
  return apptDate.getTime() < today.getTime();
}

AppointmentUtils.getRawAppointment = function (appointments, id) {
  const filteredAppoint = appointments.filter((f) => f.id === id);
  if (filteredAppoint.length > 0) {
    return {
      ...filteredAppoint[0],
    };
  }

  return null;
};

AppointmentUtils.getEventColor = function (status) {
  switch (status) {
    case AppointmentStatuses.rescheduled:
      return {
        backgroundColor: "#FAFAFA",
        color: "#000000",
      };
    case AppointmentStatuses.scheduled:
      return {
        backgroundColor: "#FAFAFA",
        color: "#000000",
      };
    case AppointmentStatuses.done:
      return {
        backgroundColor: "#def1dd",
        color: "#000000",
      };
    case AppointmentStatuses.mia:
      return {
        backgroundColor: "#ffa3a6",
        color: "#000000",
      };
    default:
      return {
        backgroundColor: "#FAFAFA",
        color: "#000000",
      };
  }
};

AppointmentUtils.mapToEvents = function (appointments) {
  return (appointments ?? [])
    .filter((f) => f.status !== AppointmentStatuses.rescheduled)
    .map((value) => {
      value.walkin = value.walkin === 1;
      return AppointmentUtils.mapToEvent(value);
    });
};

AppointmentUtils.mapToEvent = function (value) {
  return {
    id: value.id,
    patientID: value.patient_id,
    title: `${value.lastname}, ${value.firstname}`,
    start: moment(value.time_start).toISOString(),
    end: moment(value.time_end).toISOString(),
    isWalkin: value.walkin,
    ...AppointmentUtils.getEventColor(value.status)
  };
}

AppointmentUtils.toSQLDateTime = function (date) {
  const dateTime = date ? moment(date) : moment();
  return dateTime.format("yyyy-MM-DD HH:mm:ss");
};

AppointmentUtils.isWalkIn = function (calendarEvents, id) {
  const events = calendarEvents.filter(
    (f) => f.isWalkin && f.id.toString() === id
  );
  return events.length !== 0;
};

AppointmentUtils.getPatientInfo = function (patients, id) {
  const selectedPatient = patients.filter((f) => f.id === id);
  return selectedPatient.length > 0 ? selectedPatient[0] : {};
};

AppointmentUtils.updateRecords = function ({
  patients,
  events,
  appointments,
  patient,
}) {
  const filteredPatients = patients.filter((f) => f.id === patient.id);
  if (filteredPatients.length > 0) {
    const selectedPatient = filteredPatients[0];
    const index = patients.indexOf(selectedPatient);
    patients[index] = patient;
  }

  const filteredEvents = events.filter((f) => f.patientID === patient.id);
  if (filteredEvents.length > 0) {
    filteredEvents.forEach((event) => {
      const index = events.indexOf(event);
      events[index] = {
        ...event,
        title: `${patient.lastname} ${patient.firstname}`,
      };
    });
  }

  const filteredAppointments = appointments.filter(
    (f) => f.patient_id === patient.id
  );
  if (filteredAppointments.length > 0) {
    filteredAppointments.forEach((appointment) => {
      const index = appointments.indexOf(appointment);
      appointments[index] = {
        ...appointment,
        firstname: patient.firstname,
        lastname: patient.lastname,
      };
    });
  }

  return {
    patients,
    events,
    appointments,
  };
};

AppointmentUtils.updateScheduleRecords = function ({
  appointments,
  events,
  payload,
}) {
  const filteredAppoint = appointments.filter((f) => f.id === payload.id);
  if (filteredAppoint.length > 0) {
    const appointment = filteredAppoint[0];
    const index = appointments.indexOf(appointment);
    appointments.push({
      ...appointment,
      id: payload.newID,
      date: payload.date,
      walkin: payload.walkin ? 1 : 0,
      time_start: payload.time_start,
      time_end: payload.time_end,
    });
    appointments[index] = {
      ...appointment,
      status: AppointmentStatuses.rescheduled,
    };

    const filteredEvents = events.filter((f) => f.id === payload.id);
    if (filteredEvents.length > 0) {
      const event = filteredEvents[0];
      events.push({
        ...event,
        id: payload.newID,
        start: moment(payload.time_start).toISOString(),
        end: moment(payload.time_end).toISOString(),
        isWalkin: payload.walkin,
        ...AppointmentUtils.getEventColor(appointment.status),
      });

      events = events.filter((f) => f.id !== payload.id);
    }
  }

  return {
    appointments,
    events,
  };
};

AppointmentUtils.updateAppointmentStatus = function ({
  appointments,
  events,
  payload,
}) {
  const filteredAppoint = appointments.filter((f) => f.id === payload.id);
  if (filteredAppoint.length > 0) {
    let appointment, index;
    switch (payload.status) {
      case AppointmentStatuses.done:
        appointment = filteredAppoint[0];
        index = appointments.indexOf(appointment);
        appointments[index] = {
          ...appointment,
          status: AppointmentStatuses.done,
        };
        break;
      case AppointmentStatuses.cancelled:
        appointments = appointments.filter((f) => f.id !== payload.id);
        break;
      case AppointmentStatuses.mia:
        appointment = filteredAppoint[0];
        index = appointments.indexOf(appointment);
        appointments[index] = {
          ...appointment,
          status: AppointmentStatuses.mia,
        };
        break;
      default:
        break;
    }
  }

  const filteredEvents = events.filter((f) => f.id === payload.id);
  if (filteredEvents.length > 0) {
    let event, index;
    switch (payload.status) {
      case AppointmentStatuses.done:
        event = filteredEvents[0];
        index = events.indexOf(event);
        events[index] = {
          ...event,
          ...AppointmentUtils.getEventColor(payload.status),
        };
        break;
      case AppointmentStatuses.cancelled:
        events = events.filter((f) => f.id !== payload.id);
        break;
      case AppointmentStatuses.mia:
        event = filteredEvents[0];
        index = events.indexOf(event);
        events[index] = {
          ...event,
          ...AppointmentUtils.getEventColor(payload.status),
        };
        break;
      default:
        break;
    }
  }

  return {
    appointments,
    events,
  };
};

AppointmentUtils.updateAppointmentProcedures = function (procedures, procedure) {
  const selectedProc = procedures.find(f => f.id === procedure.id);
  if (selectedProc) {
    const index = procedures.indexOf(selectedProc);
    procedures[index] = {
      ...selectedProc,
      ...procedure,
    }
  }

  return procedures;
}

AppointmentUtils.addAppointmentProcedures = function (procedures, addProcedures) {
  addProcedures.forEach((procedure) => {
    const procedureInfo = procedures.find(f => f.id === procedure.id);
    if (procedureInfo) {
      const index = procedures.indexOf(procedureInfo);
      procedures[index] = {
        ...procedureInfo,
        ...procedure
      }
    }
    else procedures = procedures.concat([procedure]);
  });

  return procedures;
}

AppointmentUtils.setAppointmentPusherEvent = function (pusherEvents, event, isTriggered) {
  switch (event) {
    case AppointmentPusherEvents.addAppointmentEvent:
      pusherEvents.addAppointmentEvent = isTriggered;
      break;
    case AppointmentPusherEvents.editAppointmentEvent:
      pusherEvents.editAppointmentEvent = isTriggered;
      break;
    case AppointmentPusherEvents.addAppointmentProcEvent:
      pusherEvents.addAppointmentProcEvent = isTriggered;
      break;
    case AppointmentPusherEvents.editAppointmentProcEvent:
      pusherEvents.editAppointmentProcEvent = isTriggered;
      break;
    case AppointmentPusherEvents.editClinicEvent:
      pusherEvents.editClinicEvent = isTriggered;
      break;
    case AppointmentPusherEvents.addInvoiceEvent:
      pusherEvents.addInvoiceEvent = isTriggered;
      break;
    default:
      break;
  }

  return pusherEvents;
}

export default AppointmentUtils;
