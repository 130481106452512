import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

const AddServiceModal = React.forwardRef((props, ref) => {
    const defaultVal = {
        name: "",
        procedure_code: "None",
        description: "",
        price: "",
        category_id: 96,
        checked: true,
        children: [],
        enabled: true,
        new_procedure: true,
        has_changes: false
    };
    const [visible, setVisible] = React.useState(false);
    const { control, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: defaultVal
    });

    React.useImperativeHandle(ref, () => {
        return {
            show() {
                setVisible(true);
            }
        }
    });

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setVisible(false);
            setTimeout(() => {
                reset(defaultVal);
            }, 200);
        }
    }

    const isFieldError = (value) => {
        return value === undefined ? false : value !== null && value !== "";
    };

    const onSubmit = (values) => {
        values.price = parseFloat(values.price);
        values.id = `temp_id_${values.name}${values?.description ?? ""}${[values.price]}`.replace(/\s/g, '');
        props.onSubmit(values); 
        handleClose();
    }

    return (
        <Dialog
            open={visible}
            onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "30%"
                    },
                },
            }}>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogTitle variant="h5" sx={{ backgroundColor: "#fafafa" }}>
                    Add Service
                </DialogTitle>
                <DialogContent dividers>
                    <Stack direction={'column'} spacing={2}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: "Please fill out all required fields"
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Name"
                                    multiline
                                    maxRows={2}
                                    variant="standard"
                                    error={isFieldError(errors?.name)}
                                    helperText={errors?.name?.message}
                                    required />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Description"
                                    variant="standard"
                                    multiline
                                    maxRows={3} />
                            )}
                        />
                        <Controller
                            name="price"
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => {
                                        if (!value) return "Please fill out all required fields";
                                        if (parseFloat(value) < 0) return "Invalid Price";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    label="Price"
                                    variant="standard"
                                    error={isFieldError(errors?.price)}
                                    helperText={errors?.price?.message}
                                    required />
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </form >
        </Dialog >
    );
});

export default AddServiceModal;