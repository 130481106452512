import { IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { axiosInstance as axios } from "../../../../Utils/AxiosHttp";
import Swal from "sweetalert2";
import {
  getClinics,
  setAppCurrentActiveClinic,
  setClinics,
} from "../../../../Utils/authStored";
import { getCurrentLoggedInUser } from "../../../../Utils/authStored";
import { setCurrentLoggedInUser } from "../../../../Utils/authStored";
import { useDispatch } from "react-redux";
import { refreshClinicUsers } from "../../Redux/settingsSlice";
import useBilling from "../../../../hooks/useBilling";

const SettingForm = ({ clinic, clinicIcon }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = React.useState(false);

  const { subscriptionInfo } = useBilling();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      address: "",
      branch: "",
      name: "",
      parallelAppts: 0,
    },
  });

  useEffect(() => {
    if (clinic) {
      reset({
        address: clinic?.address,
        branch: clinic?.branch,
        name: clinic?.name,
        parallelAppts: clinic?.parallelAppts,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleCaterPatient = (action) => {
    if (!submitting) {
      const isIncrement = action === "increment";
      let parallAppts = getValues("parallelAppts");
      if (parallAppts > 0) {
        setValue("parallelAppts", isIncrement ? ++parallAppts : --parallAppts);
      }
    }
  };

  const isFieldError = (value) => {
    return value === undefined ? false : value !== null && value !== "";
  };

  const onSubmit = async (payload) => {
    // setSubmitting(true);

    const response = await axios.put(`clinics/${clinic.id}`, payload);
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Clinic info updated!",
      });

      //update clinics
      const clinics = getClinics();
      const filteredClinics = clinics.filter((f) => f.id === clinic.id);
      if (filteredClinics.length > 0) {
        let clinicInfo = filteredClinics[0];
        const clinicIndex = clinics.indexOf(clinicInfo);
        clinics[clinicIndex] = {
          ...clinicInfo,
          ...payload,
        };

        setClinics(clinics);
      }

      dispatch(refreshClinicUsers(true));

      const authUser = getCurrentLoggedInUser();
      authUser.clinics = clinics;
      setCurrentLoggedInUser(authUser);
      if (clinicIcon.current !== undefined) {
        payload = {
          entity: "clinics",
          entity_id: clinic?.id,
          base64_image: clinicIcon?.current?.base64_image,
          size: clinicIcon?.current?.size,
          title: "icon",
        };
        await axios.post("attachments", payload).then(function (response) {
          const newIcon = { ...clinic };
          newIcon.icon = response.data.url;
          setAppCurrentActiveClinic({
            ...clinic,
            ...payload,
            ...newIcon,
          });
        });
      } else {
        setAppCurrentActiveClinic({
          ...clinic,
          ...payload,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to update Clinic info!",
      });
    }

    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={`column`} spacing={2}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "Please fill out all required fields",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={submitting}
              error={isFieldError(errors?.name)}
              helperText={errors?.name?.message}
              label="Clinic Name"
            />
          )}
        />
        <Controller
          name="branch"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={submitting}
              label="Branch Name (optional)"
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          rules={{
            required: "Please fill out all required fields",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={submitting}
              error={isFieldError(errors?.address)}
              helperText={errors?.address?.message}
              label="Clinic Address"
            />
          )}
        />
        <Paper
          elevate={1}
          sx={{
            backgroundColor: "#FAF2B3",
          }}
        >
          <Stack
            direction={`column`}
            spacing={1}
            justifyContent={`center`}
            alignItems={`center`}
            sx={{
              py: 5,
              px: 5,
            }}
          >
            <Typography variant={`body1`}>
              How many patients can you cater at a time in this clinic
            </Typography>
            <Stack direction={`row`} spacing={1}>
              <IconButton onClick={() => handleCaterPatient("decrement")}>
                <RemoveIcon />
              </IconButton>

              <Controller
                name="parallelAppts"
                control={control}
                rules={{
                  required: "Please fill out all required fields",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled
                    type={`number`}
                    sx={{
                      ".MuiInputBase-input": {
                        backgroundColor: "#ffffff",
                        paddingX: "5px",
                      },
                    }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={() => {}}
                  />
                )}
              />

              <IconButton onClick={() => handleCaterPatient("increment")}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Paper>

        <Stack
          direction={{ sm: `column`, md: `row` }}
          justifyContent={{ sm: `center`, md: `flex-end` }}
        >
          <LoadingButton
            type="submit"
            loading={submitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={subscriptionInfo?.is_expired}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default SettingForm;
