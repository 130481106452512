import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Utils/Redux/store";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ThemeProvider from "./theme";
import { HelmetProvider } from "react-helmet-async";
import PusherJS from "./Utils/Pusher";
import SizeScreenChecker from "./layouts/screen-size-layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              {/* <SizeScreenChecker> */}
                <App />
              {/* </SizeScreenChecker> */}
              <PusherJS />
            </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  </Provider>
);

reportWebVitals();
