import React from "react";
import AppointmentCalendar from "./Components/AppointmentCalendar";
import ViewPatientDialog from "./Components/Dialog/ViewPatientsDialog";
import { axiosInstance as axios } from '../../Utils/AxiosHttp';
import { useDispatch, useSelector } from "react-redux";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";
import { appointmentStore, clearSchedulingPatient, setAppointmentPusherEvent, setAppointments, setPatients, setRequestStatus } from "./Redux/appointmentSlice";
import CalendarContainer from "./Components/CalendarContainer";
import Swal from "sweetalert2";
import { AppointmentPusherEvents } from "./Redux/AppointmentUtils";
import AppointmentSnackbar from "./Components/AppointmentSnackbar";

export default function Appointments() {
  const { pusherEvents: { addAppointmentEvent, editAppointmentEvent, editAppointmentProcEvent, editClinicEvent } } = useSelector(appointmentStore);
  const dispatch = useDispatch();
  const patientDialogRef = React.useRef();
  const appointmentSnackbarRef = React.useRef();

  React.useEffect(() => {
    initService();
    // eslint-disable-next-line
    return () => {
      dispatch(clearSchedulingPatient());
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (addAppointmentEvent || editAppointmentEvent || editAppointmentProcEvent) {
      handleAppointmentEvent();
    }

  }, [addAppointmentEvent, editAppointmentEvent, editAppointmentProcEvent]);

  const handleAppointmentEvent = async () => {
    const clinic = getAppCurrentActiveClinic();
    appointmentSnackbarRef.current?.show({
      severity: 'info',
      message: 'Changes in appointment/s is detected! Refreshing data...'
    });

    try {
      const [appointmentRes, patientsRes] = await Promise.all([
        axios.get(`appointments?clinic_id=${clinic.id}`),
        axios.get(`clinic_patients?clinic_id=${clinic.id}`),
      ]);
      dispatch(setAppointments(appointmentRes.data));
      dispatch(setPatients(patientsRes.data));

      appointmentSnackbarRef.current?.show({
        severity: 'success',
        message: 'Calendar appointment/s is succesfully updated!',
        duration: 2000
      });
    } catch (_) {
      appointmentSnackbarRef.current?.show({
        severity: 'success',
        message: 'Failed to fetch appointment data',
        duration: 2000
      });
    }

    if (addAppointmentEvent) dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.addAppointmentEvent, isTrigger: false }));
    else if (editAppointmentEvent) dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editAppointmentEvent, isTrigger: false }));
    else if (editAppointmentProcEvent) dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editAppointmentProcEvent, isTrigger: false }));
  }

  React.useEffect(() => {
    if (editClinicEvent && appointmentSnackbarRef.current) {
      appointmentSnackbarRef.current?.show({
        severity: 'info',
        message: 'Changes in Clinic Info is detected! Refreshing data...',
        duration: 2000
      });
      if (editClinicEvent) dispatch(setAppointmentPusherEvent({ event: AppointmentPusherEvents.editClinicEvent, isTrigger: false }));
    }
  }, [editClinicEvent]);

  const appointmentReqStatus = (status) => dispatch(setRequestStatus({
    request: 'appointment',
    status: status
  }));

  const initService = async () => {
    const clinic = getAppCurrentActiveClinic();
    appointmentReqStatus(true);
    try {
      const [appointmentRes, patientsRes] = await Promise.all([
        axios.get(`appointments?clinic_id=${clinic.id}`),
        axios.get(`clinic_patients?clinic_id=${clinic.id}`),
      ]);

      const appointments = appointmentRes.data;
      dispatch(setAppointments(appointments));
      const patients = patientsRes.data;
      dispatch(setPatients(patients));
    } catch (_) {
      Swal.fire({
        icon: 'error',
        title: 'Failed to fetch appointment data'
      });
    }
    // dispatch(setAppointments(SampleAppointments));
    // dispatch(setPatients(SamplePatients));
    appointmentReqStatus(false);
  }

  const eventClickHandler = (args) => {
    patientDialogRef.current.show(args);
  }

  return (
    <>
      <CalendarContainer>
        <AppointmentCalendar onEventClick={eventClickHandler} />
      </CalendarContainer>
      <ViewPatientDialog ref={patientDialogRef} />
      <AppointmentSnackbar ref={appointmentSnackbarRef} />
    </>
  );
};