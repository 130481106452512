import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { getCurrentLoggedInUser, getSpecialization, setSpecialization as setSpec } from "../../../../Utils/authStored";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { updateSpecialization as updateSpec } from "../../../../api/doctor";

const Specialization = ({
    openModal,
    closeModal
}) => {

    const user = getCurrentLoggedInUser();
    const specializations = getSpecialization();
    const [isLoading, setIsLoading] = useState(false);
    const [specialization, setSpecialization] = useState(specializations)

    const handleOnChecked = (e, key, row) => {
        setSpecialization(current =>
            current.map(obj => {
                if (obj.id === row.id) {
                    return { ...obj, enabled: e.target.checked };
                }

                return obj;
            }),
        );

    }

    const updateSpecialization = async() => {
        setIsLoading(true)

        const response = await updateSpec(specialization);

        if (response) {
            setIsLoading(false)
            return;
        }

        setIsLoading(false)
    }

    return (
        <Dialog
            open={openModal}
            onClose={closeModal}
            maxWidth={`xl`}
            PaperProps={{
                sx: {
                    top: 15,
                    width: '100vh'
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: "#fafafa" }}>
                <Stack
                    direction={`row`}
                    justifyContent={`space-between`}
                    alignItems={`center`}
                >
                    <Typography variant="h6" sx={{ fontWeight: '700' }}>Specialization</Typography>
                    <CloseIcon
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={closeModal}
                    />
                </Stack>
            </DialogTitle>

            <DialogContent sx={{ backgroundColor: "#fafafa" }}>
                <Stack
                    spacing={2}
                >

                    {
                        specializations.map((row, key) => (
                            <>
                                <Stack
                                    direction={`column`}
                                >
                                    <Stack
                                        direction={`row`}
                                        justifyContent={`space-between`}
                                        alignItems={`center`}

                                    >
                                        <Typography variant={`subtitle1`}>{row.specialization}</Typography>

                                        <Checkbox
                                            defaultChecked={row?.enabled}
                                            name={row.id}
                                            onChange={(e) => handleOnChecked(e, key, row)}
                                        />

                                    </Stack>
                                    <Divider />
                                </Stack>
                            </>
                        ))
                    }
                </Stack>

            </DialogContent>

            <Stack
                direction={`row`}
                justifyContent={`flex-end`}
                alignItems={`flex-end`}
                spacing={2}
                sx={{
                    py: 2,
                    px: 5
                }}
            >
                <LoadingButton
                    variant="contained"
                    color="success"
                    loading={isLoading}
                    onClick={() => updateSpecialization()}
                >
                    Save Changes
                </LoadingButton>
                <Button
                    variant="contained"
                    color="error"
                    onClick={closeModal}
                >
                    Cancel
                </Button>
            </Stack>
        </Dialog>
    )
}

export default Specialization