import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Paper,
  FormHelperText,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AvatarImage from "../AvatarImage";
import { Controller, useForm } from "react-hook-form";
import { axiosInstance as axios } from "../../Utils/AxiosHttp";
import moment from "moment";
import { getAppCurrentActiveClinic } from "../../Utils/authStored";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import {
  patientRecordModal,
  refreshPatientTableV2,
} from "../../Pages/Patients/Redux/patientSlice";
import dayjs from "dayjs";
import {
  addPatient,
  updateAppointmentRecords,
} from "../../Pages/Appointments/Redux/appointmentSlice";

const FieldContainer = ({ isError, isSelect, children }) => (
  <Box
    style={{
      margin: 0,
      marginBottom: !isError ? "28px" : isSelect ? "8px" : "4px",
    }}
  >
    {children}
  </Box>
);

const defaultVal = {
  date: moment().format("yyyy-MM-DD"),
  firstname: "",
  middlename: "",
  lastname: "",
  nickname: "",
  since: "",
  birthdate: "",
  sex: "",
  marital_status: "",
  phone: "",
  address: "",
  occupation: "",
  company: "",
  medical_notes: "",
};

const PatientRecordModal = ({ handleClose }) => {
  const { isOpenPatientRecModal, patientInfo, patientRecModalOptions } =
    useSelector((store) => store.patient);
  const isEdit = patientInfo?.id !== null && patientInfo?.id !== undefined;
  const modalOptions = patientRecModalOptions;
  const dispatch = useDispatch();

  const patientProfPic = React.useRef(null);
  const modifPatientInfoRef = React.useRef({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultVal,
  });

  const isFieldError = (value) => {
    return value === undefined ? false : value !== null && value !== "";
  };

  React.useEffect(() => {
    if (patientInfo?.id) {
      const info = {
        date: moment().format("yyyy-MM-DD"),
        firstname: patientInfo.firstname,
        middlename: patientInfo?.middlename ?? "",
        lastname: patientInfo.lastname,
        nickname: patientInfo?.nickname ?? "",
        since: patientInfo?.since ?? "",
        birthdate: dayjs(moment(patientInfo.birthdate).format("yyyy-MM-DD")),
        sex: patientInfo.sex,
        marital_status: patientInfo.marital_status,
        phone: patientInfo.phone,
        address: patientInfo?.address ?? "",
        occupation: patientInfo?.occupation ?? "",
        company: patientInfo?.company ?? "",
        medical_notes: "",
      };
      reset(info);
    } else {
      reset(defaultVal);
    }
    // eslint-disable-next-line
  }, [patientInfo]);

  const onClose = (isCancel) => {
    const payload = {
      visible: false,
      patientInfo: modifPatientInfoRef.current,
    };

    if (!isCancel) {
      patientProfPic.current = null;
      reset(defaultVal);
    } else {
      delete payload.patientInfo;
    }

    dispatch(patientRecordModal(payload));

    if (!isCancel && handleClose && !modalOptions?.isViewProfile) handleClose();
  };

  const modalOnClose = (_, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };

  const onImageLoaded = async (payload) => {
    patientProfPic.current = payload;
  };

  const uploadPatient = async (values) => {
    const clinic = getAppCurrentActiveClinic();
    values.birthdate = moment(values.birthdate.$d).format("yyyy-MM-DD");
    values.clinic_id = clinic.id;

    let patientResponse;
    try {
      if (isEdit) {
        patientResponse = await axios.put(
          `patients/${patientInfo?.id}`,
          values
        );
      } else {
        patientResponse = await axios.post(`patients`, values);
      }
    } catch (_) {
      const error = {
        title: `Failed to ${isEdit ? "Update" : "upload"} patient information`,
        closeModal: false,
      };
      throw error;
    }

    const patientProfile = patientProfPic.current;
    if (patientResponse?.status === 201 || patientResponse?.status === 200) {
      const patient = patientResponse.data;

      if (patientProfile) {
        patientProfile.entity_id = isEdit ? patientInfo?.id : patient.id;
        try {
          Swal.update({
            icon: "info",
            title: `${isEdit ? "Updating" : "Saving"} Patient Profile`,
            showConfirmButton: false,
          });
          Swal.showLoading();
          const response = await axios.post("attachments", patientProfile);
          const attachment = response.data;
          values.avatar = attachment?.url ?? patientInfo?.avatar;
          patient.avatar = values.avatar;
        } catch (_) {
          const error = {
            title: `Failed to ${isEdit ? "Update" : "upload"} patient profile`,
            text: "You can update the patient profile picture using edit patient record feature",
            closeModal: true,
          };
          throw error;
        }
      }

      if (isEdit) {
        let modifPatientInfo = {
          ...patientInfo,
          ...values,
        };

        if (modalOptions?.isViewProfile) {
          modifPatientInfo = {
            ...modifPatientInfo,
            ...{
              fullname: `${values.lastname}, ${values.firstname}`,
              birthdateFormat: moment(values.birthdate).format("M/D/yyyy"),
            },
          };
          dispatch(refreshPatientTableV2(true));
        }

        if (modalOptions?.isViewAppointment) {
          dispatch(updateAppointmentRecords(modifPatientInfo));
        }

        modifPatientInfoRef.current = modifPatientInfo;
      } else {
        if (modalOptions?.isAddPatientAppointment) {
          dispatch(addPatient(patient));
        }
      }
    }
  };

  const onSubmit = async (values) => {
    Swal.fire({
      icon: "info",
      title: `${isEdit ? "Updating" : "Saving"} Patient`,
      text: "Please wait...",
      timerProgressBar: true,
      backdrop: true,
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: () => {
        Swal.showLoading();
        uploadPatient(values).then(
          () => {
            Swal.fire({
              title: !isEdit
                ? "Patient added successfully"
                : "Patient record updated",
              icon: "success",
            }).then(() => {
              Swal.close();
              onClose();
            });
          },
          (e) => {
            Swal.fire({
              title: e.title,
              text: e.text,
              icon: "error",
            }).then(() => {
              if (e.closeModal) onClose();
            });
          }
        );
      },
    });
  };

  return (
    <Dialog
      open={isOpenPatientRecModal}
      disableScrollLock={true}
      onClose={modalOnClose}
      // sx={{
      //   "& .MuiDialog-container": {
      //     "& .MuiPaper-root": {
      //       width: "100%",
      //       maxWidth: "80%",
      //     },
      //   },
      // }}
      // maxWidth={"xl"}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 15,
        },
      }}
      fullScreen={matches}
      maxWidth="xl"
    >
      <DialogTitle
        variant="h5"
        component={"div"}
        sx={{ backgroundColor: "#fafafa" }}
      >
        {modalOptions?.isViewOnly
          ? "Patient Record"
          : isEdit
          ? "Update Patient Record"
          : "Create New Patient Record"}
      </DialogTitle>

      <DialogContent dividers sx={{ backgroundColor: "#fafafa" }}>
        <Stack direction={{ xs: `column`, lg: `row` }} spacing={3}>
          <Paper
            elevation={1}
            sx={{
              backgroundColor: "#fff",
              px: 10,
              py: 5,
              height: "20%",
            }}
          >
            <AvatarImage
              disabled={modalOptions?.isViewOnly ?? false}
              fileData={patientInfo?.avatar}
              onImageLoaded={onImageLoaded}
            />
            <Box sx={{ textAlign: "center", mt: 1.5, color: "#6f7e8b" }}>
              <Typography sx={{ fontSize: "13px" }}>Allowed *.jpeg</Typography>
              <Typography sx={{ fontSize: "13px" }}>
                max size of 31 MB
              </Typography>
            </Box>
          </Paper>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#fff",
                px: 5,
                py: 3,
              }}
            >
              <Stack direction={`column`} spacing={5}>
                <Stack spacing={2} direction={{ xs: `row` }}>
                  <Stack direction={`column`} spacing={2}>
                    <Controller
                      name="firstname"
                      control={control}
                      rules={{
                        required: "Please fill out all required fields",
                      }}
                      render={({ field }) => (
                        <FieldContainer
                          isError={isFieldError(errors?.firstname)}
                        >
                          <TextField
                            {...field}
                            label="First Name"
                            variant="outlined"
                            size="medium"
                            fullWidth
                            disabled={modalOptions?.isViewOnly ?? false}
                            helperText={errors?.firstname?.message ?? ""}
                            error={isFieldError(errors?.firstname)}
                            required
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="nickname"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Nickname"
                            variant="outlined"
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                            fullWidth
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="sex"
                      control={control}
                      rules={{
                        required: "Please fill out all required fields",
                      }}
                      render={({ field }) => (
                        <FieldContainer
                          isError={isFieldError(errors?.sex)}
                          isSelect={true}
                        >
                          <FormControl
                            size="medium"
                            error={isFieldError(errors?.sex)}
                            fullWidth
                            required
                          >
                            <InputLabel>Sex</InputLabel>
                            <Select
                              disabled={modalOptions?.isViewOnly ?? false}
                              {...field}
                              variant="outlined"
                              label="Sex"
                            >
                              <MenuItem value={"male"}>Male</MenuItem>
                              <MenuItem value={"female"}>Female</MenuItem>
                            </Select>
                            <FormHelperText>
                              {errors?.sex?.message ?? ""}
                            </FormHelperText>
                          </FormControl>
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Address"
                            variant="outlined"
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                            fullWidth
                          />
                        </FieldContainer>
                      )}
                    />
                  </Stack>

                  <Stack direction={`column`} spacing={2}>
                    <Controller
                      name="middlename"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="since"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Patient Since"
                            variant="outlined"
                            fullWidth
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="marital_status"
                      control={control}
                      rules={{
                        required: "Please fill out all required fields",
                      }}
                      render={({ field }) => (
                        <FieldContainer
                          isError={isFieldError(errors?.marital_status)}
                          isSelect={true}
                        >
                          <FormControl
                            size="medium"
                            error={isFieldError(errors?.marital_status)}
                            fullWidth
                            required
                          >
                            <InputLabel>Marital Status</InputLabel>
                            <Select
                              {...field}
                              variant="outlined"
                              label="Marital Status"
                              disabled={modalOptions?.isViewOnly ?? false}
                            >
                              <MenuItem value={"single"}>Single</MenuItem>
                              <MenuItem value={"married"}>Married</MenuItem>
                              <MenuItem value={"widowed"}>Widowed</MenuItem>
                            </Select>
                            <FormHelperText>
                              {errors?.marital_status?.message ?? ""}
                            </FormHelperText>
                          </FormControl>
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="occupation"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Occupation"
                            fullWidth
                            variant="outlined"
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                          />
                        </FieldContainer>
                      )}
                    />
                  </Stack>
                  <Stack direction={`column`} spacing={2}>
                    <Controller
                      name="lastname"
                      control={control}
                      rules={{
                        required: "Please fill out all required fields",
                      }}
                      render={({ field }) => (
                        <FieldContainer
                          isError={isFieldError(errors?.lastname)}
                        >
                          <TextField
                            {...field}
                            label="Last Name"
                            variant="outlined"
                            size="medium"
                            fullWidth
                            disabled={modalOptions?.isViewOnly ?? false}
                            helperText={errors?.lastname?.message ?? ""}
                            error={isFieldError(errors?.lastname)}
                            required
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        validate: {
                          required: (value) => {
                            if (!value)
                              return "Please fill out all required fields";
                            const fnum = value.replace(/ /g, "");
                            const isNotValid =
                              (value.startsWith("09") && fnum.length !== 11) ||
                              (value.startsWith("+639") &&
                                fnum.length !== 13) ||
                              (value.startsWith("(") && fnum.length !== 13) ||
                              (fnum.length !== 8 &&
                                !value.startsWith("09") &&
                                value.startsWith("+639") &&
                                !this.state.phone.startsWith("("));

                            if (isNotValid) return "Invalid Phone Number";
                          },
                        },
                      }}
                      render={({ field }) => (
                        <FieldContainer isError={isFieldError(errors?.phone)}>
                          <TextField
                            {...field}
                            label="Phone Number"
                            variant="outlined"
                            size="medium"
                            fullWidth
                            type="number"
                            disabled={modalOptions?.isViewOnly ?? false}
                            helperText={errors?.phone?.message ?? ""}
                            error={isFieldError(errors?.phone)}
                            required
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <FieldContainer>
                          <TextField
                            {...field}
                            label="Company"
                            variant="outlined"
                            fullWidth
                            disabled={modalOptions?.isViewOnly ?? false}
                            size="medium"
                          />
                        </FieldContainer>
                      )}
                    />
                    <Controller
                      name="birthdate"
                      control={control}
                      rules={{
                        required: "Please fill out all required fields",
                      }}
                      render={({ field }) => (
                        <FieldContainer
                          isError={isFieldError(errors?.birthdate)}
                        >
                          <DatePicker
                            {...field}
                            label="Birthday"
                            slotProps={{
                              textField: {
                                size: "medium",
                                disabled: modalOptions?.isViewOnly ?? false,
                                error: isFieldError(errors?.birthdate),
                                helperText: errors?.birthdate?.message ?? "",
                                required: true,
                              },
                            }}
                          />
                        </FieldContainer>
                      )}
                    />
                  </Stack>
                </Stack>

                <Stack
                  direction={{ xs: `column`, md: `row` }}
                  spacing={2}
                  justifyContent={`flex-end`}
                >
                  {!modalOptions?.isViewOnly && (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ fontWeight: "bold" }}
                      disableElevation
                      size="large"
                    >
                      {isEdit ? "Update Record" : "Submit"}
                    </Button>
                  )}

                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={() => onClose(true)}
                    disableElevation
                    size="large"
                    sx={{ fontWeight: "bold" }}
                  >
                    {modalOptions?.isViewOnly ? "Close" : "Cancel"}
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PatientRecordModal;
